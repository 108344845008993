import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  margin: auto;
`;

const Bar = styled.div`
  background-color: ${props => props.theme.colors.grey200};
  padding: ${props => props.theme.spacing.xSmall}
    ${props => props.theme.spacing.base};
  width: ${props => props.width || 100}%;
  margin: ${props => props.theme.spacing.small} 0;
  font-size: ${props => props.theme.fontSizes.xSmall};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  strong {
    color: ${props => props.theme.colors.green500};
  }


  ${props =>
    props.blue &&
    `
    color: ${props.theme.colors.white};
    background-color: ${props.theme.colors.yellow500};

    strong {
      color: ${props.theme.colors.white};
    }
  `}

${props =>
  props.green &&
  `
    color: ${props.theme.colors.white};
    background-color: ${props.theme.colors.green500};
    
    strong {
      color: ${props.theme.colors.white};
    }
  `}


${props =>
  props.red &&
  `
    color: ${props.theme.colors.white};
    background-color: ${props.theme.colors.red500};

    strong {
      color: ${props.theme.colors.white};
    }
  `}
`;

function BarList({ data, calculateWidth, indexBase, hideNumber, ...props }) {
  return (
    <Wrapper>
      {data?.map((item, i) => {
        return (
          <Bar
            key={item.id || item.value || item.label}
            width={
              calculateWidth ? (item.value / data.length) * 100 : item.value
            }
            {...props}
          >
            {!hideNumber && <strong>{i + 1 + indexBase}.</strong>} {item.label}
          </Bar>
        );
      })}
    </Wrapper>
  );
}

BarList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  calculateWidth: PropTypes.bool,
  indexBase: PropTypes.number,
  hideNumber: PropTypes.bool,
};

BarList.defaultProps = {
  calculateWidth: true,
  indexBase: 0,
  hideNumber: false,
};

export default BarList;
