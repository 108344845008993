import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useQuery, useApolloClient } from '@apollo/react-hooks';

import Select from './Select';

import { GET_CURRENCY } from '../graph/currency';

import geoLocationPosition from '../utils/geoLocatePosition';
import CURRENCIES from '../config/currency';

const Wrapper = styled.div``;

function CurrencySelector() {
  const client = useApolloClient();
  const {
    data: { currency, geolocated },
  } = useQuery(GET_CURRENCY);

  const handleChangeCurrency = (e) => {
    client.cache.writeQuery({
      query: GET_CURRENCY,
      data: {
        currency: e.target.value,
        geolocated: true,
      },
    });
  };

  useEffect(() => {
    const handleIpLookup = async () => {
      const { country_code2: countryCode } = await geoLocationPosition();

      if (!countryCode) return;

      client.cache.writeQuery({
        query: GET_CURRENCY,
        data: {
          currency: countryCode === 'US' ? 'USD' : 'GBP',
          geolocated: true,
        },
      });
    };

    if (geolocated) return;
    handleIpLookup();
  }, [client, geolocated]);

  return (
    <Wrapper>
      <Select
        name="currency"
        id="currency"
        options={Object.values(CURRENCIES)}
        value={currency}
        label="Change currency"
        hideLabel
        onChange={handleChangeCurrency}
      />
    </Wrapper>
  );
}

export default CurrencySelector;
