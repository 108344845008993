import React, { useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import Layout from '../components/Layout';
import Container from '../components/Container';
import TextContent from '../components/TextContent';
import Section from '../components/Section';
import FooterBar from '../components/FooterBar';
import ResultContent from './ResultContent';
import ResultVisualisation from './ResultVisualisation';
import PersonalityThemeTable from '../components/PersonalityThemeTable';
import References from '../components/References';
import ContentBlock from '../components/ContentBlock';

import Notification from '../components/Notification';
import Tag from '../components/Tag';

import { GET_RESULTS, UPDATE_RESULT } from '../graph/results';
import { GET_CMS_CONTENT } from '../graph/cms';

import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';

import results from '../config/results';
import { ACTIVITY_STATUS } from '../config/levels';

function Result({ resultId, type }) {
  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data, loading } = useQuery(GET_RESULTS, { variables: { resultId } });

  const [handleUpdateResult] = useMutation(UPDATE_RESULT);

  const resultData = data?.results?.[type];
  const { new: isNew } = resultData || {};

  useEffect(() => {
    if (isNew) {
      handleUpdateResult({
        variables: {
          id: resultId,
          new: false,
        },
      });
    }
  }, [handleUpdateResult, isNew, resultId]);

  const currentIndex = results?.findIndex(({ id }) => id === type);

  const {
    title,
    subtitle,
    content_1: content1,
    content_1_read_more: content1ReadMore,
    content_1_show_visualisation: content1Visualisation,
    content_1_quote: content1Quote,
    content_1_quote_citation: content1QuoteCitation,
    content_2: content2,
    content_2_read_more: content2ReadMore,
    content_2_show_visualisation: content2Visualisation,
    content_2_quote: content2Quote,
    content_2_quote_citation: content2QuoteCitation,
    content_3: content3,
    content_3_read_more: content3ReadMore,
    content_3_show_visualisation: content3Visualisation,
    content_3_quote: content3Quote,
    content_3_quote_citation: content3QuoteCitation,
    content_4: content4,
    content_4_read_more: content4ReadMore,
    content_4_show_visualisation: content4Visualisation,
    content_4_quote: content4Quote,
    content_4_quote_citation: content4QuoteCitation,
    help_text: helpText,
    body: blocks,
    references,
  } = cmsData?.cmsContent?.result?.find(activity => activity.id === type) || {};

  const { level } = results?.[currentIndex];

  const { id: nextType } = results?.[currentIndex + 1] || {};

  const { title: nextTitle } =
    cmsData?.cmsContent?.result?.find(activity => activity.id === nextType) ||
    {};

  const { id: nextId, status: nextStatus } = data?.results?.[nextType] || {};

  const rederVisualisation = i => {
    if (!resultData) return false;

    return (
      <ResultVisualisation
        i={i}
        type={type}
        resultData={resultData}
        cmsData={cmsData}
      />
    );
  };

  const renderContent = () => {
    if (!resultData) return false;
    return (
      <ResultContent type={type} resultData={resultData} cmsData={cmsData} />
    );
  };

  return (
    <Layout
      title={`${extractPrismicString(title)}`}
      loading={loading}
      hasFooterBar
    >
      <Container narrow>
        <Section noPaddingBottom={blocks?.length}>
          {isNew && <Tag marginBottom>New</Tag>}

          <TextContent marginBottom>
            <h1>{extractPrismicString(title)}</h1>
          </TextContent>

          {hasContent(content1) && (
            <TextContent maxHeight={content1ReadMore ? 200 : null} marginBottom>
              {hasContent(content1Quote) && (
                <Notification type="green">
                  <blockquote>{extractPrismicString(content1Quote)}</blockquote>
                  {hasContent(content1QuoteCitation) && (
                    <cite> {extractPrismicString(content1QuoteCitation)}</cite>
                  )}
                </Notification>
              )}

              {content1Visualisation && <aside>{rederVisualisation(1)}</aside>}

              {extractPrismicContent(content1)}
            </TextContent>
          )}

          {hasContent(content2) && (
            <TextContent maxHeight={content2ReadMore ? 200 : null} marginBottom>
              {hasContent(content2Quote) && (
                <Notification type="green">
                  <blockquote>{extractPrismicString(content2Quote)}</blockquote>
                  {hasContent(content2QuoteCitation) && (
                    <cite> {extractPrismicString(content2QuoteCitation)}</cite>
                  )}
                </Notification>
              )}

              {content2Visualisation && <aside>{rederVisualisation(2)}</aside>}

              {extractPrismicContent(content2)}
            </TextContent>
          )}

          {type === 'personality' && (
            <PersonalityThemeTable
              data={resultData?.themes}
              cmsContent={cmsData?.cmsContent}
            />
          )}

          {hasContent(content3) && (
            <TextContent maxHeight={content3ReadMore ? 200 : null} marginBottom>
              {hasContent(content3Quote) && (
                <Notification type="green">
                  <blockquote>{extractPrismicString(content3Quote)}</blockquote>
                  {hasContent(content3QuoteCitation) && (
                    <cite>{extractPrismicString(content3QuoteCitation)}</cite>
                  )}
                </Notification>
              )}

              {content3Visualisation && <aside>{rederVisualisation(3)}</aside>}

              {extractPrismicContent(content3)}
            </TextContent>
          )}

          {hasContent(content4) && (
            <TextContent maxHeight={content4ReadMore ? 200 : null} marginBottom>
              {hasContent(content4Quote) && (
                <Notification type="green">
                  <blockquote>{extractPrismicString(content4Quote)}</blockquote>
                  {hasContent(content4QuoteCitation) && (
                    <cite> {extractPrismicString(content4QuoteCitation)}</cite>
                  )}
                </Notification>
              )}

              {content4Visualisation && <aside>{rederVisualisation(4)}</aside>}

              {extractPrismicContent(content4)}
            </TextContent>
          )}
        </Section>
      </Container>

      {renderContent()}

      {!!blocks && blocks.map(block => <ContentBlock {...block} />)}

      {hasContent(helpText) && (
        <Section smallPadding>
          <Container narrow>
            <Notification blue showUser>
              {extractPrismicContent(helpText)}
            </Notification>
          </Container>
        </Section>
      )}

      {hasContent(references) && (
        <Section smallPadding>
          <Container narrow>
            <References>{extractPrismicContent(references)}</References>
          </Container>
        </Section>
      )}

      <FooterBar
        isNew={isNew}
        title={extractPrismicString(title)}
        subTitle={extractPrismicString(subtitle)}
        primaryButton={
          nextType && {
            label: `Next: ${extractPrismicString(nextTitle)}`,
            to: `/results/${nextType}/${nextId}`,
            level,
            disabled: nextStatus !== ACTIVITY_STATUS.COMPLETED,
          }
        }
        secondaryButton={
          nextStatus !== ACTIVITY_STATUS.COMPLETED &&
          !cmsData?.user?.hasActiveSubscription
            ? {
                label: 'Back to activities',
                to: '/activities',
              }
            : {
                label: 'Back to results',
                to: cmsData?.user?.isAdmin ? null : '/results',
                onClick: cmsData?.user?.isAdmin
                  ? () => navigate(-1)
                  : undefined,
              }
        }
      />
    </Layout>
  );
}

Result.propTypes = {
  resultId: PropTypes.string,
  type: PropTypes.string,
};

Result.defaultProps = {
  resultId: null,
  type: null,
};

export default Result;
