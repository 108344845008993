import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import tagBase from '../theme/tagBase';

import { ReactComponent as RemoveIcon } from '../assets/remove-icon.svg';
import getColorFromLevel from '../utils/getColorFromLevel';
import getColorFromRating from '../utils/getColorFromRating';

import { ACTIVITY_STATUS } from '../config/levels';

const Wrapper = styled.div`
  ${tagBase}

  display: inline-flex;
  align-items: center;

  ${(props) =>
    props.level &&
    `
    background-color: ${getColorFromLevel(props)}
  `}

  ${(props) =>
    props.rating &&
    `
    background-color: ${getColorFromRating(props)}
  `}

  ${(props) =>
    !!(
      props.grey ||
      (props.status &&
        props.status !== ACTIVITY_STATUS.PENDING &&
        props.status !== ACTIVITY_STATUS.COMPLETED)
    ) &&
    `
    background-color: ${props.theme.colors.grey200};
    color: ${props.theme.colors.grey500};
  `}


${(props) =>
    props.light &&
    `
    background-color: ${props.theme.colors.grey200};
    color: ${props.theme.colors.grey500};
  `}

${(props) =>
    props.dark &&
    `
    background-color: ${props.theme.colors.black};
  `}


${(props) =>
    props.onClick &&
    `
    cursor: pointer;
  `}

${(props) =>
    props.marginBottom &&
    `
   margin-bottom:  ${props.theme.spacing.midLarge};
  `}


${(props) =>
    props.marginRight &&
    `
   margin-right:  ${props.theme.spacing.xSmall};
  `}


${(props) =>
    !!(props.status && props.status === ACTIVITY_STATUS.COMPLETED) &&
    `
    background-color: ${props.theme.colors.green500};
  `}

  button {
    margin-left: ${(props) => props.theme.spacing.xSmall};

    ${(props) =>
      props.loading &&
      `
      opacity: 0.3;
      pointer-events: none;
    `}
  }

  svg {
    width: 10px;
    height: 10px;
    fill: ${(props) => props.theme.colors.white};
  }
`;

function Tag({ children, onRemove, loading, onClick, ...props }) {
  return (
    <Wrapper loading={loading} onClick={onClick} {...props}>
      {children}
      {onRemove && (
        <button onClick={onRemove} type="button">
          <RemoveIcon />
        </button>
      )}
    </Wrapper>
  );
}

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  onRemove: PropTypes.func,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

Tag.defaultProps = {
  onRemove: undefined,
  loading: false,
  onClick: undefined,
};

export default Tag;
