import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FieldWrapper from './FieldWrapper';

import fieldBase from '../theme/fieldBase';

const Input = styled.textarea`
  ${fieldBase}
  resize: vertical;
  line-height: 1.4;

  ${props =>
    props.error &&
    `
    border-color: ${props.theme.colors.red500};
  `}
`;

function TextArea({
  name,
  hintText,
  value,
  errorMessage,
  disabled,
  readOnly,
  label,
  id,
  ...props
}) {
  return (
    <FieldWrapper
      errorMessage={errorMessage}
      name={name}
      label={label}
      hintText={hintText}
    >
      <Input
        name={name}
        disabled={disabled}
        readOnly={readOnly}
        rows="10"
        id={id}
        error={!!errorMessage}
        {...props}
      >
        {value}
      </Input>
    </FieldWrapper>
  );
}

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  hintText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  errorMessage: PropTypes.string,
};

TextArea.defaultProps = {
  disabled: false,
  hintText: null,
  readOnly: false,
  value: null,
  errorMessage: null,
};

export default TextArea;
