import React from 'react';
import styled from 'styled-components';

import Container from './Container';
import Section from './Section';
import TextContentMarketing from './TextContentMarketing';
import Button from './Button';

import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';

const Wrapper = styled.div`
  margin-top: -${props => props.theme.components.headerHeightMarketing};
  padding-top: ${props => props.theme.spacing.xLarge};
  width: 100%;
  overflow: hidden;
  background-image: url('/hero-background.jpg');
  background-size: cover;
`;

const Inner = styled.div`
  position: relative;
`;

const TextArea = styled.div`
  ${props => props.theme.breakpoints.large`
    width: 60%;
    padding-right: ${props.theme.spacing.large};
  `}

  h3 {
    color: ${props => props.theme.colors.red500};
  }
`;

const RightArea = styled.div`
  background-image: url('/background-tile.jpg');
  background-size: 500px 500px;
  color: ${props => props.theme.colors.white};
  padding: 0 ${props => props.theme.spacing.midLarge};

  h4 {
    color: ${props => props.theme.colors.white};
  }

  ${props => props.theme.breakpoints.large`
    padding: 0 ${props.theme.spacing.large};
    width: 33.33%;
    position: absolute;
    right: 0;
    top: 0;
  `}

  ${props => props.theme.breakpoints.xLarge`
    padding: 0 ${props.theme.spacing.xLarge};
    width: 33.33%;
    position: absolute;
    right: -${props.theme.spacing.large};
    top: 0;
  `}
`;

function MarketingHeroWithSignup({
  hero_signup_small: small,
  hero_signup_title: title,
  hero_signup_sub_title: subTitle,
  hero_signup_text: text,
  hero_signup_cta_title: ctaTitle,
  hero_signup_cta_text: ctaText,
  hero_signup_button_text: buttonText,
  hero_signup_button_url: buttonUrl,
  ...props
}) {
  return (
    <Wrapper>
      <Section padding={small ? 'small' : 'medium'}>
        <Container>
          <Inner>
            <TextArea>
              <TextContentMarketing {...props}>
                <h1>{extractPrismicString(title)}</h1>
                {hasContent(subTitle) && (
                  <h3>{extractPrismicString(subTitle)}</h3>
                )}

                {extractPrismicContent(text)}
              </TextContentMarketing>
            </TextArea>

            <RightArea>
              <Section padding="small">
                <TextContentMarketing>
                  <h4>{extractPrismicString(ctaTitle)}</h4>
                  {extractPrismicContent(ctaText)}
                </TextContentMarketing>

                {!!(hasContent(buttonText) && hasContent(buttonUrl)) && (
                  <Button
                    whiteMono
                    marginTop
                    href={extractPrismicString(buttonUrl)}
                  >
                    {extractPrismicString(buttonText)}
                  </Button>
                )}
              </Section>
            </RightArea>
          </Inner>
        </Container>
      </Section>
    </Wrapper>
  );
}

MarketingHeroWithSignup.propTypes = {};

export default MarketingHeroWithSignup;
