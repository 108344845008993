import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import throttle from 'lodash.throttle';
import { useQuery, useApolloClient, useMutation } from '@apollo/react-hooks';
import { navigate } from '@reach/router';
import axios from 'axios';

import TextButton from './TextButton';
import UserStatusCircle from './UserStatusCircle';
import Button from './Button';

import { GET_CMS_CONTENT } from '../graph/cms';
import {
  DISCONNECT_USER_LINKEDIN,
  IS_AUTHENTICATED,
  GET_TOKEN,
} from '../graph/auth';

import backgroundImageBase from '../theme/backgroundImageBase';

import { TOTAL_ACTIVITIES } from '../config/levels';

import useSiteSettings from '../hooks/useSiteSettings';

import sleep from '../utils/sleep';

const Wrapper = styled.div`
  ${backgroundImageBase};

  ${(props) => props.theme.breakpoints.medium`
    position: absolute;
    top: 0;
    left: 0;
    width: ${props.theme.components.sidebarWidth};
    bottom: 0;
  `}

  ${(props) =>
    props.isFixed &&
    props.theme.breakpoints.medium`
      position: fixed;
    `}
`;

const Container = styled.aside`
  ${(props) => props.theme.breakpoints.medium`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: ${props.theme.components.sidebarWidth};
  `}
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  height: 100%;
  padding: ${(props) => props.theme.spacing.midLarge}
    ${(props) => props.theme.spacing.base};

  ${(props) => props.theme.breakpoints.medium`
    padding: ${props.theme.spacing.xLarge};
  `}

  h2,
  h6,
  a {
    color: ${(props) => props.theme.colors.white};
  }

  h2 {
    font-family: ${(props) => props.theme.base.altFontFamily};
    font-weight: ${(props) => props.theme.fontWeights.normal};
  }
`;

const Footer = styled.footer`
  ${(props) => props.theme.breakpoints.medium`
    padding: 0;
    left: ${props.theme.spacing.xLarge};
    bottom: ${props.theme.spacing.midLarge};
    width: calc(
      ${props.theme.components.sidebarWidth} -
        ${props.theme.spacing.xLarge} -
        ${props.theme.spacing.xLarge}
    );
    position: fixed;
    z-index: 1;
  `}
`;

function Sidebar({ profile }) {
  const { profileBgImage } = useSiteSettings();

  const client = useApolloClient();

  const { data } = useQuery(GET_CMS_CONTENT, { fetchPolicy: 'cache-only' });
  const [handleDisconnectLinkedIn] = useMutation(DISCONNECT_USER_LINKEDIN);

  const containerRef = useRef(null);
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const getImageExists = async () => {
      if (!data?.user?.profilePicture) return;

      try {
        await axios.get(data?.user?.profilePicture);
      } catch {
        handleDisconnectLinkedIn({
          variables: { id: data?.user?.id },
        });
      }
    };

    getImageExists();
  }, [data.user.profilePicture]); // eslint-disable-line

  const totalCompletedActivities = data?.user?.completedActivities?.reduce(
    (amount, a) => a + amount,
    0,
  );

  const handleScroll = throttle(() => {
    if (!containerRef || !containerRef.current) return;

    const scrollTop = containerRef.current.getBoundingClientRect().top;

    if (scrollTop > 0 && isFixed) {
      setIsFixed(false);
      return;
    }

    if (scrollTop <= 0 && !isFixed) {
      setIsFixed(true);
    }
  }, 8);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  const handleLogOut = async () => {
    await client.clearStore();

    await client.cache.writeQuery({
      query: IS_AUTHENTICATED,
      data: {
        isAuthenticated: false,
      },
    });

    await client.cache.writeQuery({
      query: GET_TOKEN,
      data: {
        accessToken: null,
        refreshToken: null,
      },
    });

    await sleep(1200);

    navigate('/');

    window.location.reload();
  };

  return (
    <Container ref={containerRef}>
      <Wrapper
        isFixed={isFixed}
        style={{ backgroundImage: `url(${profileBgImage})` }}
      >
        <Inner>
          {!profile && <h6>👋 Welcome 👋</h6>}

          <UserStatusCircle
            activitiesComplete={totalCompletedActivities}
            totalActivities={TOTAL_ACTIVITIES}
            level={data?.user?.currentLevel}
            profilePicture={data?.user?.profilePicture}
          />

          <h6>
            {totalCompletedActivities}/{TOTAL_ACTIVITIES} activities completed
          </h6>
          <h2>{data?.user?.name}</h2>

          {!profile && <TextButton to="/profile">Edit profile</TextButton>}
        </Inner>
      </Wrapper>

      <Footer>
        <Button small fullWidth onClick={handleLogOut}>
          Log out
        </Button>
      </Footer>
    </Container>
  );
}

export default Sidebar;
