import { useEffect } from 'react';
import ReactGA from 'react-ga';

const useTrackActivityGA = (type, step) => {
  useEffect(() => {
    if(type && step) {
      ReactGA.pageview(`/activities/${type}/${step}`);
    }
  }, [type, step])
};

export default useTrackActivityGA;
