import React from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { navigate, Link } from '@reach/router';
import Cookies from 'js-cookie';

import Card from '../components/Card';
import LayoutAuth from '../components/LayoutAuth';
import Form from '../components/Form';
import Notification from '../components/Notification';

import { LOGIN, GET_TOKEN, GET_USER, IS_AUTHENTICATED } from '../graph/auth';

import useSiteSettings from '../hooks/useSiteSettings';

import { validate, requiredString, requiredEmail } from '../utils/validate';
import extractGraphQLError from '../utils/extractGraphQLError';

const loginFormFields = [
  {
    id: 'email',
    type: 'email',
    name: 'email',
    label: 'Email Address',
  },

  {
    id: 'password',
    type: 'password',
    name: 'password',
    label: 'Password',
    hintText: (
      <>
        Forgot password? <Link to="/reset-password">Reset here</Link>
      </>
    ),
  },
];

const loginFormValidationSchema = validate({
  email: requiredEmail,
  password: requiredString,
});

const loginFormInitialValues = {
  email: '',
  password: '',
};

function Login() {
  const client = useApolloClient();

  const { siteName, siteHost } = useSiteSettings();

  const [handleLogin, { loading, error }] = useMutation(LOGIN, {
    async onCompleted(response) {
      if (!response?.login) return;

      client.cache.writeQuery({
        query: GET_USER,
        data: response?.login,
      });

      await client.cache.writeQuery({
        query: IS_AUTHENTICATED,
        data: {
          isAuthenticated: true,
        },
      });
      client.cache.writeQuery({
        query: GET_TOKEN,
        data: response?.login,
      });

      Cookies.set('ck-has-account', true, { domain: siteHost });

      (window.dataLayer || []).push({
        event: 'login',
        userID: response?.login?.user?.id,
      });

      navigate('/');
    },
  });

  const renderSignupLink = () => {
    return (
      <Notification>
        <p>
          Don't have an account with {siteName}?{' '}
          <Link to="/sign-up">Sign up for free</Link>.
        </p>
      </Notification>
    );
  };

  return (
    <LayoutAuth title="Log in">
      <Card header={`Log in to ${siteName}`}>
        <Form
          fields={loginFormFields}
          onSubmit={handleLogin}
          validationSchema={loginFormValidationSchema}
          initialValues={loginFormInitialValues}
          loading={loading}
          error={!!error}
          errorMessage={extractGraphQLError(error)}
          submitButtonText="Login"
          fullWidthButton
          container
          renderAfterFields={renderSignupLink}
        />
      </Card>
    </LayoutAuth>
  );
}

export default Login;
