import gql from 'graphql-tag';

const GET_EXPERIENCES = gql`
  query Experiences($activityId: ID!) {
    experiences(activityId: $activityId) {
      id
      title
      description1
    }
  }
`;

const ADD_EXPERIENCE = gql`
  mutation AddExperience(
    $activityId: ID!
    $title: String!
    $description1: String!
  ) {
    addExperience(
      activityId: $activityId
      title: $title
      description1: $description1
    ) {
      id
      title
      description1
    }
  }
`;

const UPDATE_EXPERIENCE = gql`
  mutation UpdateExperience($id: ID!, $title: String, $description1: String) {
    updateExperience(id: $id, title: $title, description1: $description1) {
      id
      title
      description1
    }
  }
`;

const DELETE_EXPERIENCE = gql`
  mutation DeleteExperience($id: ID!) {
    deleteExperience(id: $id) {
      id
      title
      description1
    }
  }
`;

export {
  GET_EXPERIENCES,
  ADD_EXPERIENCE,
  UPDATE_EXPERIENCE,
  DELETE_EXPERIENCE,
};
