import React from 'react';
import { Link } from '@reach/router';

import Layout from '../components/Layout';
import Container from '../components/Container';
import TextContent from '../components/TextContent';
import Section from '../components/Section';

function NotFound() {
  return (
    <Layout title="Page not found">
      <Container>
        <Section>
          <TextContent>
            <h1>Ooops, page not found!</h1>

            <p>
              Maybe head back to the <Link to="/">homepage</Link> and navigate
              from there.
            </p>
          </TextContent>
        </Section>
      </Container>
    </Layout>
  );
}

export default NotFound;
