const RESULTS = [
  {
    id: 'values',
    level: 1,
  },
  {
    id: 'culture',
    level: 1,
  },
  {
    id: 'organisation',
    level: 1,
  },
  {
    id: 'strengths',
    level: 1,
  },
  {
    id: 'interests',
    level: 1,
  },
  {
    id: 'vision',
    level: 1,
  },
  {
    id: 'personality',
    level: 2,
  },
  {
    id: 'competencies',
    level: 2,
  },
  {
    id: 'roles',
    level: 2,
  },
  {
    id: 'experiences',
    level: 2,
  },
  {
    id: 'abilities',
    level: 2,
  },
  {
    id: 'goals',
    level: 3,
  },
  {
    id: 'beliefs',
    level: 3,
  },
  {
    id: 'actions',
    level: 3,
  },
];

export default RESULTS;
