import React from 'react';
import { Link } from '@reach/router';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';

import Avatar from './Avtatar';

import { GET_CMS_CONTENT } from '../graph/cms';

import { visuallyHidden } from '../theme/utils';

import { ReactComponent as UserIcon } from '../assets/user-icon.svg';

import { colors } from '../theme/main';

import useSiteSettings from '../hooks/useSiteSettings';

const Nav = styled.nav`
  flex: 1 1 auto;
  margin-left: ${(props) => props.theme.spacing.large};
`;

const Menu = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const NavItem = styled.li`
  list-style: none;
  padding: 0;
  margin: 0 ${(props) => props.theme.spacing.xSmall};

  ${(props) => props.theme.breakpoints.medium`
    margin: 0 ${props.theme.spacing.base};
  `}

  a {
    font-size: ${(props) => props.theme.fontSizes.small};
    font-weight: ${(props) => props.theme.fontWeights.normal};
    line-height: 1;
    text-decoration: none;
    border-bottom: solid 2px transparent;

    &:hover {
      border-color: ${(props) => props.theme.colors.green300};
    }
  }
`;

const ProfileNavItem = styled.li`
  list-style: none;
  padding: 0 ${(props) => props.theme.spacing.xxSmall};
  margin: 0 0 0 ${(props) => props.theme.spacing.base};
  display: none;

  ${(props) => props.theme.breakpoints.medium`
    display: block;
  `}

  a {
    background-color: ${(props) => props.theme.colors.grey200};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;

    &:hover {
      opacity: 0.8;
    }

    svg {
      fill: ${(props) => props.theme.colors.grey800}
      display: block;
      width: ${(props) => props.theme.spacing.small};
      height: ${(props) => props.theme.spacing.small};
      transform: translate(0, -1px);
    }
  }

  span {
    ${visuallyHidden}
  }
`;

function NavLink({ href, ...props }) {
  if (href) {
    return (
      <a {...props} href={href} target="_blank" rel="noreferrer noopener" />
    );
  }

  return (
    <Link
      {...props}
      getProps={({ isCurrent }) => {
        // the object returned here is passed to the
        // anchor element's props
        return {
          style: {
            borderColor: isCurrent && colors.green500,
          },
        };
      }}
    />
  );
}

function Navigation() {
  const { data } = useQuery(GET_CMS_CONTENT, { fetchPolicy: 'cache-only' });
  const { resourcesLink } = useSiteSettings();

  if (data?.user?.isAdmin)
    return (
      <Nav>
        <Menu>
          <NavItem>
            <NavLink to="/">Users</NavLink>
          </NavItem>

          <NavItem>
            <NavLink to="/payments">Payments</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/feedback">Feedback</NavLink>
          </NavItem>

          <ProfileNavItem>
            <NavLink to="/profile">
              {data?.user?.profilePicture ? (
                <Avatar image={data?.user?.profilePicture} small />
              ) : (
                <UserIcon />
              )}
              <span>Profile</span>
            </NavLink>
          </ProfileNavItem>
        </Menu>
      </Nav>
    );

  return (
    <Nav>
      <Menu>
        <NavItem>
          <NavLink to="/activities">Activities</NavLink>
        </NavItem>

        <NavItem>
          <NavLink to="/results">Results</NavLink>
        </NavItem>

        <NavItem>
          <NavLink to="/coaching">Coaching</NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            href={resourcesLink.includes('http') ? resourcesLink : undefined}
            to={!resourcesLink.includes('http') ? resourcesLink : undefined}
          >
            Resources
          </NavLink>
        </NavItem>

        <ProfileNavItem>
          <NavLink to="/profile">
            {data?.user?.profilePicture ? (
              <Avatar image={data?.user?.profilePicture} small />
            ) : (
              <UserIcon />
            )}
            <span>Profile</span>
          </NavLink>
        </ProfileNavItem>
      </Menu>
    </Nav>
  );
}

export default Navigation;
