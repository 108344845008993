import gql from 'graphql-tag';

const GET_CMS_CONTENT = gql`
  query CmsContent {
    user {
      id
      email
      phoneNumber
      firstName
      lastName
      name
      isAdmin
      hasActiveSubscription
      typeOfSubscription
      coupon
      currentLevel
      lastCompletedActivity
      completedActivities
      country
      city
      dateOfBirth
      profession
      hasCompletedProfile
      profilePicture
      availableFreeActivities
      createdAt
      currentSubscription {
        id
        lastBillDate
        nextBillDate
        price
        currency
      }
    }
    cmsContent {
      level {
        id
        title
        intro_text
        quote
        quote_citation
      }
      activity {
        id
        show_on_level_4
        title
        sub_title
        intro_text
        intro_text_2
        intro_image
        quote_1
        quote_1_citation
        quote_2
        quote_2_citation
        quote_3
        quote_3_citation
        instructions
        marketplace
        marketplace_title
        marketplace_sub_title
        products {
          product_uid
          product_title
          price
          details
        }
        help_text
        audio_instructions
        short_instructions_1
        help_text_1
        audio_instructions_1
        step_label_1
        short_instructions_2
        help_text_2
        audio_instructions_2
        step_label_2
        short_instructions_3
        help_text_3
        audio_instructions_3
        step_label_3
        short_instructions_4
        help_text_4
        audio_instructions_4
        step_label_4
        short_instructions_5
        help_text_5
        audio_instructions_5
        step_label_5
        short_instructions_6
        help_text_6
        audio_instructions_6
        step_label_6
        short_instructions_7
        help_text_7
        audio_instructions_7
        step_label_7
        short_instructions_8
        help_text_8
        audio_instructions_8
        step_label_8
        activity_complete_message
        references
        dashboard_quote
        dashboard_quote_cite
        dashboard_instructions
        externalurl
        body {
          type
          label
          primary {
            hero_title
            hero_sub_title
            hero_text
            hero_button_text
            hero_button_url
            hero_image
            hero_small
            hero_background_color
            hero_signup_title
            hero_signup_sub_title
            hero_signup_text
            hero_signup_cta_title
            hero_signup_cta_text
            hero_signup_button_text
            hero_signup_button_url
            hero_signup_small
            sign_up_banner_title
            sign_up_banner_text
            sign_up_banner_button_text
            sign_up_banner_button_url
            content_grid_small
            feature_image_title
            feature_image_image
            feature_image_small
            feature_image_background_color
            split_content_title
            split_content_sub_title
            split_content_text
            split_content_button_text
            split_content_button_url
            split_content_reverse
            split_content_image_width
            split_content_image
            split_content_small
            split_content_background_color
            sign_up_cta_title
            sign_up_cta_text
            sign_up_cta_signup_title
            sign_up_cta_signup_text
            sign_up_cta_signup_button_text
            sign_up_cta_signup_button_url
            contact_us_title
            contact_us_sub_title
            contact_us_button_text
            contact_us_button_url
            title_banner_title
            title_banner_image
            title_banner_text
            rich_text_content_title
            rich_text_content_text
            rich_text_content_button_text
            rich_text_content_button_url
            rich_text_content_small
            rich_text_content_background_color
            rich_text_content_title_icon {
              url
            }
            feature_list_title
            feature_list_small
            feature_list_background_color
            testimonial_title
            testimonial_small
            testimonial_background_color
            case_study_title
            case_study_small
            case_study_background_color
            case_study_image
            faq_title
            faq_small
            faq_background_color
            logo_grid_title
            logo_grid_small
            logo_grid_background_color
            button_link_text
            button_link_url
            button_link_center
            button_link_small
            button_link_background_color
            video_button_text
            video_embed
            video_show_button
            video_button_center
            video_small
            video_background_color
          }

          items {
            content_grid_title
            content_grid_text
            content_grid_button_text
            content_grid_button_url
            content_grid_icon {
              url
            }
            feature_list_title
            feature_list_text
            feature_list_icon {
              url
            }
            testimonial_text
            testimonial_citation
            case_study_slide_title
            case_study_slide_text
            faq_item_title
            faq_item_text
            logo_grid_image
            logo_grid_link_url
          }
        }
        body1 {
          type
          label
          primary {
            hero_title
            hero_sub_title
            hero_text
            hero_button_text
            hero_button_url
            hero_image
            hero_small
            hero_background_color
            hero_signup_title
            hero_signup_sub_title
            hero_signup_text
            hero_signup_cta_title
            hero_signup_cta_text
            hero_signup_button_text
            hero_signup_button_url
            hero_signup_small
            sign_up_banner_title
            sign_up_banner_text
            sign_up_banner_button_text
            sign_up_banner_button_url
            content_grid_small
            feature_image_title
            feature_image_image
            feature_image_small
            feature_image_background_color
            split_content_title
            split_content_sub_title
            split_content_text
            split_content_button_text
            split_content_button_url
            split_content_reverse
            split_content_image_width
            split_content_image
            split_content_small
            split_content_background_color
            sign_up_cta_title
            sign_up_cta_text
            sign_up_cta_signup_title
            sign_up_cta_signup_text
            sign_up_cta_signup_button_text
            sign_up_cta_signup_button_url
            contact_us_title
            contact_us_sub_title
            contact_us_button_text
            contact_us_button_url
            title_banner_title
            title_banner_image
            title_banner_text
            rich_text_content_title
            rich_text_content_text
            rich_text_content_button_text
            rich_text_content_button_url
            rich_text_content_small
            rich_text_content_background_color
            rich_text_content_title_icon {
              url
            }
            feature_list_title
            feature_list_small
            feature_list_background_color
            testimonial_title
            testimonial_small
            testimonial_background_color
            case_study_title
            case_study_small
            case_study_background_color
            case_study_image
            faq_title
            faq_small
            faq_background_color
            logo_grid_title
            logo_grid_small
            logo_grid_background_color
            button_link_text
            button_link_url
            button_link_center
            button_link_small
            button_link_background_color
            video_button_text
            video_embed
            video_show_button
            video_button_center
            video_small
            video_background_color
          }

          items {
            content_grid_title
            content_grid_text
            content_grid_button_text
            content_grid_button_url
            content_grid_icon {
              url
            }
            feature_list_title
            feature_list_text
            feature_list_icon {
              url
            }
            testimonial_text
            testimonial_citation
            case_study_slide_title
            case_study_slide_text
            faq_item_title
            faq_item_text
            logo_grid_image
            logo_grid_link_url
          }
        }
      }
      value {
        id
        title
        value_text
      }
      strength {
        id
        title
        value_text
      }
      culture {
        id
        title
        main_content
        quote
        quote_citation
      }
      organisation_type {
        id
        title
        main_content
      }
      trait {
        id
        title
        text
      }
      ability {
        id
        title
        text
        above_average_text
        average_text
        below_average_text
      }
      competency {
        id
        title
        text
      }
      theme {
        id
        title
        text
      }
      role {
        id
        title
        text
      }
      dashboard {
        quote
        quote_citation
        help_text
      }
      result {
        id
        title
        subtitle
        content_1
        content_1_read_more
        content_1_show_visualisation
        content_1_quote
        content_1_quote_citation
        content_2
        content_2_read_more
        content_2_show_visualisation
        content_2_quote
        content_2_quote_citation
        content_3
        content_3_read_more
        content_3_show_visualisation
        content_3_quote
        content_3_quote_citation
        content_4
        content_4_read_more
        content_4_show_visualisation
        content_4_quote
        content_4_quote_citation
        help_text
        references
        body {
          type
          label
          primary {
            hero_title
            hero_sub_title
            hero_text
            hero_button_text
            hero_button_url
            hero_image
            hero_small
            hero_background_color
            hero_signup_title
            hero_signup_sub_title
            hero_signup_text
            hero_signup_cta_title
            hero_signup_cta_text
            hero_signup_button_text
            hero_signup_button_url
            hero_signup_small
            sign_up_banner_title
            sign_up_banner_text
            sign_up_banner_button_text
            sign_up_banner_button_url
            content_grid_small
            feature_image_title
            feature_image_image
            feature_image_small
            feature_image_background_color
            split_content_title
            split_content_sub_title
            split_content_text
            split_content_button_text
            split_content_button_url
            split_content_reverse
            split_content_image_width
            split_content_image
            split_content_small
            split_content_background_color
            sign_up_cta_title
            sign_up_cta_text
            sign_up_cta_signup_title
            sign_up_cta_signup_text
            sign_up_cta_signup_button_text
            sign_up_cta_signup_button_url
            contact_us_title
            contact_us_sub_title
            contact_us_button_text
            contact_us_button_url
            title_banner_title
            title_banner_image
            title_banner_text
            rich_text_content_title
            rich_text_content_text
            rich_text_content_button_text
            rich_text_content_button_url
            rich_text_content_small
            rich_text_content_background_color
            rich_text_content_title_icon {
              url
            }
            feature_list_title
            feature_list_small
            feature_list_background_color
            testimonial_title
            testimonial_small
            testimonial_background_color
            case_study_title
            case_study_small
            case_study_background_color
            case_study_image
            faq_title
            faq_small
            faq_background_color
            logo_grid_title
            logo_grid_small
            logo_grid_background_color
            button_link_text
            button_link_url
            button_link_center
            button_link_small
            button_link_background_color
            video_button_text
            video_embed
            video_show_button
            video_button_center
            video_small
            video_background_color
          }

          items {
            content_grid_title
            content_grid_text
            content_grid_button_text
            content_grid_button_url
            content_grid_icon {
              url
            }
            feature_list_title
            feature_list_text
            feature_list_icon {
              url
            }
            testimonial_text
            testimonial_citation
            case_study_slide_title
            case_study_slide_text
            faq_item_title
            faq_item_text
            logo_grid_image
            logo_grid_link_url
          }
        }
      }
      interview_guide {
        content_1
        content_1_read_more
        content_2
        content_2_read_more
        content_3
        content_3_read_more
        content_4
        content_4_read_more
        content_5
        content_5_read_more
        content_6
        content_6_read_more
        content_7
        content_7_read_more
        content_8
        content_8_read_more
        content_9
        content_9_read_more
        content_10
        content_10_read_more
        references
      }
      goal_guide {
        content_1
        content_1_read_more
        content_1_quote
        content_1_quote_citation
        content_2
        content_2_read_more
        content_2_quote
        content_2_quote_citation
        content_3
        content_3_read_more
        content_3_quote
        content_3_quote_citation
        content_4
        content_4_read_more
        content_4_quote
        content_4_quote_citation
        content_4_image
        content_5
        content_5_read_more
        content_5_quote
        content_5_quote_citation
        content_6
        content_6_read_more
        content_6_quote
        content_6_quote_citation
        content_7
        content_7_read_more
        content_7_quote
        content_7_quote_citation
        references
      }
      page {
        id
        title
        content
        show_back_link
        references
        body {
          type
          label
          primary {
            hero_title
            hero_sub_title
            hero_text
            hero_button_text
            hero_button_url
            hero_image
            hero_small
            hero_background_color
            hero_signup_title
            hero_signup_sub_title
            hero_signup_text
            hero_signup_cta_title
            hero_signup_cta_text
            hero_signup_button_text
            hero_signup_button_url
            hero_signup_small
            sign_up_banner_title
            sign_up_banner_text
            sign_up_banner_button_text
            sign_up_banner_button_url
            content_grid_small
            feature_image_title
            feature_image_image
            feature_image_small
            feature_image_background_color
            split_content_title
            split_content_sub_title
            split_content_text
            split_content_button_text
            split_content_button_url
            split_content_reverse
            split_content_image_width
            split_content_image
            split_content_small
            split_content_background_color
            sign_up_cta_title
            sign_up_cta_text
            sign_up_cta_signup_title
            sign_up_cta_signup_text
            sign_up_cta_signup_button_text
            sign_up_cta_signup_button_url
            contact_us_title
            contact_us_sub_title
            contact_us_button_text
            contact_us_button_url
            title_banner_title
            title_banner_image
            title_banner_text
            rich_text_content_title
            rich_text_content_text
            rich_text_content_button_text
            rich_text_content_button_url
            rich_text_content_small
            rich_text_content_background_color
            rich_text_content_title_icon {
              url
            }
            feature_list_title
            feature_list_small
            feature_list_background_color
            testimonial_title
            testimonial_small
            testimonial_background_color
            case_study_title
            case_study_small
            case_study_background_color
            case_study_image
            faq_title
            faq_small
            faq_background_color
            logo_grid_title
            logo_grid_small
            logo_grid_background_color
            button_link_text
            button_link_url
            button_link_center
            button_link_small
            button_link_background_color
            video_button_text
            video_embed
            video_show_button
            video_button_center
            video_small
            video_background_color
          }

          items {
            content_grid_title
            content_grid_text
            content_grid_button_text
            content_grid_button_url
            content_grid_icon {
              url
            }
            feature_list_title
            feature_list_text
            feature_list_icon {
              url
            }
            testimonial_text
            testimonial_citation
            case_study_slide_title
            case_study_slide_text
            faq_item_title
            faq_item_text
            logo_grid_image
            logo_grid_link_url
          }
        }
      }
      coaching {
        id
        title
        sub_title
        hero_text
        content1
        content2
      }
      interview_questions {
        id
        title
        text
        result_link
      }
      products {
        id
        title
        sub_title
        short_description
        price_text_1
        price_text_2
        long_description
        success_text
      }
      industries {
        id
        title
        content
      }
      plans {
        id
        title
        text
        left_section_header
        left_section_content
        left_section_content_1
        left_section_content_2
        left_section_content_3
        right_section_header
        right_section_content
      }
      activity_home {
        hero_title
        hero_text
        intro_text
      }
      results_home {
        hero_title
      }
      coaches {
        id
        name
        text
        image
        thirty_minute_calendly_url
        sixty_minute_calendly_url
      }
    }
  }
`;

export { GET_CMS_CONTENT };
