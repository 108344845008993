import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from './Button';
import Tag from './Tag';
import GridItem from './GridItem';

import { extractPrismicString } from '../utils/prismic';

import cardBase from '../theme/cardBase';

const Item = styled.div`
  ${cardBase}
  display: flex;
  margin: 0;
  flex-direction: column;
`;

const Text = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.midLarge};

  p {
    margin-bottom: 0;
  }

  h4 {
    display: flex;
    align-items: center;

    div {
      margin-left: ${(props) => props.theme.spacing.xSmall};
    }
  }
`;

const ButtonContainer = styled.div`
  margin-top: auto;
  flex: 0 0 auto;
`;

function ActivityGridItem({
  type,
  cmsType,
  level,
  onStartActivity,
  loading,
  cmsContent,
  activityData,
  ongoing,
  marketplace,
}) {
  const activityType = cmsType || type;

  const {
    title,
    sub_title: subTitle,
    show_on_level_4: showOnLevel4,
  } = cmsContent?.activity?.find((activity) => activity.id === activityType) ||
  {};

  const renderButton = () => {
    const { id } =
      (ongoing && activityData?.find((item) => item.type === type)) || {};

    if (id) {
      return (
        <Button
          small
          to={`/activities/${id}/introduction`}
          level={!marketplace && level}
          disabled={loading}
          secondary={marketplace}
        >
          {marketplace ? 'Find out more' : 'Start Activity'}
        </Button>
      );
    }

    return (
      <Button
        small
        onClick={() => onStartActivity({ type, level })}
        level={!marketplace && level}
        disabled={loading}
        secondary={marketplace}
      >
        {marketplace ? 'Find out more' : 'Start Activity'}
      </Button>
    );
  };

  const renderTag = () => {
    if (!marketplace) return false;

    return <Tag>Marketplace</Tag>;
  };

  if (!showOnLevel4) return false;

  return (
    <GridItem width={33.33} grow>
      <Item>
        <Text>
          <h4>
            {extractPrismicString(title)}
            {renderTag()}
          </h4>
          <p>{extractPrismicString(subTitle)}</p>
        </Text>

        <ButtonContainer>{renderButton()}</ButtonContainer>
      </Item>
    </GridItem>
  );
}

ActivityGridItem.propTypes = {
  level: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  cmsType: PropTypes.string,
  onStartActivity: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  cmsContent: PropTypes.shape({}).isRequired,
  activityData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  ongoing: PropTypes.bool,
};

ActivityGridItem.defaultProps = {
  loading: false,
  cmsType: null,
  ongoing: false,
};

export default ActivityGridItem;
