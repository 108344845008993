import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Expandable from './Expandable';
import Section from './Section';

import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';
import cardBase from '../theme/cardBase';

const Wrapper = styled.div`
  ${cardBase}
  padding-top: 0;
  padding-bottom: 0;
`;

function RoleTable({ data, cmsContent }) {
  const dataWithCMSValues = data.map(item => {
    const cmsValue = cmsContent?.role?.find(value => item.type === value.id);

    return {
      ...item,
      title: cmsValue?.title,
      description: cmsValue?.text,
    };
  });

  return (
    <Section smallPadding>
      <h3>Your Suited Role Areas</h3>

      <Wrapper>
        {dataWithCMSValues.map(item => (
          <Expandable title={extractPrismicString(item.title)}>
            {hasContent(item.description) &&
              extractPrismicContent(item.description)}
          </Expandable>
        ))}
      </Wrapper>
    </Section>
  );
}

RoleTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  cmsContent: PropTypes.shape({}).isRequired,
};

export default RoleTable;
