import React from 'react';
import styled from 'styled-components';

import Container from './Container';
import TextContentMarketing from './TextContentMarketing';
import Button from './Button';

import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.red500};
  color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing.large} 0;

  h3 {
    color: ${props => props.theme.colors.white};
    font-family: ${props => props.theme.base.altFontFamily};
  }
`;

const Inner = styled.div`
  ${props => props.theme.breakpoints.large`
    width: 60%;
  `}
`;

function MarketingSignUpBanner({
  sign_up_banner_title: title,
  sign_up_banner_text: text,
  sign_up_banner_button_text: buttonText,
  sign_up_banner_button_url: buttonUrl,
}) {
  return (
    <Wrapper>
      <Container>
        <Inner>
          <TextContentMarketing>
            {hasContent(title) && <h3>{extractPrismicString(title)}</h3>}

            {extractPrismicContent(text)}
          </TextContentMarketing>

          {!!(hasContent(buttonText) && hasContent(buttonUrl)) && (
            <Button white marginTop href={extractPrismicString(buttonUrl)}>
              {extractPrismicString(buttonText)}
            </Button>
          )}
        </Inner>
      </Container>
    </Wrapper>
  );
}

MarketingSignUpBanner.propTypes = {};

export default MarketingSignUpBanner;
