import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveCirclePacking } from '@nivo/circle-packing';
import styled from 'styled-components';
import Color from 'color';
import startCase from 'lodash.startcase';

import main, { colors } from '../theme/main';

const baseColor = Color(colors.green500);

const getColor = (value) => baseColor.lighten(1 - value / 10);

const ChartWrapper = styled.div`
  height: calc(
    ${(props) => props.theme.components.chartHeight} +
      ${(props) => props.theme.spacing.midLarge} +
      ${(props) => props.theme.spacing.midLarge}
  );
  margin: -${(props) => props.theme.spacing.midLarge};
`;

function AbilitiesVisualisation({
  data: { status, __typename, id, new: isNew, ...abilities },
}) {
  const transformedData = Object.entries(abilities).map(([key, value]) => ({
    id: startCase(key),
    label: startCase(key),
    value,
  }));

  return (
    <ChartWrapper>
      <ResponsiveCirclePacking
        data={{ name: 'parent', children: transformedData }}
        identity="label"
        value="value"
        padding={4}
        leavesOnly
        animate
        colorBy="value"
        colors={({ value }) => getColor(value)}
        isInteractive={false}
        enableLabels
        label={({ id: labelId }) => {
          return (
            <tspan fill={colors.white} fontFamily={main.base.fontFamily}>
              {labelId}
            </tspan>
          );
        }}
      />
    </ChartWrapper>
  );
}

AbilitiesVisualisation.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default AbilitiesVisualisation;
