import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { navigate } from '@reach/router';

import ActivityJourney from '../components/ActivityJourney';
import Layout from '../components/Layout';
import Container from '../components/Container';
import Notification from '../components/Notification';
import OngoingActivityList from '../components/OngoingActivityList';
import TextContent from '../components/TextContent';
import Section from '../components/Section';

import { GET_CMS_CONTENT } from '../graph/cms';
import { GET_ACTIVITIES, ADD_ACTIVITY } from '../graph/activities';
import { GET_RESULTS, ADD_RESULT } from '../graph/results';

import levels, { activities, ACTIVITY_STATUS } from '../config/levels';

import extractGraphQLError from '../utils/extractGraphQLError';
import { extractPrismicContent, extractPrismicString } from '../utils/prismic';

function Activities() {
  const { data } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
  });

  const { data: activityData, loading } = useQuery(GET_ACTIVITIES);
  const { data: resultData, loading: loadingResults } = useQuery(GET_RESULTS);
  const [handeAddResult, { loading: loadingAddResult }] = useMutation(
    ADD_RESULT,
  );

  const { hero_title: heroTitle, hero_text: heroText, intro_text: introText } =
    data?.cmsContent?.activity_home || {};

  console.log(data?.cmsContent);

  // useEffect(() => {
  //   if (!data?.user?.currentLevel || loading || loadingResults) return;
  //   window.location.href = `activities#level-${data?.user?.currentLevel}`;
  // }, [data?.user?.currentLevel, loading, loadingResults]); //eslint-disable-line

  const [
    handleAddActivity,
    { loading: loadingAddActivity, error },
  ] = useMutation(ADD_ACTIVITY, {
    async onCompleted(response) {
      if (!response?.addActivity?.activity?.id) return;

      const resultTypes =
        activities[(response?.addActivity?.activity?.type)].results;

      if (resultTypes && resultTypes.length) {
        await Promise.all(
          resultTypes.map(resultType =>
            handeAddResult({
              variables: {
                activityId: response?.addActivity?.activity?.id,
                type: resultType,
                new: true,
              },
            }),
          ),
        );
      }

      navigate(
        `/activities/${response?.addActivity?.activity?.id}/introduction`,
      );
    },
  });

  const handleStartActivity = ({ type, level, paid }) => {
    // Check if user has active subscription
    if (
      paid &&
      !data?.user?.hasActiveSubscription &&
      data?.user?.availableFreeActivities === 0
    ) {
      navigate('/payment');
      return;
    }

    handleAddActivity({
      variables: { type, level, status: ACTIVITY_STATUS.OPEN },
    });
  };

  return (
    <Layout
      title="Activities"
      loading={loading || loadingResults}
      hero={{
        title: extractPrismicString(heroTitle),
        subTitle: extractPrismicString(heroText),
      }}
    >
      {data?.user?.completedActivities?.[2] === levels[3].totalActivities && (
        <OngoingActivityList
          activities={levels[4].activities}
          cmsContent={data?.cmsContent}
          level={4}
          onStartActivity={handleStartActivity}
          loading={loadingAddActivity || loadingAddResult}
          activityData={activityData?.activities}
        />
      )}

      <Container narrow center>
        <Section>
          <TextContent redStrong smallMargin center>
            {extractPrismicContent(introText)}
          </TextContent>
        </Section>
      </Container>

      <Container xNarrow>
        {!!error && (
          <Notification type="red">{extractGraphQLError(error)}</Notification>
        )}

        {Object.entries(levels).map(([level, levelData]) => (
          <ActivityJourney
            level={level}
            {...levelData}
            activityData={activityData?.activities}
            resultData={resultData?.results}
            activitiesComplete={data?.user?.completedActivities?.[level - 1]}
            onStartActivity={handleStartActivity}
            loading={loadingAddActivity || loadingAddResult}
            cmsContent={data?.cmsContent}
            hasActiveSubscription={data?.user?.hasActiveSubscription}
            typeOfSubscription={data?.user?.typeOfSubscription}
            availableFreeActivities={data?.user?.availableFreeActivities}
          />
        ))}
      </Container>
    </Layout>
  );
}

export default Activities;
