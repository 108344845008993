import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ResultListItem from './ResultListItem';

import cardBase from '../theme/cardBase';

const List = styled.ul`
  ${cardBase};
  margin: 0;
  padding: 0;
`;

function ResultList({ results, ...props }) {
  return (
    <List>
      {results.map(result => (
        <ResultListItem {...props} {...result} />
      ))}
    </List>
  );
}

ResultList.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default ResultList;
