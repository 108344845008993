/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/react-hooks';
import { navigate } from '@reach/router';
import Papa from 'papaparse';
import dayjs from 'dayjs';
import CsvDownload from 'react-json-to-csv';

import styled from 'styled-components';
import Layout from '../components/Layout';
import { Input } from '../components/TextInput';
import Container from '../components/Container';
import Section from '../components/Section';
import Tag from '../components/Tag';
import Form from '../components/Form';
import Button from '../components/Button';
import TablePagination from '../components/TablePagenation';
import AdminUserTag from '../components/AdminUserTag';
import AdminBulkTags from '../components/AdminBulkTags';
import Table from '../components/Table';

import { GET_USERS, IMPORT_PERSONALITY_TEST } from '../graph/admin';
import { IS_AUTHENTICATED, GET_TOKEN } from '../graph/auth';

import { TOTAL_ACTIVITIES } from '../config/levels';

import { ReactComponent as ChevronIcon } from '../assets/chevron-icon.svg';

import transformCSVData from '../utils/transformCSVData';

import sleep from '../utils/sleep';

const SearchBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
}`;

const SearchButtonBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
}`;

function Dashboard() {
  const client = useApolloClient();
  const [sortBy, setSortBy] = React.useState({
    column: '',
    isAscending: false,
  });
  const [dSortBy, setDSortBy] = React.useState({
    column: '',
    isAscending: false,
  });
  const [totalPageCount, setTotalPageCount] = React.useState(10);
  const [nextDisabled, setNextDisabled] = React.useState(false);
  const [preDisabled, setPreDisabled] = React.useState(true);
  const [search, setSearch] = React.useState('');
  const [searchTemp, setSearchTemp] = React.useState('');
  const [selectedItems, setSelectedItems] = React.useState([]);

  const { data, loading, fetchMore, refetch } = useQuery(GET_USERS, {
    variables: {
      limit: totalPageCount,
      offset: 0,
      search,
      sortBy: dSortBy.column,
      isAscending: dSortBy.isAscending ? '1' : '0',
    },
    onCompleted: (formData) => {
      setNextDisabled(formData?.users?.length < 10);
      if (formData?.users?.length < totalPageCount) {
        setNextDisabled(true);
      } else {
        setPreDisabled(false);
      }
    },
  });

  React.useEffect(() => {
    if (!loading) {
      fetchMore({
        variables: {
          limit: totalPageCount,
          offset: 0,
          search,
          sortBy: dSortBy.column,
          isAscending: dSortBy.isAscending ? '1' : '0',
        },
        updateQuery: (pre, { fetchMoreResult }) => {
          if (fetchMoreResult?.users?.length < totalPageCount) {
            setNextDisabled(true);
          }
          if (fetchMoreResult?.users?.length) {
            setPreDisabled(false);
            return fetchMoreResult;
          }
          return pre;
        },
      });
    }
  }, [dSortBy, fetchMore, loading, search, totalPageCount]);

  React.useEffect(() => {
    if (!loading) {
      fetchMore({
        variables: {
          limit: totalPageCount,
          offset: 0,
          search,
          sortBy: dSortBy.column,
          isAscending: dSortBy.isAscending ? '1' : '0',
        },
        updateQuery: (pre, { fetchMoreResult }) => {
          if (fetchMoreResult?.users?.length < totalPageCount) {
            setNextDisabled(true);
          }
          if (fetchMoreResult?.users?.length) {
            setPreDisabled(false);
            return fetchMoreResult;
          }
          return pre;
        },
      });
    }
  }, [
    dSortBy.column,
    dSortBy.isAscending,
    fetchMore,
    loading,
    search,
    totalPageCount,
  ]);

  React.useEffect(() => {});

  const [
    handleImportPersonalityTest,
    { data: importResponse, loading: loadingImport },
  ] = useMutation(IMPORT_PERSONALITY_TEST);

  const sortedUserData = data?.users.filter(
    (item) => item.email !== 'DEACTIVATED',
  );

  const handleViewUser = ({ variables: { id } }) => {
    navigate(`/users/${id}`);
  };

  const handleUpload = async ({ variables }) => {
    if (!variables || !variables.upload) return;

    Papa.parse(variables.upload, {
      delimiter: ',',
      header: true,
      complete: ({ data: parsedData }) => {
        const transformedData = transformCSVData(parsedData);

        handleImportPersonalityTest({ variables: { users: transformedData } });
      },
    });
  };

  const handleLogOut = async () => {
    await client.clearStore();

    await client.cache.writeQuery({
      query: IS_AUTHENTICATED,
      data: {
        isAuthenticated: false,
      },
    });

    await client.cache.writeQuery({
      query: GET_TOKEN,
      data: {
        accessToken: null,
        refreshToken: null,
      },
    });

    await sleep(1200);

    navigate('/');

    window.location.reload();
  };

  const transformedData = sortedUserData
    ?.map(
      ({
        completedActivities,
        createdAt,
        hasActiveSubscription,
        __typename,
        ...feedback
      }) => ({
        ...feedback,
        completedActivitiesCount: completedActivities?.reduce(
          (a, count) => a + count,
          0,
        ),
        completedActivities: `${completedActivities?.reduce(
          (a, count) => a + count,
          0,
        )}/${TOTAL_ACTIVITIES}`,
        createdAt: dayjs(parseInt(createdAt, 10)).format('D MMMM YYYY'),
        hasActiveSubscription: hasActiveSubscription ? 'Premium' : 'Free',
      }),
    )
    .sort((a, b) => {
      const _a = sortBy.isAscending ? a : b;
      const _b = sortBy.isAscending ? b : a;
      switch (sortBy.column) {
        case 'Name':
          return 1;
        case 'Email':
          return 1;
        case 'Subscription':
          return _a.hasActiveSubscription.localeCompare(
            _b.hasActiveSubscription,
          );

        case 'Pending':
          return _b.pendingActivities - _a.pendingActivities;
        case 'Level':
          return _b.currentLevel > _a.currentLevel;
        case 'Completed':
          return _b.completedActivitiesCount - _a.completedActivitiesCount;

        default:
          return _b.pendingActivities - _a.pendingActivities;
      }
    });

  const downloadData = React.useMemo(() => {
    return selectedItems.map((id) =>
      transformedData.find((row) => row.id === id),
    );
  }, [selectedItems, transformedData]);

  const handleSelctedItem = React.useCallback(
    (type, isChecked) => {
      let selected = [...selectedItems];
      if (type === 'All') {
        if (isChecked) {
          selected = transformedData.map((e) => e.id);
        } else {
          selected = [];
        }
      } else if (isChecked) {
        selected.push(type);
      } else {
        selected = selectedItems.filter((row) => row !== type);
      }
      setSelectedItems(selected);
    },
    [selectedItems, transformedData],
  );

  const handleSorting = (column) => {
    let sort = { ...sortBy };
    if (sortBy.column === column) sort.isAscending = !sort.isAscending;
    else sort = { column, isAscending: true };

    if (column === 'Name' || column === 'Email' || column === 'Signed up') {
      setDSortBy(sort);
    }
    setSortBy(sort);
  };

  return (
    <Layout title="Dashboard" loading={loading}>
      <Container>
        <Section>
          <SearchBox>
            <SearchBox>
              <h2 style={{ marginBottom: 0, marginRight: 5 }}>
                Admin user list
              </h2>
              <AdminBulkTags
                selected={selectedItems}
                users={sortedUserData}
                onSuccess={refetch}
              />
            </SearchBox>
            <SearchButtonBox>
              <Input
                style={{ maxWidth: 500, maxHeight: 40, marginRight: 5 }}
                value={searchTemp}
                onChange={(e) => setSearchTemp(e.currentTarget.value)}
              />
              <Button
                small
                onClick={() => {
                  setSearch(searchTemp);
                }}
              >
                Search
              </Button>
            </SearchButtonBox>
          </SearchBox>

          <Table
            cols={[
              { id: 'name', value: 'Name' },
              { id: 'email', value: 'Email' },
              {
                id: 'hasActiveSubscription',
                value: 'Subscription',
              },
              {
                id: 'tag',
                value: 'Tags',
                transformer: (value, index, id) => (
                  <AdminUserTag id={id} tag={value} />
                ),
              },
              {
                id: 'pendingActivities',
                value: 'Pending',
                small: true,
                transformer: (value) => value > 0 && <Tag>{value}</Tag>,
              },
              {
                id: 'currentLevel',
                value: 'Level',
                small: true,
                transformer: (value) =>
                  value > 0 && <Tag level={value}>{value}</Tag>,
              },
              {
                id: 'completedActivities',
                value: 'Completed',
                small: true,
              },
              { id: 'lastCompletedActivityTime', value: 'Last Completed' },
              {
                id: 'createdAt',
                value: 'Signed up',
              },
            ]}
            data={transformedData || []}
            actions={[
              {
                id: 'view',
                text: 'view',
                icon: <ChevronIcon />,
                onClick: handleViewUser,
              },
            ]}
            hasSelectable
            onSelectItemClick={handleSelctedItem}
            selectedItems={selectedItems}
            onSorting={handleSorting}
            sortBy={sortBy}
          />

          <TablePagination
            pageCount={totalPageCount}
            onPageCountChange={(e) => setTotalPageCount(+e)}
            onNext={() => {
              fetchMore({
                variables: {
                  limit: totalPageCount,
                  offset: data?.users?.length,
                  search,
                  sortBy: dSortBy.column,
                  isAscending: dSortBy.isAscending ? '1' : '0',
                },
                updateQuery: (pre, { fetchMoreResult }) => {
                  if (fetchMoreResult?.users?.length < totalPageCount) {
                    setNextDisabled(true);
                  }
                  if (fetchMoreResult?.users?.length) {
                    setPreDisabled(false);
                    return fetchMoreResult;
                  }
                  return pre;
                },
              });
            }}
            onPre={() => {
              fetchMore({
                variables: {
                  limit: totalPageCount,
                  offset: data?.users?.length - totalPageCount,
                  search,
                  sortBy: dSortBy.column,
                  isAscending: dSortBy.isAscending ? '1' : '0',
                },
                updateQuery: (pre, { fetchMoreResult }) => {
                  if (fetchMoreResult?.users?.length) {
                    return fetchMoreResult;
                  }
                  setPreDisabled(true);
                  return pre;
                },
              });
            }}
            isPreDisabled={preDisabled}
            isNextDisabled={nextDisabled}
          />

          <CsvDownload data={downloadData}>
            Download Data ({downloadData.length})
          </CsvDownload>

          <Section>
            <h2>Import Personality test data</h2>

            <Form
              fields={[
                { id: 'upload', name: 'upload', label: 'Upload', type: 'file' },
              ]}
              onSubmit={handleUpload}
              submitButtonText="Upload"
              loading={loadingImport}
              success={importResponse?.importPersonalityTest}
              successMessage={`${importResponse?.importPersonalityTest?.usersImported} activities imported successfully`}
            />
          </Section>

          <Button fullWidth onClick={handleLogOut}>
            Log out
          </Button>
        </Section>
      </Container>
    </Layout>
  );
}

export default Dashboard;
