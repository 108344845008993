import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TextContent from './TextContent';
import Button from './Button';

const ChartWrapper = styled.div`
  min-height: ${props => props.theme.components.chartHeight};
  display: flex;
  padding: ${props => props.theme.spacing.small};

  blockquote {
    color: ${props => props.theme.colors.black};
    padding: 0;
    margin: auto;
    font-size: 24px;
    line-height: 38px;
    font-style: italic;
  }
`;

function VisionSummary({
  data,
  emptyDataMessage,
  emptyDataLink,
  emptyDataLinkText,
}) {
  if (!data?.vision && emptyDataMessage) {
    return (
      <>
        <p>{emptyDataMessage}</p>
        <Button small to={emptyDataLink}>
          {emptyDataLinkText}
        </Button>
      </>
    );
  }

  return (
    <ChartWrapper>
      <TextContent maxHeight={235}>
        <blockquote>{data?.vision}</blockquote>
      </TextContent>
    </ChartWrapper>
  );
}

VisionSummary.propTypes = {
  data: PropTypes.string.isRequired,
  emptyDataMessage: PropTypes.string,
  emptyDataLink: PropTypes.string,
  emptyDataLinkText: PropTypes.string,
};

VisionSummary.defaultProps = {
  emptyDataMessage: null,
  emptyDataLink: null,
  emptyDataLinkText: null,
};

export default VisionSummary;
