import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import AudioPlayer from '../components/AudioPlayer';
import Layout from '../components/Layout';
import Container from '../components/Container';
import Notification from '../components/Notification';
import TextContent from '../components/TextContent';
import Section from '../components/Section';
import FooterBar from '../components/FooterBar';
import Grid from '../components/Grid';
import GridItem from '../components/GridItem';
import SimpleBarList from '../components/SimpleBarList';
import Card from '../components/Card';
import Table from '../components/Table';

import { GET_ACTIVITY_DETAILS, UPDATE_ACTIVITY } from '../graph/activities';
import { GET_CMS_CONTENT } from '../graph/cms';
import { GET_BELIEFS, ADD_BELIEF, DELETE_BELIEF } from '../graph/beliefs';
import { GET_RESULTS } from '../graph/results';

import extractGraphQLError from '../utils/extractGraphQLError';
import sleep from '../utils/sleep';
import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';
import { validate, requiredString } from '../utils/validate';
import useTrackActivityGA from '../utils/useTrackActivityGA';

import { activities, ACTIVITY_STATUS } from '../config/levels';

import { ReactComponent as RemoveIcon } from '../assets/remove-icon.svg';

const formValidationSchema = validate({
  title: requiredString,
  description1: requiredString,
});

const formInitialValues = {
  title: '',
  description1: '',
};

const formFields = [
  {
    id: 'title',
    name: 'title',
    label: 'Empowering Belief',
    type: 'textarea',
    placeholder: 'Enter an empowering belief...',
  },
  {
    id: 'description1',
    name: 'description1',
    label: 'Why you believe it',
    type: 'textarea',
    placeholder: 'Why do you believe it?',
  },
  {
    id: 'description2',
    name: 'description2',
    label: 'How it helps you',
    type: 'textarea',
    placeholder: 'How does it helps you?',
  },
];

function ActivityBelief({ activityId }) {
  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data, loading } = useQuery(GET_ACTIVITY_DETAILS, {
    variables: { id: activityId },
  });

  const { loading: loadingBeliefs, data: dataBeliefs } = useQuery(GET_BELIEFS, {
    variables: { activityId },
  });

  const { data: resultData, loading: loadingResults } = useQuery(GET_RESULTS);

  const filteredBeliefs = dataBeliefs?.beliefs?.filter(
    belief => belief?.type === 'EMPOWERING',
  );

  const isBeliefValid = filteredBeliefs.length > 0;

  const [
    handleAddBelief,
    { loading: loadingAddBelief, error: errorAddBelief },
  ] = useMutation(ADD_BELIEF, {
    refetchQueries: ['Beliefs'],
  });

  const handleNewBelief = formData => {
    handleAddBelief({
      variables: {
        activityId,
        type: 'EMPOWERING',
        ...formData,
      },
    });
  };

  const [
    handleDeleteBelief,
    { loading: loadingDeleteBelief, error: errorDeleteBelief },
  ] = useMutation(DELETE_BELIEF, {
    refetchQueries: ['Beliefs'],
  });

  const [
    handleUpdateActivity,
    { loading: loadingUpdateActivity, error },
  ] = useMutation(UPDATE_ACTIVITY);

  const handleCompleteActivity = async () => {
    await handleUpdateActivity({
      variables: {
        id: activityId,
        status: ACTIVITY_STATUS.COMPLETED,
      },
    });

    await sleep(500);

    navigate(`/activities/${activityId}/completed`);
  };

  const activityType = data?.activity?.type;

  useTrackActivityGA(data?.activity?.type, '3');

  const { level } = activities[activityType] || {};

  const {
    title,
    sub_title: subTitle,
    audio_instructions_3: audioInstructions,
    help_text_3: helpText,
    short_instructions_3: shortInstructions,
    step_label_3: stepLabel,
  } =
    cmsData?.cmsContent?.activity?.find(
      activity =>
        activity.id ===
        (cmsData?.user?.currentLevel === 4 &&
        data?.activity?.status === ACTIVITY_STATUS.COMPLETED
          ? `ongoing_${activityType}`
          : activityType),
    ) ||
    cmsData?.cmsContent?.activity?.find(
      activity => activity.id === activityType,
    ) ||
    {};

  return (
    <Layout
      title={`${extractPrismicString(title)}`}
      loading={loading || loadingBeliefs || loadingResults}
      hasFooterBar
    >
      <Container>
        <Section>
          {!!(hasContent(shortInstructions) || hasContent(helpText)) && (
            <Grid>
              <GridItem
                alignTop
                width={
                  audioInstructions?.url || hasContent(helpText) ? 60 : 100
                }
              >
                <TextContent redh6 smallMargin>
                  {extractPrismicContent(shortInstructions)}
                </TextContent>
              </GridItem>

              {!!(audioInstructions?.url || hasContent(helpText)) && (
                <GridItem width={40} alignTop>
                  <Notification showUser noMarginBottom>
                    {extractPrismicContent(helpText)}

                    {!!audioInstructions?.url && (
                      <AudioPlayer fileUrl={audioInstructions?.url} />
                    )}
                  </Notification>
                </GridItem>
              )}
            </Grid>
          )}
        </Section>

        <Section smallPadding>
          <Grid>
            <GridItem width={33.33} alignTop>
              <Card header="Top 5 competencies">
                <SimpleBarList
                  data={resultData?.results?.competencies?.competencies?.slice(
                    0,
                    5,
                  )}
                  emptyDataMessage="Completing the 'Your Competencies in Action' activity first, can be super helpful for this activity!"
                  emptyDataLink="/activities"
                  emptyDataLinkText="Back to activities"
                />
              </Card>
            </GridItem>

            <GridItem width={33.33} alignTop>
              <Card header="Top 5 character strengths">
                <SimpleBarList
                  data={resultData?.results?.strengths?.strengths?.slice(0, 5)}
                  emptyDataMessage="Completing the 'Character Strengths Assessment
                  ' activity first, can be super helpful for this activity!"
                  emptyDataLink="/activities"
                  emptyDataLinkText="Back to activities"
                />
              </Card>
            </GridItem>

            <GridItem width={33.33} alignTop>
              <Card header="Top 5 career goals">
                <SimpleBarList
                  data={resultData?.results?.goals?.goals?.slice(0, 5)}
                  emptyDataMessage="Completing the 'Your SMART Goals' activity first, can be super helpful for this activity!"
                  emptyDataLink="/activities"
                  emptyDataLinkText="Back to activities"
                />
              </Card>
            </GridItem>
          </Grid>
        </Section>

        <TextContent redh6 smallMargin>
          <h6>Step 3</h6>
          {extractPrismicContent(stepLabel)}
        </TextContent>

        <Table
          cols={[
            { id: 'title', value: 'Empowering Beliefs' },
            { id: 'description1', value: 'Why you believe it' },
            { id: 'description2', value: 'How it helps you' },
          ]}
          data={filteredBeliefs}
          actions={[
            {
              id: 'remove',
              text: 'remove',
              icon: <RemoveIcon />,
              onClick: handleDeleteBelief,
              loading: loadingDeleteBelief,
            },
          ]}
          form={{
            fields: formFields,
            loading: loadingAddBelief,
            validationSchema: formValidationSchema,
            initialValues: formInitialValues,
            onSubmit: handleNewBelief,
          }}
        />

        {!!(error || errorAddBelief || errorDeleteBelief) && (
          <Notification type="red">
            {extractGraphQLError(error || errorAddBelief || errorDeleteBelief)}
          </Notification>
        )}
      </Container>

      <FooterBar
        title={extractPrismicString(title)}
        subTitle={extractPrismicString(subTitle)}
        primaryButton={{
          label: 'Complete Activity',
          onClick: handleCompleteActivity,
          loading: loadingUpdateActivity,
          disabled: !isBeliefValid,
          level,
        }}
        secondaryButton={{
          label: 'Back to Growth Mindset Response',
          to: `/activities/${activityId}/limiting_beliefs/2`,
        }}
      />
    </Layout>
  );
}

ActivityBelief.propTypes = {
  activityId: PropTypes.string,
};

ActivityBelief.defaultProps = {
  activityId: null,
};

export default ActivityBelief;
