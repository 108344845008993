import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Link } from '@reach/router';

import Card from '../components/Card';
import LayoutAuth from '../components/LayoutAuth';
import Form from '../components/Form';
import Notification from '../components/Notification';

import { REQUEST_RESET } from '../graph/auth';

import { validate, requiredEmail } from '../utils/validate';
import extractGraphQLError from '../utils/extractGraphQLError';

const resetRequestFormFields = [
  {
    id: 'email',
    type: 'email',
    name: 'email',
    label: 'Email Address',
  },
];

const resetRequestFormValidationSchema = validate({
  email: requiredEmail,
});

const resetRequestFormInitialValues = {
  email: '',
};

const renderLoginLink = () => {
  return (
    <Notification>
      <p>
        Know your password? <Link to="/login">Login here</Link>.
      </p>
    </Notification>
  );
};

function ResetRequest() {
  const [handleResetRequest, { loading, error, data }] = useMutation(
    REQUEST_RESET,
  );

  return (
    <LayoutAuth title="Reset password">
      <Card
        header="Reset password"
        subheader="Enter the email address associated with your account to receive a link to reset your password."
      >
        <Form
          fields={resetRequestFormFields}
          onSubmit={handleResetRequest}
          validationSchema={resetRequestFormValidationSchema}
          initialValues={resetRequestFormInitialValues}
          loading={loading}
          error={!!error}
          errorMessage={extractGraphQLError(error)}
          success={data?.requestReset?.success}
          successMessage="Your reset password link has been sent, please check your email inbox."
          submitButtonText="Send reset link"
          renderAfterFields={renderLoginLink}
          fullWidthButton
        />
      </Card>
    </LayoutAuth>
  );
}

export default ResetRequest;
