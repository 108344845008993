import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import CsvDownload from 'react-json-to-csv';
import dayjs from 'dayjs';

import Layout from '../components/Layout';
import Container from '../components/Container';
import Section from '../components/Section';
import Table from '../components/Table';
import Tag from '../components/Tag';

import { GET_ACTIVITY_FEEDBACK, GET_USERS } from '../graph/admin';

function AdminFeedback() {
  const { data, loading } = useQuery(GET_USERS);
  const { data: feedbackData, loading: loadingFeedback } = useQuery(
    GET_ACTIVITY_FEEDBACK,
  );
  const filteredUserData = data?.users?.filter(
    (user) => !!user.deactivateFeedback,
  );

  const transformedData = feedbackData?.activityFeedback?.map(
    ({ user, updatedAt, __typename, ...feedback }) => ({
      ...feedback,
      userId: user?.id,
      updatedAt: dayjs(parseInt(updatedAt, 10)).format('D MMMM YYYY'),
    }),
  );

  return (
    <Layout title="Feedback" loading={loading || loadingFeedback}>
      <Container>
        <Section>
          <h2>Activity Feedback</h2>

          <Table
            cols={[
              {
                id: 'level',
                value: 'Level',
                small: true,
                transformer: (value) =>
                  value > 0 && <Tag level={value}>{value}</Tag>,
              },
              { id: 'type', value: 'Type', medium: true },
              {
                id: 'rating',
                value: 'Rating',
                small: true,
                transformer: (value) =>
                  value > 0 && <Tag rating={value}>{value}</Tag>,
              },
              { id: 'ratingText', value: 'Feedback' },

              {
                id: 'updatedAt',
                value: 'Date Submitted',
              },
              {
                id: 'userId',
                value: 'UserID',
              },
            ]}
            data={transformedData}
          />

          <CsvDownload data={transformedData} />
        </Section>

        <Section>
          <h2>Deactivation Feedback</h2>

          <Table
            cols={[{ id: 'deactivateFeedback', value: 'Feedback' }]}
            data={filteredUserData}
          />
        </Section>
      </Container>
    </Layout>
  );
}

export default AdminFeedback;
