import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FieldWrapper from './FieldWrapper';

import fieldBase from '../theme/fieldBase';

import { ReactComponent as DownIcon } from '../assets/down-icon.svg';

const SelectWrapper = styled.div`
  ${fieldBase}
  position: relative;
  padding-right: 50px;

  ${props =>
    props.small &&
    `
    padding: 9px 50px 8px 14px;
    font-size: ${props.theme.fontSizes.small};
  `}

  ${props =>
    props.error &&
    `
    border-color: ${props.theme.colors.red500};
  `}

  ${props =>
    props.disabled &&
    `
    opacity: 0.3
  `}

  select {
    cursor: pointer;
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  svg {
    position: absolute;
    pointer-events: none;
    fill: ${props => props.theme.colors.green500};
    width: 10px;
    height: 10px;
    right: ${props => props.theme.spacing.small};
    top: 50%;
    transform: translateY(-50%);
  }
`;

function Select({
  name,
  hintText,
  disabled,
  readOnly,
  id,
  label,
  defaultLabel,
  errorMessage,
  onChange,
  value,
  options,
  noMargin,
  small,
  ...props
}) {
  const selectedOption = options.find(option => option.value === value);

  return (
    <FieldWrapper
      errorMessage={errorMessage}
      name={name}
      label={label}
      hintText={hintText}
      noMargin={noMargin}
      {...props}
    >
      <SelectWrapper error={!!errorMessage} disabled={disabled} small={small}>
        {selectedOption?.label || value || defaultLabel}
        <select
          name={name}
          disabled={disabled}
          readOnly={readOnly}
          onChange={onChange}
          id={id}
          value={value}
        >
          {defaultLabel && <option value="">{defaultLabel}</option>}

          {options.map(option => (
            <option key={option.id} value={option.value}>
              {option.label || option.value}
            </option>
          ))}
        </select>

        <DownIcon />
      </SelectWrapper>
    </FieldWrapper>
  );
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  hintText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  defaultLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  noMargin: PropTypes.bool,
  small: PropTypes.bool,
};

Select.defaultProps = {
  disabled: false,
  hintText: null,
  readOnly: false,
  errorMessage: null,
  value: null,
  noMargin: false,
  small: false,
  defaultLabel: '',
};

export default Select;
