import gql from 'graphql-tag';

const GET_INTERVIEW_QUESTIONS = gql`
  query InterviewQuestions($activityId: ID!) {
    interviewQuestions(activityId: $activityId) {
      id
      title
      description1
      description2
      type
    }
  }
`;

const ADD_INTERVIEW_QUESTION = gql`
  mutation AddInterviewQuestion(
    $activityId: ID!
    $title: String
    $description1: String
    $description2: String
    $type: String
  ) {
    addInterviewQuestion(
      activityId: $activityId
      title: $title
      description1: $description1
      description2: $description2
      type: $type
    ) {
      id
      title
      description1
      description2
      type
    }
  }
`;

const UPDATE_INTERVIEW_QUESTION = gql`
  mutation UpdateInterviewQuestion(
    $id: ID!
    $title: String
    $description1: String
    $description2: String
    $type: String
  ) {
    updateInterviewQuestion(
      id: $id
      title: $title
      description1: $description1
      description2: $description2
      type: $type
    ) {
      id
      title
      description1
      description2
      type
    }
  }
`;

const DELETE_INTERVIEW_QUESTION = gql`
  mutation DeleteInterviewQuestion($id: ID!) {
    deleteInterviewQuestion(id: $id) {
      id
      title
      description1
      description2
      type
    }
  }
`;

export {
  GET_INTERVIEW_QUESTIONS,
  ADD_INTERVIEW_QUESTION,
  UPDATE_INTERVIEW_QUESTION,
  DELETE_INTERVIEW_QUESTION,
};
