import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import TextButton from './TextButton';

const TextContentWraper = styled.div`
  a {
    color: ${props => props.theme.colors.blue500};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
  
  &:after {
    content: "";
    clear: both;
    display: table;
  }

  ${props =>
    props.center &&
    `
    text-align: center;
  `}

  ${props =>
    props.marginBottom &&
    `
      margin-bottom: ${props.theme.spacing.midLarge};
    `}

  ${props =>
    props.leadParagraph &&
    `
    p:first-of-type {
      font-size: ${props.theme.fontSizes.large};
      font-weight: ${props.theme.fontWeights.light};
    }
  `}

  p {
    margin-bottom: ${props => props.theme.spacing.midLarge};
    line-height: 2;

    ${props =>
      props.smallMargin &&
      `
      margin-bottom: ${props.theme.spacing.small};
    `}

    &:last-child {
      margin-bottom: 0;
    }

    small {
      font-size: ${props => props.theme.fontSizes.small};
    }
  }

  h1 {
    margin-bottom: 0;

    ${props => props.theme.breakpoints.medium`
      margin-bottom: ${props.theme.spacing.midLarge};
    `}

    ${props =>
      props.leadParagraph &&
      `
    &:first-of-type {
      margin-bottom: ${props.theme.spacing.small};
    }
  `}

${props =>
  props.smallMargin &&
  `
      margin-bottom: ${props.theme.spacing.small};
    `}
  }


  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: ${props => props.theme.spacing.midLarge};
    margin-bottom: ${props => props.theme.spacing.base};

    ${props =>
      props.smallMargin &&
      `
      margin-bottom: ${props.theme.spacing.small};
    `}

    ${props =>
      props.noMarginTop &&
      `
      margin-top: 0;
    `}

    &:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  h5 {
    font-size: ${props => props.theme.fontSizes.large};
    font-weight: ${props => props.theme.fontWeights.light};
    color: ${props => props.theme.colors.grey500};
  }

  ${props =>
    props.redh6 &&
    `
      h6 {
        color: ${props.theme.colors.green500};
      }
    `}


${props =>
  props.redStrong &&
  `
      strong {
        color: ${props.theme.colors.green500};
      }
    `}

  ol,
  ul {
 
    margin-bottom: ${props => props.theme.spacing.midLarge};

    ${props =>
      props.smallMargin &&
      `
      margin-bottom: ${props.theme.spacing.small};
    `}

    &:last-child {
      margin-bottom: 0;
    }
  }

  li {
    margin-bottom: ${props => props.theme.spacing.base};

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    li {
      padding-left: ${props => props.theme.spacing.base};
      
      &:before {
        display: inline-block;
        content: '';
        background-color: ${props => props.theme.colors.red500};
        margin-left: -${props => props.theme.spacing.base};
        margin-right: ${props => props.theme.spacing.xSmall};
        position: relative;
        height: 8px;
        width: 8px;
        border-radius: 100%;
        top: -1px;
      }
    }
  }

  ol {
    counter-reset: custom-counter;

    li {
      counter-increment: custom-counter;
      padding-left: ${props => props.theme.spacing.base};

      &:before {
        display: inline-block;
        content: counter(custom-counter) '. ';
        color: ${props => props.theme.colors.red500};
        font-size: ${props => props.theme.fontSizes.small};
        font-weight: ${props => props.theme.fontWeights.bold};
        margin-left: -${props => props.theme.spacing.base};
        text-transform: uppercase;
        letter-spacing: 1px;
        position: relative;
        top: -1px;
        width: ${props => props.theme.spacing.base};
      }
    }
  }

  .highlight-text {
    color: ${props => props.theme.colors.red500};
  }

  .bold-black-text {
    color: ${props => props.theme.colors.black};
    font-weight: ${props => props.theme.fontWeights.bold};
    font-size: ${props => props.theme.fontSizes.medium};
  }

  .block-img {
    margin: 0 0 ${props => props.theme.spacing.base};
   
     img {
      max-height: 33vh;
      margin: auto;
     }
    
    ${props => props.theme.breakpoints.medium`
      margin: 0 ${props.theme.spacing.midLarge} ${props.theme.spacing.base};
      width: 460px;

      img {
        max-height: none;
      }
    `}

    &:nth-of-type(even) {
      ${props => props.theme.breakpoints.medium`
        float: left;
      `}

      ${props => props.theme.breakpoints.large`
        margin-left: calc(( ${props.theme.grid.maxWidthNarrow} - 100vw) / 2);
      `}

      ${props => props.theme.breakpoints.xLarge`
        margin-left: calc(( ${props.theme.grid.maxWidthNarrow} - ${
        props.theme.grid.maxWidth
      }) / 2);
      `}
    }

    &:nth-of-type(odd) {
      ${props => props.theme.breakpoints.medium`
        float: right;
      `}

      ${props => props.theme.breakpoints.large`
        margin-right: calc(( ${props.theme.grid.maxWidthNarrow} - 100vw) / 2);
      `}

      ${props => props.theme.breakpoints.xLarge`
        margin-right: calc(( ${props.theme.grid.maxWidthNarrow} - ${
        props.theme.grid.maxWidth
      }) / 2);
      `}
    }
  }

  .left-image {
    ${props => props.theme.breakpoints.medium`
      float: left!important;
    `}

    ${props => props.theme.breakpoints.large`
      margin-left: calc(( ${
        props.theme.grid.maxWidthNarrow
      } - 100vw) / 2)!important;
    `}

    ${props => props.theme.breakpoints.xLarge`
      margin-left: calc(( ${props.theme.grid.maxWidthNarrow} - ${
      props.theme.grid.maxWidth
    }) / 2)!important;
    `}
  }

  .right-image {
    ${props => props.theme.breakpoints.medium`
      float: right!important;
    `}

    ${props => props.theme.breakpoints.large`
      margin-right: calc(( ${
        props.theme.grid.maxWidthNarrow
      } - 100vw) / 2)!important;
    `}

    ${props => props.theme.breakpoints.xLarge`
      margin-right: calc(( ${props.theme.grid.maxWidthNarrow} - ${
      props.theme.grid.maxWidth
    }) / 2)!important;
    `}
  }

  .center-image {
    width: 100%;

    ${props => props.theme.breakpoints.medium`
      float: none!important;
    `}

    ${props => props.theme.breakpoints.large`
      margin: 0!important;
    `}

    ${props => props.theme.breakpoints.xLarge`
        margin: 0!important;
    `}
  }

  aside {
    ${props =>
      !props.noFloat &&
      props.theme.breakpoints.medium`
        width: 460px;
        float: right;
        margin: 0  0 ${props.theme.spacing.base} ${
        props.theme.spacing.midLarge
      };
    `}

    ${props => props.theme.breakpoints.large`
      margin-right: calc(( ${props.theme.grid.maxWidthNarrow} - 100vw) / 2);
    `}

    ${props => props.theme.breakpoints.xLarge`
      margin-right: calc(( ${props.theme.grid.maxWidthNarrow} - ${
      props.theme.grid.maxWidth
    }) / 2);
    `}
  } 

  .embed {
    background-color: ${props => props.theme.colors.grey200};
    display: block;
    overflow: hidden;
    padding-bottom: ${(9 / 16) * 100}%;
    position: relative;
    width: 100%;
    z-index: 1;

    iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  .quote-right, .quote-left {
    display: block;
    background-color: ${props => props.theme.colors.yellow100};
    border-radius: ${props => props.theme.base.radius};
    width: 100%;
    padding: ${props => props.theme.spacing.midLarge};
    margin-bottom: ${props => props.theme.spacing.medium};
    color: ${props => props.theme.colors.black};
    font-size: 24px;
    line-height:38px;
    font-style: italic;
    margin: ${props => props.theme.spacing.midLarge} auto;
    
    ${props => props.theme.breakpoints.medium`
      width: 460px;
      margin: 0 ${props.theme.spacing.midLarge} ${props.theme.spacing.base};
    `}
  }

  .quote-left {
    ${props => props.theme.breakpoints.medium`
      float: left;
    `}

    ${props => props.theme.breakpoints.large`
      margin-left: calc(( ${props.theme.grid.maxWidthNarrow} - 100vw) / 2);
    `}

    ${props => props.theme.breakpoints.xLarge`
      margin-left: calc(( ${props.theme.grid.maxWidthNarrow} - ${
      props.theme.grid.maxWidth
    }) / 2);
    `}
  }

  .quote-right {
    ${props => props.theme.breakpoints.medium`
      float: right;
    `}

    ${props => props.theme.breakpoints.large`
      margin-right: calc(( ${props.theme.grid.maxWidthNarrow} - 100vw) / 2);
    `}

    ${props => props.theme.breakpoints.xLarge`
      margin-right: calc(( ${props.theme.grid.maxWidthNarrow} - ${
      props.theme.grid.maxWidth
    }) / 2);
    `}
  }


  .additional-information {
    display: block;
    background-color: ${props => props.theme.colors.green100};
    border-radius: ${props => props.theme.base.radius};
    width: 100%;
    padding: ${props => props.theme.spacing.base};
    margin-bottom: ${props => props.theme.spacing.medium};
  }

  .pullout {
    display: block;
    margin: auto;
    max-width: 480px;
    font-size: ${props => props.theme.fontSizes.xxLarge};
    font-family: ${props => props.theme.base.altFontFamily};
    font-style: normal;
    line-height: 1.4;
    color: ${props => props.theme.colors.black};
  }

  .strikeout {
    text-decoration: line-through;
  }

  ${props =>
    props.homepage &&
    `
    h1 {
      color: ${props.theme.colors.red500};
    }

    h1, h2, h3 {
      font-weight: ${props.theme.fontWeights.normal};
    }


    h1 + h5 {
      margin-top: -${props.theme.spacing.base};
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      margin: ${props.theme.spacing.midLarge} 0;

      li {
        flex: 0 0 50%
        width: 50%;
        padding: ${props.theme.spacing.small};

        &:before {
          display: block;
          margin: 0 auto ${props.theme.spacing.base};
          background-color: transparent;
          border: solid 1px ${props.theme.colors.red500};
          width: ${props.theme.spacing.base};
          height: ${props.theme.spacing.base};
        }
      }
    }
  `}


${props =>
  props.homepage &&
  props.theme.breakpoints.medium`
    ul {
      li {
          flex: 0 0 33.33%
          width: 33.33%;
      }
    }
  `}

  ${props =>
    props.white &&
    `
      p, ul, li, h1, h2, h3, h4, h5 {
        color: ${props.theme.colors.white};
      }
    `}
`;

const Inner = styled.div`
  ${props =>
    props.maxHeight &&
    ` 
      overflow: hidden;
      margin-bottom: ${props.theme.spacing.base};
      position: relative;
  `}

  ${props =>
    props.maxHeight &&
    !props.isExpanded &&
    ` 
      max-height: ${props.maxHeight}px;
  `}
`;

function TextContent({ children, maxHeight, ...props }) {
  const [isExpanded, setIsExpanded] = useState(!maxHeight);

  const handleToggleExapnded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <TextContentWraper {...props}>
      <Inner maxHeight={maxHeight} isExpanded={isExpanded}>
        {children}
      </Inner>

      {maxHeight && (
        <TextButton onClick={handleToggleExapnded}>
          Read {isExpanded ? 'less' : 'more'}
        </TextButton>
      )}
    </TextContentWraper>
  );
}

TextContent.propTypes = {
  children: PropTypes.node.isRequired,
  maxHeight: PropTypes.number,
};

TextContent.defaultProps = {
  maxHeight: null,
};

export default TextContent;
