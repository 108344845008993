import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { useMutation } from '@apollo/react-hooks';

import Button from './Button';
import CouponForm from './CouponForm';
import Notification from './Notification';
import TextContent from './TextContent';
import CurrencySelector from './CurrencySelector';

import { CREATE_PAYMENT, UPDATE_PAYMENT } from '../graph/payment';

import fieldBase from '../theme/fieldBase';

import { ReactComponent as LockIcon } from '../assets/lock-icon.svg';

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;

  ${(props) => props.theme.breakpoints.medium`
    flex: 0 0 50%;
  `}
`;

const StyledLockIcon = styled(LockIcon)`
  display: block;
  margin: 0 auto ${(props) => props.theme.spacing.midLarge};
  height: 50px;
  width: 50px;
  fill: ${(props) => props.theme.colors.green500};
`;

const Currency = styled.div`
  ${(props) => props.theme.breakpoints.medium`
    position: absolute;
    top: 0;
    right: 0;
  `}
`;

const FormElement = styled.div`
  ${fieldBase}
  display: flex;
  height: 50px;
  align-items: center;

  div {
    width: 100%;
  }
`;

const ElementWrapper = styled.div`
  margin: ${(props) => props.theme.spacing.base} 0;
`;

const FormInner = styled.div`
  margin: 0 0 ${(props) => props.theme.spacing.midLarge} 0;
`;

const TotalTable = styled.ul`
  background-color: ${(props) => props.theme.colors.grey100};
  margin-top: ${(props) => props.theme.spacing.midLarge};
  padding: ${(props) => props.theme.spacing.small};

  li {
    display: flex;
    font-size: ${(props) => props.theme.fontSizes.small};
    padding-bottom: ${(props) => props.theme.spacing.xxSmall};

    strong {
      margin-left: auto;
    }

    &:last-child {
      border-top: solid 1px ${(props) => props.theme.colors.grey300};
      margin-top: ${(props) => props.theme.spacing.xxSmall};
      padding-top: ${(props) => props.theme.spacing.xSmall};
      padding-bottom: 0;
    }

    &:first-child {
      border-top: none;
      margin-top: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`;

function PaymentForm({
  stripe,
  onSuccess,
  user,
  productId,
  price,
  discountedPrice,
  hasDiscount,
  isFree,
  freeText,
  currency,
  priceWithVAT,
  message,
  discountAmount,
  vatAmount,
  chargeVAT,
}) {
  const [loading, setLoading] = useState(false);
  const [isComplete, setComplete] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [handleCreatePayment] = useMutation(CREATE_PAYMENT);
  const [handleUpdatePayment] = useMutation(UPDATE_PAYMENT);
  const stripeElement = useRef(null);

  // Creates payment intent and gets client secret from the API
  const getPaymentIntent = async () => {
    try {
      const response = await handleCreatePayment({
        variables: { productId, currency },
      });
      return response;
    } catch (error) {
      setError(
        'There has been a problem processing your payment, please try again.',
      );
      return null;
    }
  };

  // Creates stripe payment
  const createStripePayment = async (clientSecret) => {
    try {
      const response = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: stripeElement?.current._element,
          billing_details: { name: user.name },
        },
      });
      return response;
    } catch (error) {
      setError(
        'There has been a problem processing your payment, please try again.',
      );
      return null;
    }
  };

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();
    if (!isComplete && !isFree) return;

    setLoading(true);
    setError(null);

    // Get the client secret
    const response = await getPaymentIntent();

    // handle a successful status here, means that the product is free and we can continue
    if (response?.data?.createStripePayment?.status === 'succeeded') {
      onSuccess();
      setLoading(false);
      return;
    }

    // Handle the unlikely event of a missing client secret
    if (!response?.data?.createStripePayment?.clientSecret) {
      setLoading(false);
      setError(
        'There has been a problem processing your payment, please try again.',
      );
      return;
    }

    // Make card payment
    const stripeResponse = await createStripePayment(
      response?.data?.createStripePayment?.clientSecret,
    );

    // Handle response
    if (!stripeResponse || stripeResponse?.error) {
      setError(
        'There has been a problem processing your payment, please try again.',
      );

      setLoading(false);

      return;
    }

    await handleUpdatePayment({
      variables: {
        paymentId: response?.data?.createStripePayment?.paymentId,
        status: stripeResponse?.paymentIntent?.status,
      },
    });

    if (stripeResponse?.paymentIntent?.status === 'succeeded') {
      onSuccess();
    }

    setLoading(false);
  };

  const handleFormChange = ({ complete }) => {
    setComplete(complete);
  };

  return (
    <FormWrapper>
      <Currency>
        <CurrencySelector />
      </Currency>

      <form onSubmit={handlePaymentSubmit}>
        <StyledLockIcon />

        <TextContent center>
          <h4>Your payment details</h4>

          <p>
            <small>
              One off payment of only{' '}
              {hasDiscount && (
                <strong>
                  <strike>{`${price}`}</strike>
                </strong>
              )}{' '}
              <strong>
                {`${discountedPrice}`}
                {chargeVAT ? ' + VAT' : ''}
              </strong>
              . {message}
            </small>
          </p>
        </TextContent>

        <TotalTable>
          {(hasDiscount || chargeVAT) && (
            <li>
              Sub total <strong>{price}</strong>
            </li>
          )}

          {hasDiscount && (
            <li>
              Discount <strong>-{discountAmount}</strong>
            </li>
          )}

          {chargeVAT && (
            <li>
              VAT <strong>{vatAmount}</strong>
            </li>
          )}

          <li>
            Total price <strong>{priceWithVAT}</strong>
          </li>
        </TotalTable>

        <FormInner>
          <ElementWrapper>
            {!isFree && (
              <FormElement>
                <CardElement ref={stripeElement} onChange={handleFormChange} />
              </FormElement>
            )}
          </ElementWrapper>

          <Button
            type="submit"
            loading={loading}
            disabled={!isFree && !isComplete}
            fullWidth
            green
          >
            {isFree ? freeText : `Pay now`}
          </Button>
        </FormInner>

        {errorMessage && <Notification type="red">{errorMessage}</Notification>}
      </form>

      <CouponForm />
    </FormWrapper>
  );
}

PaymentForm.propTypes = {
  stripe: PropTypes.shape({
    confirmCardPayment: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    name: PropTypes.string,
  }),
  onSuccess: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
  price: PropTypes.string,
  discountedPrice: PropTypes.string,
  hasDiscount: PropTypes.bool.isRequired,
  isFree: PropTypes.bool.isRequired,
  freeText: PropTypes.string,
  currency: PropTypes.string.isRequired,
  priceWithVAT: PropTypes.string,
  message: PropTypes.string,
  discountAmount: PropTypes.string,
  vatAmount: PropTypes.string,
  chargeVAT: PropTypes.bool,
};

PaymentForm.defaultProps = {
  user: { name: null },
  discountedPrice: null,
  price: null,
  freeText: 'Join for free',
  priceWithVAT: null,
  message: null,
  discountAmount: null,
  vatAmount: null,
  chargeVAT: false,
};

export default injectStripe(PaymentForm);
