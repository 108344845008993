import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Button from './Button';
import { ReactComponent as AddIcon } from '../assets/add-icon.svg';
import { ReactComponent as TickIcon } from '../assets/tick-icon-alt.svg';

function TableButton({ loading, update }) {
  return useMemo(
    () => (
      <Button type="submit" round disabled={loading}>
        {update ? <TickIcon /> : <AddIcon />}
      </Button>
    ),
    [loading, update],
  );
}

TableButton.propTypes = {
  loading: PropTypes.bool,
  update: PropTypes.bool,
};

TableButton.defaultProps = {
  loading: false,
  update: false,
};

export default TableButton;
