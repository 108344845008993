import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import getColorFromLevel from '../utils/getColorFromLevel';

import { ReactComponent as LevelOneIllustration } from '../assets/level-1-illustration.svg';
import { ReactComponent as LevelTwoIllustration } from '../assets/level-2-illustration.svg';
import { ReactComponent as LevelThreeIllustration } from '../assets/level-3-illustration.svg';
import { ReactComponent as LevelFourIllustration } from '../assets/level-4-illustration.svg';
import { ReactComponent as LockIcon } from '../assets/lock-icon-alt.svg';

const RADIUS = 90;
const STROKE = 6;

const Wrapper = styled.div`
  box-shadow: ${props => props.theme.base.shadow};
  height: ${RADIUS * 2}px;
  width: ${RADIUS * 2}px;
  border-radius: 100%;
  margin: 0 auto;
  position: relative;

  svg {
    transform: rotate(90deg);
  }
`;

const BackgroundCircle = styled.circle`
  fill: ${props => props.theme.colors.white};
  stroke: ${props => props.theme.colors.grey100};
`;

const ProgressCircle = styled.circle`
  fill: transparent;
  stroke: ${getColorFromLevel};
`;

const Illustration = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: block;
    transform: none;
    width: 100px;
    height: 100px;
  }
`;

const StatusCircle = styled.div`
  position: absolute;
  bottom: ${props => props.theme.spacing.xxSmall};
  right: ${props => props.theme.spacing.xxSmall};
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: ${props => props.theme.fontSizes.small};
    line-height: 1;
    margin-bottom: 0;

    span {
      font-size: 28px;
      font-weight: ${props => props.theme.fontWeights.light};
    }
  }

  svg {
    display: block;
    fill: ${props => props.theme.colors.white};
    width: ${props => props.theme.spacing.small};
    height: ${props => props.theme.spacing.small};
    transform: translate(0, -1px);
  }
`;

function LevelStatusCircle({
  activitiesComplete,
  totalActivities,
  level,
  isDisabled,
}) {
  const progress =
    activitiesComplete !== null && totalActivities !== null
      ? (activitiesComplete / totalActivities) * 100
      : 100;
  const normalizedRadius = RADIUS - STROKE / 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  const renderIllustration = () => {
    if (level === '4') {
      return <LevelFourIllustration />;
    }

    if (level === '3') {
      return <LevelThreeIllustration />;
    }

    if (level === '2') {
      return <LevelTwoIllustration />;
    }

    return <LevelOneIllustration />;
  };

  return (
    <Wrapper>
      <svg height={RADIUS * 2} width={RADIUS * 2}>
        <BackgroundCircle
          strokeWidth={STROKE}
          r={normalizedRadius}
          cx={RADIUS}
          cy={RADIUS}
          level={level}
        />

        {progress && (
          <ProgressCircle
            strokeDasharray={`${circumference} ${circumference}`}
            style={{ strokeDashoffset }}
            strokeWidth={STROKE}
            r={normalizedRadius}
            cx={RADIUS}
            cy={RADIUS}
            level={level}
          />
        )}
      </svg>

      <Illustration>{renderIllustration()}</Illustration>
      {!!(activitiesComplete !== null && totalActivities !== null) && (
        <StatusCircle>
          {isDisabled ? (
            <LockIcon />
          ) : (
            <p>
              <span>{activitiesComplete}</span>/{totalActivities}
            </p>
          )}
        </StatusCircle>
      )}
    </Wrapper>
  );
}

LevelStatusCircle.propTypes = {
  level: PropTypes.number.isRequired,
  activitiesComplete: PropTypes.number,
  totalActivities: PropTypes.number,
  isDisabled: PropTypes.bool,
};

LevelStatusCircle.defaultProps = {
  activitiesComplete: null,
  totalActivities: null,
  isDisabled: false,
};

export default LevelStatusCircle;
