import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from './Button';

const Wrapper = styled.span`
  position: relative;
  margin-left: auto;

  ul {
    position: absolute;
    top: 100%;
    right: ${props => props.theme.spacing.small};
    background-color: ${props => props.theme.colors.white};
    box-shadow: ${props => props.theme.base.shadow};
    width: 180px;
    border-radius: ${props => props.theme.base.radiusSmall};
    z-index: ${props => props.theme.layers.dropdown};

    button {
      display: block;
    }
  }
`;

function ButtonDropdown({ children, items, ...props }) {
  const [showDropDown, setShowDropDown] = useState(false)
  return (
    <Wrapper>
      <Button {...props} onClick={() => setShowDropDown(!showDropDown)}>{children}</Button>

      {showDropDown && (
      <ul>
        {items.map(item => <li key={item.id}><button onClick={item.onClick} type="button" disabled={item.disabled}>{item.text}</button></li>)}
      </ul>
)}
    </Wrapper>
  );
}

ButtonDropdown.propTypes = {
  children: PropTypes.node.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ButtonDropdown;