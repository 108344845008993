import React from 'react';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/react-hooks';
import CsvDownload from 'react-json-to-csv';

import Layout from '../components/Layout';
import Container from '../components/Container';
import Section from '../components/Section';
import Table from '../components/Table';

import { GET_PAYMENTS } from '../graph/admin';

function AdminPayments() {
  const { data, loading } = useQuery(GET_PAYMENTS);

  return (
    <Layout title="Feedback" loading={loading}>
      <Container>
        <Section>
          <h2>Payments</h2>

          <Table
            cols={[
              {
                id: 'purchaseDate',
                value: 'Purchase Date',
                transformer: (date) =>
                  dayjs(parseFloat(date)).format('D MMMM YYYY : HH:MM'),
              },
              {
                id: 'status',
                value: 'Status',
              },
              {
                id: 'product',
                value: 'Product Name',
                transformer: (value) => value?.name,
              },
              {
                id: 'user',
                value: 'User ID',
                transformer: (value) => value?.id,
              },
            ]}
            data={data?.payments}
          />

          <CsvDownload data={data?.payments} />
        </Section>
      </Container>
    </Layout>
  );
}

export default AdminPayments;
