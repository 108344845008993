import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import AudioPlayer from '../components/AudioPlayer';
import Layout from '../components/Layout';
import Container from '../components/Container';
import Notification from '../components/Notification';
import TextContent from '../components/TextContent';
import Section from '../components/Section';
import FooterBar from '../components/FooterBar';
import Grid from '../components/Grid';
import GridItem from '../components/GridItem';
import Table from '../components/Table';
import Card from '../components/Card';
import SimpleBarList from '../components/SimpleBarList';
import VisionSummary from '../components/VisionSummary';

import { GET_ACTIVITY_DETAILS } from '../graph/activities';
import { GET_CMS_CONTENT } from '../graph/cms';
import { GET_GOALS, ADD_GOAL, DELETE_GOAL } from '../graph/goals';
import { GET_RESULTS } from '../graph/results';

import extractGraphQLError from '../utils/extractGraphQLError';
import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';
import { validate, requiredString } from '../utils/validate';
import useTrackActivityGA from '../utils/useTrackActivityGA';

import { activities, ACTIVITY_STATUS } from '../config/levels';

import { ReactComponent as RemoveIcon } from '../assets/remove-icon.svg';

const goalFormValidationSchema = validate({
  title: requiredString,
});

const goalFormInitialValues = {
  title: '',
};

const goalFormFields = [
  {
    id: 'title',
    name: 'title',
    label: 'Goal',
    type: 'textarea',
    placeholder: 'Enter your goal...',
  },
];

function ActivityGoalsStepTwo({ activityId }) {
  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data, loading } = useQuery(GET_ACTIVITY_DETAILS, {
    variables: { id: activityId },
  });

  const { data: resultData, loading: loadingResults } = useQuery(GET_RESULTS);

  const { data: goalData, loading: loadingGoals } = useQuery(GET_GOALS, {
    variables: { activityId },
  });

  const filteredGoals = goalData?.goals?.filter(goal => {
    return goal.type === 'FINAL';
  });

  const isGoalsValid = filteredGoals?.length > 4;

  const [
    handleAddGoal,
    { loading: loadingAddGoal, error: errorAddGoal },
  ] = useMutation(ADD_GOAL, {
    refetchQueries: ['Goals'],
  });

  const handleNewGoal = formData => {
    handleAddGoal({
      variables: {
        activityId,
        type: 'FINAL',
        ...formData,
      },
    });
  };

  const [
    handleDeleteGoal,
    { loading: loadingDeleteGoal, error: errorDeleteGoal },
  ] = useMutation(DELETE_GOAL, {
    refetchQueries: ['Goals'],
  });

  const activityType = data?.activity?.type;

  useTrackActivityGA(data?.activity?.type, '2');

  const { level } = activities[activityType] || {};

  const {
    title,
    sub_title: subTitle,
    audio_instructions_2: audioInstructions2,
    help_text_2: helpText2,
    short_instructions_2: shortInstructions2,
    step_label_2: stepInstructions2,
  } =
    cmsData?.cmsContent?.activity?.find(
      activity =>
        activity.id ===
        (cmsData?.user?.currentLevel === 4 &&
        data?.activity?.status === ACTIVITY_STATUS.COMPLETED
          ? `ongoing_${activityType}`
          : activityType),
    ) ||
    cmsData?.cmsContent?.activity?.find(
      activity => activity.id === activityType,
    ) ||
    {};

  return (
    <Layout
      title={`${extractPrismicString(title)}`}
      loading={loading || loadingGoals || loadingResults}
      hasFooterBar
    >
      <Container>
        <Section>
          {!!(hasContent(shortInstructions2) || hasContent(helpText2)) && (
            <Grid>
              <GridItem width={60}>
                <TextContent redh6 smallMargin>
                  {extractPrismicContent(shortInstructions2)}
                </TextContent>
              </GridItem>

              <GridItem width={40}>
                {!!(audioInstructions2?.url || hasContent(helpText2)) && (
                  <Notification showUser noMarginBottom>
                    {extractPrismicContent(helpText2)}

                    {!!audioInstructions2?.url && (
                      <AudioPlayer fileUrl={audioInstructions2?.url} />
                    )}
                  </Notification>
                )}
              </GridItem>
            </Grid>
          )}
        </Section>
        <Section smallPadding>
          <Grid>
            <GridItem width={50} alignTop>
              <Card header="Top 3 career interests">
                <SimpleBarList
                  data={resultData?.results?.interests?.interests?.slice(0, 3)}
                  emptyDataMessage="Completing the 'Your interests' activity first, can be super helpful for this activity!"
                  emptyDataLink="/activities"
                  emptyDataLinkText="Back to activities"
                />
              </Card>
            </GridItem>

            <GridItem width={50} alignTop>
              <Card header="Your Vision">
                <VisionSummary
                  data={resultData?.results?.vision}
                  emptyDataMessage="Completing the 'Your Vision' activity first, can be super helpful for this activity!"
                  emptyDataLink="/activities"
                  emptyDataLinkText="Back to activities"
                />
              </Card>
            </GridItem>
          </Grid>
        </Section>

        <TextContent redh6 smallMargin>
          <h6>Step 2</h6>
          <p>{extractPrismicString(stepInstructions2)}</p>
        </TextContent>

        <Table
          cols={[{ id: 'title', value: 'Goal' }]}
          data={filteredGoals}
          redorderId="goalId"
          actions={[
            {
              id: 'remove',
              text: 'remove',
              icon: <RemoveIcon />,
              onClick: handleDeleteGoal,
              loading: loadingDeleteGoal,
            },
          ]}
          form={{
            fields: goalFormFields,
            loading: loadingAddGoal,
            validationSchema: goalFormValidationSchema,
            initialValues: goalFormInitialValues,
            onSubmit: handleNewGoal,
          }}
        />

        {!!(errorAddGoal || errorDeleteGoal) && (
          <Notification type="red">
            {extractGraphQLError(errorAddGoal || errorDeleteGoal)}
          </Notification>
        )}
      </Container>

      <FooterBar
        title={extractPrismicString(title)}
        subTitle={extractPrismicString(subTitle)}
        primaryButton={{
          label: 'Next: Rank Goals',
          to: `/activities/${activityId}/goals/3`,
          disabled: !isGoalsValid,
          level,
        }}
        secondaryButton={{
          label: 'Back to goal reflection',
          to: `/activities/${activityId}/goals`,
        }}
      />
    </Layout>
  );
}

ActivityGoalsStepTwo.propTypes = {
  activityId: PropTypes.string,
};

ActivityGoalsStepTwo.defaultProps = {
  activityId: null,
};

export default ActivityGoalsStepTwo;
