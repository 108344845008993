import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Cell = styled.td(
  ({ theme, small, medium, xSmall }) => `
    border-bottom: solid 1px ${theme.colors.grey200};
    color: ${theme.colors.grey500};
    padding: ${theme.spacing.base} ${theme.spacing.medium};
    font-size: ${theme.fontSizes.small};
    word-wrap: break-word;

    h3 {
      margin-bottom: 0;
    }

    ${
      small
        ? `
      width: ${theme.spacing.xLarge};
    `
        : ''
    }


    ${
      xSmall
        ? `
      width: 60px;
      padding: ${theme.spacing.base};
    `
        : ''
    }


    ${medium &&
      `
      width: 200px;
    `}
  `,
);

function TableCell({ children, value, transformer, i, id, ...props }) {
  return <Cell {...props}>{children || transformer(value, i, id)}</Cell>;
}

TableCell.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  transformer: PropTypes.func,
  i: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
};

TableCell.defaultProps = {
  children: null,
  value: '',
  transformer: value => value,
};

export default memo(TableCell);
