import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import FormField from './FormField';
import TableCell from './TableCell';
import TableButton from './TableButton';

import isArrayWithLength from '../utils/isArrayWithLength';

const FormWrapper = styled.form`
  width: 100%;
`;

const Table = styled.table`
  border-spacing: 0;
  padding: 0;
  table-layout: fixed;
  width: 100%;
  
  td {
    background-color: ${props => props.theme.colors.grey100}
    border: none;
  }

  ${props =>
    props.white &&
    `
    td {
      background-color: transparent;
      border-bottom: solid 1px ${props.theme.colors.grey200};
    }
  `}
`;

const CellInner = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  button {
    margin-left: ${props => props.theme.spacing.base};
  }
`;

function TableForm({
  fields,
  initialValues,
  onSubmit,
  validationSchema,
  dataTransformer,
  loading,
  totalCols,
  sortable,
  actions,
  editable,
  update,
  outerButton,
  ...props
}) {
  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={
        initialValues?.Goal?.id
          ? { ...initialValues, goalId: initialValues.Goal.id }
          : initialValues
      }
      onSubmit={onSubmit}
      validationSchema={validationSchema && validationSchema}
      {...props}
      render={({ handleSubmit, values, errors }) => {
        return (
          <tfoot>
            <tr>
              <td colSpan={totalCols}>
                <FormWrapper onSubmit={handleSubmit} noValidate>
                  <Table {...props}>
                    <tbody>
                      <tr>
                        {sortable && <TableCell small />}

                        {isArrayWithLength(fields) &&
                          fields.map((field, i) => (
                            <TableCell
                              key={field.id}
                              small={field.small}
                              medium={field.medium}
                            >
                              <CellInner>
                                <FormField
                                  values={values}
                                  errors={errors}
                                  {...props}
                                  {...field}
                                  hideLabel
                                  rows="5"
                                  noMargin
                                  small
                                  hideErrorMessage
                                />

                                {!!(
                                  !outerButton && i + 1 === fields.length
                                ) && (
                                  <TableButton
                                    loading={loading}
                                    update={update}
                                  />
                                )}
                              </CellInner>
                            </TableCell>
                          ))}

                        {outerButton && (
                          <TableCell small>
                            <TableButton loading={loading} update={update} />
                          </TableCell>
                        )}

                        {!!isArrayWithLength(actions) &&
                          actions.map(({ id: actionId }) => (
                            <TableCell key={actionId} xSmall />
                          ))}

                        {editable && <TableCell xSmall />}
                      </tr>
                    </tbody>
                  </Table>
                </FormWrapper>
              </td>
            </tr>
          </tfoot>
        );
      }}
    />
  );
}

TableForm.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  initialValues: PropTypes.shape({}),
  onSubmit: PropTypes.func,
  validationSchema: PropTypes.shape({}),
  dataTransformer: PropTypes.func,
  loading: PropTypes.bool,
  totalCols: PropTypes.number.isRequired,
  sortable: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.shape({})),
  editable: PropTypes.bool,
  update: PropTypes.bool,
  outerButton: PropTypes.bool,
};

TableForm.defaultProps = {
  onSubmit: undefined,
  initialValues: {},
  validationSchema: null,
  dataTransformer: undefined,
  fields: null,
  loading: false,
  sortable: false,
  actions: null,
  editable: false,
  update: false,
  outerButton: false,
};

export default memo(TableForm);
