import React from 'react';
import styled from 'styled-components';

import Container from './Container';
import Section from './Section';
import TextContentMarketing from './TextContentMarketing';

import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';

const Wrapper = styled.div`
  h2 {
    color: ${props => props.theme.colors.red500};
    margin-bottom: ${props => props.theme.spacing.large};
  }
`;

const ItemList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -${props => props.theme.spacing.midLarge};
`;

const Item = styled.div`
  width: 100%;
  flex: 0 0 100%;
  padding: ${props => props.theme.spacing.midLarge};

  ${props => props.theme.breakpoints.medium`
    width: 50%;
    flex: 0 0 50%;
  `}

  ${props => props.theme.breakpoints.large`
    width: 33.33%;
    flex: 0 0 33.33%;
  `}
`;

const Icon = styled.img`
  max-height: 100px;
  max-width: 100px;
  margin-bottom: ${props => props.theme.spacing.midLarge};
`;

function MarketingFeatureList({
  feature_list_small: small,
  feature_list_background_color: backgroundColor,
  feature_list_title: title,
  items,
}) {
  if (!items) return null;

  return (
    <Wrapper>
      <Section
        padding={small ? 'medium' : 'large'}
        backgroundColor={backgroundColor}
      >
        <Container>
          {hasContent(title) && <h2>{extractPrismicString(title)}</h2>}

          <ItemList>
            {items.map(
              ({
                feature_list_title: itemTitle,
                feature_list_text: text,
                feature_list_icon: icon,
              }) => (
                <Item>
                  <TextContentMarketing>
                    {!!(icon && icon.url) && <Icon src={icon.url} />}
                    {hasContent(itemTitle) && (
                      <h3>{extractPrismicString(itemTitle)}</h3>
                    )}

                    {extractPrismicContent(text)}
                  </TextContentMarketing>
                </Item>
              ),
            )}
          </ItemList>
        </Container>
      </Section>
    </Wrapper>
  );
}

MarketingFeatureList.propTypes = {};

export default MarketingFeatureList;
