import gql from 'graphql-tag';

const GET_ACTIVITIES = gql`
  query Activities {
    activities {
      id
      type
      status
      type
      level
      isJoinedFree
    }
  }
`;

const GET_ACTIVITY_DETAILS = gql`
  query Activity($id: ID!) {
    activity(id: $id) {
      id
      type
      status
      type
      level
      vision
      visionSummary
      flexibility
      interdependence
      entrepreneurial
      professional
      agile
      beurocratic
      values {
        id
        type
        ranking
      }
      interests {
        id
        title
        description1
        description2
        ranking
      }
      hashtags {
        id
        title
        description1
        type
      }
      experiences {
        id
        title
        description1
      }
      beliefs {
        id
        title
        description1
        description2
      }
      goals {
        id
        title
        description1
        description2
        description3
        description4
        description5
      }
      rating
      ratingText
      name
      companyName
      jobDescription
      interviewDate
      notes
      companyCulture
      companyStructure
      result {
        id
        type
      }
    }
  }
`;

const ADD_ACTIVITY = gql`
  mutation AddActivity($status: String!, $level: Int!, $type: String!) {
    addActivity(status: $status, level: $level, type: $type) {
      user {
        id
        currentLevel
        lastCompletedActivity
        completedActivities
      }
      activity {
        id
        type
        status
        type
        level
        rating
        ratingText
      }
    }
  }
`;

const UPDATE_ACTIVITY = gql`
  mutation UpdateActivity(
    $id: ID!
    $status: String
    $vision: String
    $visionSummary: String
    $rating: Int
    $ratingText: String
    $name: String
    $companyName: String
    $jobDescription: String
    $interviewDate: String
    $notes: String
    $companyCulture: String
    $companyStructure: String
  ) {
    updateActivity(
      id: $id
      status: $status
      vision: $vision
      visionSummary: $visionSummary
      rating: $rating
      ratingText: $ratingText
      name: $name
      companyName: $companyName
      jobDescription: $jobDescription
      interviewDate: $interviewDate
      notes: $notes
      companyCulture: $companyCulture
      companyStructure: $companyStructure
    ) {
      user {
        id
        currentLevel
        completedActivities
      }
      activity {
        id
        type
        status
        vision
        visionSummary
        rating
        ratingText
        name
        companyName
        jobDescription
        interviewDate
        notes
        companyCulture
        companyStructure
      }
    }
  }
`;

export { GET_ACTIVITIES, ADD_ACTIVITY, GET_ACTIVITY_DETAILS, UPDATE_ACTIVITY };
