import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BarList from './BarList';

import transformArrayToBarChartObject from '../utils/transformArrayToBarChartObject';

const ChartWrapper = styled.div`
  height: ${props => props.theme.components.chartHeight};
  display: flex;
`;

function ActionsVisualisation({ data }) {
  const transformedData = transformArrayToBarChartObject(
    data?.actions,
    null,
    null,
    'title',
    false,
  ).slice(0, 5);

  return (
    <ChartWrapper>
      <BarList data={transformedData} />
    </ChartWrapper>
  );
}

ActionsVisualisation.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
export default ActionsVisualisation;
