import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/react-hooks';
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';

import App from './App';
import GlobalStyle from './theme/GlobalStyle';
import main from './theme/main';

import client from './config/client';

const SCHEMA_VERSION = '7';
const SCHEMA_VERSION_KEY = 'CK_SCHEMA_VERSION';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
});

// Initialise GA
ReactGA.initialize(process.env.REACT_APP_GA_ID);

const renderApp = async () => {
  const currentVersion = await window.localStorage.getItem(SCHEMA_VERSION_KEY);

  try {
    if (currentVersion !== SCHEMA_VERSION) {
      await client.clearStore();
      await window.localStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
    }
  } catch (err) {
    console.error(err);
  }

  const container = document.getElementById('root');
  const root = createRoot(container);

  root.render(
    <ApolloProvider client={client}>
      <ThemeProvider theme={main}>
        <GlobalStyle />
        <App />
      </ThemeProvider>
    </ApolloProvider>,
  );

  // TODO: Configure service worker
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  // serviceWorker.unregister();
};

renderApp();
