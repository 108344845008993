import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useSpring, animated } from 'react-spring';

import Header from './Header';
import Hero from './Hero';
import Footer from './Footer';
import Loader from './Loader';

import useSiteSettings from '../hooks/useSiteSettings';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.grey100};

  ${props =>
    props.hasFooterBar &&
    props.theme.breakpoints.medium`
    padding-bottom: calc(${props.theme.components.footerBar} + ${
      props.theme.spacing.midLarge
    });
  `}
`;

const Main = styled(animated.main)`
  min-height: calc(
    100vh - ${props => props.theme.components.headerHeight} -
      ${props => props.theme.spacing.xLarge}
  );
  position: relative;

  ${props =>
    props.fullHero &&
    `
    min-height: 0;
  `}
`;

function Layout({
  title,
  children,
  hero,
  loading,
  hasFooterBar,
  nofooter,
  fullHero,
  ...props
}) {
  const { favIconURL, siteName, bannerBgImage } = useSiteSettings();

  const style = useSpring({
    opacity: loading ? 0 : 1,
  });

  return (
    <Wrapper hasFooterBar={hasFooterBar}>
      <Helmet>
        <title>{`${siteName} - ${title}`}</title>
        <link rel="icon" href={favIconURL} />
      </Helmet>

      <Header {...props} />

      <Hero
        {...props}
        {...hero}
        fullHero={fullHero}
        backgroundImage={bannerBgImage}
      />

      {loading && <Loader white={Object.entries(hero).length} />}

      <Main style={style} fullHero={fullHero}>
        {!loading && children}
      </Main>

      {!!(!hasFooterBar && !nofooter) && <Footer dark />}
    </Wrapper>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  hero: PropTypes.shape({}),
  loading: PropTypes.bool,
  hasFooterBar: PropTypes.bool,
  nofooter: PropTypes.bool,
  fullHero: PropTypes.bool,
};

Layout.defaultProps = {
  hero: {},
  loading: false,
  hasFooterBar: false,
  nofooter: false,
  fullHero: false,
};

export default Layout;
