import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import getButtonElement from '../utils/getButtonElement';

const Wrapper = styled.a`
  ${({ theme, disabled }) => `
    color: ${theme.colors.black};
    text-decoration: underline;
    font-weight: ${theme.fontWeights.bold}; 

    &:hover {
      text-decoration: none;
    }


  ${
    disabled
      ? `
    pointer-events: none;
    opacity: 0.5;
  `
      : ``
  }
`}
`;

function TextButton({ children, disabled, ...props }) {
  const elementType = getButtonElement(props);
  const ElementType = Wrapper.withComponent(elementType);

  return (
    <ElementType {...props} disabled={disabled}>
      {children}
    </ElementType>
  );
}

TextButton.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

TextButton.defaultProps = {
  disabled: false,
};

export default memo(TextButton);
