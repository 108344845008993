import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import Container from './Container';
import Section from './Section';
import TextContentMarketing from './TextContentMarketing';
import ImageLoader from './ImageLoader';

import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';

const Wrapper = styled.div`
  h3 {
    color: ${props => props.theme.colors.red500};
    margin-bottom: ${props => props.theme.spacing.large};
  }

  .slick-prev {
    left: -100px;
  }

  .slick-next {
    right: -100px;
    z-index: 1;
  }

  .slick-dots li {
    margin: 0;
  }

  .slick-dots {
    bottom: -30px;
  }

  .slick-dots li button:before {
    color: ${props => props.theme.colors.white};
    opacity: 0.6;
  }

  .slick-dots li.slick-active button:before {
    color: ${props => props.theme.colors.white};
    opacity: 1;
  }
`;

const ItemList = styled.div`
  background-color: ${props => props.theme.colors.green500};
  background-image: url('/case-study-bg.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing.midLarge};

  ${props => props.theme.breakpoints.medium`
    padding: 60px 130px;
    position: relative;
  `}

  h4 {
    color: ${props => props.theme.colors.white};
  }
`;

const Item = styled.div`
  ${props => props.theme.breakpoints.medium`
    width: 50%;
  `}

  ${props => props.theme.breakpoints.large`
    width: 60%;
  `}
`;

const ImageContainer = styled.div`
  display: none;

  ${props => props.theme.breakpoints.medium`
    display: block;
    width: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    z-index: 0;
  `}

  ${props => props.theme.breakpoints.large`
    width: 35%;
  `}
`;

function MarketingCaseStudy({
  case_study_small: small,
  case_study_background_color: backgroundColor,
  case_study_title: title,
  case_study_image: image,
  items,
}) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  if (!items) return null;

  return (
    <Wrapper>
      <Section
        padding={small ? 'medium' : 'large'}
        backgroundColor={backgroundColor}
      >
        <Container>
          {hasContent(title) && <h3>{extractPrismicString(title)}</h3>}

          <ItemList>
            <Slider {...settings}>
              {items.map(
                ({
                  case_study_slide_title: itemTitle,
                  case_study_slide_text: itemText,
                }) => (
                  <div>
                    <Item>
                      <TextContentMarketing>
                        {hasContent(itemTitle) && (
                          <h4>{extractPrismicString(itemTitle)}</h4>
                        )}

                        {extractPrismicContent(itemText)}
                      </TextContentMarketing>
                    </Item>
                  </div>
                ),
              )}
            </Slider>

            <ImageContainer>
              {!!(image && image.url) && (
                <ImageLoader contain alignBottom auto imageUrl={image.url} />
              )}
            </ImageContainer>
          </ItemList>
        </Container>
      </Section>
    </Wrapper>
  );
}

MarketingCaseStudy.propTypes = {};

export default MarketingCaseStudy;
