import gql from 'graphql-tag';

const GET_CURRENCY = gql`
  query GetCurrency {
    currency @client
    geolocated @client
  }
`;

export { GET_CURRENCY };
