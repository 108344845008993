import React from 'react';
import styled from 'styled-components';
import BarLoader from 'react-spinners/BarLoader';

import { colors } from '../theme/main';

import useSiteSettings from '../hooks/useSiteSettings';

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    display: block;
    width: 50px;
    height: 50px;
    margin: 0 auto ${props => props.theme.spacing.midLarge} auto;
    fill: ${props => props.theme.colors.black};
  }
`;

function MainLoader() {
  const {logoIconURL, siteName} = useSiteSettings()

  return (
    <Wrapper>
      <img src={logoIconURL} alt={siteName} />
      <BarLoader color={colors.green500} size={10} loading />
    </Wrapper>
  );
}

export default MainLoader;
