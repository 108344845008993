import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from './Button';
import StatusIcon from './StatusIcon';
import Tag from './Tag';

import { ACTIVITY_STATUS } from '../config/levels';

import { extractPrismicString } from '../utils/prismic';
import { isThirdPartyAssignment } from '../utils/isAssigment';

const Item = styled.li`
  border-bottom: solid 1px ${(props) => props.theme.colors.grey200};
  margin: 0;
  padding: ${(props) => props.theme.spacing.base}
    ${(props) => props.theme.spacing.midLarge};
  text-align: left;

  ${(props) => props.theme.breakpoints.medium`
    display: flex;
    align-items: center;
  `}

  &:last-child {
    border-bottom: none;
  }

  ${(props) =>
    props.isDisabled &&
    `
      opacity: 0.1;
      pointer-events: none;
  `}
`;

const Text = styled.div`
  margin: ${(props) => props.theme.spacing.base} 0;

  ${(props) => props.theme.breakpoints.medium`
    margin: 0 auto 0 ${props.theme.spacing.midLarge};
  `}

  h4,
  p {
    margin-bottom: 0;
  }

  h4 {
    display: flex;
    align-items: center;

    div {
      margin-left: ${(props) => props.theme.spacing.xSmall};
    }
  }
`;

const ButtonContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.medium`
    align-items: flex-end;
  `}

  p {
    font-size: ${(props) => props.theme.fontSizes.xSmall};
    margin: ${(props) => props.theme.spacing.xxSmall} 0 0 0;
    color: ${(props) => props.theme.colors.grey500};
    text-align: center;

    ${(props) => props.theme.breakpoints.medium`
     text-align: right;
    `}
  }
`;

function ActivityListItem({
  type,
  level,
  activityData,
  isDisabled,
  onStartActivity,
  loading,
  cmsContent,
  activities,
  resultData,
  paid,
  hasActiveSubscription,
  availableFreeActivities,
  dependentActivity,
  lockedText,
  typeOfSubscription,
}) {
  const isFreeUserWithAssignment =
    typeOfSubscription === 'Free with No Assignment';

  const { status, id, isJoinedFree } =
    activityData?.find((item) => item.type === type) || {};
  const activityMetaArray = Object.values(activities);
  const activityIndex = activityMetaArray.findIndex(
    (item) => item.type === type,
  );

  const { results, editable } = activityMetaArray[activityIndex] || {};

  const { status: dependentActivityStatus } =
    activityData?.find((item) => item.type === dependentActivity) || {};

  const { title, sub_title: subTitle } =
    cmsContent?.activity?.find((activity) => activity.id === type) || {};

  const isAssignment = String(title?.[0]?.text || '').includes('Assessment');

  const is3rdPartyAssignment = React.useMemo(
    () => isThirdPartyAssignment(extractPrismicString(title)),
    [title],
  );

  const renderButton = () => {
    const { id: resultId } = resultData?.[results?.[0]] || {};

    if (
      dependentActivity &&
      dependentActivityStatus !== ACTIVITY_STATUS.COMPLETED
    ) {
      return (
        <>
          <Button small level={level} disabled>
            Start Activity
          </Button>
          <p>{lockedText}</p>
        </>
      );
    }

    if (status === ACTIVITY_STATUS.COMPLETED) {
      return (
        <>
          {editable && (
            <Button
              marginRight
              small
              to={`/activities/${id}/introduction`}
              level={level}
              disabled={loading}
            >
              Edit
            </Button>
          )}
          <Button
            secondary
            small
            to={resultId ? `/results/${results[0]}/${resultId}` : '/results'}
            disabled={loading}
          >
            View results
          </Button>
        </>
      );
    }

    if (status === ACTIVITY_STATUS.PENDING) {
      return (
        <Button small secondary disabled>
          Processing...
        </Button>
      );
    }

    if (status === ACTIVITY_STATUS.OPEN) {
      return (
        <Button
          small
          to={`/activities/${id}/introduction`}
          level={level}
          disabled={loading}
        >
          Continue
        </Button>
      );
    }

    if (isFreeUserWithAssignment && !is3rdPartyAssignment) {
      return (
        <Button
          small
          onClick={() => onStartActivity({ type, level, paid })}
          level={level}
          disabled={loading}
        >
          Start Activity
        </Button>
      );
    }

    if (
      !hasActiveSubscription &&
      paid &&
      (availableFreeActivities === 0 || level !== 1 || isAssignment)
    ) {
      return (
        <Button small to={`/activities/${type}/start`} level={level}>
          Find out more
        </Button>
      );
    }

    return (
      <Button
        small
        onClick={() => onStartActivity({ type, level, paid })}
        level={level}
        disabled={loading}
      >
        Start Activity
      </Button>
    );
  };

  const renderPaidTag = () => {
    if (
      hasActiveSubscription ||
      (isFreeUserWithAssignment && !is3rdPartyAssignment)
    )
      return false;
    if (
      !isJoinedFree &&
      paid &&
      (availableFreeActivities === 0 || level !== 1 || isAssignment)
    )
      return <Tag grey>Premium</Tag>;

    return <Tag>Free</Tag>;
  };

  if (level === 4) return false;

  return (
    <Item isDisabled={isDisabled}>
      <StatusIcon status={status} level={level} />

      <Text>
        <h4>
          {extractPrismicString(title)}
          {renderPaidTag()}
        </h4>
        <p>{extractPrismicString(subTitle)}</p>
      </Text>

      <ButtonContainer>{renderButton()}</ButtonContainer>
    </Item>
  );
}

ActivityListItem.propTypes = {
  level: PropTypes.number.isRequired,
  activityData: PropTypes.arrayOf(PropTypes.shape).isRequired,
  type: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onStartActivity: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  cmsContent: PropTypes.shape({}).isRequired,
  activities: PropTypes.arrayOf(PropTypes.shape).isRequired,
  resultData: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

ActivityListItem.defaultProps = {
  loading: false,
};

export default ActivityListItem;
