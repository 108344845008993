import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BarLoader from 'react-spinners/BarLoader';

import { colors } from '../theme/main';

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

function Loader({ white }) {
  return (
    <Wrapper>
      <BarLoader
        color={white ? colors.white : colors.green500}
        size={10}
        loading
      />
    </Wrapper>
  );
}

Loader.propTypes = {
  white: PropTypes.bool,
};

Loader.defaultProps = {
  white: false,
};

export default Loader;
