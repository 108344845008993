import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';

import Navigation from './Navigation';
import Container from './Container';

import useSiteSettings from '../hooks/useSiteSettings';

const Wrapper = styled.header`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.black};
  height: ${props => props.theme.components.headerHeight};
  display: flex;
  align-items: center;
  box-shadow: ${props => props.theme.base.shadow};
  position: relative;
  z-index: ${props => props.theme.layers.header};
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
`;

const LogoLink = styled(Link)`
  display: block;
  width: 80px;

  ${props => props.theme.breakpoints.medium`
    width: 112px;
  `}

  & svg {
    display: block;
    fill: ${props => props.theme.colors.black};
    width: 80px;

    ${props => props.theme.breakpoints.medium`
      width: 112px;
    `}
  }

  &:hover,
  &:active {
    opacity: 0.8;
  }

  span {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
`;

function Header({ marketing, ...props }) {
  const {logoURL, siteName} = useSiteSettings()
  return (
    <Wrapper>
      <Container {...props}>
        <Inner>
          <LogoLink to="/">
            <img src={logoURL} alt={siteName} />
            <span>{siteName}</span>
          </LogoLink>
          <Navigation {...props} />
        </Inner>
      </Container>
    </Wrapper>
  );
}

Header.propTypes = {
  marketing: PropTypes.bool,
};

Header.defaultProps = {
  marketing: false,
};

export default Header;
