import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Tag from './Tag';
import Button from './Button';

import { extractPrismicString } from '../utils/prismic';

import { ReactComponent as ChevronIcon } from '../assets/chevron-right-icon.svg';

const Item = styled.li`
  border-bottom: solid 1px ${props => props.theme.colors.grey200};
  display: flex;
  align-items: center;
  margin: 0;
  padding: ${props => props.theme.spacing.base}
    ${props => props.theme.spacing.midLarge}
    ${props => props.theme.spacing.base} 0;
  text-align: left;

  &:last-child {
    border-bottom: none;
  }

  ${props =>
    props.isDisabled &&
    `
      opacity: 0.1;
      pointer-events: none;
  `}
`;

const Text = styled.div`
  margin: 0 auto 0 ${props => props.theme.spacing.midLarge};

  h4 {
    display: flex;
    align-items: center;
    font-size: ${props => props.theme.fontSizes.medium};
    line-height: 1;
    margin-bottom: ${props => props.theme.spacing.xxSmall};
  }

  p {
    margin-bottom: 0;
  }
`;

const Action = styled.div`
  margin-left: auto;

  a {
    margin-left: ${props => props.theme.spacing.small};
  }
`;


function ResultListItem({ id, cmsContent, resultData }) {
  const { title, subtitle } =
    cmsContent?.result?.find(result => result.id === id) || {};

  const { new: isNew, id: resultId } = resultData?.[id] || {};

  return (
    <Item>
      <Text>
        <h4>
          {isNew && <Tag marginRight>New</Tag>}
          {extractPrismicString(title)}
        </h4>
        <p>{extractPrismicString(subtitle)}</p>
      </Text>

      <Action>
        <Button round to={`/results/${id}/${resultId}`}>
          <ChevronIcon />
        </Button>
      </Action>
    </Item>
  );
}

ResultListItem.propTypes = {
  id: PropTypes.string.isRequired,
  cmsContent: PropTypes.shape({}).isRequired,
  resultData: PropTypes.shape({}).isRequired,
};

export default ResultListItem;
