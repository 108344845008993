import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';

import Layout from '../components/Layout';
import Container from '../components/Container';
import Section from '../components/Section';
import Table from '../components/Table';
import Tag from '../components/Tag';
import Card from '../components/Card';
import TextContent from '../components/TextContent';
import Notification from '../components/Notification';
import Button from '../components/Button';
import Grid from '../components/Grid';
import GridItem from '../components/GridItem';
import ResultsSummaryCard from '../components/ResultsSummaryCard';

import { GET_USER_DETAILS, GET_USER_RESULTS } from '../graph/admin';
import { DELETE_USER } from '../graph/auth';
import { GET_CMS_CONTENT } from '../graph/cms';

import extractGraphQLError from '../utils/extractGraphQLError';

import { ACTIVITY_STATUS, TOTAL_ACTIVITIES } from '../config/levels';
import RESULTS from '../config/results';

import { ReactComponent as ChevronIcon } from '../assets/chevron-icon.svg';
import AdminUserTagList from '../components/AdminUserTagList';

function AdminUserDetails({ userId }) {
  const { data, loading } = useQuery(GET_USER_DETAILS, {
    variables: { userId },
  });

  const { data: resultData, loadingResults } = useQuery(GET_USER_RESULTS, {
    variables: { userId },
  });

  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const activityArray = [...(data?.userDetails?.activities || [])];
  const sortedActivityData = activityArray?.sort((a, b) => {
    if (a.status === ACTIVITY_STATUS.PENDING) {
      return -1;
    }

    if (b.status === ACTIVITY_STATUS.PENDING) {
      return 1;
    }

    return 0;
  });

  const handleViewUser = ({ variables: { id } }) => {
    navigate(`/activities/${id}`);
  };

  const [handleDeleteUser, { loading: loadingDelete, error: errorDelete }] =
    useMutation(DELETE_USER);

  const handleDeleteAccount = async () => {
    const deleteAccount = window.confirm(
      'Are you really sure you want to delete this account? There is no going back, the account and all associated data will be deleted permanantly.',
    );

    if (!deleteAccount) return;

    const result = await handleDeleteUser({
      variables: { id: userId },
    });

    if (!result?.data?.deleteUser?.success) return;

    navigate('/');
  };

  const renderItem = ({ id, level, result }) => (
    <GridItem key={id} width={50} grow>
      <ResultsSummaryCard
        cmsContent={cmsData?.cmsContent}
        currentLevel={data?.user?.level}
        resultData={resultData?.results}
        result={result}
        level={level}
        id={id}
      />
    </GridItem>
  );

  return (
    <Layout
      title="AdminUserDetails"
      isAdmin
      loading={loading || loadingResults}
    >
      <Container>
        <Section>
          <Grid>
            <GridItem width={50} style={{ textAlign: 'left' }}>
              <h2>User details</h2>

              <p>
                Name: <strong>{data?.userDetails?.name}</strong>
              </p>

              <p>
                Email Address: <strong>{data?.userDetails?.email}</strong>
              </p>

              <AdminUserTagList tag={data?.userDetails?.tag} />
            </GridItem>
            <GridItem width={50} style={{ textAlign: 'left' }}>
              <h2 style={{ opacity: 0 }}>User details</h2>

              <p>
                Pending Activities:{' '}
                <Tag>{data?.userDetails?.pendingActivities}</Tag>
              </p>

              <p>
                Current Level:{' '}
                <Tag level={data?.userDetails?.currentLevel}>
                  {data?.userDetails?.currentLevel}
                </Tag>
              </p>

              <p>
                Completed Activities:{' '}
                <strong>
                  {`${data?.userDetails?.completedActivities?.reduce(
                    (a, count) => a + count,
                    0,
                  )}/${TOTAL_ACTIVITIES}`}
                </strong>
              </p>
            </GridItem>
          </Grid>

          <Section>
            <h3>Activities</h3>

            <Table
              cols={[
                { id: 'type', value: 'Type' },
                {
                  id: 'level',
                  value: 'Level',
                  transformer: (value) =>
                    value > 0 && <Tag level={value}>{value}</Tag>,
                },
                {
                  id: 'status',
                  value: 'Status',
                  transformer: (value) => <Tag status={value}>{value}</Tag>,
                },
              ]}
              data={sortedActivityData}
              actions={[
                {
                  id: 'view',
                  text: 'view',
                  icon: <ChevronIcon />,
                  onClick: handleViewUser,
                },
              ]}
            />
          </Section>

          <Section>
            <h3>Results</h3>

            <Grid>
              {RESULTS?.map((resultMeta) =>
                renderItem({ ...resultMeta, result: null }),
              )}
              {resultData?.results?.interviewGuides?.map((result) =>
                renderItem({
                  id: 'interview_guide',
                  level: 4,
                  result,
                }),
              )}
            </Grid>
          </Section>

          <Section noPaddingTop>
            <Card header="Danger zone">
              <TextContent>
                <p>
                  Once you delete an account, there is no going back, the
                  account and all associated data will be deleted permanantly.
                  an account, there is no going back, the account and all
                  associated data will be deleted permanantly.
                </p>

                {!!errorDelete && (
                  <Notification type="red">
                    {extractGraphQLError(errorDelete)}
                  </Notification>
                )}

                <Button
                  red
                  small
                  onClick={handleDeleteAccount}
                  loading={loadingDelete}
                >
                  Delete account
                </Button>
              </TextContent>
            </Card>
          </Section>
        </Section>
      </Container>
    </Layout>
  );
}

AdminUserDetails.propTypes = {
  userId: PropTypes.string,
};

AdminUserDetails.defaultProps = {
  userId: null,
};

export default AdminUserDetails;
