import gql from 'graphql-tag';

const GET_HASHTAGS = gql`
  query Hashtags($activityId: ID!) {
    hashtags(activityId: $activityId) {
      id
      title
      description1
      type
    }
  }
`;

const ADD_HASHTAG = gql`
  mutation AddHashtag(
    $activityId: ID!
    $title: String!
    $description1: String
    $type: String!
  ) {
    addHashtag(
      activityId: $activityId
      title: $title
      description1: $description1
      type: $type
    ) {
      id
      title
      description1
      type
    }
  }
`;

const UPDATE_HASHTAG = gql`
  mutation UpdateHashtag(
    $id: ID!
    $title: String
    $description1: String
    $type: String
  ) {
    updateHashtag(
      id: $id
      title: $title
      description1: $description1
      type: $type
    ) {
      id
      title
      description1
      type
    }
  }
`;

const DELETE_HASHTAG = gql`
  mutation DeleteHashtag($id: ID!) {
    deleteHashtag(id: $id) {
      id
      title
      description1
      type
    }
  }
`;

export { GET_HASHTAGS, ADD_HASHTAG, UPDATE_HASHTAG, DELETE_HASHTAG };
