import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const PageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 10px;
`;

const NextContainer = styled.div`
  margin-left: 10px;
  cursor: pointer;
`;

const PreContainer = styled.div`
  cursor: pointer;
`;

function TablePagination({
  onPageCountChange,
  onPre,
  onNext,
  isPreDisabled,
  isNextDisabled,
  pageCount,
}) {
  return (
    <Container>
      <select
        value={pageCount}
        name=""
        id=""
        onChange={(e) => onPageCountChange(e.currentTarget.value)}
      >
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="200">200</option>
      </select>
      <PageContainer>
        {!isPreDisabled && <PreContainer onClick={onPre}>Pre</PreContainer>}
        {!isNextDisabled && (
          <NextContainer onClick={onNext}>Next</NextContainer>
        )}
      </PageContainer>
    </Container>
  );
}

export default TablePagination;
