import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Expandable from './Expandable';
import Section from './Section';

import { extractPrismicString, extractPrismicContent } from '../utils/prismic';
import cardBase from '../theme/cardBase';

const Wrapper = styled.div`
  ${cardBase}
  padding-top: 0;
  padding-bottom: 0;
`;

function PersonalityThemeTable({ data, cmsContent }) {
  const transformedData = data?.slice(0, 4)?.map(item => {
    const cmsTheme = cmsContent?.theme.find(theme => theme.id === item.type);
    const cmsTrait = cmsContent?.trait.find(
      trait => trait.id === item.topTrait,
    );

    return {
      title: extractPrismicString(cmsTheme?.title),
      value: extractPrismicContent(cmsTheme?.text),
      traitTitle: extractPrismicString(cmsTrait?.title),
      traitValue: extractPrismicContent(cmsTrait?.text),
    };
  });

  return (
    <Section smallPadding>
      <h3>Your strongest Personality Themes</h3>

      <Wrapper>
        {transformedData?.map(item => (
          <Expandable title={item.title}>
            {item.value}

            <h4>{item.traitTitle}</h4>
            {item.traitValue}
          </Expandable>
        ))}
      </Wrapper>
    </Section>
  );
}

PersonalityThemeTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  cmsContent: PropTypes.shape({}).isRequired,
};

export default PersonalityThemeTable;
