import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ResponsiveRadar } from '@nivo/radar';
import startCase from 'lodash.startcase';

import main, { colors } from '../theme/main';

const ChartWrapper = styled.div`
  height: calc(
    ${props => props.theme.components.chartHeight} +
      ${props => props.theme.spacing.midLarge} +
      ${props => props.theme.spacing.midLarge}
  );
  margin: -${props => props.theme.spacing.midLarge};
`;

function PersonalityVisualisation({ data }) {
  const transformedData = data?.themeVisualisation?.map(({ id, type, value }) => ({
    id,
    label: startCase(type),
    value,
  }));

  if (!transformedData || !transformedData.length) return false;

  return (
    <ChartWrapper>
      <ResponsiveRadar
        data={transformedData}
        indexBy="label"
        keys={['value']}
        maxValue={100}
        curve="catmullRomClosed"
        margin={{
          top: 40,
          right: 90,
          bottom: 40,
          left: 90,
        }}
        colors={colors.green500}
        radialLabelsTextColor={colors.grey500}
        enableDots
        gridLevels={1}
        animate
        isInteractive={false}
        theme={{
          textColor: colors.grey500,
          fontFamily: main.base.fontFamily,
        }}
      />
    </ChartWrapper>
  );
}

PersonalityVisualisation.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default PersonalityVisualisation;
