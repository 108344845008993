const geoLocationPosition = async () => {
  try {
    const response = await fetch(
      `https://api.ipgeolocation.io/ipgeo?apiKey=${
        process.env.REACT_APP_GEOLOCATE_API_KEY
      }`,
    );
    const responseJson = await response.json();

    return responseJson;
  } catch {
    return false;
  }
};

export default geoLocationPosition;
