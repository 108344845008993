import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { navigate } from '@reach/router';

import Layout from '../components/Layout';
import Container from '../components/Container';
import Section from '../components/Section';
import TextContent from '../components/TextContent';
import FullRoleVisualisation from '../components/FullRoleVisualisation';
import Notification from '../components/Notification';
import IndustryList from '../components/IndustryList';
import CultureVisualisation from '../components/CultureVisualisation';
import OrganisationVisualisation from '../components/OrganisationVisualisation';
import TextButton from '../components/TextButton';
import Button from '../components/Button';
import References from '../components/References';

import { GET_RESULTS } from '../graph/results';
import { GET_CMS_CONTENT } from '../graph/cms';

import {
  extractPrismicContent,
  extractPrismicString,
  hasContent,
} from '../utils/prismic';
import { ACTIVITY_STATUS } from '../config/levels';

function GoalGuide() {
  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data, loading } = useQuery(GET_RESULTS);

  const {
    content_1: content1,
    content_1_read_more: content1ReadMore,
    content_1_quote: content1Quote,
    content_1_quote_citation: content1QuoteCitation,
    content_2: content2,
    content_2_read_more: content2ReadMore,
    content_2_quote: content2Quote,
    content_2_quote_citation: content2QuoteCitation,
    content_3: content3,
    content_3_read_more: content3ReadMore,
    content_3_quote: content3Quote,
    content_3_quote_citation: content3QuoteCitation,
    content_4: content4,
    content_4_read_more: content4ReadMore,
    content_4_quote: content4Quote,
    content_4_quote_citation: content4QuoteCitation,
    content_4_image: content4Image,
    content_5: content5,
    content_5_read_more: content5ReadMore,
    content_5_quote: content5Quote,
    content_5_quote_citation: content5QuoteCitation,
    content_6: content6,
    content_6_read_more: content6ReadMore,
    content_6_quote: content6Quote,
    content_6_quote_citation: content6QuoteCitation,
    content_7: content7,
    content_7_read_more: content7ReadMore,
    content_7_quote: content7Quote,
    content_7_quote_citation: content7QuoteCitation,
    references,
  } = cmsData?.cmsContent?.goal_guide || {};

  const renderCultures = () => {
    const cmsCulture = cmsData?.cmsContent?.culture?.find(
      value => data?.results?.culture?.culture === value.id,
    );

    return (
      <>
        <Section white>
          <Container narrow>
            <TextContent redh6>
              <h6>You are likely to feel most comfortable in</h6>

              <h3>{extractPrismicString(cmsCulture?.title)}</h3>

              {hasContent(cmsCulture?.quote) && (
                <Notification type="green">
                  <blockquote>
                    {extractPrismicString(cmsCulture?.quote)}
                  </blockquote>
                  {hasContent(cmsCulture?.quote_citation) && (
                    <cite>
                      {' '}
                      {extractPrismicString(cmsCulture?.quote_citation)}
                    </cite>
                  )}
                </Notification>
              )}

              {extractPrismicContent(cmsCulture?.main_content)}
            </TextContent>
          </Container>
        </Section>

        {data?.results?.culture?.altCultures?.map((culture, i) => {
          const altCmsCulture = cmsData?.cmsContent?.culture?.find(
            value => culture === value.id,
          );

          return (
            <Section white={i % 2}>
              <Container narrow>
                <TextContent redh6>
                  <h6>You would also feel comfortable in</h6>

                  <h3>{extractPrismicString(altCmsCulture?.title)}</h3>

                  {hasContent(altCmsCulture?.quote) && (
                    <Notification type="green">
                      <blockquote>
                        {extractPrismicString(altCmsCulture?.quote)}
                      </blockquote>
                      {hasContent(altCmsCulture?.quote_citation) && (
                        <cite>
                          {' '}
                          {extractPrismicString(altCmsCulture?.quote_citation)}
                        </cite>
                      )}
                    </Notification>
                  )}

                  {extractPrismicContent(altCmsCulture?.main_content)}
                </TextContent>
              </Container>
            </Section>
          );
        })}
      </>
    );
  };

  const renderOrganisation = () => {
    const organisationType = [
      {
        id: 'bureaucratic',
        value: data?.results?.organisation?.bureaucratic,
      },
      {
        id: 'entrepreneurial',
        value: data?.results?.organisation?.entrepreneurial,
      },
      {
        id: 'professional',
        value: data?.results?.organisation?.professional,
      },
      {
        id: 'smoagile',
        value: data?.results?.organisation?.smoagile,
      },
    ].reduce(
      (a, org) => {
        return org.value > a.value ? org : a;
      },
      { value: 0 },
    );

    const cmsOrg = cmsData?.cmsContent?.organisation_type?.find(
      value => organisationType.id === value.id,
    );

    return (
      <Section white>
        <Container narrow>
          <TextContent redh6>
            <h6>My structural fit</h6>

            <h3>{extractPrismicString(cmsOrg?.title)}</h3>

            {extractPrismicContent(cmsOrg?.main_content)}
          </TextContent>
        </Container>
      </Section>
    );
  };

  return (
    <Layout title="Goal Setting Guide" loading={loading}>
      <Section>
        <Container narrow>
          <TextContent marginBottom>
            <p>
              <TextButton onClick={() => navigate(-1)}>
                Back to Goal Activity
              </TextButton>
            </p>
          </TextContent>

          {hasContent(content1) && (
            <TextContent maxHeight={content1ReadMore ? 200 : null} marginBottom>
              {hasContent(content1Quote) && (
                <Notification type="green">
                  <blockquote>{extractPrismicString(content1Quote)}</blockquote>
                  {hasContent(content1QuoteCitation) && (
                    <cite> {extractPrismicString(content1QuoteCitation)}</cite>
                  )}
                </Notification>
              )}

              {extractPrismicContent(content1)}
            </TextContent>
          )}
        </Container>
      </Section>

      <Section white>
        <Container narrow>
          {hasContent(content2) && (
            <TextContent maxHeight={content2ReadMore ? 200 : null} marginBottom>
              {hasContent(content2Quote) && (
                <Notification type="green">
                  <blockquote>{extractPrismicString(content2Quote)}</blockquote>
                  {hasContent(content2QuoteCitation) && (
                    <cite> {extractPrismicString(content2QuoteCitation)}</cite>
                  )}
                </Notification>
              )}

              {extractPrismicContent(content2)}
            </TextContent>
          )}
        </Container>

        <Container>
          {data?.results?.roles?.status === ACTIVITY_STATUS.COMPLETED && (
            <FullRoleVisualisation data={data?.results?.roles} />
          )}
        </Container>
      </Section>

      <Section>
        <Container narrow>
          {hasContent(content3) && (
            <TextContent maxHeight={content3ReadMore ? 200 : null} marginBottom>
              {hasContent(content3Quote) && (
                <Notification type="green">
                  <blockquote>{extractPrismicString(content3Quote)}</blockquote>
                  {hasContent(content3QuoteCitation) && (
                    <cite> {extractPrismicString(content3QuoteCitation)}</cite>
                  )}
                </Notification>
              )}

              {extractPrismicContent(content3)}
            </TextContent>
          )}
        </Container>

        <Container>
          {!!cmsData?.cmsContent?.industries && (
            <IndustryList data={cmsData?.cmsContent?.industries} />
          )}
        </Container>
      </Section>

      <Section white>
        <Container narrow>
          {hasContent(content4) && (
            <TextContent maxHeight={content4ReadMore ? 200 : null} marginBottom>
              {hasContent(content4Quote) && (
                <Notification type="green">
                  <blockquote>{extractPrismicString(content4Quote)}</blockquote>
                  {hasContent(content4QuoteCitation) && (
                    <cite> {extractPrismicString(content4QuoteCitation)}</cite>
                  )}
                </Notification>
              )}

              {extractPrismicContent(content4)}
            </TextContent>
          )}
        </Container>

        {content4Image?.url && (
          <Container>
            <Section smallPadding noPaddingBottom>
              <img src={content4Image?.url} alt={content4Image?.alt} />
            </Section>
          </Container>
        )}
      </Section>

      <Section>
        <Container narrow>
          {hasContent(content5) && (
            <TextContent maxHeight={content5ReadMore ? 200 : null} marginBottom>
              {hasContent(content5Quote) && (
                <Notification type="green">
                  <blockquote>{extractPrismicString(content5Quote)}</blockquote>
                  {hasContent(content5QuoteCitation) && (
                    <cite> {extractPrismicString(content5QuoteCitation)}</cite>
                  )}
                </Notification>
              )}

              {extractPrismicContent(content5)}
            </TextContent>
          )}
        </Container>
      </Section>

      <Section>
        <Container narrow>
          {hasContent(content6) && (
            <TextContent maxHeight={content6ReadMore ? 200 : null} marginBottom>
              {hasContent(content6Quote) && (
                <Notification type="green">
                  <blockquote>{extractPrismicString(content6Quote)}</blockquote>
                  {hasContent(content6QuoteCitation) && (
                    <cite> {extractPrismicString(content6QuoteCitation)}</cite>
                  )}
                </Notification>
              )}

              {data?.results?.culture?.status === ACTIVITY_STATUS.COMPLETED && (
                <aside>
                  <CultureVisualisation data={data?.results?.culture} />
                </aside>
              )}

              {extractPrismicContent(content6)}
            </TextContent>
          )}
        </Container>

        {data?.results?.culture?.status === ACTIVITY_STATUS.COMPLETED &&
          renderCultures()}
      </Section>

      <Section>
        <Container narrow>
          {hasContent(content7) && (
            <TextContent maxHeight={content7ReadMore ? 200 : null} marginBottom>
              {hasContent(content7Quote) && (
                <Notification type="green">
                  <blockquote>{extractPrismicString(content7Quote)}</blockquote>
                  {hasContent(content7QuoteCitation) && (
                    <cite> {extractPrismicString(content7QuoteCitation)}</cite>
                  )}
                </Notification>
              )}

              {data?.results?.organisation?.status ===
                ACTIVITY_STATUS.COMPLETED && (
                <aside>
                  <OrganisationVisualisation
                    data={data?.results?.organisation}
                  />
                </aside>
              )}

              {extractPrismicContent(content7)}
            </TextContent>
          )}
        </Container>

        {data?.results?.organisation?.status === ACTIVITY_STATUS.COMPLETED &&
          renderOrganisation()}

        {hasContent(references) && (
          <Section smallPadding>
            <Container narrow>
              <References>{extractPrismicContent(references)}</References>
            </Container>
          </Section>
        )}

        <Section white smallPadding>
          <Container narrow>
            <TextContent>
              <p>
                <Button small secondary onClick={() => navigate(-1)}>
                  Back to Goal Activity
                </Button>
              </p>
            </TextContent>
          </Container>
        </Section>
      </Section>
    </Layout>
  );
}

export default GoalGuide;
