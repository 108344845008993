import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import { TagsInput } from 'react-tag-input-component';
import { UPDATE_USER_TAG } from '../graph/auth';

const DivCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DivContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

function AdminUserTag({ id, tag }) {
  const [text, setText] = React.useState([]);
  const [handleAddTag, { loading }] = useMutation(UPDATE_USER_TAG);

  React.useEffect(() => {
    if (!text.length && tag?.length) {
      setText(JSON.parse(tag));
    }
  }, [tag, text.length]);

  const handleSubmit = _.debounce(value => {
    handleAddTag({
      variables: {
        updateUserTagsId: id,
        tag: JSON.stringify(value),
      },
    });
  }, 500);

  const handelUpdate = value => {
    setText(value);
    const currentTag = tag ? JSON.parse(tag) : [];
    if (JSON.stringify(currentTag) !== JSON.stringify(value)) {
      handleSubmit(value);
    }
  };

  return (
    <DivContainer>
      <DivCenter>
        <TagsInput
          value={text}
          onChange={handelUpdate}
          placeHolder="Tags"
          disabled={loading}
        />
      </DivCenter>
    </DivContainer>
  );
}

export default AdminUserTag;
