import React, { useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { navigate } from '@reach/router';

import Layout from '../components/Layout';
import Container from '../components/Container';
import Section from '../components/Section';
import TextContent from '../components/TextContent';
import Table from '../components/Table';
import References from '../components/References';

import Tag from '../components/Tag';
import FooterBar from '../components/FooterBar';

import { GET_RESULTS, UPDATE_RESULT } from '../graph/results';
import { GET_CMS_CONTENT } from '../graph/cms';

import {
  extractPrismicContent,
  extractPrismicString,
  hasContent,
} from '../utils/prismic';

import { ACTIVITY_STATUS } from '../config/levels';

function InterviewGuide({ resultId }) {
  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data, loading } = useQuery(GET_RESULTS, { variables: { resultId } });

  const [handleUpdateResult] = useMutation(UPDATE_RESULT);

  const currentIndex = data?.results?.interviewGuides?.findIndex(
    result => result.id === resultId,
  );

  const resultData = data?.results?.interviewGuides?.[currentIndex];
  const nextResultData = data?.results?.interviewGuides?.[currentIndex + 1];

  const { new: isNew } = resultData || {};

  useEffect(() => {
    if (isNew) {
      handleUpdateResult({
        variables: {
          id: resultId,
          new: false,
        },
      });
    }
  }, [handleUpdateResult, isNew, resultId]);

  const { title, subtitle } =
    cmsData?.cmsContent?.result?.find(
      cmsResult => cmsResult.id === 'interview_guide',
    ) || {};

  const {
    content_1: content1,
    content_1_read_more: content1ReadMore,
    content_2: content2,
    content_2_read_more: content2ReadMore,
    content_3: content3,
    content_3_read_more: content3ReadMore,
    content_4: content4,
    content_4_read_more: content4ReadMore,
    content_5: content5,
    content_5_read_more: content5ReadMore,
    content_6: content6,
    content_6_read_more: content6ReadMore,
    content_7: content7,
    content_7_read_more: content7ReadMore,
    content_8: content8,
    content_8_read_more: content8ReadMore,
    content_9: content9,
    content_9_read_more: content9ReadMore,
    references,
  } = cmsData?.cmsContent?.interview_guide || {};

  const renderTips = typeName => {
    const filteredInterviewTips = resultData?.interviewTips?.filter(
      interviewTip => interviewTip?.type === typeName,
    );

    return (
      <Section smallPadding noPaddingBottom>
        <Table
          data={filteredInterviewTips}
          cols={[
            {
              id: 'description1',
              value: 'Interview Tip',
            },
          ]}
        />
      </Section>
    );
  };

  const renderQuestions = () => {
    return (
      <Section smallPadding noPaddingBottom>
        <Table
          data={resultData?.interviewQuestions}
          cols={[
            {
              id: 'title',
              value: 'Category',
              medium: true,
              transformer: value => <strong>{value}</strong>,
            },
            { id: 'description1', value: 'Question' },
          ]}
        />
      </Section>
    );
  };

  return (
    <Layout
      title={`${extractPrismicString(title)} - ${resultData?.name}`}
      loading={loading}
      hasFooterBar
    >
      <Section>
        <Container narrow>
          {isNew && <Tag marginBottom>New</Tag>}

          <TextContent marginBottom redh6>
            <h6>{extractPrismicString(title)}</h6>
            <h1>{resultData?.name}</h1>
            <p>
              Company <strong>{resultData?.companyName}</strong> <br />
              {resultData?.interviewDate && (
                <>
                  Interview date{' '}
                  <strong>
                    {dayjs(resultData?.interviewDate).format('D MMMM YYYY')}
                  </strong>
                </>
              )}
            </p>
          </TextContent>

          {!!resultData?.jobDescription && (
            <TextContent redh6 marginBottom>
              <h6>Job Description</h6>
              <p>{resultData?.jobDescription}</p>
            </TextContent>
          )}

          {hasContent(content1) && (
            <TextContent
              redh6
              maxHeight={content1ReadMore ? 200 : null}
              marginBottom
            >
              {extractPrismicContent(content1)}
            </TextContent>
          )}
        </Container>
      </Section>

      <Section white>
        <Container narrow>
          {hasContent(content2) && (
            <TextContent
              redh6
              maxHeight={content2ReadMore ? 200 : null}
              marginBottom
            >
              {extractPrismicContent(content2)}
              {renderTips('PERSONALITY')}
            </TextContent>
          )}
        </Container>
      </Section>

      <Section>
        <Container narrow>
          {hasContent(content3) && (
            <TextContent
              redh6
              maxHeight={content3ReadMore ? 200 : null}
              marginBottom
            >
              {extractPrismicContent(content3)}
              {renderTips('COMPETENCY')}
            </TextContent>
          )}
        </Container>
      </Section>

      <Section white>
        <Container narrow>
          {hasContent(content4) && (
            <TextContent
              redh6
              maxHeight={content4ReadMore ? 200 : null}
              marginBottom
            >
              {extractPrismicContent(content4)}
              {renderTips('ABILITIES')}
            </TextContent>
          )}
        </Container>
      </Section>

      <Section>
        <Container narrow>
          {hasContent(content5) && (
            <TextContent
              redh6
              maxHeight={content5ReadMore ? 200 : null}
              marginBottom
            >
              {extractPrismicContent(content5)}
              {renderTips('STRENGTH')}
            </TextContent>
          )}
        </Container>
      </Section>

      <Section white>
        <Container narrow>
          {hasContent(content6) && (
            <TextContent
              redh6
              maxHeight={content6ReadMore ? 200 : null}
              marginBottom
            >
              {extractPrismicContent(content6)}
              {renderTips('INTEREST')}
            </TextContent>
          )}
        </Container>
      </Section>

      <Section>
        <Container narrow>
          {hasContent(content7) && (
            <TextContent
              redh6
              maxHeight={content7ReadMore ? 200 : null}
              marginBottom
            >
              {extractPrismicContent(content7)}

              <h6>Company Culture</h6>
              <p>
                <strong>{resultData?.companyCulture}</strong>
              </p>

              <h6>Company Structure</h6>
              <p>
                <strong>{resultData?.companyStructure}</strong>
              </p>

              {!!resultData?.notes && (
                <>
                  <h6>Notes</h6>
                  <p>{resultData?.notes}</p>
                </>
              )}
            </TextContent>
          )}
        </Container>
      </Section>

      <Section white>
        <Container narrow>
          {hasContent(content8) && (
            <TextContent
              redh6
              maxHeight={content8ReadMore ? 200 : null}
              marginBottom
            >
              {extractPrismicContent(content8)}
              {renderQuestions()}
            </TextContent>
          )}
        </Container>
      </Section>

      <Section>
        <Container narrow>
          {hasContent(content9) && (
            <TextContent
              redh6
              maxHeight={content9ReadMore ? 200 : null}
              marginBottom
            >
              {extractPrismicContent(content9)}
            </TextContent>
          )}
        </Container>
      </Section>

      {hasContent(references) && (
        <Section smallPadding>
          <Container narrow>
            <References>{extractPrismicContent(references)}</References>
          </Container>
        </Section>
      )}

      <FooterBar
        isNew={isNew}
        title={`${extractPrismicString(title)}: ${resultData?.name}`}
        subTitle={extractPrismicString(subtitle)}
        primaryButton={
          nextResultData && {
            label: `Next: ${extractPrismicString(title)}: ${
              nextResultData?.name
            }`,
            to: `/results/interview_guide/${nextResultData?.id}`,
            level: 4,
            disabled: nextResultData?.status !== ACTIVITY_STATUS.COMPLETED,
          }
        }
        secondaryButton={{
          label: 'Back to results',
          to: cmsData?.user?.isAdmin ? null : '/results',
          onClick: cmsData?.user?.isAdmin ? () => navigate(-1) : undefined,
        }}
      />
    </Layout>
  );
}

InterviewGuide.propTypes = {
  resultId: PropTypes.string,
};

InterviewGuide.defaultProps = {
  resultId: null,
};

export default InterviewGuide;
