import CURRENCIES from '../config/currency';

export const VAT_AMOUNT = 0.2;
export const VAT_MULTIPLYER = VAT_AMOUNT + 1;

export const calculateVAT = (value, onlyVat, chargeVAT) => {
  if (chargeVAT && onlyVat) {
    return Math.floor(value * VAT_AMOUNT);
  }

  if (chargeVAT) {
    return Math.floor(value * VAT_MULTIPLYER);
  }

  if (onlyVat) {
    return 0;
  }

  return value;
};

const getValue = (product, currency, discounted, showVAT) => {
  if (!product) return 0;

  if (discounted) {
    return (
      product[CURRENCIES[currency].discountedAmount] *
      (showVAT ? VAT_MULTIPLYER : 1)
    );
  }

  return product[CURRENCIES[currency].amount] * (showVAT ? VAT_MULTIPLYER : 1);
};

export default getValue;
