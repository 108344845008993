import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import Layout from '../components/Layout';
import Container from '../components/Container';
import Notification from '../components/Notification';
import Section from '../components/Section';
import Table from '../components/Table';
import Tag from '../components/Tag';
import StatusIcon from '../components/StatusIcon';
import TextContent from '../components/TextContent';
import FooterBar from '../components/FooterBar';
import ActionsFilterBar from '../components/ActionsFilterBar';

import { GET_ACTIONS, UPDATE_ACTION, DELETE_ACTION } from '../graph/actions';
import { GET_GOALS } from '../graph/goals';
import { GET_RESULTS, UPDATE_RESULT } from '../graph/results';
import { GET_ACTIVITIES } from '../graph/activities';
import { GET_CMS_CONTENT } from '../graph/cms';

import extractGraphQLError from '../utils/extractGraphQLError';
import { validate, requiredString } from '../utils/validate';
import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';

import results from '../config/results';
import { PRIORITIES } from '../config/types';
import { activities, ACTIVITY_STATUS } from '../config/levels';

import { ReactComponent as RemoveIcon } from '../assets/remove-icon.svg';

const actionFormValidationSchema = validate({
  title: requiredString,
  priority: requiredString,
  dueDate: requiredString,
  goalId: requiredString,
});

function Actions({ resultId }) {
  const [filterValues, setFilterValues] = useState({
    [PRIORITIES.HIGH]: false,
    [PRIORITIES.MEDIUM]: false,
    [PRIORITIES.LOW]: false,
  });

  const [filterGoalId, setFilterGoalId] = useState(null);

  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data: activityData, loading: loadingActivities } = useQuery(
    GET_ACTIVITIES,
  );

  const { data, loading } = useQuery(GET_RESULTS, { variables: { resultId } });

  const [handleUpdateResult] = useMutation(UPDATE_RESULT);
  const type = 'actions';
  const resultData = data?.results?.[type];
  const { new: isNew } = resultData || {};

  useEffect(() => {
    if (isNew) {
      handleUpdateResult({
        variables: {
          id: resultId,
          new: false,
        },
      });
    }
  }, [handleUpdateResult, isNew, resultId]);

  const currentIndex = results?.findIndex(({ id }) => id === type);

  const { id: activityId } =
    activityData?.activities?.find(
      activity =>
        activity.type === activities.actions.type &&
        activity.status === ACTIVITY_STATUS.COMPLETED,
    ) || {};

  const { id: goalActivityId } =
    activityData?.activities?.find(
      activity =>
        activity.type === activities.goals.type &&
        activity.status === ACTIVITY_STATUS.COMPLETED,
    ) || {};

  const { data: actionData, loading: loadingActions } = useQuery(GET_ACTIONS, {
    variables: { activityId },
    skip: !activityId,
  });

  const { data: goalData, loading: loadingGoals } = useQuery(GET_GOALS, {
    variables: { activityId: goalActivityId },
    skip: !goalActivityId,
  });

  const [
    handleUpdateAction,
    { loading: loadingUpdateAction, error: errorUpdateAction },
  ] = useMutation(UPDATE_ACTION, {
    refetchQueries: ['Actions'],
  });

  const [
    handleDeleteAction,
    { loading: loadingDeleteAction, error: errorDeleteAction },
  ] = useMutation(DELETE_ACTION, {
    refetchQueries: ['Actions'],
  });

  const handlCompleteAction = id => {
    handleUpdateAction({
      variables: {
        id,
        status: 'COMPLETE',
      },
    });
  };

  const goalOptions =
    goalData?.goals
      ?.filter(goal => {
        return goal.type === 'FINAL';
      })
      .map(goal => ({
        id: goal?.id,
        value: goal?.id,
        label: goal?.title,
      })) || [];

  const actionFormFields = [
    {
      id: 'priority',
      name: 'priority',
      label: 'Priority',
      type: 'select',
      options: Object.entries(PRIORITIES).map(([key, value]) => ({
        id: key,
        value,
      })),
      defaultLabel: 'How important is it?',
      medium: true,
    },
    {
      id: 'dueDate',
      name: 'dueDate',
      label: 'Date Due',
      type: 'date',
      placeholder: 'When do you need to do it by?',
      medium: true,
    },
    {
      id: 'goalId',
      name: 'goalId',
      label: 'Goal',
      type: 'select',
      options: goalOptions,
      defaultLabel: 'Select goal',
    },
    {
      id: 'title',
      name: 'title',
      label: 'Action',
      type: 'textarea',
      placeholder: 'What do you need to do?',
    },
  ];

  const {
    title,
    subtitle,
    content_1: content1,
    content_1_read_more: content1ReadMore,
    content_1_quote: content1Quote,
    content_1_quote_citation: content1QuoteCitation,
    content_2: content2,
    content_2_read_more: content2ReadMore,
    content_2_quote: content2Quote,
    content_2_quote_citation: content2QuoteCitation,
    content_3: content3,
    content_3_read_more: content3ReadMore,
    content_3_quote: content3Quote,
    content_3_quote_citation: content3QuoteCitation,
    content_4: content4,
    content_4_read_more: content4ReadMore,
    content_4_quote: content4Quote,
    content_4_quote_citation: content4QuoteCitation,
    help_text: helpText,
  } = cmsData?.cmsContent?.result?.find(activity => activity.id === type) || {};

  const { level } = results?.[currentIndex];

  const { id: nextType } = results?.[currentIndex + 1] || {};

  const { title: nextTitle } =
    cmsData?.cmsContent?.result?.find(activity => activity.id === nextType) ||
    {};

  const { id: nextId, status: nextStatus } = data?.results?.[nextType] || {};

  const filteredActions = actionData?.actions
    ?.filter(({ priority }) =>
      !filterValues.HIGH && !filterValues.MEDIUM && !filterValues.LOW
        ? true
        : (priority === PRIORITIES.HIGH && filterValues.HIGH) ||
          (priority === PRIORITIES.MEDIUM && filterValues.MEDIUM) ||
          (priority === PRIORITIES.LOW && filterValues.LOW),
    )
    .filter(({ Goal }) => (!filterGoalId ? true : Goal.id === filterGoalId));

  return (
    <Layout
      title={`${extractPrismicString(title)}`}
      loading={loading || loadingActivities || loadingActions || loadingGoals}
      hasFooterBar
    >
      <Container narrow>
        <Section>
          {isNew && <Tag marginBottom>New</Tag>}

          <TextContent marginBottom>
            <h1>{extractPrismicString(title)}</h1>
          </TextContent>

          {hasContent(content1) && (
            <TextContent maxHeight={content1ReadMore ? 200 : null} marginBottom>
              {hasContent(content1Quote) && (
                <Notification type="green">
                  <blockquote>{extractPrismicString(content1Quote)}</blockquote>
                  {hasContent(content1QuoteCitation) && (
                    <cite> {extractPrismicString(content1QuoteCitation)}</cite>
                  )}
                </Notification>
              )}

              {extractPrismicContent(content1)}
            </TextContent>
          )}

          {hasContent(content2) && (
            <TextContent maxHeight={content2ReadMore ? 200 : null} marginBottom>
              {hasContent(content2Quote) && (
                <Notification type="green">
                  <blockquote>{extractPrismicString(content2Quote)}</blockquote>
                  {hasContent(content2QuoteCitation) && (
                    <cite> {extractPrismicString(content2QuoteCitation)}</cite>
                  )}
                </Notification>
              )}

              {extractPrismicContent(content2)}
            </TextContent>
          )}

          {hasContent(content3) && (
            <TextContent maxHeight={content3ReadMore ? 200 : null} marginBottom>
              {hasContent(content3Quote) && (
                <Notification type="green">
                  <blockquote>{extractPrismicString(content3Quote)}</blockquote>
                  {hasContent(content3QuoteCitation) && (
                    <cite>{extractPrismicString(content3QuoteCitation)}</cite>
                  )}
                </Notification>
              )}

              {extractPrismicContent(content3)}
            </TextContent>
          )}

          {hasContent(content4) && (
            <TextContent maxHeight={content4ReadMore ? 200 : null} marginBottom>
              {hasContent(content4Quote) && (
                <Notification type="green">
                  <blockquote>{extractPrismicString(content4Quote)}</blockquote>
                  {hasContent(content4QuoteCitation) && (
                    <cite> {extractPrismicString(content4QuoteCitation)}</cite>
                  )}
                </Notification>
              )}

              {extractPrismicContent(content4)}
            </TextContent>
          )}
        </Section>
      </Container>

      <Section>
        <Container>
          {hasContent(helpText) && (
            <Notification blue showUser>
              {extractPrismicContent(helpText)}
            </Notification>
          )}

          <ActionsFilterBar
            filterValues={filterValues}
            onChangeFilters={setFilterValues}
            goalIdValue={filterGoalId}
            onChangeGoal={setFilterGoalId}
            goalOptions={goalOptions}
          />

          <Table
            cols={[
              {
                id: 'status',
                value: 'Status',
                small: true,
                transformer: (status, i, id) => (
                  <StatusIcon
                    disabled={loadingUpdateAction}
                    onClick={
                      status === 'COMPLETE'
                        ? undefined
                        : () => handlCompleteAction(id)
                    }
                    status={status}
                  />
                ),
              },
              {
                id: 'priority',
                value: 'Priority',
                transformer: priority => (
                  <Tag light={priority !== PRIORITIES.HIGH}>{priority}</Tag>
                ),
                medium: true,
              },
              {
                id: 'dueDate',
                value: 'Date Due',
                transformer: date => dayjs(date).format('D MMMM YYYY'),
                medium: true,
              },
              { id: 'Goal', value: 'Goal', transformer: goal => goal.title },
              { id: 'title', value: 'Action' },
            ]}
            data={filteredActions}
            redorderId="actionId"
            editable
            updateForm={{
              fields: actionFormFields,
              validationSchema: actionFormValidationSchema,
              onSubmit: handleUpdateAction,
              loading: loadingUpdateAction,
            }}
            actions={[
              {
                id: 'remove',
                text: 'remove',
                icon: <RemoveIcon />,
                onClick: handleDeleteAction,
                loading: loadingDeleteAction,
              },
            ]}
          />

          {!!(errorUpdateAction || errorUpdateAction || errorDeleteAction) && (
            <Notification type="red">
              {extractGraphQLError(
                errorUpdateAction || errorUpdateAction || errorDeleteAction,
              )}
            </Notification>
          )}
        </Container>
      </Section>

      <FooterBar
        isNew={isNew}
        title={extractPrismicString(title)}
        subTitle={extractPrismicString(subtitle)}
        primaryButton={
          nextType && {
            label: `Next: ${extractPrismicString(nextTitle)}`,
            to: `/results/${nextType}/${nextId}`,
            level,
            disabled: nextStatus !== ACTIVITY_STATUS.COMPLETED,
          }
        }
        secondaryButton={{
          label: 'Back to results',
          to: cmsData?.user?.isAdmin ? null : '/results',
          onClick: cmsData?.user?.isAdmin ? () => navigate(-1) : undefined,
        }}
      />
    </Layout>
  );
}

Actions.propTypes = {
  resultId: PropTypes.string,
};

Actions.defaultProps = {
  resultId: null,
};

export default Actions;
