import styled from 'styled-components';

const Avtatar = styled.div`
  display: block;
  border-radius: 100%;
  background-image: url(${props => props.image});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 170px;
  height: 170px;

  ${props =>
    props.small &&
    `
    width: 40px;
    height: 40px;
  `}
`;

export default Avtatar;
