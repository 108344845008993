import React from 'react';
import styled from 'styled-components';

import Container from './Container';
import Section from './Section';
import TextContentMarketing from './TextContentMarketing';
import Button from './Button';

import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';

const Outer = styled.div``;

const Wrapper = styled.div`
  margin: 0 -${props => props.theme.spacing.base};

  ${props => props.theme.breakpoints.medium`
    margin: 0;
    display: flex;
    flex-wrap: wrap;
  `}
`;

const Item = styled.div`
  background-color: ${props => props.theme.colors.green320};
  position: relative;
  padding: 0 ${props => props.theme.spacing.base};


  ${props => props.theme.breakpoints.medium`
    flex: 0 0 50%;
    width: 50%;
    padding: 0 ${props.theme.spacing.xLarge} 0 0;

    &:before  {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 50vw;
      z-index: 1;
    }

    &:before  {
      right: 0;
      background-color: ${props.theme.colors.green320};
    }
  `}



${props =>
  props.light &&
  props.theme.breakpoints.medium`
    background-color: ${props.theme.colors.green200};

    &:before  {
      background-color: ${props.theme.colors.green200};
    }
  `}


${props =>
  props.right &&
  props.theme.breakpoints.mediumDown`
  background-color: ${props.theme.colors.green200};
  `}

${props =>
  props.right &&
  props.theme.breakpoints.medium`
    &:before  {
      right: auto;
      left: 0;
    }
  `}


  ${props =>
    props.padding &&
    props.theme.breakpoints.medium`
      padding: 0 0 0 ${props.theme.spacing.xLarge};
    `}
`;

const Content = styled.div`
  position: relative;
  z-index: 2;
`;

const Icon = styled.img`
  height: 100px;
`;

function MarketingContentGrid({ content_grid_small: small, items }) {
  if (!items) return null;

  return (
    <Outer>
      <Container>
        <Wrapper>
          {items.map(
            (
              {
                content_grid_title: title,
                content_grid_text: text,
                content_grid_button_text: buttonText,
                content_grid_button_url: buttonUrl,
                content_grid_icon: icon,
              },
              i,
            ) => {
              const isAlt = !(Math.floor(i / 2 + 1) % 2);

              return (
                <Item
                  light={!!(isAlt ? !(i % 2) : i % 2)}
                  padding={i % 2}
                  right={i % 2}
                >
                  <Content>
                    <Section padding={small ? 'small' : 'medium'}>
                      <TextContentMarketing>
                        {!!(icon && icon.url) && <Icon src={icon.url} />}
                        {hasContent(title) && (
                          <h3>{extractPrismicString(title)}</h3>
                        )}

                        {extractPrismicContent(text)}
                      </TextContentMarketing>

                      {!!(hasContent(buttonText) && hasContent(buttonUrl)) && (
                        <Button
                          marginTop
                          href={extractPrismicString(buttonUrl)}
                        >
                          {extractPrismicString(buttonText)}
                        </Button>
                      )}
                    </Section>
                  </Content>
                </Item>
              );
            },
          )}
        </Wrapper>
      </Container>
    </Outer>
  );
}

MarketingContentGrid.propTypes = {};

export default MarketingContentGrid;
