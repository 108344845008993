const cleanUpKey = (key, valueType) => {
  const firstIndex = key.lastIndexOf(' - ') + 3;
  return key
    .slice(firstIndex)
    .replace(` ${valueType}`, '')
    .replace(/ /g, '_')
    .toLowerCase();
};

const reduceValueArray = (array, type, valueType) => {
  return array.reduce((a, [key, value]) => {
    if (
      key.includes(type) &&
      (!valueType || key.includes(valueType)) &&
      value &&
      value !== '-'
    ) {
      return [...a, { type: cleanUpKey(key, valueType), value }];
    }

    return a;
  }, []);
};

const transformCSVData = data => {
  if (!data) return [];

  return data.map(({ Email: email, ...rest }) => {
    const valuesAsArray = Object.entries(rest);

    return {
      email,
      competencies: reduceValueArray(
        valuesAsArray,
        'Competencies - Effective',
        '(percentile)',
      ),
      traits: reduceValueArray(valuesAsArray, 'Qualities - Effective'),
    };
  });
};

export default transformCSVData;
