import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import getColorFromLevel from '../utils/getColorFromLevel';
import getTintColorFromLevel from '../utils/getTintColorFromLevel';

import { ACTIVITY_STATUS } from '../config/levels';

import { ReactComponent as TickIcon } from '../assets/tick-icon-alt.svg';

const Circle = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${getColorFromLevel};
  width: 40px;
  height: 40px;
  border-radius: 100%;

  ${props =>
    props.center &&
    `
    margin: auto;
  `}

  svg {
    display: block;
    height: 24px;
    width: 24px;
    fill: transparent;
    transform: translate(1px, 1px);
  }

  ${props =>
    !!(
      props.status === ACTIVITY_STATUS.COMPLETED || props.status === 'COMPLETE'
    ) &&
    `
    background-color: ${getColorFromLevel({ ...props })};

    svg {
      fill: ${props.theme.colors.white};
    }
  `}

  ${props =>
    props.onClick &&
    `
    cursor: pointer;

    &:hover {
      border-color: ${getTintColorFromLevel({ ...props })}
    }
  `}
`;

function StatusIcon({ status, onClick, ...props }) {
  return (
    <Circle status={status} onClick={onClick} {...props}>
      {!!(status === ACTIVITY_STATUS.COMPLETED || status === 'COMPLETE') && (
        <TickIcon />
      )}
    </Circle>
  );
}

StatusIcon.propTypes = {
  status: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

StatusIcon.defaultProps = {
  onClick: undefined,
};

export default StatusIcon;
