import startCase from 'lodash.startcase';

import { extractPrismicString } from './prismic';

const transformArrayToBubbleChartObject = (
  array,
  valueOffset,
  key,
  cmsContent,
) => {
  if (!array) return null;

  return {
    name: 'parent',
    children: array.map(item => ({
      label: cmsContent
        ? extractPrismicString(
            cmsContent?.value?.find(value => value.id === item.type)?.title,
          )
        : startCase(item.type),
      value: valueOffset ? valueOffset - item[key] : item[key],
    })),
  };
};

export default transformArrayToBubbleChartObject;
