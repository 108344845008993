import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Avatar from './Avtatar';

import getColorFromLevel from '../utils/getColorFromLevel';

import { ReactComponent as UserIcon } from '../assets/user-icon.svg';

const RADIUS = 90;
const STROKE = 6;

const Wrapper = styled.div`
  box-shadow: ${props => props.theme.base.shadow};
  height: ${RADIUS * 2}px;
  width: ${RADIUS * 2}px;
  border-radius: 100%;
  margin: 0 auto;
  position: relative;
  margin: ${props => props.theme.spacing.base} auto
    ${props => props.theme.spacing.midLarge};

  svg {
    transform: rotate(90deg);
  }
`;

const BackgroundCircle = styled.circle`
  fill: ${props => props.theme.colors.white};
  stroke: ${props => props.theme.colors.grey100};
`;

const ProgressCircle = styled.circle`
  fill: transparent;
  stroke: ${getColorFromLevel};
`;

const Illustration = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transform: none;
    fill: ${props => props.theme.colors.grey200};
    width: 70px;
    height: 70px;
    display: block;
  }
`;

const StatusCircle = styled.div`
  position: absolute;
  bottom: ${props => props.theme.spacing.xxSmall};
  right: ${props => props.theme.spacing.xxSmall};
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: ${getColorFromLevel};
  color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    line-height: 1;
    margin-bottom: 0;

    span {
      font-size: 28px;
      font-weight: ${props => props.theme.fontWeights.light};
    }
  }
`;

function UserStatusCircle({
  activitiesComplete,
  totalActivities,
  level,
  profilePicture,
}) {
  const progress = (activitiesComplete / totalActivities) * 100;
  const normalizedRadius = RADIUS - STROKE / 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <Wrapper>
      <svg height={RADIUS * 2} width={RADIUS * 2}>
        <BackgroundCircle
          strokeWidth={STROKE}
          r={normalizedRadius}
          cx={RADIUS}
          cy={RADIUS}
          level={level}
        />

        {progress && (
          <ProgressCircle
            strokeDasharray={`${circumference} ${circumference}`}
            style={{ strokeDashoffset }}
            strokeWidth={STROKE}
            r={normalizedRadius}
            cx={RADIUS}
            cy={RADIUS}
            level={level}
          />
        )}
      </svg>

      <Illustration>
        {profilePicture ? <Avatar image={profilePicture} /> : <UserIcon />}
      </Illustration>

      <StatusCircle level={level}>
        <p>
          <span>{level}</span>
        </p>
      </StatusCircle>
    </Wrapper>
  );
}

UserStatusCircle.propTypes = {
  level: PropTypes.number.isRequired,
  activitiesComplete: PropTypes.number.isRequired,
  totalActivities: PropTypes.number.isRequired,
};

export default UserStatusCircle;
