import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import Layout from '../components/Layout';
import Container from '../components/Container';
import Section from '../components/Section';
import Grid from '../components/Grid';
import GridItem from '../components/GridItem';
import ResultsSummaryCard from '../components/ResultsSummaryCard';
import FilterBar from '../components/FilterBar';

import { GET_CMS_CONTENT } from '../graph/cms';
import { GET_RESULTS } from '../graph/results';

import RESULTS from '../config/results';

import { extractPrismicString } from '../utils/prismic';

import useSiteSettings from '../hooks/useSiteSettings';

function Results() {
  const [filterValues, setFilterValues] = useState({
    1: false,
    2: false,
    3: false,
    new: false,
    interviewGuide: false,
  });

  const { bannerBgImage } = useSiteSettings();

  const [searchValue, setSearchValue] = useState(null);

  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { hero_title: heroTitle, hero_text: heroText } =
    cmsData?.cmsContent?.results_home || {};

  const { data, loading } = useQuery(GET_RESULTS);

  const filteredResults = RESULTS?.filter(({ level }) =>
    !filterValues[1] && !filterValues[2] && !filterValues[3]
      ? true
      : filterValues[level],
  )
    .filter(({ id }) => (!filterValues.new ? true : data?.results[id]?.new))
    .filter(({ id }) =>
      !searchValue ? true : id.includes(searchValue.toLowerCase()),
    )
    .filter(() => !filterValues.interviewGuide);

  const filteredInterviewGuideResults = data?.results?.interviewGuides
    ?.filter(() =>
      !filterValues[1] && !filterValues[2] && !filterValues[3]
        ? true
        : filterValues[3],
    )
    .filter(({ new: isNew }) => (!filterValues.new ? true : isNew))
    .filter(({ type }) =>
      !searchValue ? true : type.includes(searchValue.toLowerCase()),
    );

  const renderItem = ({ id, level, result }) => (
    <GridItem key={id} width={50} grow>
      <ResultsSummaryCard
        cmsContent={cmsData?.cmsContent}
        currentLevel={cmsData?.user?.currentLevel}
        resultData={data?.results}
        result={result}
        level={level}
        id={id}
      />
    </GridItem>
  );

  return (
    <Layout
      title="Results"
      loading={loading}
      hero={{
        title: extractPrismicString(heroTitle),
        subTitle: extractPrismicString(heroText),
        backgroundImage: bannerBgImage,
      }}
    >
      <FilterBar
        filterValue={filterValues}
        onChangeFilters={setFilterValues}
        searchValue={searchValue}
        onChangeSearchValue={setSearchValue}
      />

      <Container>
        <Section smallPadding>
          <Grid>
            {filteredResults?.map((resultMeta) =>
              renderItem({ ...resultMeta, result: null }),
            )}
            {filteredInterviewGuideResults?.map((result) =>
              renderItem({
                id: 'interview_guide',
                level: 4,
                result,
              }),
            )}
          </Grid>
        </Section>
      </Container>
    </Layout>
  );
}

export default Results;
