import React from 'react';
import Tag from './Tag';

function AdminUserTagList({ tag }) {
  const data = React.useMemo(() => {
    if (!tag) return [];

    return JSON.parse(tag).map((row, i) => ({
      id: i + 1,
      title: row,
    }));
  }, [tag]);

  return (
    <>
      {data.map(row => (
        <Tag>{row.title}</Tag>
      ))}
    </>
  );
}

export default AdminUserTagList;
