import React from 'react';
import styled from 'styled-components';

import Container from './Container';
import Button from './Button';

import { extractPrismicString, hasContent } from '../utils/prismic';

import Facebook from '../assets/facebook.svg';
import Instagram from '../assets/instagram.svg';
import LinkedIn from '../assets/linkedin.svg';

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.red500};
  background-image: url('/background-curves.png');
  background-position: cover;
  color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing.large} 0;

  h2 {
    font-family: ${props => props.theme.base.altFontFamily};
    font-weight: ${props => props.theme.fontWeights.normal};
    font-size: ${props => props.theme.fontSizes.xxLarge};
    line-height: 1.1;
    color: ${props => props.theme.colors.white};

    ${props => props.theme.breakpoints.medium`
      font-size: ${props.theme.fontSizes.xxxxLarge};
    `}
  }

  h3 {
    color: ${props => props.theme.colors.white};
  }
`;

const SocialLinks = styled.div`
  margin-top: 30px;
`;

const SocialLink = styled.a`
  display: inline-block;
  margin-left: 10px;

  svg {
    display: block;
    width: 24px;
    fill: ${props => props.theme.colors.white};
  }
`;

function MarketingContactUsBanner({
  contact_us_title: title,
  contact_us_sub_title: subTitle,
  contact_us_button_text: buttonText,
  contact_us_button_url: buttonUrl,
  contact_us_facebook_url: facebookURL,
  contact_us_linkedin_url: linkedInURL,
  contact_us_instagram_url: instagramURL,
}) {
  return (
    <Wrapper>
      <Container>
        {hasContent(title) && <h2>{extractPrismicString(title)}</h2>}
        {hasContent(subTitle) && <h3>{extractPrismicString(subTitle)}</h3>}

        {!!hasContent(buttonText) && (
          <Button
            white
            marginTop
            href={extractPrismicString(buttonUrl)}
            onClick={() => {
              if (!hasContent(buttonUrl) && window?.Tawk_API) {
                window.Tawk_API.toggle();
              }
            }}
          >
            {extractPrismicString(buttonText)}
          </Button>
        )}

        <SocialLinks>
          {linkedInURL && (
            <SocialLink
              href={linkedInURL}
              target="_blank"
              rel="noreferrer noopener"
            >
              <LinkedIn />
            </SocialLink>
          )}
          {facebookURL && (
            <SocialLink
              href={facebookURL}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Facebook />
            </SocialLink>
          )}
          {instagramURL && (
            <SocialLink
              href={instagramURL}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Instagram />
            </SocialLink>
          )}
        </SocialLinks>
      </Container>
    </Wrapper>
  );
}

MarketingContactUsBanner.propTypes = {};

export default MarketingContactUsBanner;
