import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import userAvatar from '../assets/user-avatar.png';

const Wrapper = styled.aside`
  background-color: ${props => props.theme.colors.yellow100};
  border-radius: ${props => props.theme.base.radius};
  width: 100%;
  padding: ${props => props.theme.spacing.base};
  margin-bottom: ${props => props.theme.spacing.medium};
  color: ${props => props.theme.colors.black};

  p:last-child {
    margin-bottom: 0;
  }

  blockquote {
    color: ${props => props.theme.colors.black};
    margin: 0;
    font-size: 18px;
    line-height:28px;
    font-style: italic;
    padding: 0 0 ${props => props.theme.spacing.small};

    ${props =>
      props.theme.breakpoints.medium`
      font-size: 24px;
      line-height:38px;
      padding: ${props.theme.spacing.small};
    `}


    ${props =>
      props.featured &&
      `
      font-size: 21px;
      font-family: ${props.theme.base.altFontFamily};
      font-style: normal;
      line-height: 1.4;
    `}

${props =>
  props.featured &&
  props.theme.breakpoints.medium`
      font-size: 28px;
    `}
  }

  cite {
    display: block;
    color: ${props => props.theme.colors.green500};
   
    margin: 0;

    ${props =>
      props.theme.breakpoints.medium`
      padding: ${props.theme.spacing.small};
    `}
  }

  ${props =>
    props.type === 'red' &&
    `
    background-color: ${props.theme.colors.red100};
    color: ${props.theme.colors.red500};
  `}

  ${props =>
    props.type === 'green' &&
    `
    background-color: ${props.theme.colors.green100};
  `}

  ${props =>
    props.center &&
    `
    text-align: center;
  `}


${props =>
  props.noMarginBottom &&
  `
    margin-bottom: 0;
  `}

${props =>
  props.noMarginBottomMedium &&
  props.theme.breakpoints.medium`
    margin-bottom: 0;
  `}


${props =>
  props.marginTopMobile &&
  `
    margin-top: ${props.theme.spacing.base};
  `}


${props =>
  props.marginTopMobile &&
  props.theme.breakpoints.medium`
    margin-top: 0;
  `}



`;

const UserHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.small};

  h6 {
    margin: 0 0 0 ${props => props.theme.spacing.xSmall};
  }

  &:before {
    background-image: url(${userAvatar});
    width: 40px;
    height: 40px;
    display: block;
    content: '';
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

function Notification({ children, showUser, ...props }) {
  return (
    <Wrapper {...props}>
      {showUser && (
        <UserHeader>
          <h6>Nora</h6>
        </UserHeader>
      )}
      {children}
    </Wrapper>
  );
}

Notification.propTypes = {
  children: PropTypes.node.isRequired,
  showUser: PropTypes.bool,
};

Notification.defaultProps = {
  showUser: false,
};

export default memo(Notification);
