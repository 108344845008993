import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import dayjs from 'dayjs';

import AudioPlayer from '../components/AudioPlayer';
import Layout from '../components/Layout';
import Container from '../components/Container';
import Notification from '../components/Notification';
import TextContent from '../components/TextContent';
import Section from '../components/Section';
import FooterBar from '../components/FooterBar';
import Grid from '../components/Grid';
import GridItem from '../components/GridItem';
import Table from '../components/Table';
import Tag from '../components/Tag';
import SimpleBarList from '../components/SimpleBarList';
import Card from '../components/Card';
import ActionsFilterBar from '../components/ActionsFilterBar';
import StatusIcon from '../components/StatusIcon';

import {
  GET_ACTIVITY_DETAILS,
  UPDATE_ACTIVITY,
  GET_ACTIVITIES,
} from '../graph/activities';
import { GET_CMS_CONTENT } from '../graph/cms';
import {
  GET_ACTIONS,
  ADD_ACTION,
  UPDATE_ACTION,
  DELETE_ACTION,
} from '../graph/actions';
import { GET_GOALS } from '../graph/goals';
import { GET_RESULTS } from '../graph/results';

import extractGraphQLError from '../utils/extractGraphQLError';
import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';
import { validate, requiredString } from '../utils/validate';
import sleep from '../utils/sleep';
import useTrackActivityGA from '../utils/useTrackActivityGA';

import { activities, ACTIVITY_STATUS } from '../config/levels';
import { PRIORITIES } from '../config/types';

import { ReactComponent as RemoveIcon } from '../assets/remove-icon.svg';

const actionFormValidationSchema = validate({
  title: requiredString,
  priority: requiredString,
  dueDate: requiredString,
  goalId: requiredString,
});

function ActivityActions({ activityId }) {
  const [filterValues, setFilterValues] = useState({
    [PRIORITIES.HIGH]: false,
    [PRIORITIES.MEDIUM]: false,
    [PRIORITIES.LOW]: false,
  });

  const [filterGoalId, setFilterGoalId] = useState(null);

  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data: activityData, loading: loadingActivities } = useQuery(
    GET_ACTIVITIES,
  );

  const { id: goalActivityId } =
    activityData?.activities?.find(
      activity =>
        activity.type === activities.goals.type &&
        activity.status === ACTIVITY_STATUS.COMPLETED,
    ) || {};

  const { data, loading } = useQuery(GET_ACTIVITY_DETAILS, {
    variables: { id: activityId },
  });

  const { data: actionData, loading: loadingActions } = useQuery(GET_ACTIONS, {
    variables: { activityId },
  });

  const { data: goalData, loading: loadingGoals } = useQuery(GET_GOALS, {
    variables: { activityId: goalActivityId },
    skip: !goalActivityId,
  });

  const { data: resultData, loading: loadingResults } = useQuery(GET_RESULTS);

  const [
    handleUpdateActivity,
    { loading: loadingUpdateActivity, error },
  ] = useMutation(UPDATE_ACTIVITY);

  const handleCompleteActivity = async () => {
    await handleUpdateActivity({
      variables: {
        id: activityId,
        status: ACTIVITY_STATUS.COMPLETED,
      },
    });

    await sleep(500);

    navigate(`/activities/${activityId}/completed`);
  };

  const isActionsValid = actionData?.actions?.length > 4;

  const [
    handleAddAction,
    { loading: loadingAddAction, error: errorAddAction },
  ] = useMutation(ADD_ACTION, {
    refetchQueries: ['Actions'],
  });

  const handleNewAction = formData => {
    handleAddAction({
      variables: {
        activityId,
        status: 'OPEN',
        ...formData,
      },
    });
  };

  const [
    handleUpdateAction,
    { loading: loadingUpdateAction, error: errorUpdateAction },
  ] = useMutation(UPDATE_ACTION, {
    refetchQueries: ['Actions'],
  });

  const [
    handleDeleteAction,
    { loading: loadingDeleteAction, error: errorDeleteAction },
  ] = useMutation(DELETE_ACTION, {
    refetchQueries: ['Actions'],
  });

  const handlCompleteAction = id => {
    handleUpdateAction({
      variables: {
        id,
        status: 'COMPLETE',
      },
    });
  };

  const activityType = data?.activity?.type;

  useTrackActivityGA(data?.activity?.type, '1');

  const { level } = activities[activityType] || {};

  const {
    title,
    sub_title: subTitle,
    audio_instructions_1: audioInstructions1,
    help_text_1: helpText1,
    short_instructions_1: shortInstructions1,
    step_label_1: stepInstructions1,
    instructions,
  } =
    cmsData?.cmsContent?.activity?.find(
      activity =>
        activity.id ===
        (cmsData?.user?.currentLevel === 4 &&
        data?.activity?.status === ACTIVITY_STATUS.COMPLETED
          ? `ongoing_${activityType}`
          : activityType),
    ) ||
    cmsData?.cmsContent?.activity?.find(
      activity => activity.id === activityType,
    ) ||
    {};

  const goalOptions =
    goalData?.goals
      ?.filter(goal => {
        return goal.type === 'FINAL';
      })
      .map(goal => ({
        id: goal?.id,
        value: goal?.id,
        label: goal?.title,
      })) || [];

  const actionFormFields = [
    {
      id: 'priority',
      name: 'priority',
      label: 'Priority',
      type: 'select',
      options: Object.entries(PRIORITIES).map(([key, value]) => ({
        id: key,
        value,
      })),
      defaultLabel: 'How important is it?',
      medium: true,
    },
    {
      id: 'dueDate',
      name: 'dueDate',
      label: 'Date Due',
      type: 'date',
      placeholder: 'When do you need to do it by?',
      medium: true,
    },
    {
      id: 'goalId',
      name: 'goalId',
      label: 'Goal',
      type: 'select',
      options: goalOptions,
      defaultLabel: 'Select goal',
    },
    {
      id: 'title',
      name: 'title',
      label: 'Action',
      type: 'textarea',
      placeholder: 'What do you need to do?',
    },
  ];

  const filteredActions = actionData?.actions
    ?.filter(({ priority }) =>
      !filterValues.HIGH && !filterValues.MEDIUM && !filterValues.LOW
        ? true
        : (priority === PRIORITIES.HIGH && filterValues.HIGH) ||
          (priority === PRIORITIES.MEDIUM && filterValues.MEDIUM) ||
          (priority === PRIORITIES.LOW && filterValues.LOW),
    )
    .filter(({ Goal }) => (!filterGoalId ? true : Goal.id === filterGoalId));

  return (
    <Layout
      title={`${extractPrismicString(title)}`}
      loading={
        loadingActivities ||
        loading ||
        loadingActions ||
        loadingGoals ||
        loadingResults
      }
      hasFooterBar
    >
      <Container>
        <Section>
          {!!(hasContent(shortInstructions1) || hasContent(helpText1)) && (
            <Grid>
              <GridItem
                width={
                  audioInstructions1?.url || hasContent(helpText1) ? 60 : 100
                }
              >
                <TextContent redh6 smallMargin>
                  {extractPrismicContent(shortInstructions1)}
                </TextContent>
              </GridItem>

              <GridItem width={40}>
                {!!(audioInstructions1?.url || hasContent(helpText1)) && (
                  <Notification showUser noMarginBottom>
                    {extractPrismicContent(helpText1)}

                    {!!audioInstructions1?.url && (
                      <AudioPlayer fileUrl={audioInstructions1?.url} />
                    )}
                  </Notification>
                )}
              </GridItem>
            </Grid>
          )}
        </Section>

        <TextContent redh6 smallMargin>
          <p>{extractPrismicString(stepInstructions1)}</p>
        </TextContent>

        <Section smallPadding>
          <Grid>
            <GridItem width={33.33} alignTop>
              <Card header="Your Goals">
                <SimpleBarList
                  data={resultData?.results?.goals?.goals}
                  emptyDataMessage="Completing the 'Your SMART Goals' activity first, can be super helpful for this activity!"
                  emptyDataLink="/activities"
                  emptyDataLinkText="Back to activities"
                />
              </Card>
            </GridItem>

            <GridItem width={33.33} alignTop>
              <Card header="Your character strengths">
                <SimpleBarList
                  data={resultData?.results?.strengths?.strengths}
                  emptyDataMessage="Completing the 'Character Strengths Assessment' activity first, can be super helpful for this activity!"
                  emptyDataLink="/activities"
                  emptyDataLinkText="Back to activities"
                />
              </Card>
            </GridItem>

            <GridItem width={33.33} alignTop>
              <Card header="Your competencies">
                <SimpleBarList
                  data={resultData?.results?.competencies?.competencies?.slice(
                    0,
                    5,
                  )}
                  emptyDataMessage="Completing the 'Your Competencies in Action' activity first, can be super helpful for this activity!"
                  emptyDataLink="/activities"
                  emptyDataLinkText="Back to activities"
                />
              </Card>
            </GridItem>
          </Grid>
        </Section>

        <ActionsFilterBar
          filterValues={filterValues}
          onChangeFilters={setFilterValues}
          goalIdValue={filterGoalId}
          onChangeGoal={setFilterGoalId}
          goalOptions={goalOptions}
        />

        <Table
          cols={[
            {
              id: 'status',
              value: 'Status',
              small: true,
              transformer: (status, i, id) => (
                <StatusIcon
                  disabled={loadingUpdateAction}
                  onClick={
                    status === 'COMPLETE'
                      ? undefined
                      : () => handlCompleteAction(id)
                  }
                  status={status}
                />
              ),
            },
            {
              id: 'priority',
              value: 'Priority',
              transformer: priority => (
                <Tag light={priority !== PRIORITIES.HIGH}>{priority}</Tag>
              ),
              medium: true,
            },
            {
              id: 'dueDate',
              value: 'Date Due',
              transformer: date => dayjs(date).format('D MMMM YYYY'),
              medium: true,
            },
            { id: 'Goal', value: 'Goal', transformer: goal => goal.title },
            { id: 'title', value: 'Action' },
          ]}
          data={filteredActions}
          redorderId="actionId"
          form={{
            fields: actionFormFields,
            validationSchema: actionFormValidationSchema,
            onSubmit: handleNewAction,
            loading: loadingAddAction,
          }}
          editable
          updateForm={{
            fields: actionFormFields,
            validationSchema: actionFormValidationSchema,
            onSubmit: handleUpdateAction,
            loading: loadingUpdateAction,
          }}
          actions={[
            {
              id: 'remove',
              text: 'remove',
              icon: <RemoveIcon />,
              onClick: handleDeleteAction,
              loading: loadingDeleteAction,
            },
          ]}
        />

        {!!(
          error ||
          errorAddAction ||
          errorUpdateAction ||
          errorUpdateAction ||
          errorDeleteAction
        ) && (
          <Notification type="red">
            {extractGraphQLError(
              error ||
                errorAddAction ||
                errorUpdateAction ||
                errorUpdateAction ||
                errorDeleteAction,
            )}
          </Notification>
        )}
      </Container>

      <FooterBar
        title={extractPrismicString(title)}
        subTitle={extractPrismicString(subTitle)}
        primaryButton={{
          label: 'Complete Activity',
          onClick: handleCompleteActivity,
          disabled: !isActionsValid,
          loading: loadingUpdateActivity,
          level,
        }}
        secondaryButton={{
          label: hasContent(instructions)
            ? 'Back to instructions'
            : 'Back to introduction',
          to: `/activities/${activityId}/${
            hasContent(instructions) ? 'instructions' : 'introduction'
          }`,
        }}
      />
    </Layout>
  );
}

ActivityActions.propTypes = {
  activityId: PropTypes.string,
};

ActivityActions.defaultProps = {
  activityId: null,
};

export default ActivityActions;
