import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { navigate, Link } from '@reach/router';

import Layout from '../components/Layout';
import Container from '../components/Container';

import Button from '../components/Button';
import Grid from '../components/Grid';
import GridItem from '../components/GridItem';
import ActivityList from '../components/ActivityList';
import ResultList from '../components/ResultList';
import Section from '../components/Section';
import ListHeader from '../components/ListHeader';
import Sidebar from '../components/Sidebar';
import Notification from '../components/Notification';

import { GET_CMS_CONTENT } from '../graph/cms';
import { GET_ACTIVITIES, ADD_ACTIVITY } from '../graph/activities';
import { ADD_RESULT, GET_RESULTS } from '../graph/results';

import levels, { activities, ACTIVITY_STATUS } from '../config/levels';
import RESULTS from '../config/results';

import extractGraphQLError from '../utils/extractGraphQLError';
import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';

function Dashboard() {
  const { data } = useQuery(GET_CMS_CONTENT, { fetchPolicy: 'cache-only' });
  const { data: activityData, loading } = useQuery(GET_ACTIVITIES);
  const { data: resultData, loadingResults } = useQuery(GET_RESULTS);

  const {
    currentLevel,
    completedActivities,
    hasCompletedProfile,
    lastCompletedActivity,
  } = data?.user;
  const levelMeta = levels[currentLevel];

  const incompleteActivities = levelMeta?.activities.filter((activity) => {
    const { status } =
      activityData?.activities?.find((item) => item.type === activity.type) ||
      {};
    if (status === ACTIVITY_STATUS.COMPLETED) return false;
    return true;
  });

  const filteredResults = RESULTS?.filter(
    ({ id }) => resultData?.results?.[id]?.new,
  );

  const [handeAddResult, { loading: loadingAddResult }] =
    useMutation(ADD_RESULT);

  const [handleAddActivity, { loading: loadingAddActivity, error }] =
    useMutation(ADD_ACTIVITY, {
      async onCompleted(response) {
        if (!response?.addActivity?.activity?.id) return;

        const resultTypes =
          activities[response?.addActivity?.activity?.type].results;

        if (resultTypes && resultTypes.length) {
          await Promise.all(
            resultTypes.map((resultType) =>
              handeAddResult({
                variables: {
                  activityId: response?.addActivity?.activity?.id,
                  type: resultType,
                  new: true,
                },
              }),
            ),
          );
        }

        navigate(
          `/activities/${response?.addActivity?.activity?.id}/introduction`,
        );
      },
    });

  const handleStartActivity = ({ type, level, paid }) => {
    // Check if user has active subscription
    if (
      paid &&
      !data?.user?.hasActiveSubscription &&
      data?.user?.availableFreeActivities === 0
    ) {
      navigate('/payment');
      return;
    }

    handleAddActivity({
      variables: { type, level, status: ACTIVITY_STATUS.OPEN },
    });
  };

  const {
    quote,
    quote_citation: quoteCitation,
    help_text: helpText,
  } = data?.cmsContent?.dashboard || {};

  const {
    dashboard_quote: dashboardQuote,
    dashboard_quote_cite: dashboardQuoteCite,
    dashboard_instructions: dashboardInstructions,
  } = data?.cmsContent?.activity?.find(
    (item) => item.id === lastCompletedActivity,
  ) || {};

  return (
    <Layout
      title="Dashboard"
      nofooter
      noProgressBar
      loading={loading || loadingResults}
    >
      <Section smallPadding white>
        <Container hasSidebar>
          <Grid>
            <GridItem width={50} grow>
              <Notification type="green" noMarginBottomMedium featured>
                <blockquote>
                  {hasContent(dashboardQuote)
                    ? extractPrismicString(dashboardQuote)
                    : extractPrismicString(quote)}
                </blockquote>

                <cite>
                  {hasContent(dashboardQuoteCite)
                    ? extractPrismicString(dashboardQuoteCite)
                    : extractPrismicString(quoteCitation)}
                </cite>
              </Notification>
            </GridItem>

            <GridItem width={50} grow>
              <Notification showUser noMarginBottomMedium>
                <p>
                  <strong>Hi {data?.user?.firstName}!</strong>
                </p>

                {hasContent(dashboardInstructions)
                  ? extractPrismicContent(dashboardInstructions)
                  : extractPrismicContent(helpText)}
              </Notification>
            </GridItem>
          </Grid>
        </Container>
      </Section>
      <Section smallPadding>
        <Container hasSidebar>
          {!hasCompletedProfile && (
            <Section smallPadding>
              <ListHeader>
                <div>
                  <h3>Complete your profile</h3>
                  <p>
                    Don't forget to complete your profile and connect your
                    LinkedIn account.
                  </p>

                  <Button to="/profile" secondary small>
                    Complete profile
                  </Button>
                </div>
              </ListHeader>
            </Section>
          )}

          <Section smallPadding>
            {incompleteActivities?.length ? (
              <>
                <ListHeader>
                  <div>
                    <h3>Pick up where you left off</h3>
                    {levelMeta.totalActivities ? (
                      <p>
                        You still have{' '}
                        {levelMeta.totalActivities -
                          completedActivities?.[currentLevel - 1]}{' '}
                        Level {currentLevel} activites to complete
                      </p>
                    ) : (
                      <p>Why not take a look at your ongoing activities</p>
                    )}
                  </div>

                  <Button to="/activities" secondary small>
                    View all Activities
                  </Button>
                </ListHeader>

                {!!error && (
                  <Notification type="red">
                    {extractGraphQLError(error)}
                  </Notification>
                )}

                <ActivityList
                  level={currentLevel}
                  cmsContent={data?.cmsContent}
                  activities={incompleteActivities}
                  activityData={activityData?.activities}
                  activitiesComplete={
                    data?.user?.completedActivities?.[currentLevel - 1]
                  }
                  onStartActivity={handleStartActivity}
                  loading={loadingAddActivity || loadingAddResult}
                  hasActiveSubscription={data?.user?.hasActiveSubscription}
                  typeOfSubscription={data?.user?.typeOfSubscription}
                  availableFreeActivities={data?.user?.availableFreeActivities}
                />
              </>
            ) : (
              <ListHeader>
                <div>
                  <h3>Great work!</h3>
                  <p>
                    You've completed the Careers Kitchen program, why not view
                    your <Link to="/interview-guide">Interview Guide</Link> or
                    manage your <Link to="/actions">actions</Link>.
                  </p>
                </div>
              </ListHeader>
            )}
          </Section>

          <Section smallPadding>
            {incompleteActivities?.length ? (
              <>
                <ListHeader>
                  <div>
                    <h3>New results</h3>
                    <p>
                      You have {filteredResults.length} new results to check out
                    </p>
                  </div>

                  <Button to="/results" secondary small>
                    View all Results
                  </Button>
                </ListHeader>

                <ResultList
                  cmsContent={data?.cmsContent}
                  results={filteredResults}
                  resultData={resultData?.results}
                />
              </>
            ) : (
              <ListHeader>
                <div>
                  <h3>Nothing to do here!</h3>
                  <p>
                    There are no new results waiting for you, however why not
                    review your old results.
                  </p>

                  <Button to="/results" secondary small>
                    View all Results
                  </Button>
                </div>
              </ListHeader>
            )}
          </Section>
        </Container>
      </Section>
      <Sidebar />
    </Layout>
  );
}

export default Dashboard;
