import React, { useState } from 'react';
import styled from 'styled-components';
import Reorder, { reorder } from 'react-reorder';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import Card from './Card';
import Loader from './Loader';
import TextContent from './TextContent';
import ButtonDropdown from './ButtonDropdown';

import { extractPrismicContent, extractPrismicString } from '../utils/prismic';
import isMobileDevice from '../utils/isMobileDevice';

import { GET_VALUES, ADD_VALUE, UPDATE_VALUE } from '../graph/values';

import { ReactComponent as DraggableIcon } from '../assets/draggable-icon.svg';
import { ReactComponent as DownArrow } from '../assets/down-arrow.svg';
import { ReactComponent as UpArrow } from '../assets/up-arrow.svg';

import { visuallyHidden } from '../theme/utils';

const DragButton = styled(DraggableIcon)`
  fill: ${props => props.theme.colors.grey200};
  width: ${props => props.theme.spacing.base};
  height: ${props => props.theme.spacing.base};
  display: block;
  margin: auto auto 0 auto;
  transition: fill ${props => props.theme.base.transitionSpeed}
    ${props => props.theme.easing.move};
`;

const NavigateButton = styled.button`
  svg {
    fill: ${props => props.theme.colors.green500};
    width: 20px;
    height: 20px;
    display: block;
    margin: 0 ${props => props.theme.spacing.xxSmall};
  }

  span {
    ${visuallyHidden}
  }
`;

const Wraper = styled.div`
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -${props => props.theme.spacing.small};
  }

  li {
    cursor: grab;
    display: flex;
    user-select: none;
    padding: ${props => props.theme.spacing.small};
    flex: 0 0 100%;
    width: 100%;

    ${props => props.theme.breakpoints.medium`
     flex: 0 0 50%;
     width: 50%;
    `}

    ${props => props.theme.breakpoints.large`
     flex: 0 0 33.33%;
     width: 33.33%;
    `}

    div {
      margin: 0;
    }

    h6 {
      color: ${props => props.theme.colors.red500};
    }

    &:hover {
      ${DragButton} {
        fill: ${props => props.theme.colors.green100};
      }
    }

    &.dragged {
      z-index: ${props => props.theme.layers.modal};

      ${DragButton} {
        fill: ${props => props.theme.colors.green500};
      }
    }

    &.placeholder {
      div {
        box-shadow: none;
        background-color: ${props => props.theme.colors.grey200};
        opacity: 0.5;

        p,
        h6,
        ${DragButton} {
          visibility: hidden;
        }
      }
    }
  }
`;

const Item = styled.li`
  h6 {
    font-size: ${props => props.theme.fontSizes.base};
    margin-bottom: ${props => props.theme.spacing.xSmall};

    ${props => props.theme.breakpoints.medium`
      font-size: ${props.theme.fontSizes.small};
    `}
  }

  p {
    display: inline;

    ${props => props.theme.breakpoints.medium`
      display: block;
    `}
  }
`;

const FooterIcons = styled.footer`
  display: flex;
  margin-top: auto;
  padding-top: ${props => props.theme.spacing.xSmall};
  justify-content: center;
  width: 100%;

  ${props => props.theme.breakpoints.medium`
    padding-top: ${props.theme.spacing.small};
  `}
`;

let isAddingValues = false;

function ValueRankingActivity({ values, activityId }) {
  const [orderedValues, setOrderedValues] = useState();

  const [handleAddValue] = useMutation(ADD_VALUE);
  const [handleUpdateValue] = useMutation(UPDATE_VALUE);

  const mobile = isMobileDevice();

  const { loading } = useQuery(GET_VALUES, {
    variables: { activityId },
    onCompleted: async valueData => {
      if (isAddingValues) return;

      isAddingValues = true;
      const mergedValueData = await Promise.all(
        values.map(async (value, i) => {
          const matchedValue = valueData?.values?.find(
            valueDateItem => valueDateItem.type === value.id,
          );

          if (!matchedValue) {
            const { data } = await handleAddValue({
              variables: {
                activityId,
                type: value.id,
                ranking: i,
              },
            });

            return { ...value, ...data?.addValue };
          }

          return { ...value, ...matchedValue };
        }),
      );

      isAddingValues = false;

      const mergedOrderedValues = [...mergedValueData].sort(
        (a, b) => a.ranking - b.ranking,
      );

      setOrderedValues(mergedOrderedValues);
    },
  });

  const updateValues = reorderdValues => {
    reorderdValues.map(({ id }, i) => {
      return handleUpdateValue({ variables: { id, ranking: i } });
    });
  };

  const handleReorder = (event, previousIndex, nextIndex) => {
    const reorderdValues = reorder(orderedValues, previousIndex, nextIndex);
    updateValues(reorderdValues);
    setOrderedValues(reorderdValues);
  };

  if (loading || !orderedValues) return <Loader />;

  return (
    <Wraper>
      <Reorder
        reorderId="id" // Unique ID that is used internally to track this list (required)
        onReorder={handleReorder} // Callback when an item is dropped (you will need this to update your state)
        lock={false}
        disabled={mobile}
        component="ul"
      >
        {orderedValues.map((item, i) => (
          <Item>
            <Card key={item.id} overflow>
              <TextContent maxHeight={mobile ? 30 : undefined}>
                <h6>
                  {i + 1} {extractPrismicString(item.title)}
                </h6>
                {extractPrismicContent(item.value_text)}
              </TextContent>

              <FooterIcons>
                {mobile ? (
                  <>
                    {' '}
                    <NavigateButton
                      type="button"
                      onClick={() => handleReorder(null, i, i - 1)}
                      disabled={i === 0}
                    >
                      <UpArrow />
                      <span>Up</span>
                    </NavigateButton>
                    <NavigateButton
                      type="button"
                      onClick={() => handleReorder(null, i, i + 1)}
                      disabled={i === orderedValues.length - 1}
                    >
                      <DownArrow />
                      <span>Down</span>
                    </NavigateButton>
                    <ButtonDropdown
                      small
                      secondary
                      items={[
                        {
                          id: '1',
                          text: 'Up to the top',
                          onClick: () => handleReorder(null, i, 0),
                          disabled: i === 0,
                        },
                        {
                          id: '2',
                          text: 'Up one',
                          onClick: () => handleReorder(null, i, i - 1),
                          disabled: i === 0,
                        },
                        {
                          id: '4',
                          text: 'Down one',
                          onClick: () => handleReorder(null, i, i - 1),
                          disabled: i === orderedValues.length - 1,
                        },
                        {
                          id: '4',
                          text: 'Down to the bottom',
                          onClick: () =>
                            handleReorder(null, i, orderedValues.length - 1),
                          disabled: i === orderedValues.length - 1,
                        },
                      ]}
                    >
                      Reorder
                    </ButtonDropdown>
                  </>
                ) : (
                  <DragButton />
                )}
              </FooterIcons>
            </Card>
          </Item>
        ))}
      </Reorder>
    </Wraper>
  );
}

ValueRankingActivity.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activityId: PropTypes.string.isRequired,
};

export default ValueRankingActivity;
