import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import Layout from '../components/Layout';
import Container from '../components/Container';
import Section from '../components/Section';
import TextContent from '../components/TextContent';
import FooterBar from '../components/FooterBar';
import Notification from '../components/Notification';
import References from '../components/References';
import Marketplace from '../components/Marketplace';
import ContentBlock from '../components/ContentBlock';

import { GET_ACTIVITY_DETAILS } from '../graph/activities';
import { GET_CMS_CONTENT } from '../graph/cms';

import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';
import useTrackActivityGA from '../utils/useTrackActivityGA';

import { activities, ACTIVITY_STATUS } from '../config/levels';

function ActivityIntro({ activityId }) {
  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data, loading } = useQuery(GET_ACTIVITY_DETAILS, {
    variables: { id: activityId },
  });

  useTrackActivityGA(data?.activity?.type, 'introduction');

  const activityType = data?.activity?.type;

  const { level } = activities[activityType] || {};

  const {
    title,
    sub_title: subTitle,
    intro_text: introText,
    intro_text_2: introText2,
    quote_1: quote1,
    quote_1_citation: quote1Cite,
    quote_2: quote2,
    quote_2_citation: quote2Cite,
    intro_image: introImage,
    instructions,
    references,
    marketplace,
    body1: blocks,
    ...marketplaceProps
  } = cmsData?.cmsContent?.activity?.find(
    (activity) =>
      activity.id ===
      (cmsData?.user?.currentLevel === 4 &&
      data?.activity?.status === ACTIVITY_STATUS.COMPLETED
        ? `ongoing_${activityType}`
        : activityType),
  ) ||
  cmsData?.cmsContent?.activity?.find(
    (activity) => activity.id === activityType,
  ) ||
  {};

  return (
    <Layout
      title={`${extractPrismicString(title)}`}
      loading={loading}
      hasFooterBar
    >
      <Container narrow>
        <Section noPaddingBottom={blocks?.length}>
          {hasContent(introText) && (
            <TextContent marginBottom>
              {hasContent(quote1) && (
                <Notification type="green">
                  <blockquote>{extractPrismicString(quote1)}</blockquote>
                  {hasContent(quote1Cite) && (
                    <cite> {extractPrismicString(quote1Cite)}</cite>
                  )}
                </Notification>
              )}

              {extractPrismicContent(introText)}
            </TextContent>
          )}

          {hasContent(introText2) && (
            <TextContent>
              {!!quote2?.length && (
                <Notification type="green">
                  <blockquote>{extractPrismicString(quote2)}</blockquote>
                  {!!quote2Cite?.length && (
                    <cite> {extractPrismicString(quote2Cite)}</cite>
                  )}
                </Notification>
              )}

              {extractPrismicContent(introText2)}
            </TextContent>
          )}

          {introImage?.url && (
            <img src={introImage?.url} alt={introImage?.alt} />
          )}
        </Section>
      </Container>

      {!!blocks && blocks.map((block) => <ContentBlock {...block} />)}

      {hasContent(references) && (
        <Section smallPadding>
          <Container narrow>
            <References>{extractPrismicContent(references)}</References>
          </Container>
        </Section>
      )}

      {marketplace && <Marketplace {...marketplaceProps} />}

      <FooterBar
        title={extractPrismicString(title)}
        subTitle={extractPrismicString(subTitle)}
        primaryButton={
          !marketplace && {
            label: hasContent(instructions)
              ? 'Next: Activity instructions'
              : 'Next: Start activity',
            to: `/activities/${activityId}/${
              hasContent(instructions) ? 'instructions' : activityType
            }`,
            level,
          }
        }
        secondaryButton={{
          label: 'Back to activities',
          to: '/activities',
        }}
      />
    </Layout>
  );
}

ActivityIntro.propTypes = {
  activityId: PropTypes.string,
};

ActivityIntro.defaultProps = {
  activityId: null,
};

export default ActivityIntro;
