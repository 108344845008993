import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { reorder } from 'react-reorder';

import AudioPlayer from '../components/AudioPlayer';
import Layout from '../components/Layout';
import Container from '../components/Container';
import Notification from '../components/Notification';
import TextContent from '../components/TextContent';
import Section from '../components/Section';
import FooterBar from '../components/FooterBar';
import Grid from '../components/Grid';
import GridItem from '../components/GridItem';
import Table from '../components/Table';

import { GET_ACTIVITY_DETAILS } from '../graph/activities';
import { GET_CMS_CONTENT } from '../graph/cms';
import { GET_GOALS, ADD_GOAL, DELETE_GOAL, UPDATE_GOAL } from '../graph/goals';

import extractGraphQLError from '../utils/extractGraphQLError';
import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';
import { validate, requiredString } from '../utils/validate';
import useTrackActivityGA from '../utils/useTrackActivityGA';

import { activities, ACTIVITY_STATUS } from '../config/levels';

import { ReactComponent as RemoveIcon } from '../assets/remove-icon.svg';
import { colors } from '../theme/main';

const goalFormValidationSchema = validate({
  title: requiredString,
});

const goalFormInitialValues = {
  title: '',
};

const goalFormFields = [
  {
    id: 'title',
    name: 'title',
    label: 'Goal',
    type: 'textarea',
    placeholder: 'Enter your goal...',
  },
];

function ActivityGoalsStepThree({ activityId }) {
  const [orderedGoals, setOrderedGoals] = useState([]);

  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data, loading } = useQuery(GET_ACTIVITY_DETAILS, {
    variables: { id: activityId },
  });

  const { loading: loadingGoals } = useQuery(GET_GOALS, {
    variables: { activityId },
    onCompleted: async goals => {
      const orderedGoalData = [...goals?.goals]
        .filter(goal => {
          return goal.type === 'FINAL';
        })
        .sort((a, b) => a.ranking - b.ranking);

      setOrderedGoals(orderedGoalData);
    },
  });

  const isGoalsValid = orderedGoals?.length > 4;

  const [handleUpdateGoal] = useMutation(UPDATE_GOAL);

  const [
    handleUpdateGoalWithRefetch,
    { loading: loadingUpdateGoal, error: errorUpdateGoal },
  ] = useMutation(UPDATE_GOAL, {
    refetchQueries: ['Goals'],
  });

  const [
    handleAddGoal,
    { loading: loadingAddGoal, error: errorAddGoal },
  ] = useMutation(ADD_GOAL, {
    refetchQueries: ['Goals'],
  });

  const handleNewGoal = formData => {
    handleAddGoal({
      variables: {
        activityId,
        type: 'FINAL',
        ...formData,
      },
    });
  };

  const [
    handleDeleteGoal,
    { loading: loadingDeleteGoal, error: errorDeleteGoal },
  ] = useMutation(DELETE_GOAL, {
    refetchQueries: ['Goals'],
  });

  const updateGoals = reorderdGoals => {
    reorderdGoals.map(({ id }, i) => {
      return handleUpdateGoal({ variables: { id, ranking: i } });
    });
  };

  const handleReorder = (event, previousIndex, nextIndex) => {
    const reorderdGoals = reorder(orderedGoals, previousIndex, nextIndex);
    updateGoals(reorderdGoals);
    setOrderedGoals(reorderdGoals);
  };

  const activityType = data?.activity?.type;

  useTrackActivityGA(data?.activity?.type, '3');

  const { level } = activities[activityType] || {};

  const {
    title,
    sub_title: subTitle,
    audio_instructions_3: audioInstructions3,
    help_text_3: helpText3,
    short_instructions_3: shortInstructions3,
    step_label_3: stepInstructions3,
  } =
    cmsData?.cmsContent?.activity?.find(
      activity =>
        activity.id ===
        (cmsData?.user?.currentLevel === 4 &&
        data?.activity?.status === ACTIVITY_STATUS.COMPLETED
          ? `ongoing_${activityType}`
          : activityType),
    ) ||
    cmsData?.cmsContent?.activity?.find(
      activity => activity.id === activityType,
    ) ||
    {};

  return (
    <Layout
      title={`${extractPrismicString(title)}`}
      loading={loading || loadingGoals}
      hasFooterBar
    >
      <Container>
        <Section>
          {!!(hasContent(shortInstructions3) || hasContent(helpText3)) && (
            <Grid>
              <GridItem width={60}>
                <TextContent redh6 smallMargin>
                  {extractPrismicContent(shortInstructions3)}
                </TextContent>
              </GridItem>

              <GridItem width={40}>
                {!!(audioInstructions3?.url || hasContent(helpText3)) && (
                  <Notification showUser noMarginBottom>
                    {extractPrismicContent(helpText3)}

                    {!!audioInstructions3?.url && (
                      <AudioPlayer fileUrl={audioInstructions3?.url} />
                    )}
                  </Notification>
                )}
              </GridItem>
            </Grid>
          )}
        </Section>

        <TextContent redh6 smallMargin>
          <h6>Step 3</h6>
          <p>{extractPrismicString(stepInstructions3)}</p>
        </TextContent>

        <Table
          cols={[
            {
              id: 'ranking',
              value: 'Ranking',
              small: true,
              transformer: (value, i) => (
                <h3
                  style={{
                    color: i < 5 ? colors.green500 : colors.grey200,
                  }}
                >
                  {i + 1}
                </h3>
              ),
            },
            { id: 'title', value: 'Goal' },
          ]}
          sortable
          data={orderedGoals}
          redorderId="goalId"
          onReorder={handleReorder}
          editable
          actions={[
            {
              id: 'remove',
              text: 'remove',
              icon: <RemoveIcon />,
              onClick: handleDeleteGoal,
              loading: loadingDeleteGoal,
            },
          ]}
          updateForm={{
            fields: goalFormFields,
            validationSchema: goalFormValidationSchema,
            onSubmit: handleUpdateGoalWithRefetch,
            loading: loadingUpdateGoal,
          }}
          form={{
            fields: goalFormFields,
            loading: loadingAddGoal,
            validationSchema: goalFormValidationSchema,
            initialValues: goalFormInitialValues,
            onSubmit: handleNewGoal,
          }}
        />

        {!!(errorAddGoal || errorDeleteGoal || errorUpdateGoal) && (
          <Notification type="red">
            {extractGraphQLError(
              errorAddGoal || errorDeleteGoal || errorUpdateGoal,
            )}
          </Notification>
        )}
      </Container>

      <FooterBar
        title={extractPrismicString(title)}
        subTitle={extractPrismicString(subTitle)}
        primaryButton={{
          label: 'Next: Make goals SMART',
          to: `/activities/${activityId}/goals/4`,
          disabled: !isGoalsValid,
          level,
        }}
        secondaryButton={{
          label: 'Back to defining goals',
          to: `/activities/${activityId}/goals/2`,
        }}
      />
    </Layout>
  );
}

ActivityGoalsStepThree.propTypes = {
  activityId: PropTypes.string,
};

ActivityGoalsStepThree.defaultProps = {
  activityId: null,
};

export default ActivityGoalsStepThree;
