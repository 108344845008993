/**
 * @format
 * @flow
 */

import gql from 'graphql-tag';

const GET_PRODUCTS = gql`
  query Products($type: String!) {
    products(type: $type) {
      id
      name
      amount
      amount_usd
      discountedAmount
      discountedAmount_usd
      cmsId
      chargeVAT
    }
  }
`;

const CREATE_PAYMENT = gql`
  mutation CreateStripePayment($productId: ID!, $currency: String!) {
    createStripePayment(productId: $productId, currency: $currency) {
      clientSecret
      paymentId
      status
      user {
        id
        coupon
      }
    }
  }
`;

const UPDATE_PAYMENT = gql`
  mutation UpdateStripePayment($paymentId: ID!, $status: String!) {
    updateStripePayment(paymentId: $paymentId, status: $status) {
      success
      user {
        id
        coupon
      }
    }
  }
`;

const ADD_COUPON = gql`
  mutation AddCoupon($couponId: ID!) {
    addCoupon(couponId: $couponId) {
      user {
        id
        hasActiveSubscription
        coupon
      }
      products {
        id
        name
        amount
        amount_usd
        discountedAmount
        discountedAmount_usd
        cmsId
        chargeVAT
      }
    }
  }
`;

const REMOVE_COUPON = gql`
  mutation RemoveCoupon {
    removeCoupon {
      user {
        id
        hasActiveSubscription
        coupon
      }
      products {
        id
        name
        amount
        amount_usd
        discountedAmount
        discountedAmount_usd
        cmsId
        chargeVAT
      }
    }
  }
`;

const CREATE_SUBSCRIPTION = gql`
  mutation ($productId: ID!, $paymentMethodId: ID, $currency: String!) {
    createStripeSubscription(
      productId: $productId
      paymentMethodId: $paymentMethodId
      currency: $currency
    ) {
      clientSecret
      subscriptionId
      status
      user {
        id
        coupon
      }
    }
  }
`;

const CANCEL_SUBSCRIPTION = gql`
  mutation {
    cancelStripeSubscription {
      user {
        id
        hasActiveSubscription
        currentSubscription {
          id
          lastBillDate
          nextBillDate
          price
          currency
        }
      }
    }
  }
`;

export {
  GET_PRODUCTS,
  CREATE_PAYMENT,
  UPDATE_PAYMENT,
  ADD_COUPON,
  REMOVE_COUPON,
  CREATE_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
};
