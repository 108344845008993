import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  margin: auto;
`;

const Bar = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 2px ${props => props.theme.spacing.base};
  margin: ${props => props.theme.spacing.xxSmall} 0;
  position: relative;
  width: 100%;

  p {
    position: relative;
    z-index: 2;
    margin: 0;
    width: 100%;
    font-size: ${props => props.theme.fontSizes.xSmall};
    overflow: visible;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: ${props => props.theme.colors.grey200};
    width: ${props => props.width || 100}%;
  }

  strong {
    color: ${props => props.theme.colors.green500};
    padding-right: ${props => props.theme.spacing.xxSmall};
  }

  span {
    margin-left: auto;
    font-weight: ${props => props.theme.fontWeights.bold};
    display: inline-block;
    padding-left: ${props => props.theme.spacing.base};
    color: ${props => props.theme.colors.black};
  }
`;

function AdvancedBarList({ data, calculateWidth, showValue, ...props }) {
  return (
    <Wrapper>
      {data?.map((item, i) => {
        return (
          <Bar
            key={item.id || item.value || item.label}
            width={
              calculateWidth ? (item.value / data.length) * 100 : item.value
            }
            {...props}
          >
            <p>
              <strong>{i + 1}.</strong> {item.label}
              {showValue && <span>{item.value}%</span>}
            </p>
          </Bar>
        );
      })}
    </Wrapper>
  );
}

AdvancedBarList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  calculateWidth: PropTypes.bool,
  showValue: PropTypes.bool,
};

AdvancedBarList.defaultProps = {
  calculateWidth: true,
  showValue: false,
};

export default AdvancedBarList;
