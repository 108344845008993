import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import AudioPlayer from '../components/AudioPlayer';
import Card from '../components/Card';
import Layout from '../components/Layout';
import Container from '../components/Container';
import Notification from '../components/Notification';
import TextContent from '../components/TextContent';
import Section from '../components/Section';
import FooterBar from '../components/FooterBar';
import Grid from '../components/Grid';
import GridItem from '../components/GridItem';
import VisionActivity from '../components/VisionActivity';
import SimpleBarList from '../components/SimpleBarList';

import { GET_ACTIVITY_DETAILS, UPDATE_ACTIVITY } from '../graph/activities';
import { GET_CMS_CONTENT } from '../graph/cms';
import { GET_FORM_DATA } from '../graph/cache';
import { GET_RESULTS } from '../graph/results';

import extractGraphQLError from '../utils/extractGraphQLError';
import sleep from '../utils/sleep';
import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';
import { validate, requiredString } from '../utils/validate';
import useTrackActivityGA from '../utils/useTrackActivityGA';

import { activities, ACTIVITY_STATUS } from '../config/levels';

const visionValidationSchema = validate({
  vision: requiredString.test('len', 'Must be at least 100 words', (val) => {
    const { length } = val?.split(' ') || {};

    return (length || 0) >= 100;
  }),
  visionSummary: requiredString.test(
    'len',
    'Must be at least 20 words',
    (val) => {
      const { length } = val?.split(' ') || {};

      return (length || 0) >= 20;
    },
  ),
});

function ActivityVision({ activityId }) {
  const [isValid, setIsValid] = useState(false);
  const client = useApolloClient();
  const { data: formData } = useQuery(GET_FORM_DATA);

  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data, loading } = useQuery(GET_ACTIVITY_DETAILS, {
    variables: { id: activityId },
  });

  const { data: resultData, loading: loadingResults } = useQuery(GET_RESULTS);

  const activityType = data?.activity?.type;

  useTrackActivityGA(data?.activity?.type, '1');

  const { level } = activities[activityType] || {};

  const {
    title,
    sub_title: subTitle,
    instructions,
    audio_instructions_1: audioInstructions,
    help_text_1: helpText,
    short_instructions_1: shortInstructions,
  } = cmsData?.cmsContent?.activity?.find(
    (activity) =>
      activity.id ===
      (cmsData?.user?.currentLevel === 4 &&
      data?.activity?.status === ACTIVITY_STATUS.COMPLETED
        ? `ongoing_${activityType}`
        : activityType),
  ) ||
  cmsData?.cmsContent?.activity?.find(
    (activity) => activity.id === activityType,
  ) ||
  {};

  useEffect(() => {
    const validateActivity = async () => {
      const isActivityValid = await visionValidationSchema.isValid({
        vision: formData?.formData.vision || data?.activity?.vision,
        visionSummary:
          formData?.formData.visionSummary || data?.activity?.visionSummary,
      });

      setIsValid(isActivityValid);
    };

    validateActivity();
  }, [
    activityType,
    data,
    formData?.formData.vision,
    formData?.formData.visionSummary,
  ]);

  const [handleUpdateActivity, { loading: loadingUpdateActivity, error }] =
    useMutation(UPDATE_ACTIVITY);

  const handleCompleteActivity = async () => {
    await handleUpdateActivity({
      variables: {
        id: activityId,
        status: ACTIVITY_STATUS.COMPLETED,
        ...(formData?.formData.vision
          ? { vision: formData?.formData.vision }
          : {}),
        ...(formData?.formData.visionSummary
          ? { visionSummary: formData?.formData.visionSummary }
          : {}),
      },
    });

    client.cache.writeQuery({
      query: GET_FORM_DATA,
      data: {
        formData: {
          vision: undefined,
          visionSummary: undefined,
        },
      },
    });

    await sleep(500);

    navigate(`/activities/${activityId}/completed`);
  };

  return (
    <Layout
      title={`${extractPrismicString(title)}`}
      loading={loading || loadingResults}
      hasFooterBar
    >
      <Container>
        <Section>
          {!!(hasContent(shortInstructions) || hasContent(helpText)) && (
            <Grid>
              <GridItem width={60}>
                <TextContent redh6 smallMargin>
                  {extractPrismicContent(shortInstructions)}
                </TextContent>
              </GridItem>

              <GridItem width={40}>
                {!!(audioInstructions?.url || hasContent(helpText)) && (
                  <Notification showUser noMarginBottom>
                    {extractPrismicContent(helpText)}

                    {!!audioInstructions?.url && (
                      <AudioPlayer fileUrl={audioInstructions?.url} />
                    )}
                  </Notification>
                )}
              </GridItem>
            </Grid>
          )}
        </Section>

        {!!error && (
          <Notification type="red">{extractGraphQLError(error)}</Notification>
        )}

        <Section smallPadding>
          <Grid>
            <GridItem width={33.33} alignTop>
              <Card header="Top 5 values">
                <SimpleBarList
                  data={resultData?.results?.values?.values?.slice(0, 5)}
                  emptyDataMessage="Completing the 'Your values' activity first, can be super helpful for this activity!"
                  emptyDataLink="/activities"
                  emptyDataLinkText="Back to activities"
                />
              </Card>
            </GridItem>

            <GridItem width={33.33} alignTop>
              <Card header="Top 5 character strengths">
                <SimpleBarList
                  data={resultData?.results?.strengths?.strengths?.slice(0, 5)}
                  emptyDataMessage="Completing the 'Character Strengths Assessment' activity first, can be super helpful for this activity!"
                  emptyDataLink="/activities"
                  emptyDataLinkText="Back to activities"
                />
              </Card>
            </GridItem>

            <GridItem width={33.33} alignTop>
              <Card header="Top 3 career interests">
                <SimpleBarList
                  data={resultData?.results?.interests?.interests?.slice(0, 3)}
                  emptyDataMessage="Completing the 'Your interests' activity first, can be super helpful for this activity!"
                  emptyDataLink="/activities"
                  emptyDataLinkText="Back to activities"
                />
              </Card>
            </GridItem>
          </Grid>
        </Section>

        <VisionActivity activity={data?.activity} />
      </Container>

      <FooterBar
        title={extractPrismicString(title)}
        subTitle={extractPrismicString(subTitle)}
        primaryButton={{
          label: 'Complete Activity',
          onClick: handleCompleteActivity,
          loading: loadingUpdateActivity,
          disabled: !isValid,
          level,
        }}
        secondaryButton={{
          label: hasContent(instructions)
            ? 'Back to instructions'
            : 'Back to introduction',
          to: `/activities/${activityId}/${
            hasContent(instructions) ? 'instructions' : 'introduction'
          }`,
        }}
      />
    </Layout>
  );
}

ActivityVision.propTypes = {
  activityId: PropTypes.string,
};

ActivityVision.defaultProps = {
  activityId: null,
};

export default ActivityVision;
