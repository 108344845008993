import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { navigate } from '@reach/router';

import Layout from '../components/Layout';
import Container from '../components/Container';
import Section from '../components/Section';
import TextContent from '../components/TextContent';
import TextButton from '../components/TextButton';
import References from '../components/References';
import ContentBlock from '../components/ContentBlock';

import { GET_CMS_CONTENT } from '../graph/cms';

import useSiteSettings from '../hooks/useSiteSettings';

import {
  extractPrismicContent,
  extractPrismicString,
  hasContent,
} from '../utils/prismic';

function Page({ id }) {
  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const {
    title,
    content,
    show_back_link: showBackLink,
    references,
    body: blocks,
  } = cmsData?.cmsContent?.page?.find(page => page.id === id) || {};

  const { bannerBgImage } = useSiteSettings();

  return (
    <Layout title={extractPrismicString(title)}>
      {(hasContent(title) || hasContent(content)) && (
        <Section noPaddingBottom={!hasContent(content)}>
          <Container narrow={hasContent(content)}>
            {showBackLink && (
              <TextContent marginBottom>
                <p>
                  <TextButton onClick={() => navigate(-1)}>Back</TextButton>
                </p>
              </TextContent>
            )}

            <TextContent marginBottom={hasContent(content)}>
              <h1>{extractPrismicString(title)}</h1>
            </TextContent>

            {hasContent(content) && (
              <TextContent redh6>{extractPrismicContent(content)}</TextContent>
            )}
          </Container>
        </Section>
      )}

      {!!blocks &&
        blocks.map(block => (
          <ContentBlock backgroundImage={bannerBgImage} {...block} />
        ))}

      {hasContent(references) && (
        <Section smallPadding>
          <Container narrow>
            <References>{extractPrismicContent(references)}</References>
          </Container>
        </Section>
      )}
    </Layout>
  );
}

export default Page;
