import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Tag from './Tag';
import Select from './Select';

const Inner = styled.div`
  display: flex;
  align-items: center;

  p {
    font-size: ${props => props.theme.fontSizes.small};
    color: ${props => props.theme.colors.grey500};
    line-height: 1;
    margin: 0 ${props => props.theme.spacing.base} 0 0;
  }
`;

const SearchArea = styled.div`
  margin: auto 0 auto auto;
  transform: translateY(-3px);
`;

function ActionsFilterBar({
  filterValues,
  onChangeFilters,
  goalIdValue,
  onChangeGoal,
  goalOptions,
}) {
  const handleSelectFilter = filter => {
    onChangeFilters({
      ...filterValues,
      [filter]: true,
    });
  };

  const handleRemoveFilter = filter => {
    onChangeFilters({
      ...filterValues,
      [filter]: false,
    });
  };

  const handleChangeGoal = e => {
    onChangeGoal(e?.target?.value);
  };

  return (
    <Inner>
      <p>Filters:</p>
      <Tag
        dark={filterValues.HIGH}
        light={!filterValues.HIGH}
        onClick={
          !filterValues.HIGH ? () => handleSelectFilter('HIGH') : undefined
        }
        onRemove={
          filterValues.HIGH ? () => handleRemoveFilter('HIGH') : undefined
        }
      >
        High
      </Tag>
      <Tag
        dark={filterValues.MEDIUM}
        light={!filterValues.MEDIUM}
        onClick={
          !filterValues.MEDIUM ? () => handleSelectFilter('MEDIUM') : undefined
        }
        onRemove={
          filterValues.MEDIUM ? () => handleRemoveFilter('MEDIUM') : undefined
        }
      >
        Medium
      </Tag>
      <Tag
        dark={filterValues.LOW}
        light={!filterValues.LOW}
        onClick={
          !filterValues.LOW ? () => handleSelectFilter('LOW') : undefined
        }
        onRemove={
          filterValues.LOW ? () => handleRemoveFilter('LOW') : undefined
        }
      >
        Low
      </Tag>

      <SearchArea>
        <Select
          id="goal"
          name="goal"
          onChange={handleChangeGoal}
          value={goalIdValue}
          small
          defaultLabel="Filter by goal"
          noMargin
          options={goalOptions || []}
        />
      </SearchArea>
    </Inner>
  );
}

ActionsFilterBar.propTypes = {
  filterValues: PropTypes.shape({}).isRequired,
  onChangeFilters: PropTypes.func.isRequired,
  goalIdValue: PropTypes.string.isRequired,
  onChangeGoal: PropTypes.func.isRequired,
  goalOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ActionsFilterBar;
