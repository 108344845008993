import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'formik';

import FieldWrapper from './FieldWrapper';

import fieldBase from '../theme/fieldBase';

export const Input = styled.input`
  ${fieldBase}

  ${props =>
    props.modifiers.error &&
    `
    border-color: ${props.theme.colors.red500};
  `}

${props =>
  props.modifiers.readOnly &&
  `
    color: ${props.theme.colors.grey300};

    &:focus {
      border-color: ${props.theme.colors.grey300};
    }
  `}

${props =>
  props.modifiers.small &&
  `
    padding: 9px 14px 8px;
    font-size: ${props.theme.fontSizes.small};
  `}
`;

function TextField({
  name,
  hintText,
  type,
  disabled,
  readOnly,
  id,
  label,
  hideLabel,
  placeholder,
  noMargin,
  small,
  ...props
}) {
  return (
    <Field
      name={name}
      render={({ field, form: { errors, touched, submitCount } }) => {
        const errorMessage =
          ((touched && touched[field.name]) || submitCount > 0) &&
          errors &&
          errors[field.name];

        return (
          <FieldWrapper
            errorMessage={errorMessage}
            name={name}
            label={label}
            hintText={hintText}
            hideLabel={hideLabel}
            noMargin={noMargin}
            {...props}
          >
            <Input
              name={name}
              type={type}
              modifiers={{ error: !!errorMessage, small, readOnly }}
              disabled={disabled}
              readOnly={readOnly}
              placeholder={placeholder}
              {...field}
              id={id}
              value={field.value}
            />
          </FieldWrapper>
        );
      }}
    />
  );
}

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  hintText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  hideLabel: PropTypes.bool,
  placeholder: PropTypes.string,
  noMargin: PropTypes.bool,
  small: PropTypes.bool,
};

TextField.defaultProps = {
  disabled: false,
  hintText: null,
  readOnly: false,
  hideLabel: false,
  placeholder: undefined,
  noMargin: false,
  small: false,
};

export default TextField;
