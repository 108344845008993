import theme from './main';

const fieldBase = `
  height: ${theme.spacing.base};
  border-radius: ${theme.base.radius};
  background-color: ${theme.colors.green500};
  color: ${theme.colors.white};
  font-size: ${theme.fontSizes.xxSmall};
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  padding: 0 ${theme.spacing.xSmall};
  margin-right: ${theme.spacing.xxSmall};
`;

export default fieldBase;
