import React, { useState, useEffect } from 'react';
import StripeScriptLoader from 'react-stripe-script-loader';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { navigate } from '@reach/router';

import Card from '../components/Card';
import Button from '../components/Button';
import Loader from '../components/Loader';
import LayoutAuth from '../components/LayoutAuth';
import CardIcons from '../components/CardIcons';
import TextContent from '../components/TextContent';

import { GET_PRODUCTS } from '../graph/payment';
import { GET_USER } from '../graph/auth';
import { GET_CMS_CONTENT } from '../graph/cms';
import { GET_CURRENCY } from '../graph/currency';

import formatCurrency from '../utils/formatCurrency';
import getValue, { VAT_AMOUNT } from '../utils/getValue';
import { extractPrismicString, extractPrismicContent } from '../utils/prismic';

import useSiteSettings from '../hooks/useSiteSettings';

import { ReactComponent as TickIcon } from '../assets/tick-icon.svg';
import SubscriptionForm from '../components/SubscriptionForm';

const SuccessIcon = styled(TickIcon)`
  display: block;
  margin: 0 auto ${(props) => props.theme.spacing.midLarge};
  height: 50px;
  width: 50px;
  fill: ${(props) => props.theme.colors.green500};
`;

function Payment() {
  const { siteName } = useSiteSettings();

  const client = useApolloClient();
  const [paymentComplete, setPaymentComplete] = useState(false);
  const [isFetchingUser, setIsFetchingUser] = useState(false);

  const {
    data: { currency },
  } = useQuery(GET_CURRENCY);

  const style = useSpring({
    opacity: paymentComplete ? 1 : 0,
    transform: paymentComplete ? 'translate(0, 0)' : 'translate(0, 50px)',
  });

  const { data, loading } = useQuery(GET_PRODUCTS, {
    variables: { type: 'subscription' },
    fetchPolicy: 'network-only',
  });

  const { data: userData, loadingUser } = useQuery(GET_USER, {
    fetchPolicy: 'cache-first',
  });

  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  useEffect(() => {
    if (cmsData?.user?.hasActiveSubscription) {
      navigate('/activities');
    }
  }, [cmsData]);

  const {
    title,
    long_description: longDescription,
    price_text_1: priceText1,
    price_text_2: priceText2,
    success_text: successText,
  } = cmsData?.cmsContent?.products?.find(
    (item) => item.id === data?.products?.[0]?.cmsId,
  ) || {};

  const isFree = data?.products?.[0]?.discountedAmount === 0;
  const hasDiscount = data?.products?.[0]?.discountedAmount !== null;
  const discountAmount = formatCurrency(
    getValue(data?.products?.[0], currency) -
      getValue(data?.products?.[0], currency, true),
    currency,
  );
  const formattedPrice = formatCurrency(
    getValue(data?.products?.[0], currency),
    currency,
  );
  const formattedDiscountedPrice = formatCurrency(
    getValue(data?.products?.[0], currency, true),
    currency,
  );
  const priceWithDiscount = hasDiscount
    ? formattedDiscountedPrice
    : formattedPrice;

  const chargeVAT = data?.products?.[0].chargeVAT;

  const vatAmount = formatCurrency(
    getValue(data?.products?.[0], currency, hasDiscount) *
      (chargeVAT ? VAT_AMOUNT : 0),
    currency,
  );

  const priceWithVAT = formatCurrency(
    getValue(data?.products?.[0], currency, hasDiscount, chargeVAT),
    currency,
  );

  const handlePaymentSuccess = () => {
    (window.dataLayer || []).push({
      event: 'plan-purchase',
      plan: data?.products?.[0].name,
      price: priceWithDiscount,
      userID: userData?.user?.id,
    });

    setPaymentComplete(true);
  };

  const handleUpdateUserCache = async () => {
    setIsFetchingUser(true);

    await client.query({
      query: GET_USER,
      fetchPolicy: 'network-only',
    });

    setIsFetchingUser(false);

    navigate('/activities');
  };

  const renderSideContent = () => {
    return (
      <TextContent>
        <h4>{extractPrismicString(title)}</h4>

        <p>
          {extractPrismicString(priceText1)}
          <strong>
            {priceWithDiscount}
            {chargeVAT ? ' + VAT' : ''} per month
          </strong>
          . {extractPrismicString(priceText2)}
        </p>

        {extractPrismicContent(longDescription)}
      </TextContent>
    );
  };

  const renderPaymentForm = () => {
    if (loading || loadingUser) {
      return <Loader />;
    }

    return (
      <StripeScriptLoader
        uniqueId="stripe"
        script="https://js.stripe.com/v3/"
        loader={<Loader />}
      >
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PK}>
          <Elements>
            <SubscriptionForm
              onSuccess={handlePaymentSuccess}
              user={userData?.user}
              productId={data?.products?.[0]?.id}
              price={formattedPrice}
              discountedPrice={priceWithDiscount}
              priceWithVAT={priceWithVAT}
              hasDiscount={hasDiscount}
              isFree={isFree}
              currency={currency}
              message="This gives you access to the platform, group coaching, Q&A calls, private accountability group and access to all the premium activities for as long as your subscription is active."
              vatAmount={vatAmount}
              discountAmount={discountAmount}
              chargeVAT={chargeVAT}
            />
          </Elements>
        </StripeProvider>
      </StripeScriptLoader>
    );
  };

  if (paymentComplete) {
    return (
      <LayoutAuth title="Payment Successful" loading={loading || loadingUser}>
        <animated.div style={style}>
          <Card>
            <SuccessIcon />

            <TextContent center>
              <h1>
                {isFree ? `Welcome to ${siteName}` : 'Payment Successful'}
              </h1>

              {extractPrismicContent(successText)}

              <Button onClick={handleUpdateUserCache} loading={isFetchingUser}>
                Continue to Activities
              </Button>
            </TextContent>
          </Card>
        </animated.div>
      </LayoutAuth>
    );
  }

  return (
    <LayoutAuth title="Payment" large loading={loading || loadingUser}>
      <Card renderSideContent={renderSideContent}>{renderPaymentForm()}</Card>
      <CardIcons />
    </LayoutAuth>
  );
}

export default Payment;
