import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TableCell from './TableCell';
import TableForm from './TableForm';
import Tag from './Tag';

import isArrayWithLength from '../utils/isArrayWithLength';
import cardBase from '../theme/cardBase';

const TableWrapper = styled.table(
  ({ theme }) => `
    ${cardBase};
    border-spacing: 0;
    border-top: solid 1px ${theme.colors.grey200};
    margin: ${theme.spacing.medium} 0;
    max-width: ${theme.components.tableMaxWidthSmall};
    padding: 0;
    table-layout: fixed;
    width: 100%;

    thead {
      td {
        color: ${theme.colors.black};
        font-weight: ${theme.fontWeights.bold};
      }
    }

    tfoot {
     & > tr {
        & > td {
          padding: 0;
        }
      }
    }


  `,
);

function TagTable({ data, form, onDeleteTag, tagLoading }) {
  return (
    <TableWrapper cellspacing={0} cellpadding={0}>
      <thead>
        <tr>
          <TableCell>Hashtags</TableCell>
        </tr>
      </thead>

      <tbody>
        <tr>
          <TableCell>
            {!!isArrayWithLength(data) &&
              data.map(({ id, ...item }) => (
                <Tag
                  key={id}
                  loading={tagLoading}
                  onRemove={
                    onDeleteTag
                      ? () => onDeleteTag({ variables: { id } })
                      : undefined
                  }
                >
                  {item.title}
                </Tag>
              ))}
          </TableCell>
        </tr>
      </tbody>

      {!!form && <TableForm {...form} />}
    </TableWrapper>
  );
}

TagTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  actions: PropTypes.arrayOf(PropTypes.shape({})),
  cols: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onReorder: PropTypes.func,
  form: PropTypes.shape({}),
  sortable: PropTypes.bool,
  redorderId: PropTypes.string,
  onDeleteTag: PropTypes.func,
  tagLoading: PropTypes.bool,
};

TagTable.defaultProps = {
  data: null,
  actions: null,
  onReorder: undefined,
  form: null,
  sortable: null,
  redorderId: 'id',
  onDeleteTag: undefined,
  tagLoading: false,
};

export default memo(TagTable);
