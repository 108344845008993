import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import formatTime from '../utils/formatTime';

import { visuallyHidden } from '../theme/utils';

import playIcon from '../assets/play-icon.svg';

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.white};
  margin-top: ${props => props.theme.spacing.base};
  font-size: ${props => props.theme.fontSizes.small};
  align-items: center;
  display: flex;
  padding: ${props => props.theme.spacing.small};
  border-radius: ${props => props.theme.base.radiusSmall};
`;

const PlayButton = styled.button`
  border: solid 1px ${props => props.theme.colors.green500};
  border-radius: 100%;
  display: flex;
  flex: 0 0 32px;
  height: 32px;
  margin-right: ${props => props.theme.spacing.small};
  position: relative;
  transition: border-color 0.4s ease;
  width: 32px;

  span {
    ${visuallyHidden}
  }

  &:after {
    content: '';
    display: block;
    background: url(${playIcon}) center center / contain no-repeat;
    height: 12px;
    margin: auto;
    transform: translateX(1px);
    width: 12px;
  }

  &:hover {
      border-color: ${props => props.theme.colors.green600};
  }

  ${props =>
    props.playing &&
    `
    &:after,
    &:before {
      content: '';
      display: block;
      background: ${props.theme.colors.green500};
      height: 12px;
      margin: auto;
      width: 3px;
    }

    &:before {
      margin-right: 1px;
    }

    &:after {
      margin-left: 1px;
    }
    `}
  }
`;

const Range = styled.input`
  border-radius: ${props => props.theme.base.radiusSmall};
  -webkit-appearance: none;
  cursor: pointer;
  flex: 1 1 auto;
  height: 2px;
  background-color: ${props => props.theme.colors.green100};
  background-image: linear-gradient(
    90deg,
    ${props => props.theme.colors.green500} 0%,
    ${props => props.theme.colors.green500} 100%
  );
  background-size: 50% 100%;
  background-repeat: no-repeat;
  outline: none;
  margin: 0 ${props => props.theme.spacing.small};
  position: relative;
  width: 100%;

  &::-webkit-slider-thumb {
    opacity: 0;
  }

  &::-webkit-slider-runnable-track {
    box-shadow: none;
    border: none;
    background: transparent;
    -webkit-appearance: none;
  }

  &::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
`;

function AudioPlayer({ fileUrl }) {
  const player = useRef();
  const [status, setStatus] = useState('stopped');
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const handleTimeUpdate = () => {
    setCurrentTime(player.current.currentTime);
    setDuration(player.current?.duration);
  };

  const handleLoaded = useCallback(() => {
    setDuration(player.current?.duration);
  }, [player]);

  const handlePause = () => {
    setStatus('paused');
  };

  const handlePlay = () => {
    setStatus('playing');
  };

  useEffect(() => {
    if (!player || !player.current || !fileUrl) return;

    const playerRef = player.current;

    playerRef.addEventListener('loadedmetadata', handleLoaded);
    playerRef.addEventListener('pause', handlePause);
    playerRef.addEventListener('play', handlePlay);
    playerRef.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      playerRef.removeEventListener('timeupdate', handleTimeUpdate);
      playerRef.removeEventListener('loadedmetadata', handleTimeUpdate);
      playerRef.removeEventListener('pause', handlePause);
      playerRef.removeEventListener('play', handlePlay);
    };
  }, [fileUrl, handleLoaded, player]);

  const handlePlayPause = () => {
    if (!player || !player.current || !fileUrl) return;

    if (status === 'playing') {
      player.current.pause();
      return;
    }

    player.current.play();
  };

  const handleChangeTime = e => {
    const newTime = e.target.value;
    player.current.currentTime = newTime;
  };

  if (!fileUrl) return false;

  return (
    <Wrapper>
      <PlayButton playing={status === 'playing'} onClick={handlePlayPause}>
        <span>{status === 'playing' ? 'Pause' : 'Play'}</span>
      </PlayButton>
      <div>{formatTime(currentTime)}</div>
      <Range
        type="range"
        min={0}
        max={duration}
        value={currentTime}
        onChange={handleChangeTime}
        className="audio-player__range"
        style={{
          backgroundSize: duration
            ? `${(currentTime / duration) * 100}% 100%`
            : '0% 0%',
        }}
      />
      <div>{formatTime(duration)}</div>
      <audio ref={player} src={fileUrl} />
    </Wrapper>
  );
}

AudioPlayer.propTypes = {
  fileUrl: PropTypes.string.isRequired,
};

export default AudioPlayer;
