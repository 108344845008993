import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Card from './Card';
import Grid from './Grid';
import GridItem from './GridItem';
import TextButton from './TextButton';

import { extractPrismicString, extractPrismicContent } from '../utils/prismic';

const Wrapper = styled.div`
  width: 100%;
`;

const Inner = styled.div`
  margin-bottom: ${props => props.theme.spacing.base};
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

function IndustryList({ data }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleExapnded = () => {
    setIsExpanded(!isExpanded);
  };

  const orderdIndustries = data?.sort((a, b) => {
    const aTitleString = extractPrismicString(a.title);
    const bTitleString = extractPrismicString(b.title);

    if (aTitleString < bTitleString) {
      return -1;
    }
    if (aTitleString > bTitleString) {
      return 1;
    }
    return 0;
  });

  const limitedIndustries = isExpanded
    ? orderdIndustries
    : orderdIndustries.slice(0, 3);

  return (
    <Wrapper>
      <Inner>
        <Grid>
          {limitedIndustries?.map(({ title, content }) => (
            <GridItem width={33.33} grow>
              <Card header={extractPrismicString(title)} flex>
                {extractPrismicContent(content)}
              </Card>
            </GridItem>
          ))}
        </Grid>
      </Inner>

      <ButtonWrapper>
        <TextButton onClick={handleToggleExapnded}>
          Read {isExpanded ? 'less' : 'more'}
        </TextButton>
      </ButtonWrapper>
    </Wrapper>
  );
}

IndustryList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default IndustryList;
