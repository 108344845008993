import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { navigate, Link } from '@reach/router';

import AudioPlayer from '../components/AudioPlayer';
import Layout from '../components/Layout';
import Container from '../components/Container';
import TextContent from '../components/TextContent';
import Section from '../components/Section';
import FooterBar from '../components/FooterBar';
import ExternalActivityOverlay from '../components/ExternalActivityOverlay';
import Notification from '../components/Notification';
import ContentBlock from '../components/ContentBlock';

import { GET_ACTIVITY_DETAILS, UPDATE_ACTIVITY } from '../graph/activities';
import { GET_CMS_CONTENT } from '../graph/cms';

import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';
import extractGraphQLError from '../utils/extractGraphQLError';
import sleep from '../utils/sleep';
import useTrackActivityGA from '../utils/useTrackActivityGA';

import useSiteSettings from '../hooks/useSiteSettings';

import { activities, ACTIVITY_STATUS } from '../config/levels';

function ActivityInstructions({ activityId }) {
  const [showOverlay, setShowOverlay] = useState(false);

  const { siteName } = useSiteSettings();

  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data, loading } = useQuery(GET_ACTIVITY_DETAILS, {
    variables: { id: activityId },
  });

  const [handleUpdateActivity, { error, loading: updateLoading }] =
    useMutation(UPDATE_ACTIVITY);

  const activityType = data?.activity?.type;

  useTrackActivityGA(data?.activity?.type, 'instructions');

  const { level } = activities[activityType] || {};

  const {
    title,
    sub_title: subTitle,
    instructions,
    audio_instructions: audioInstructions,
    help_text: helpText,
    body: blocks,
    externalurl,
  } = cmsData?.cmsContent?.activity?.find(
    (activity) =>
      activity.id ===
      (cmsData?.user?.currentLevel === 4 &&
      data?.activity?.status === ACTIVITY_STATUS.COMPLETED
        ? `ongoing_${activityType}`
        : activityType),
  ) ||
  cmsData?.cmsContent?.activity?.find(
    (activity) => activity.id === activityType,
  ) ||
  {};

  const externalurlLink = externalurl?.url;

  const handleExternalActivity = () => {
    window.open(
      externalurlLink,
      `${siteName} - External Activity`,
      'menubar=no,location=yes,resizable=yes,scrollbars=no,status=no,width=1024,height=780',
    );

    setShowOverlay(true);
  };

  const handleCancelClick = () => {
    setShowOverlay(false);
  };

  const handleContinueClick = async () => {
    await handleUpdateActivity({
      variables: { id: activityId, status: ACTIVITY_STATUS.PENDING },
    });

    setShowOverlay(false);

    await sleep(500);

    navigate(`/activities/${activityId}/completed`);
  };

  return (
    <Layout
      title={`${extractPrismicString(title)}`}
      loading={loading}
      hasFooterBar
    >
      <Container narrow>
        <Section noPaddingBottom={blocks?.length}>
          {!!error && (
            <Notification type="red">{extractGraphQLError(error)}</Notification>
          )}

          <TextContent marginBottom>
            {extractPrismicContent(instructions)}
          </TextContent>

          {activityType === 'interests' && (
            <TextContent marginBottom>
              If you already clearly know your top three career interests and
              want to skip this exercise, write down your top 3 interests{' '}
              <Link to={`/activities/${activityId}/hashtags`}>here</Link>.
            </TextContent>
          )}

          {!!(audioInstructions?.url || hasContent(helpText)) && (
            <Notification showUser>
              {extractPrismicContent(helpText)}

              {!!audioInstructions?.url && (
                <AudioPlayer fileUrl={audioInstructions?.url} />
              )}
            </Notification>
          )}
        </Section>
      </Container>

      {!!blocks && blocks.map((block) => <ContentBlock {...block} />)}

      <FooterBar
        title={extractPrismicString(title)}
        subTitle={extractPrismicString(subTitle)}
        primaryButton={{
          ...(externalurlLink
            ? {
                label: 'Next: Start External Activity',
                onClick: handleExternalActivity,
              }
            : {
                label: 'Next: Start Activity',
                to: `/activities/${activityId}/${activityType}`,
              }),
          level,
        }}
        secondaryButton={{
          label: 'Back to introduction',
          to: `/activities/${activityId}/introduction`,
        }}
      />

      {externalurlLink && (
        <ExternalActivityOverlay
          open={showOverlay}
          title={extractPrismicString(title)}
          externalurl={externalurlLink}
          onCancelClick={handleCancelClick}
          onContinueClick={handleContinueClick}
          loading={updateLoading}
        />
      )}
    </Layout>
  );
}

ActivityInstructions.propTypes = {
  activityId: PropTypes.string,
};

ActivityInstructions.defaultProps = {
  activityId: null,
};

export default ActivityInstructions;
