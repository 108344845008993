export const countryList = [
  { id: 'Afganistan', value: 'Afghanistan' },
  { id: 'Albania', value: 'Albania' },
  { id: 'Algeria', value: 'Algeria' },
  { id: 'American Samoa', value: 'American Samoa' },
  { id: 'Andorra', value: 'Andorra' },
  { id: 'Angola', value: 'Angola' },
  { id: 'Anguilla', value: 'Anguilla' },
  { id: 'Antigua & Barbuda', value: 'Antigua & Barbuda' },
  { id: 'Argentina', value: 'Argentina' },
  { id: 'Armenia', value: 'Armenia' },
  { id: 'Aruba', value: 'Aruba' },
  { id: 'Australia', value: 'Australia' },
  { id: 'Austria', value: 'Austria' },
  { id: 'Azerbaijan', value: 'Azerbaijan' },
  { id: 'Bahamas', value: 'Bahamas' },
  { id: 'Bahrain', value: 'Bahrain' },
  { id: 'Bangladesh', value: 'Bangladesh' },
  { id: 'Barbados', value: 'Barbados' },
  { id: 'Belarus', value: 'Belarus' },
  { id: 'Belgium', value: 'Belgium' },
  { id: 'Belize', value: 'Belize' },
  { id: 'Benin', value: 'Benin' },
  { id: 'Bermuda', value: 'Bermuda' },
  { id: 'Bhutan', value: 'Bhutan' },
  { id: 'Bolivia', value: 'Bolivia' },
  { id: 'Bonaire', value: 'Bonaire' },
  { id: 'Bosnia & Herzegovina', value: 'Bosnia & Herzegovina' },
  { id: 'Botswana', value: 'Botswana' },
  { id: 'Brazil', value: 'Brazil' },
  { id: 'British Indian Ocean Ter', value: 'British Indian Ocean Ter' },
  { id: 'Brunei', value: 'Brunei' },
  { id: 'Bulgaria', value: 'Bulgaria' },
  { id: 'Burkina Faso', value: 'Burkina Faso' },
  { id: 'Burundi', value: 'Burundi' },
  { id: 'Cambodia', value: 'Cambodia' },
  { id: 'Cameroon', value: 'Cameroon' },
  { id: 'Canada', value: 'Canada' },
  { id: 'Canary Islands', value: 'Canary Islands' },
  { id: 'Cape Verde', value: 'Cape Verde' },
  { id: 'Cayman Islands', value: 'Cayman Islands' },
  { id: 'Central African Republic', value: 'Central African Republic' },
  { id: 'Chad', value: 'Chad' },
  { id: 'Channel Islands', value: 'Channel Islands' },
  { id: 'Chile', value: 'Chile' },
  { id: 'China', value: 'China' },
  { id: 'Christmas Island', value: 'Christmas Island' },
  { id: 'Cocos Island', value: 'Cocos Island' },
  { id: 'Colombia', value: 'Colombia' },
  { id: 'Comoros', value: 'Comoros' },
  { id: 'Congo', value: 'Congo' },
  { id: 'Cook Islands', value: 'Cook Islands' },
  { id: 'Costa Rica', value: 'Costa Rica' },
  { id: 'Cote DIvoire', value: 'Cote DIvoire' },
  { id: 'Croatia', value: 'Croatia' },
  { id: 'Cuba', value: 'Cuba' },
  { id: 'Curaco', value: 'Curacao' },
  { id: 'Cyprus', value: 'Cyprus' },
  { id: 'Czech Republic', value: 'Czech Republic' },
  { id: 'Denmark', value: 'Denmark' },
  { id: 'Djibouti', value: 'Djibouti' },
  { id: 'Dominica', value: 'Dominica' },
  { id: 'Dominican Republic', value: 'Dominican Republic' },
  { id: 'East Timor', value: 'East Timor' },
  { id: 'Ecuador', value: 'Ecuador' },
  { id: 'Egypt', value: 'Egypt' },
  { id: 'El Salvador', value: 'El Salvador' },
  { id: 'Equatorial Guinea', value: 'Equatorial Guinea' },
  { id: 'Eritrea', value: 'Eritrea' },
  { id: 'Estonia', value: 'Estonia' },
  { id: 'Ethiopia', value: 'Ethiopia' },
  { id: 'Falkland Islands', value: 'Falkland Islands' },
  { id: 'Faroe Islands', value: 'Faroe Islands' },
  { id: 'Fiji', value: 'Fiji' },
  { id: 'Finland', value: 'Finland' },
  { id: 'France', value: 'France' },
  { id: 'French Guiana', value: 'French Guiana' },
  { id: 'French Polynesia', value: 'French Polynesia' },
  { id: 'French Southern Ter', value: 'French Southern Ter' },
  { id: 'Gabon', value: 'Gabon' },
  { id: 'Gambia', value: 'Gambia' },
  { id: 'Georgia', value: 'Georgia' },
  { id: 'Germany', value: 'Germany' },
  { id: 'Ghana', value: 'Ghana' },
  { id: 'Gibraltar', value: 'Gibraltar' },
  { id: 'Great Britain', value: 'Great Britain' },
  { id: 'Greece', value: 'Greece' },
  { id: 'Greenland', value: 'Greenland' },
  { id: 'Grenada', value: 'Grenada' },
  { id: 'Guadeloupe', value: 'Guadeloupe' },
  { id: 'Guam', value: 'Guam' },
  { id: 'Guatemala', value: 'Guatemala' },
  { id: 'Guinea', value: 'Guinea' },
  { id: 'Guyana', value: 'Guyana' },
  { id: 'Haiti', value: 'Haiti' },
  { id: 'Hawaii', value: 'Hawaii' },
  { id: 'Honduras', value: 'Honduras' },
  { id: 'Hong Kong', value: 'Hong Kong' },
  { id: 'Hungary', value: 'Hungary' },
  { id: 'Iceland', value: 'Iceland' },
  { id: 'Indonesia', value: 'Indonesia' },
  { id: 'India', value: 'India' },
  { id: 'Iran', value: 'Iran' },
  { id: 'Iraq', value: 'Iraq' },
  { id: 'Ireland', value: 'Ireland' },
  { id: 'Isle of Man', value: 'Isle of Man' },
  { id: 'Israel', value: 'Israel' },
  { id: 'Italy', value: 'Italy' },
  { id: 'Jamaica', value: 'Jamaica' },
  { id: 'Japan', value: 'Japan' },
  { id: 'Jordan', value: 'Jordan' },
  { id: 'Kazakhstan', value: 'Kazakhstan' },
  { id: 'Kenya', value: 'Kenya' },
  { id: 'Kiribati', value: 'Kiribati' },
  { id: 'Korea North', value: 'Korea North' },
  { id: 'Korea Sout', value: 'Korea South' },
  { id: 'Kuwait', value: 'Kuwait' },
  { id: 'Kyrgyzstan', value: 'Kyrgyzstan' },
  { id: 'Laos', value: 'Laos' },
  { id: 'Latvia', value: 'Latvia' },
  { id: 'Lebanon', value: 'Lebanon' },
  { id: 'Lesotho', value: 'Lesotho' },
  { id: 'Liberia', value: 'Liberia' },
  { id: 'Libya', value: 'Libya' },
  { id: 'Liechtenstein', value: 'Liechtenstein' },
  { id: 'Lithuania', value: 'Lithuania' },
  { id: 'Luxembourg', value: 'Luxembourg' },
  { id: 'Macau', value: 'Macau' },
  { id: 'Macedonia', value: 'Macedonia' },
  { id: 'Madagascar', value: 'Madagascar' },
  { id: 'Malaysia', value: 'Malaysia' },
  { id: 'Malawi', value: 'Malawi' },
  { id: 'Maldives', value: 'Maldives' },
  { id: 'Mali', value: 'Mali' },
  { id: 'Malta', value: 'Malta' },
  { id: 'Marshall Islands', value: 'Marshall Islands' },
  { id: 'Martinique', value: 'Martinique' },
  { id: 'Mauritania', value: 'Mauritania' },
  { id: 'Mauritius', value: 'Mauritius' },
  { id: 'Mayotte', value: 'Mayotte' },
  { id: 'Mexico', value: 'Mexico' },
  { id: 'Midway Islands', value: 'Midway Islands' },
  { id: 'Moldova', value: 'Moldova' },
  { id: 'Monaco', value: 'Monaco' },
  { id: 'Mongolia', value: 'Mongolia' },
  { id: 'Montserrat', value: 'Montserrat' },
  { id: 'Morocco', value: 'Morocco' },
  { id: 'Mozambique', value: 'Mozambique' },
  { id: 'Myanmar', value: 'Myanmar' },
  { id: 'Nambia', value: 'Nambia' },
  { id: 'Nauru', value: 'Nauru' },
  { id: 'Nepal', value: 'Nepal' },
  { id: 'Netherland Antilles', value: 'Netherland Antilles' },
  { id: 'Netherlands', value: 'Netherlands (Holland, Europe)' },
  { id: 'Nevis', value: 'Nevis' },
  { id: 'New Caledonia', value: 'New Caledonia' },
  { id: 'New Zealand', value: 'New Zealand' },
  { id: 'Nicaragua', value: 'Nicaragua' },
  { id: 'Niger', value: 'Niger' },
  { id: 'Nigeria', value: 'Nigeria' },
  { id: 'Niue', value: 'Niue' },
  { id: 'Norfolk Island', value: 'Norfolk Island' },
  { id: 'Norway', value: 'Norway' },
  { id: 'Oman', value: 'Oman' },
  { id: 'Pakistan', value: 'Pakistan' },
  { id: 'Palau Island', value: 'Palau Island' },
  { id: 'Palestine', value: 'Palestine' },
  { id: 'Panama', value: 'Panama' },
  { id: 'Papua New Guinea', value: 'Papua New Guinea' },
  { id: 'Paraguay', value: 'Paraguay' },
  { id: 'Peru', value: 'Peru' },
  { id: 'Phillipines', value: 'Philippines' },
  { id: 'Pitcairn Island', value: 'Pitcairn Island' },
  { id: 'Poland', value: 'Poland' },
  { id: 'Portugal', value: 'Portugal' },
  { id: 'Puerto Rico', value: 'Puerto Rico' },
  { id: 'Qatar', value: 'Qatar' },
  { id: 'Republic of Montenegro', value: 'Republic of Montenegro' },
  { id: 'Republic of Serbia', value: 'Republic of Serbia' },
  { id: 'Reunion', value: 'Reunion' },
  { id: 'Romania', value: 'Romania' },
  { id: 'Russia', value: 'Russia' },
  { id: 'Rwanda', value: 'Rwanda' },
  { id: 'St Barthelemy', value: 'St Barthelemy' },
  { id: 'St Eustatius', value: 'St Eustatius' },
  { id: 'St Helena', value: 'St Helena' },
  { id: 'St Kitts-Nevis', value: 'St Kitts-Nevis' },
  { id: 'St Lucia', value: 'St Lucia' },
  { id: 'St Maarten', value: 'St Maarten' },
  { id: 'St Pierre & Miquelon', value: 'St Pierre & Miquelon' },
  { id: 'St Vincent & Grenadines', value: 'St Vincent & Grenadines' },
  { id: 'Saipan', value: 'Saipan' },
  { id: 'Samoa', value: 'Samoa' },
  { id: 'Samoa American', value: 'Samoa American' },
  { id: 'San Marino', value: 'San Marino' },
  { id: 'Sao Tome & Principe', value: 'Sao Tome & Principe' },
  { id: 'Saudi Arabia', value: 'Saudi Arabia' },
  { id: 'Senegal', value: 'Senegal' },
  { id: 'Seychelles', value: 'Seychelles' },
  { id: 'Sierra Leone', value: 'Sierra Leone' },
  { id: 'Singapore', value: 'Singapore' },
  { id: 'Slovakia', value: 'Slovakia' },
  { id: 'Slovenia', value: 'Slovenia' },
  { id: 'Solomon Islands', value: 'Solomon Islands' },
  { id: 'Somalia', value: 'Somalia' },
  { id: 'South Africa', value: 'South Africa' },
  { id: 'Spain', value: 'Spain' },
  { id: 'Sri Lanka', value: 'Sri Lanka' },
  { id: 'Sudan', value: 'Sudan' },
  { id: 'Suriname', value: 'Suriname' },
  { id: 'Swaziland', value: 'Swaziland' },
  { id: 'Sweden', value: 'Sweden' },
  { id: 'Switzerland', value: 'Switzerland' },
  { id: 'Syria', value: 'Syria' },
  { id: 'Tahiti', value: 'Tahiti' },
  { id: 'Taiwan', value: 'Taiwan' },
  { id: 'Tajikistan', value: 'Tajikistan' },
  { id: 'Tanzania', value: 'Tanzania' },
  { id: 'Thailand', value: 'Thailand' },
  { id: 'Togo', value: 'Togo' },
  { id: 'Tokelau', value: 'Tokelau' },
  { id: 'Tonga', value: 'Tonga' },
  { id: 'Trinidad & Tobago', value: 'Trinidad & Tobago' },
  { id: 'Tunisia', value: 'Tunisia' },
  { id: 'Turkey', value: 'Turkey' },
  { id: 'Turkmenistan', value: 'Turkmenistan' },
  { id: 'Turks & Caicos Is', value: 'Turks & Caicos Is' },
  { id: 'Tuvalu', value: 'Tuvalu' },
  { id: 'Uganda', value: 'Uganda' },
  { id: 'United Kingdom', value: 'United Kingdom' },
  { id: 'Ukraine', value: 'Ukraine' },
  { id: 'United Arab Erimates', value: 'United Arab Emirates' },
  { id: 'United States of America', value: 'United States of America' },
  { id: 'Uraguay', value: 'Uruguay' },
  { id: 'Uzbekistan', value: 'Uzbekistan' },
  { id: 'Vanuatu', value: 'Vanuatu' },
  { id: 'Vatican City State', value: 'Vatican City State' },
  { id: 'Venezuela', value: 'Venezuela' },
  { id: 'Vietnam', value: 'Vietnam' },
  { id: 'Virgin Islands (Brit)', value: 'Virgin Islands (Brit)' },
  { id: 'Virgin Islands (USA)', value: 'Virgin Islands (USA)' },
  { id: 'Wake Island', value: 'Wake Island' },
  { id: 'Wallis & Futana Is', value: 'Wallis & Futana Is' },
  { id: 'Yemen', value: 'Yemen' },
  { id: 'Zaire', value: 'Zaire' },
  { id: 'Zambia', value: 'Zambia' },
  { id: 'Zimbabwe', value: 'Zimbabwe' },
];
