import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useApolloClient } from '@apollo/react-hooks';
import queryString from 'query-string';
import { navigate } from '@reach/router';

import Loader from '../components/Loader';
import Button from '../components/Button';
import Card from '../components/Card';
import Notification from '../components/Notification';
import Form from '../components/Form';
import LayoutAuth from '../components/LayoutAuth';
import Section from '../components/Section';

import { UPDATE_USER, UPDATE_USER_LINKEDIN, GET_USER } from '../graph/auth';
import { GET_CMS_CONTENT } from '../graph/cms';

import { validate, requiredString } from '../utils/validate';
import { countryList } from '../config/fields';

const profileFormFields = [
  {
    id: 'email',
    type: 'email',
    name: 'email',
    label: 'Email Address',
    readOnly: true,
  },
  {
    id: 'firstName',
    type: 'text',
    name: 'firstName',
    label: 'First name',
  },
  {
    id: 'lastName',
    type: 'text',
    name: 'lastName',
    label: 'Last name',
  },

  {
    id: 'country',
    type: 'select',
    name: 'country',
    label: 'Country',
    defaultLabel: 'Select country',
    options: countryList,
  },
  {
    id: 'city',
    type: 'text',
    name: 'city',
    label: 'City / Town',
  },
  {
    id: 'dateOfBirth',
    type: 'date',
    name: 'dateOfBirth',
    label: 'Date of Birth',
  },
  {
    id: 'profession',
    type: 'select',
    name: 'profession',
    label: 'Current Status',
    defaultLabel: 'Select status',
    options: [
      { id: 'Student', value: 'Student' },
      { id: 'Employed', value: 'Employed' },
      { id: 'Currently Looking', value: 'Currently Looking' },
      { id: 'Self-Employed', value: 'Self-Employed' },
      { id: 'Other', value: 'Other' },
    ],
  },
];

const profileFormValidationSchema = validate({
  name: requiredString,
});

const redirectUri = `${process.env.REACT_APP_URL}onboarding/profile`;

function OnboardingProfile() {
  const client = useApolloClient();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const [handleConnectLinkedIn, { loading: loadingLinkedIn }] = useMutation(
    UPDATE_USER_LINKEDIN,
  );

  const [
    handleProfileUpdate,
    { loading, error: errorUpdateUser, called },
  ] = useMutation(UPDATE_USER, {
    onCompleted: async () => {
      await client.query({
        query: GET_USER,
        fetchPolicy: 'network-only',
      });

      navigate('/');
    },
  });

  const { code, error: urlError } =
    queryString.parse(window.location.search) || {};

  useEffect(() => {
    const linkedInConnect = async () => {
      const response = await handleConnectLinkedIn({
        variables: { code, id: cmsData?.user?.id, redirectUri },
      });

      if (response.errors) {
        setError(
          'There has been a problem connecting your LinkedIn Account, please try again.',
        );

        return;
      }

      if (response.data.updateUserLinkedIn) {
        setSuccess('Your LinkedIn account has been connected successfully');
      }
    };

    if (urlError) {
      setError(
        'There has been a problem connecting your LinkedIn Account, please try again.',
      );

      return;
    }

    if (!code) return;

    linkedInConnect();
  }, []); //eslint-disable-line

  const showConnect = !loadingLinkedIn && !success;

  return (
    <LayoutAuth title="Complete Profile">
      <Card header="Complete your profile">
        {showConnect && (
          <Notification showUser>
            <p>
              Why not connect your LinkedIn account to automatically pull in
              your profile picture.
            </p>
            <Button
              href={`https://www.linkedin.com/oauth/v2/authorization?client_id=${
                process.env.REACT_APP_LINKEDIN_CLIENT_ID
              }&redirect_uri=${redirectUri}&response_type=code&scope=r_liteprofile`}
            >
              Connect your account
            </Button>
          </Notification>
        )}

        {error && <Notification type="red">{error}</Notification>}
        {success && <Notification type="green">{success}</Notification>}

        {loadingLinkedIn ? (
          <Section>
            <Loader />
          </Section>
        ) : (
          <>
            <Form
              fields={profileFormFields}
              onSubmit={handleProfileUpdate}
              initialValues={cmsData?.user}
              validationSchema={profileFormValidationSchema}
              loading={loading}
              error={!!errorUpdateUser}
              errorMessage="There has been a problem updating your profile"
              success={!!(!loading && !errorUpdateUser && called)}
              successMessage="Profile updated successfully"
              submitButtonText="Update and Continue"
              container
              fullWidthButton
            />

            <Button marginTop fullWidth secondary to="/">
              Skip this step
            </Button>
          </>
        )}
      </Card>
    </LayoutAuth>
  );
}

export default OnboardingProfile;
