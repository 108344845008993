/**
 * @format
 * @flow
 */

import gql from 'graphql-tag';

const GET_ORDERS = gql`
  query Orders {
    orders {
      id
      orderDate
      status
      marketplaceProduct
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

const CREATE_ORDER = gql`
  mutation CreateOrder($marketplaceProduct: String!) {
    createOrder(marketplaceProduct: $marketplaceProduct) {
      id
    }
  }
`;

const UPDATE_ORDER = gql`
  mutation UpdateOrder($status: String) {
    updateStripePayment(status: $status) {
      id
      orderDate
      status
      marketplaceProduct
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export {
  GET_ORDERS,
  CREATE_ORDER,
  UPDATE_ORDER,
};
