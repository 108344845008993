import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import EmojiButton from '../components/EmojiButton';
import Layout from '../components/Layout';
import Container from '../components/Container';
import TextContent from '../components/TextContent';
import Section from '../components/Section';
import FooterBar from '../components/FooterBar';
import Notification from '../components/Notification';

import { GET_ACTIVITY_DETAILS, UPDATE_ACTIVITY } from '../graph/activities';
import { GET_CMS_CONTENT } from '../graph/cms';

import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';
import extractGraphQLError from '../utils/extractGraphQLError';
import { validate, requiredString } from '../utils/validate';
import useTrackActivityGA from '../utils/useTrackActivityGA';

import { activities, ACTIVITY_STATUS } from '../config/levels';
import Form from '../components/Form';

function ActivityCompleted({ activityId }) {
  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data, loading } = useQuery(GET_ACTIVITY_DETAILS, {
    variables: { id: activityId },
  });

  const [handleUpdateActivity, { error, loading: updateLoading }] =
    useMutation(UPDATE_ACTIVITY);

  const handleSubmitFeedback = ({ variables }) => {
    handleUpdateActivity({
      variables: { id: activityId, ...variables },
    });
  };

  const { type: activityType, rating, ratingText } = data?.activity || {};
  const { level, goToActivitiesAfterComplete } = activities[activityType] || {};

  useTrackActivityGA(activityType, 'completed');

  const {
    title,
    sub_title: subTitle,
    activity_complete_message: activityCompleteMessage,
    quote_3: quote3,
    quote_3_citation: quote3Cite,
  } = cmsData?.cmsContent?.activity?.find(
    (activity) =>
      activity.id ===
      (cmsData?.user?.currentLevel === 4 &&
      data?.activity?.status === ACTIVITY_STATUS.COMPLETED
        ? `ongoing_${activityType}`
        : activityType),
  ) ||
  cmsData?.cmsContent?.activity?.find(
    (activity) => activity.id === activityType,
  ) ||
  {};

  const handleRateActivity = (newRating) => {
    handleUpdateActivity({
      variables: { id: activityId, rating: newRating },
    });
  };

  return (
    <Layout
      title={`${extractPrismicString(title)}`}
      loading={loading}
      hasFooterBar
    >
      <Container narrow>
        <Section>
          <TextContent leadParagraph>
            {hasContent(quote3) && (
              <Notification type="green">
                <blockquote>{extractPrismicString(quote3)}</blockquote>
                {hasContent(quote3Cite) && (
                  <cite> {extractPrismicString(quote3Cite)}</cite>
                )}
              </Notification>
            )}

            {extractPrismicContent(activityCompleteMessage)}
          </TextContent>

          {!!error && (
            <Notification type="red">{extractGraphQLError(error)}</Notification>
          )}

          <Notification center marginTopMobile>
            <p>
              {rating
                ? 'Thank you! Your feedback is valued.'
                : 'Let us know what you thought and rate this activity.'}
            </p>
            <EmojiButton
              emoji
              disabled={(rating && rating !== 1) || updateLoading}
              active={rating === 1}
              onClick={() => handleRateActivity(1)}
            >
              <span role="img" aria-label="1">
                🙁
              </span>
            </EmojiButton>

            <EmojiButton
              emoji
              disabled={(rating && rating !== 2) || updateLoading}
              active={rating === 2}
              onClick={() => handleRateActivity(2)}
            >
              <span role="img" aria-label="2">
                😐
              </span>
            </EmojiButton>

            <EmojiButton
              emoji
              disabled={(rating && rating !== 3) || updateLoading}
              active={rating === 3}
              onClick={() => handleRateActivity(3)}
            >
              <span role="img" aria-label="3">
                😴
              </span>
            </EmojiButton>

            <EmojiButton
              emoji
              disabled={(rating && rating !== 4) || updateLoading}
              active={rating === 4}
              onClick={() => handleRateActivity(4)}
            >
              <span role="img" aria-label="4">
                🙂
              </span>
            </EmojiButton>

            <EmojiButton
              emoji
              disabled={(rating && rating !== 5) || updateLoading}
              active={rating === 5}
              onClick={() => handleRateActivity(5)}
            >
              <span role="img" aria-label="5">
                😍
              </span>
            </EmojiButton>
          </Notification>

          {!!(rating && !ratingText) && (
            <Notification>
              <Form
                fields={[
                  {
                    id: 'ratingText',
                    name: 'ratingText',
                    type: 'textarea',
                    label:
                      "We'd love to hear a bit more about what you thought of the Activity.",
                    placeholder:
                      "E.g. what you liked, what you didn't like and how it could be improved",
                  },
                ]}
                validationSchema={validate({
                  ratingText: requiredString,
                })}
                initialValues={{
                  ratingText,
                }}
                submitButtonText="Submit feedback"
                onSubmit={handleSubmitFeedback}
                loading={updateLoading}
              />
            </Notification>
          )}
        </Section>
      </Container>

      <FooterBar
        title={extractPrismicString(title)}
        subTitle={extractPrismicString(subTitle)}
        primaryButton={
          goToActivitiesAfterComplete
            ? {
                label: 'View Results',
                to:
                  data?.activity?.status === ACTIVITY_STATUS.COMPLETED
                    ? `/results/${data?.activity?.result?.type}/${data?.activity?.result?.id}`
                    : '/results',
                level,
              }
            : {
                label: 'Back to activities',
                to: '/activities',
                level,
              }
        }
      />
    </Layout>
  );
}

ActivityCompleted.propTypes = {
  activityId: PropTypes.string,
};

ActivityCompleted.defaultProps = {
  activityId: null,
};

export default ActivityCompleted;
