import * as yup from 'yup';

const validate = schema => yup.object().shape(schema);

const requiredString = yup
  .string('This field is required.')
  .required('This field is required.')
  .nullable();

const requiredEmail = yup
  .string('This field is required.')
  .required('This field is required.')
  .email('Please enter a valid email address.')
  .nullable();

const requiredPassword = yup
  .string('This field is required.')
  .required('This field is required.')
  .min(8, 'Password must be at least 8 characters long.')
  .nullable();

const requiredTerms = yup
  .bool()
  .oneOf([true], 'You must agree to create an account.');

const requiredNumber = yup
  .number('This field is required.')
  .required('This field is required.')
  .nullable();

export {
  validate,
  requiredString,
  requiredNumber,
  requiredEmail,
  requiredPassword,
  requiredTerms,
};
