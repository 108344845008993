import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import startCase from 'lodash.startcase';

import Button from './Button';

const Wrapper = styled.div`
  width: 100%;
  margin: auto;
`;

const Bar = styled.div`
  background-color: ${props => props.theme.colors.grey200};
  padding: ${props => props.theme.spacing.xSmall}
    ${props => props.theme.spacing.base};
  width: 100%;
  margin: ${props => props.theme.spacing.small} 0;
  font-size: ${props => props.theme.fontSizes.xSmall};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  strong {
    color: ${props => props.theme.colors.green500};
  }
`;

function SimpleBarList({
  data,
  emptyDataMessage,
  emptyDataLink,
  emptyDataLinkText,
  ...props
}) {
  if ((!data || !data.length) && emptyDataMessage) {
    return (
      <Wrapper>
        <p>{emptyDataMessage}</p>
        <Button small to={emptyDataLink}>
          {emptyDataLinkText}
        </Button>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {data?.map((item, i) => {
        return (
          <Bar key={item.id || item.value || item.label} {...props}>
            <strong>{i + 1}.</strong> {item.title || startCase(item.type)}
          </Bar>
        );
      })}
    </Wrapper>
  );
}

SimpleBarList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  emptyDataMessage: PropTypes.string,
  emptyDataLink: PropTypes.string,
  emptyDataLinkText: PropTypes.string,
};

SimpleBarList.defaultProps = {
  emptyDataMessage: null,
  emptyDataLink: null,
  emptyDataLinkText: null,
};

export default SimpleBarList;
