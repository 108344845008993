import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import getColorFromLevel from '../utils/getColorFromLevel';

import levels from '../config/levels';
import Container from './Container';

const Wrapper = styled.section`
    position: relative;
    z-index: 1;
    height: 25px;
    background-color: rgba(0,0,0,0.2);

    &:before {
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      content: "";
      width: ${props => props.width}%;
      transition: width ${props =>
        props.theme.base.transitionSpeedSlow} ${props =>
  props.theme.easing.enter};
      background-color: ${getColorFromLevel};
    }
  }
`;

const Inner = styled.div`
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  strong {
    color: ${props => props.theme.colors.white};
  }
`;

const LevelText = styled.span`
  position: relative;
  z-index: 1;
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSizes.xSmall};
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1;

  strong {
    color: ${props => props.theme.colors.white};
  }
`;


const HideOnMobile = styled.span`
  display: none;

  ${props => props.theme.breakpoints.small`
    display: inline;
  `}
`;

function ProgressBar({ level, activitiesComplete, levelTitle }) {
  const totalActivities = levels[level]?.totalActivities;
  return (
    <Wrapper
      level={level}
      width={
        totalActivities ? (activitiesComplete / totalActivities) * 100 : '100'
      }
    >
      <Container>
        <Inner>
          <LevelText>
            <strong>
              Level
              {' '}
              {level}
            </strong>
            <HideOnMobile>
              {' '}
              /
              {levelTitle}
            </HideOnMobile>
          </LevelText>

          {totalActivities && (
            <LevelText>
              <strong>
                {activitiesComplete}
                /
                {totalActivities}
              </strong>
              {' '}
              <HideOnMobile>Activities</HideOnMobile>
              {' '}
              Completed
            </LevelText>
          )}
        </Inner>
      </Container>
    </Wrapper>
  );
}

ProgressBar.propTypes = {
  level: PropTypes.number.isRequired,
  activitiesComplete: PropTypes.number.isRequired,
  levelTitle: PropTypes.string.isRequired,
};

export default ProgressBar;
