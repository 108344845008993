const CURRENCIES = {
  GBP: {
    id: 'GBP',
    label: 'GBP',
    symbol: '£',
    amount: 'amount',
    discountedAmount: 'discountedAmount',
  },
  USD: {
    id: 'USD',
    label: 'USD',
    symbol: '$',
    amount: 'amount_usd',
    discountedAmount: 'discountedAmount_usd',
  },
};

export default CURRENCIES;
