/**
 * Utility function to format currency
 *
 * @param {number} value - Value as a floating point number
 * @return {string} Formatted curreny value
 *
 */

const formatCurrency = (value, currency, noDecimals) => {
  const formatter = new Intl.NumberFormat('en-UK', {
    style: 'currency',
    currency,
    minimumFractionDigits: noDecimals ? 0 : 2,
    maximumFractionDigits: noDecimals ? 0 : 2,
  });

  return formatter.format(value ? value / 100 : 0);
};

export default formatCurrency;
