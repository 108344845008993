import React from 'react';
import PropTypes from 'prop-types';

import TextField from './TextField';
import TextAreaField from './TextAreaField';
import CheckboxField from './CheckboxField';
import SelectField from './SelectField';
import FileField from './FileField';
import TagField from './TagField';

function FormField({ type, ...props }) {
  if (!type) return false;

  if (type === 'text' || type === 'password') {
    return <TextField type={type} {...props} />;
  }

  if (type === 'textarea') {
    return <TextAreaField {...props} />;
  }

  if (type === 'checkbox') {
    return <CheckboxField {...props} />;
  }

  if (type === 'select') {
    return <SelectField {...props} />;
  }

  if (type === 'tag') {
    return <TagField {...props} />;
  }

  if (type === 'file') {
    return <FileField {...props} />;
  }

  return <TextField type={type} {...props} />;
}

FormField.propTypes = {
  type: PropTypes.string.isRequired,
};

export default FormField;
