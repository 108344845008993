import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from './Button';
import Tag from './Tag';

import cardBase from '../theme/cardBase';

import { ReactComponent as ChevronIcon } from '../assets/chevron-right-icon.svg';

const Wrapper = styled.div`
  ${cardBase}
  overflow: hidden;
  padding: ${(props) => props.theme.spacing.base};

  ${(props) => props.theme.breakpoints.medium`
    display: flex;
    flex-wrap: wrap;
    padding: ${props.theme.spacing.midLarge};
  `}

  ${(props) =>
    props.flex &&
    props.theme.breakpoints.medium`
    margin: 0;
    display: block;
  `}

  ${(props) =>
    props.noMargin &&
    props.theme.breakpoints.medium`
    margin: 0;
    flex-direction: column;
  `}

${(props) =>
    props.marginTop &&
    `
   margin-top ${props.theme.spacing.midLarge};
  `}

${(props) =>
    props.overflow &&
    `
    overflow: visible;
  `}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.spacing.base};
  margin: -${(props) => props.theme.spacing.base} -${(props) =>
      props.theme.spacing.base}
    ${(props) => props.theme.spacing.base} -${(props) => props.theme.spacing.base};
  border-bottom: solid 1px ${(props) => props.theme.colors.grey200};
  flex: 1 0 auto;
  width: calc(
    100% + ${(props) => props.theme.spacing.base} +
      ${(props) => props.theme.spacing.base}
  );

  ${(props) => props.theme.breakpoints.medium`
    padding: ${props.theme.spacing.medium}
    ${props.theme.spacing.midLarge}
    ${props.theme.spacing.base};
   margin: -${props.theme.spacing.midLarge} -${props.theme.spacing.midLarge} ${props.theme.spacing.midLarge} -${props.theme.spacing.midLarge}
    width: calc(
      100% + ${props.theme.spacing.midLarge} + ${props.theme.spacing.midLarge});
  `}

  ${(props) =>
    props.isDisabled &&
    `
      opacity: 0.3;
      pointer-events: none;
  `}

  ${(props) =>
    props.noMargin &&
    `
    flex: 0 0 auto;
  `}

  ${(props) =>
    props.fixedHeightHeader &&
    props.theme.breakpoints.medium`
      height: 65px;
  `}

  h4 {
    display: flex;
    align-items: center;
    font-size: ${(props) => props.theme.fontSizes.medium};
    line-height: 1;
    margin-bottom: ${(props) => props.theme.spacing.xxSmall};

    &:last-child {
      margin-bottom: 0;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

const Action = styled.div`
  margin-left: auto;

  a {
    margin-left: ${(props) => props.theme.spacing.small};
  }
`;

const SideContent = styled.aside`
  background-color: ${(props) => props.theme.colors.grey100};

  margin: -${(props) => props.theme.spacing.midLarge} -${(props) =>
      props.theme.spacing.midLarge}
    ${(props) => props.theme.spacing.midLarge} -${(props) => props.theme.spacing.midLarge};
  padding: ${(props) => props.theme.spacing.midLarge};

  ${(props) => props.theme.breakpoints.medium`
    flex: 0 0 50%;
    order: -1;
      margin: -${props.theme.spacing.midLarge} ${props.theme.spacing.midLarge} -${props.theme.spacing.midLarge} -${props.theme.spacing.midLarge};
    padding: ${props.theme.spacing.midLarge};
  `}
`;

function Card({
  header,
  subheader,
  children,
  renderSideContent,
  to,
  isDisabled,
  flex,
  new: isNew,
  ...props
}) {
  return (
    <Wrapper flex={flex} {...props}>
      {!!header && (
        <Header isDisabled={isDisabled} {...props}>
          <div>
            <h4>
              {isNew && <Tag marginRight>New</Tag>} {header}
            </h4>
            {!!subheader && <p>{subheader}</p>}
          </div>

          {!!to && (
            <Action>
              <Button round to={to}>
                <ChevronIcon />
              </Button>
            </Action>
          )}
        </Header>
      )}
      {!!renderSideContent && <SideContent>{renderSideContent()}</SideContent>}
      {children}
    </Wrapper>
  );
}

Card.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  children: PropTypes.node.isRequired,
  renderSideContent: PropTypes.func,
  to: PropTypes.string,
  isDisabled: PropTypes.bool,
  flex: PropTypes.bool,
  new: PropTypes.bool,
};

Card.defaultProps = {
  header: null,
  subheader: null,
  renderSideContent: null,
  to: null,
  isDisabled: false,
  flex: false,
  new: false,
};

export default Card;
