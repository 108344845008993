import theme from './main';

const cardBase = `
  margin: auto;
  background-color: ${theme.colors.white};
  border-radius: ${theme.base.radius};
  padding: ${theme.spacing.midLarge};
  border: solid 1px ${theme.colors.grey100};
  box-shadow: ${theme.base.shadow};
  width: 100%;

`;

export default cardBase;
