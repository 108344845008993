const getAbilityResult = (content, data) => {
  if (data >= 8) {
    return content?.above_average_text;
  }

  if (data >= 4) {
    return content?.average_text;
  }

  return content?.below_average_text;
};

export default getAbilityResult;
