import gql from 'graphql-tag';

const GET_SITE_SETTINGS = gql`
  query SiteSettings {
    siteSettings {
      logoURL
      favIconURL
      logoIconURL
      siteName
      privacyURL
      siteMetaDescription
      siteURL
      termsAndConditionURL
      disclaimerURL
      linkedInURL
      facebookURL
      instagramURL
      profileBgImage
      bgImage
      bannerBgImage
      supportEmail
      resourcesLink
    }
  }
`;
export { GET_SITE_SETTINGS };
