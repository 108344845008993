import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { useMutation } from '@apollo/react-hooks';

import Button from './Button';
import CouponForm from './CouponForm';
import Notification from './Notification';
import TextContent from './TextContent';
import CurrencySelector from './CurrencySelector';

import { CREATE_SUBSCRIPTION } from '../graph/payment';

import fieldBase from '../theme/fieldBase';

import { ReactComponent as LockIcon } from '../assets/lock-icon.svg';

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;

  ${(props) => props.theme.breakpoints.medium`
    flex: 0 0 50%;
  `}
`;

const StyledLockIcon = styled(LockIcon)`
  display: block;
  margin: 0 auto ${(props) => props.theme.spacing.midLarge};
  height: 50px;
  width: 50px;
  fill: ${(props) => props.theme.colors.green500};
`;

const Currency = styled.div`
  ${(props) => props.theme.breakpoints.medium`
    position: absolute;
    top: 0;
    right: 0;
  `}
`;

const FormElement = styled.div`
  ${fieldBase}
  display: flex;
  height: 50px;
  align-items: center;

  div {
    width: 100%;
  }
`;

const ElementWrapper = styled.div`
  margin: ${(props) => props.theme.spacing.base} 0;
`;

const FormInner = styled.div`
  margin: 0 0 ${(props) => props.theme.spacing.midLarge} 0;
`;

const TotalTable = styled.ul`
  background-color: ${(props) => props.theme.colors.grey100};
  margin-top: ${(props) => props.theme.spacing.midLarge};
  padding: ${(props) => props.theme.spacing.small};

  li {
    display: flex;
    font-size: ${(props) => props.theme.fontSizes.small};
    padding-bottom: ${(props) => props.theme.spacing.xxSmall};

    strong {
      margin-left: auto;
    }

    &:last-child {
      border-top: solid 1px ${(props) => props.theme.colors.grey300};
      margin-top: ${(props) => props.theme.spacing.xxSmall};
      padding-top: ${(props) => props.theme.spacing.xSmall};
      padding-bottom: 0;
    }

    &:first-child {
      border-top: none;
      margin-top: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`;

function SubscriptionForm({
  stripe,
  onSuccess,
  user,
  productId,
  price,
  discountedPrice,
  hasDiscount,
  isFree,
  freeText,
  currency,
  priceWithVAT,
  message,
  discountAmount,
  vatAmount,
  chargeVAT,
}) {
  const [loading, setLoading] = useState(false);
  const [isComplete, setComplete] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [handleCreateSubscription] = useMutation(CREATE_SUBSCRIPTION);

  const stripeElement = useRef(null);

  const handleFormError = (e) => {
    setError(
      e ||
        'There has been a problem processing your payment, please try again.',
    );
  };

  // Creates payment intent and gets client secret from the API
  const createPaymentMethod = async () => {
    try {
      const response = await stripe.createPaymentMethod({
        type: 'card',
        card: stripeElement?.current._element,
        billing_details: { name: user.name },
      });
      return response;
    } catch (e) {
      handleFormError(e);
      return null;
    }
  };

  // Creates stripe payment
  const createStripeSubscription = async (paymentMethodId) => {
    try {
      const response = await handleCreateSubscription({
        variables: {
          productId,
          paymentMethodId,
          currency,
        },
      });
      return response;
    } catch (e) {
      handleFormError(e);
      return null;
    }
  };

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();

    if (isFree) {
      setLoading(true);
      const response = await createStripeSubscription();

      if (response?.data?.createStripeSubscription?.status === 'succeeded') {
        onSuccess();
        setLoading(false);
        return;
      }
    }

    if (!isComplete) return;
    setLoading(true);

    // Get the client secret
    const stripeResponse = await createPaymentMethod();

    // Handle the unlikely event of a missing payment method ID
    if (!stripeResponse?.paymentMethod?.id) {
      setLoading(false);
      handleFormError();
      return;
    }

    // Make card payment
    const response = await createStripeSubscription(
      stripeResponse?.paymentMethod?.id,
    );

    if (response?.data?.createStripeSubscription?.status === 'succeeded') {
      onSuccess();
      setLoading(false);
      return;
    }

    if (
      response?.data?.createStripeSubscription?.status === 'requires_action'
    ) {
      const stripeConfirmResponse = await stripe.confirmCardPayment(
        response?.data?.createStripeSubscription.clientSecret,
      );

      if (stripeConfirmResponse?.paymentIntent?.status === 'succeeded') {
        onSuccess();
        setLoading(false);
        return;
      }
    }

    // Handle error response
    handleFormError(response?.errors?.[0]?.message);
    setLoading(false);
  };

  const handleFormChange = ({ complete }) => {
    setComplete(complete);
  };

  return (
    <FormWrapper>
      <Currency>
        <CurrencySelector />
      </Currency>

      <form onSubmit={handlePaymentSubmit}>
        <StyledLockIcon />

        <TextContent center>
          <h4>Your payment details</h4>

          <p>
            <small>
              Monthly Subscription of only
              {hasDiscount && (
                <strong>
                  <strike>{`${price}`}</strike>
                </strong>
              )}{' '}
              <strong>
                {`${discountedPrice}`}
                {chargeVAT ? ' + VAT' : ''}
              </strong>
              . {message}
            </small>
          </p>
        </TextContent>

        <TotalTable>
          {(hasDiscount || chargeVAT) && (
            <li>
              Sub total <strong>{price}</strong>
            </li>
          )}

          {hasDiscount && (
            <li>
              Discount <strong>-{discountAmount}</strong>
            </li>
          )}

          {chargeVAT && (
            <li>
              VAT <strong>{vatAmount}</strong>
            </li>
          )}

          <li>
            Total price <strong>{priceWithVAT}</strong>
          </li>
        </TotalTable>

        <FormInner>
          <ElementWrapper>
            {!isFree && (
              <FormElement>
                <CardElement ref={stripeElement} onChange={handleFormChange} />
              </FormElement>
            )}
          </ElementWrapper>

          <Button
            type="submit"
            loading={loading}
            disabled={!isFree && !isComplete}
            fullWidth
            green
          >
            {isFree ? freeText : `Subscribe now`}
          </Button>
        </FormInner>

        {errorMessage && <Notification type="red">{errorMessage}</Notification>}
      </form>

      <CouponForm />
    </FormWrapper>
  );
}

SubscriptionForm.propTypes = {
  stripe: PropTypes.shape({
    confirmCardPayment: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    name: PropTypes.string,
  }),
  onSuccess: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
  price: PropTypes.string,
  discountedPrice: PropTypes.string,
  hasDiscount: PropTypes.bool.isRequired,
  isFree: PropTypes.bool.isRequired,
  freeText: PropTypes.string,
  currency: PropTypes.string.isRequired,
  priceWithVAT: PropTypes.string,
  message: PropTypes.string,
  discountAmount: PropTypes.string,
  vatAmount: PropTypes.string,
  chargeVAT: PropTypes.bool,
};

SubscriptionForm.defaultProps = {
  user: { name: null },
  discountedPrice: null,
  price: null,
  freeText: 'Join for free',
  priceWithVAT: null,
  message: null,
  discountAmount: null,
  vatAmount: null,
  chargeVAT: false,
};

export default injectStripe(SubscriptionForm);
