import gql from 'graphql-tag';

const GET_ACTIONS = gql`
  query Actions($activityId: ID) {
    actions(activityId: $activityId) {
      id
      Goal {
        id
        title
      }
      priority
      status
      dueDate
      title
      description1
      description2
    }
  }
`;

const ADD_ACTION = gql`
  mutation AddAction(
    $activityId: ID!
    $goalId: ID!
    $priority: String
    $status: String
    $dueDate: String
    $title: String
    $description1: String
    $description2: String
  ) {
    addAction(
      activityId: $activityId
      goalId: $goalId
      priority: $priority
      status: $status
      dueDate: $dueDate
      title: $title
      description1: $description1
      description2: $description2
    ) {
      id
      Goal {
        id
        title
      }
      priority
      status
      dueDate
      title
      description1
      description2
    }
  }
`;

const UPDATE_ACTION = gql`
  mutation UpdateAction(
    $id: ID!
    $priority: String
    $status: String
    $dueDate: String
    $title: String
    $description1: String
    $description2: String
    $goalId: ID
  ) {
    updateAction(
      id: $id
      priority: $priority
      status: $status
      dueDate: $dueDate
      title: $title
      description1: $description1
      description2: $description2
      goalId: $goalId
    ) {
      id
      Goal {
        id
        title
      }
      priority
      status
      dueDate
      title
      description1
      description2
    }
  }
`;

const DELETE_ACTION = gql`
  mutation DeleteAction($id: ID!) {
    deleteAction(id: $id) {
      id
      Goal {
        id
        title
      }
      priority
      status
      dueDate
      title
      description1
      description2
    }
  }
`;

export { GET_ACTIONS, ADD_ACTION, UPDATE_ACTION, DELETE_ACTION };
