import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { reorder } from 'react-reorder';

import AudioPlayer from '../components/AudioPlayer';
import Layout from '../components/Layout';
import Container from '../components/Container';
import Notification from '../components/Notification';
import TextContent from '../components/TextContent';
import Section from '../components/Section';
import FooterBar from '../components/FooterBar';
import Grid from '../components/Grid';
import GridItem from '../components/GridItem';
import Table from '../components/Table';

import { GET_ACTIVITY_DETAILS } from '../graph/activities';
import { GET_CMS_CONTENT } from '../graph/cms';
import {
  GET_INTERESTS,
  ADD_INTEREST,
  UPDATE_INTEREST,
  DELETE_INTEREST,
} from '../graph/interests';

import extractGraphQLError from '../utils/extractGraphQLError';
import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';
import { validate, requiredString } from '../utils/validate';
import useTrackActivityGA from '../utils/useTrackActivityGA';

import { activities, ACTIVITY_STATUS } from '../config/levels';

import { ReactComponent as RemoveIcon } from '../assets/remove-icon.svg';

const interestFormValidationSchema = validate({
  title: requiredString,
  description1: requiredString,
  description2: requiredString,
});

const interestFormInitialValues = {
  title: '',
  description1: '',
  description2: '',
};

const interestFormFields = [
  {
    id: 'title',
    name: 'title',
    label: 'Memory',
    type: 'textarea',
    placeholder: 'Enter your memory...',
  },
  {
    id: 'description1',
    name: 'description1',
    label: 'Importance',
    type: 'textarea',
    placeholder: 'Importance - Why is this memory important in your life?',
  },
  {
    id: 'description2',
    name: 'description2',
    label: 'Influence',
    type: 'textarea',
    placeholder: 'Influence - How has this memory influenced you life?',
  },
];

function ActivityInterests({ activityId }) {
  const [orderedInterests, setOrderedInterests] = useState([]);

  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data, loading } = useQuery(GET_ACTIVITY_DETAILS, {
    variables: { id: activityId },
  });

  const { loading: loadingInterests } = useQuery(GET_INTERESTS, {
    variables: { activityId },
    onCompleted: async interestData => {
      const orderedInterets = [...interestData?.interests].sort(
        (a, b) => a.ranking - b.ranking,
      );

      setOrderedInterests(orderedInterets);
    },
  });

  const isInterestsValid = orderedInterests.length > 4;

  const [
    handleAddInterest,
    { loading: loadingAddInterest, error: errorAddInterest },
  ] = useMutation(ADD_INTEREST, {
    refetchQueries: ['Interests'],
  });

  const handleNewInterest = formData => {
    handleAddInterest({
      variables: {
        activityId,
        ranking: orderedInterests?.length,
        ...formData,
      },
    });
  };

  const [handleUpdateInterest] = useMutation(UPDATE_INTEREST);

  const updateInterests = reorderdInterests => {
    reorderdInterests.map(({ id }, i) => {
      return handleUpdateInterest({ variables: { id, ranking: i } });
    });
  };

  const [
    handleUpdateInterestWithRefetch,
    { loading: loadingUpdateInterest, error: errorUpdateInterest },
  ] = useMutation(UPDATE_INTEREST, {
    refetchQueries: ['Interests'],
  });

  const [
    handleDeleteInterest,
    { loading: loadingDeleteInterest, error: errorDeleteInterest },
  ] = useMutation(DELETE_INTEREST, {
    refetchQueries: ['Interests'],
  });

  const handleReorder = (event, previousIndex, nextIndex) => {
    const reorderdInterests = reorder(
      orderedInterests,
      previousIndex,
      nextIndex,
    );
    updateInterests(reorderdInterests);
    setOrderedInterests(reorderdInterests);
  };

  const activityType = data?.activity?.type;

  useTrackActivityGA(data?.activity?.type, '1');

  const { level } = activities[activityType] || {};

  const {
    title,
    sub_title: subTitle,
    instructions,
    audio_instructions_1: audioInstructions1,
    help_text_1: helpText1,
    short_instructions_1: shortInstructions1,
    step_label_1: stepInstructions1,
  } =
    cmsData?.cmsContent?.activity?.find(
      activity =>
        activity.id ===
        (cmsData?.user?.currentLevel === 4 &&
        data?.activity?.status === ACTIVITY_STATUS.COMPLETED
          ? `ongoing_${activityType}`
          : activityType),
    ) ||
    cmsData?.cmsContent?.activity?.find(
      activity => activity.id === activityType,
    ) ||
    {};

  return (
    <Layout
      title={`${extractPrismicString(title)}`}
      loading={loading || loadingInterests}
      hasFooterBar
    >
      <Container>
        <Section>
          {!!(hasContent(shortInstructions1) || hasContent(helpText1)) && (
            <Grid>
              <GridItem width={60}>
                <TextContent redh6 smallMargin>
                  {extractPrismicContent(shortInstructions1)}
                </TextContent>
              </GridItem>

              <GridItem width={40}>
                {!!(audioInstructions1?.url || hasContent(helpText1)) && (
                  <Notification showUser noMarginBottom>
                    {extractPrismicContent(helpText1)}

                    {!!audioInstructions1?.url && (
                      <AudioPlayer fileUrl={audioInstructions1?.url} />
                    )}
                  </Notification>
                )}
              </GridItem>
            </Grid>
          )}
        </Section>

        <TextContent redh6 smallMargin>
          <h6>Step 1</h6>
          <p>{extractPrismicString(stepInstructions1)}</p>
        </TextContent>

        <Table
          cols={[
            { id: 'title', value: 'Memory' },
            { id: 'description1', value: 'Importance' },
            { id: 'description2', value: 'Influence' },
          ]}
          data={orderedInterests}
          sortable
          onReorder={handleReorder}
          redorderId="interestId"
          actions={[
            {
              id: 'remove',
              text: 'remove',
              icon: <RemoveIcon />,
              onClick: handleDeleteInterest,
              loading: loadingDeleteInterest,
            },
          ]}
          form={{
            fields: interestFormFields,
            loading: loadingAddInterest,
            validationSchema: interestFormValidationSchema,
            initialValues: interestFormInitialValues,
            onSubmit: handleNewInterest,
          }}
          editable
          updateForm={{
            fields: interestFormFields,
            validationSchema: interestFormValidationSchema,
            onSubmit: handleUpdateInterestWithRefetch,
            loading: loadingUpdateInterest,
          }}
        />

        {!!(errorAddInterest || errorDeleteInterest || errorUpdateInterest) && (
          <Notification type="red">
            {extractGraphQLError(
              errorAddInterest || errorDeleteInterest || errorUpdateInterest,
            )}
          </Notification>
        )}
      </Container>

      <FooterBar
        title={extractPrismicString(title)}
        subTitle={extractPrismicString(subTitle)}
        primaryButton={{
          label: 'Next: Hashtags',
          to: `/activities/${activityId}/interests/2`,
          disabled: !isInterestsValid,
          level,
        }}
        secondaryButton={{
          label: hasContent(instructions)
            ? 'Back to instructions'
            : 'Back to introduction',
          to: `/activities/${activityId}/${
            hasContent(instructions) ? 'instructions' : 'introduction'
          }`,
        }}
      />
    </Layout>
  );
}

ActivityInterests.propTypes = {
  activityId: PropTypes.string,
};

ActivityInterests.defaultProps = {
  activityId: null,
};

export default ActivityInterests;
