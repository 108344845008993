import React from 'react';
import { TagsInput } from 'react-tag-input-component';
import _ from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import Modal from './Modal';
import Button from './Button';
import Card from './Card';
import { UPDATE_USER_TAG } from '../graph/auth';

function AdminBulkTags({ selected = [], users = [], onSuccess }) {
  const [showModal, setShowModal] = React.useState(false);
  const [text, setText] = React.useState([]);
  const [disabled, setDisabled] = React.useState(false);
  const [handleAddTag, { loading }] = useMutation(UPDATE_USER_TAG);

  const handleSubmit = _.debounce(() => {
    setDisabled(true);
    // eslint-disable-next-line
    for (let i = 0; i < selected.length; i++) {
      const element = selected[i];
      const user = users.find(row => row.id === element);
      if (user) {
        const tagStringify = user.tag || JSON.stringify([]);
        const tag = JSON.parse(tagStringify);
        tag.push(...text);
        const uniqueArray = tag.filter((item, pos) => {
          return tag.indexOf(item) === pos;
        });

        handleAddTag({
          variables: {
            updateUserTagsId: user.id,
            tag: JSON.stringify(uniqueArray),
          },
        });
      }
    }

    setTimeout(() => {
      onSuccess();
    }, 500);
    setShowModal(false);

    setDisabled(false);
  }, 500);

  const handelUpdate = value => {
    setText(value);
  };

  return (
    <>
      <Button
        small
        disabled={!selected.length}
        onClick={() => setShowModal(true)}
      >
        {' '}
        Bulk Tags
      </Button>
      <Modal isVisible={showModal} onClose={setShowModal}>
        <Card header={`Add Bulk Tags for ${selected.length} of Users`}>
          <TagsInput
            value={text}
            onChange={handelUpdate}
            placeHolder="Tags"
            disabled={loading}
          />
          <Button
            disabled={!text.length || disabled || loading}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Card>
      </Modal>
    </>
  );
}

AdminBulkTags.propTypes = {
  selected: PropTypes.arrayOf(),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  onSuccess: PropTypes.func.isRequired,
};

AdminBulkTags.defaultProps = {
  selected: [],
  users: [],
};

export default AdminBulkTags;
