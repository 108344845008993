import styled from 'styled-components';

const CardInner = styled.div`
  flex: 1 0 auto;
  padding: ${props => props.theme.spacing.midLarge};
  width: 100%;
  margin: auto;

  ${props =>
    props.negativeMargin &&
    `
   margin: -${props.theme.spacing.midLarge};
  `}

  p {
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export default CardInner;
