import gql from 'graphql-tag';

const GET_RESULTS = gql`
  query Results($resultId: ID) {
    results(resultId: $resultId) {
      values {
        id
        status
        new
        values {
          id
          type
          ranking
        }
      }
      strengths {
        id
        status
        new
        status
        strengths {
          id
          type
          value
        }
      }
      culture {
        id
        status
        new
        status
        flexiblity
        interdependence
        culture
        altCultures
      }
      organisation {
        id
        status
        new
        status
        entrepreneurial
        smoagile
        bureaucratic
        professional
      }
      interests {
        id
        status
        new
        interests {
          id
          title
          description1
          description2
          ranking
        }
      }
      vision {
        id
        status
        new
        vision
        visionFull
      }
      personality {
        id
        status
        new
        status
        themes {
          id
          type
          value
          topTrait
        }
        themeVisualisation {
          id
          type
          value
        }
        traits {
          id
          type
          value
        }
      }
      competencies {
        id
        status
        new
        status
        competencies {
          id
          type
          value
        }
      }
      roles {
        id
        status
        new
        status
        roles {
          id
          type
          value
        }
      }
      experiences {
        id
        status
        new
        experiences {
          id
          title
          description1
        }
      }

      abilities {
        id
        status
        new
        status
        working_with_numbers
        working_with_words
        abstract_reasoning
      }

      goals {
        id
        status
        new
        goals {
          id
          title
          description1
          description2
          description3
          description4
          description5
          ranking
        }
      }
      beliefs {
        id
        status
        new
        beliefs {
          id
          title
          description1
          description2
          description3
          type
        }
      }

      actions {
        id
        status
        new
        actions {
          id
          Goal {
            id
            title
          }
          priority
          status
          dueDate
          title
          description1
          description2
        }
      }

      interviewGuides {
        id
        status
        new
        status
        name
        companyName
        jobDescription
        interviewDate
        notes
        companyCulture
        companyStructure
        interviewTips {
          id
          type
          description1
        }
        interviewQuestions {
          id
          type
          title
          description1
        }
      }
    }
  }
`;

const ADD_RESULT = gql`
  mutation AddResult($type: String!, $new: Boolean!, $activityId: ID!) {
    addResult(type: $type, new: $new, activityId: $activityId) {
      id
    }
  }
`;

const UPDATE_RESULT = gql`
  mutation UpdateResult($id: ID!, $new: Boolean) {
    updateResult(id: $id, new: $new) {
      id
    }
  }
`;

export { GET_RESULTS, ADD_RESULT, UPDATE_RESULT };
