/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { navigate, Link, useLocation } from '@reach/router';
import Cookies from 'js-cookie';

import Card from '../components/Card';
import LayoutAuth from '../components/LayoutAuth';
import Form from '../components/Form';
import Notification from '../components/Notification';

import {
  CREATE_USER,
  GET_TOKEN,
  GET_USER,
  IS_AUTHENTICATED,
} from '../graph/auth';

import {
  validate,
  requiredString,
  requiredEmail,
  requiredPassword,
  requiredTerms,
} from '../utils/validate';

import useSiteSettings from '../hooks/useSiteSettings';

const signUpFormValidationSchema = validate({
  firstName: requiredString,
  lastName: requiredString,
  email: requiredEmail,
  password: requiredPassword,
  agreeToTerms: requiredTerms,
});

const signUpFormInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  agreeToTerms: false,
};

function SignUp() {
  const client = useApolloClient();
  const [utm, setUtm] = React.useState({});
  const { search } = useLocation();

  const {
    siteName,
    disclaimerURL,
    termsAndConditionURL,
    privacyURL,
    siteHost,
  } = useSiteSettings();

  const getUTM = () => {
    const params = new URLSearchParams(search);
    const paramObj = {};
    for (const value of params.keys()) {
      paramObj[value] = params.get(value);
    }

    setUtm(paramObj);
    window.history.replaceState('', '', '/sign-up');
  };

  React.useEffect(() => {
    getUTM();
  }, []);

  const [handleSignUp, { loading, error }] = useMutation(CREATE_USER, {
    async onCompleted(response) {
      if (!response?.signup) return;

      await client.cache.writeQuery({
        query: GET_USER,
        data: response?.signup,
      });

      (window.dataLayer || []).push({
        event: 'signup',
        userID: response?.signup?.user?.id,
      });

      Cookies.set('ck-has-account', true, { domain: siteHost });

      navigate('/onboarding/plans');

      await client.cache.writeQuery({
        query: IS_AUTHENTICATED,
        data: {
          isAuthenticated: true,
        },
      });

      await client.cache.writeQuery({
        query: GET_TOKEN,
        data: response?.signup,
      });
    },
  });

  const signUpFormFields = [
    {
      id: 'firstName',
      type: 'text',
      name: 'firstName',
      label: 'First name',
    },
    {
      id: 'lastName',
      type: 'text',
      name: 'lastName',
      label: 'Last name',
    },
    {
      id: 'email',
      type: 'email',
      name: 'email',
      label: 'Email Address',
    },
    {
      id: 'password',
      type: 'password',
      name: 'password',
      label: 'Password',
    },
    {
      id: 'agreeToTerms',
      type: 'checkbox',
      name: 'agreeToTerms',
      label: (
        <>
          I have read and agree to the {siteName}{' '}
          <a target="_blank" rel="noreferrer noopener" href={privacyURL}>
            Privacy Policy
          </a>
          ,{' '}
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={termsAndConditionURL}
          >
            Terms & Condition of supply
          </a>{' '}
          and{' '}
          <a target="_blank" rel="noreferrer noopener" href={disclaimerURL}>
            Disclaimer
          </a>
        </>
      ),
    },
  ];

  const renderLoginLink = () => {
    return (
      <Notification>
        <p>
          Already have an {siteName} account?{' '}
          <Link to="/login">Log in here</Link>.
        </p>
      </Notification>
    );
  };

  return (
    <LayoutAuth title="Sign up">
      <Card header={`Sign up for a free ${siteName} account`}>
        <Form
          fields={signUpFormFields}
          onSubmit={handleSignUp}
          validationSchema={signUpFormValidationSchema}
          initialValues={signUpFormInitialValues}
          loading={loading}
          utm={utm}
          error={!!error}
          errorMessage="There has been a problem creating your account, if you already have an account please log in."
          submitButtonText="Sign up for free"
          renderAfterFields={renderLoginLink}
          fullWidthButton
        />
      </Card>
    </LayoutAuth>
  );
}

export default SignUp;
