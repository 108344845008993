import gql from 'graphql-tag';

const GET_BELIEFS = gql`
  query Beliefs($activityId: ID!) {
    beliefs(activityId: $activityId) {
      id
      title
      description1
      description2
      description3
      type
    }
  }
`;

const ADD_BELIEF = gql`
  mutation AddBelief(
    $activityId: ID!
    $title: String!
    $description1: String
    $description2: String
    $description3: String
    $type: String
  ) {
    addBelief(
      activityId: $activityId
      title: $title
      description1: $description1
      description2: $description2
      description3: $description3
      type: $type
    ) {
      id
      title
      description1
      description2
      description3
      type
    }
  }
`;

const UPDATE_BELIEF = gql`
  mutation UpdateBelief(
    $id: ID!
    $title: String
    $description1: String
    $description2: String
    $description3: String
    $type: String
  ) {
    updateBelief(
      id: $id
      title: $title
      description1: $description1
      description2: $description2
      description3: $description3
      type: $type
    ) {
      id
      title
      description1
      description2
      description3
      type
    }
  }
`;

const DELETE_BELIEF = gql`
  mutation DeleteBelief($id: ID!) {
    deleteBelief(id: $id) {
      id
      title
      description1
      description2
      description3
      type
    }
  }
`;

export { GET_BELIEFS, ADD_BELIEF, UPDATE_BELIEF, DELETE_BELIEF };
