import gql from 'graphql-tag';

const DEFAULT_QUERY = gql`
  query DefaultQuery {
    token @client
    isAuthenticated @client
    formData @client {
      vision
      visionSummary
    }
    currency @client
    geolocated @client
  }
`;

const GET_TOKEN = gql`
  query GetToken {
    token @client
  }
`;

const IS_AUTHENTICATED = gql`
  query IsAuthenticated {
    isAuthenticated @client
  }
`;

const CREATE_USER = gql`
  mutation SignUp(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $utm: String
  ) {
    signup(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      utm: $utm
    ) {
      token
      user {
        id
        firstName
        lastName
        name
        email
        hasActiveSubscription
        coupon
        currentLevel
        completedActivities
        country
        city
        dateOfBirth
        profession
        hasCompletedProfile
        profilePicture
        createdAt
      }
    }
  }
`;

const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      success
    }
  }
`;

const DEACTIVATE_USER = gql`
  mutation deactivateUser($id: ID!, $deactivateFeedback: String) {
    deactivateUser(id: $id, deactivateFeedback: $deactivateFeedback) {
      success
    }
  }
`;

const LOGIN = gql`
  mutation LogIn($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        firstName
        lastName
        name
        email
        phoneNumber
        isAdmin
        hasActiveSubscription
        coupon
        currentLevel
        lastCompletedActivity
        completedActivities
        country
        city
        dateOfBirth
        profession
        hasCompletedProfile
        profilePicture
        availableFreeActivities
        createdAt
      }
    }
  }
`;

const GET_USER = gql`
  query User {
    user {
      id
      email
      firstName
      lastName
      name
      isAdmin
      hasActiveSubscription
      coupon
      currentLevel
      completedActivities
      country
      city
      dateOfBirth
      profession
      hasCompletedProfile
      profilePicture
      availableFreeActivities
      createdAt
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: ID!
    $firstName: String
    $lastName: String
    $country: String
    $city: String
    $dateOfBirth: String
    $profession: String
    $phoneNumber: String
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      country: $country
      city: $city
      dateOfBirth: $dateOfBirth
      profession: $profession
      phoneNumber: $phoneNumber
    ) {
      id
      firstName
      lastName
      name
      country
      city
      dateOfBirth
      profession
      hasCompletedProfile
      profilePicture
      createdAt
      phoneNumber
    }
  }
`;

const UPDATE_USER_TAG = gql`
  mutation UpdateUserTags($updateUserTagsId: ID!, $tag: String) {
    updateUserTags(id: $updateUserTagsId, tag: $tag) {
      id
      tag
    }
  }
`;

const UPDATE_USER_LINKEDIN = gql`
  mutation UpdateUserLinkedIn($id: ID!, $code: String!, $redirectUri: String!) {
    updateUserLinkedIn(id: $id, code: $code, redirectUri: $redirectUri) {
      id
      firstName
      lastName
      name
      country
      city
      dateOfBirth
      profession
      hasCompletedProfile
      profilePicture
    }
  }
`;

const DISCONNECT_USER_LINKEDIN = gql`
  mutation DisconnectUserLinkedIn($id: ID!) {
    disconnectUserLinkedIn(id: $id) {
      id
      firstName
      lastName
      name
      country
      city
      dateOfBirth
      profession
      hasCompletedProfile
      profilePicture
    }
  }
`;

const REQUEST_RESET = gql`
  mutation RequestReset($email: String!) {
    requestReset(email: $email) {
      success
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation ResetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      token
      user {
        id
        firstName
        lastName
        name
        email
        phoneNumber
        hasActiveSubscription
        coupon
        currentLevel
        completedActivities
        country
        city
        dateOfBirth
        profession
        hasCompletedProfile
        profilePicture
        availableFreeActivities
      }
    }
  }
`;

export {
  DEFAULT_QUERY,
  GET_TOKEN,
  IS_AUTHENTICATED,
  CREATE_USER,
  LOGIN,
  GET_USER,
  UPDATE_USER,
  UPDATE_USER_LINKEDIN,
  DISCONNECT_USER_LINKEDIN,
  REQUEST_RESET,
  RESET_PASSWORD,
  DELETE_USER,
  DEACTIVATE_USER,
  UPDATE_USER_TAG,
};
