import React from 'react';
import styled from 'styled-components';
import ScrollLock, { TouchScrollable } from 'react-scrolllock';

import Portal from './Portal';

const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${props => props.theme.layers.modal};
  display: flex;
`;

const Container = styled.div`
  flex: 0 0 auto;
  width: 100%;
  max-width: ${props => props.theme.grid.maxWidthXnarrow};
  margin: auto;
`;

function Modal({ isVisible, children, onClose, ...props }) {
  const handleWrapperClick = e => {
    e.stopPropagation();
    onClose();
  };

  const handleContainerClick = e => {
    e.stopPropagation();
  };

  if (!isVisible) return false;

  return (
    <Portal>
      <ScrollLock />
      <Wrapper onClick={handleWrapperClick} {...props}>
        <TouchScrollable>
          <Container onClick={handleContainerClick}>{children}</Container>
        </TouchScrollable>
      </Wrapper>
    </Portal>
  );
}

export default Modal;
