import React, { useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import {
  extractPrismicString,
  hasContent,
  extractPrismicContent,
} from '../utils/prismic';

import Container from './Container';
import Section from './Section';
import Button from './Button';
import Modal from './Modal';
import TextContentMarketing from './TextContentMarketing';

const Wrapper = styled.div`
  h2 {
    color: ${(props) => props.theme.colors.red500};
  }

  .slick-prev {
    left: 0px;
    z-index: 1;
    ${(props) => props.theme.breakpoints.medium`
      display: block!important;
      left: -40px;
    `}

    &:before {
      color: ${(props) => props.theme.colors.grey500};
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 30px;
  }

  .slick-next {
    right: 10px;
    z-index: 1;

    ${(props) => props.theme.breakpoints.medium`
      display: block!important;
      right: -30px;
    `}

    &:before {
      color: ${(props) => props.theme.colors.grey500};
    }
  }

  .slick-dots li {
    margin: 0;
  }

  .slick-dots {
    bottom: -20px;
  }

  .slick-dots li button:before {
    opacity: 1;
    color: #ebebeb;
  }

  .slick-dots li.slick-active button:before {
    color: ${(props) => props.theme.colors.red500};
    opacity: 1;
  }
`;

const Inner = styled.div`
  display: flex;
  justify-content: flex-start;

  h2 {
    color: ${(props) => props.theme.colors.red500};
  }

  ${(props) =>
    props.center &&
    `
    justify-content: center;
  `}
`;

const Video = styled.div`
  background-color: ${(props) => props.theme.colors.grey200};
  display: block;
  overflow: hidden;
  padding-bottom: ${(9 / 16) * 100}%;
  position: relative;
  width: 100%;
  flex: 0 0 0;
  iframe {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;
const ItemList = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  position: relative;

  ${(props) => props.theme.breakpoints.large`
    width: 80%;
  `}
`;
function MarketingVideo({
  video_title: title,
  video_text: text,
  video_small: small,
  video_background_color: backgroundColor,
  video_button_center: center,
  video_button_text: buttonText,
  video_show_button: showButton,
  items,
}) {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
  };
  const handleToggleVideoModal = () => {
    setShowVideoModal(!showVideoModal);
  };

  return (
    <Wrapper>
      <Section
        padding={small ? 'medium' : 'large'}
        backgroundColor={backgroundColor}
      >
        <Container>
          {!!(hasContent(title) || hasContent(text)) && (
            <TextContentMarketing marginBottom>
              {hasContent(title) && <h2>{extractPrismicString(title)}</h2>}
              {hasContent(text) && extractPrismicContent(text)}
            </TextContentMarketing>
          )}
        </Container>
        <Container xNarrow={small}>
          {showButton ? (
            <Inner center={center}>
              <Button onClick={handleToggleVideoModal}>
                {extractPrismicString(buttonText)}
              </Button>
            </Inner>
          ) : (
            <ItemList>
              <Slider {...settings}>
                {items.map((i) => (
                  <div>
                    <Video
                      dangerouslySetInnerHTML={{ __html: i?.video_embed?.html }}
                    />
                  </div>
                ))}
              </Slider>
            </ItemList>
          )}
        </Container>
      </Section>

      {showButton && (
        <Modal isVisible={showVideoModal} onClose={handleToggleVideoModal} />
      )}
    </Wrapper>
  );
}

MarketingVideo.propTypes = {};

export default MarketingVideo;
