import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import AudioPlayer from '../components/AudioPlayer';
import Layout from '../components/Layout';
import Container from '../components/Container';
import Notification from '../components/Notification';
import TextContent from '../components/TextContent';
import Section from '../components/Section';
import FooterBar from '../components/FooterBar';
import Grid from '../components/Grid';
import GridItem from '../components/GridItem';
import TagTable from '../components/TagTable';
import Table from '../components/Table';

import { GET_ACTIVITY_DETAILS } from '../graph/activities';
import { GET_CMS_CONTENT } from '../graph/cms';
import { GET_HASHTAGS, ADD_HASHTAG, DELETE_HASHTAG } from '../graph/hashtags';
import { GET_INTERESTS } from '../graph/interests';

import extractGraphQLError from '../utils/extractGraphQLError';
import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';
import { validate, requiredString } from '../utils/validate';
import useTrackActivityGA from '../utils/useTrackActivityGA';

import { activities, ACTIVITY_STATUS } from '../config/levels';

const hashtagFormValidationSchema = validate({
  title: requiredString,
});

const hashtagFormInitialValues = {
  title: '',
};

const hashtagFormFields = [
  {
    id: 'title',
    name: 'title',
    label: 'Memory',
    type: 'text',
    placeholder: 'Hashtag of key interest or pattern...',
  },
];

function ActivityInterestsStep2({ activityId }) {
  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data, loading } = useQuery(GET_ACTIVITY_DETAILS, {
    variables: { id: activityId },
  });

  const { loading: loadingHashtags, data: dataHashtags } = useQuery(
    GET_HASHTAGS,
    {
      variables: { activityId },
    },
  );

  const { data: interestsData, loading: loadingInterests } = useQuery(
    GET_INTERESTS,
    {
      variables: { activityId },
    },
  );

  const filteredHashtags = dataHashtags?.hashtags?.filter(
    ({ type }) => type === 'INITIAL',
  );

  const isHashtagValid = filteredHashtags?.length > 4;

  const [
    handleAddHashtag,
    { loading: loadingAddHashtag, error: errorAddHashtag },
  ] = useMutation(ADD_HASHTAG, {
    refetchQueries: ['Hashtags'],
  });

  const handleNewHashtag = formData => {
    handleAddHashtag({
      variables: {
        activityId,
        type: 'INITIAL',
        ...formData,
      },
    });
  };

  const [
    handleDeleteHashtag,
    { loading: loadingDeleteHashtag, error: errorDeleteHashtag },
  ] = useMutation(DELETE_HASHTAG, {
    refetchQueries: ['Hashtags'],
  });

  const activityType = data?.activity?.type;

  useTrackActivityGA(data?.activity?.type, '2');

  const { level } = activities[activityType] || {};

  const {
    title,
    sub_title: subTitle,
    audio_instructions_2: audioInstructions2,
    help_text_2: helpText2,
    short_instructions_2: shortInstructions2,
    step_label_2: stepInstructions2,
  } =
    cmsData?.cmsContent?.activity?.find(
      activity =>
        activity.id ===
        (cmsData?.user?.currentLevel === 4 &&
        data?.activity?.status === ACTIVITY_STATUS.COMPLETED
          ? `ongoing_${activityType}`
          : activityType),
    ) ||
    cmsData?.cmsContent?.activity?.find(
      activity => activity.id === activityType,
    ) ||
    {};

  return (
    <Layout
      title={`${extractPrismicString(title)}`}
      loading={loading || loadingHashtags || loadingInterests}
      hasFooterBar
    >
      <Container>
        <Section>
          {!!(hasContent(shortInstructions2) || hasContent(helpText2)) && (
            <Grid>
              <GridItem width={60}>
                <TextContent redh6 smallMargin>
                  {extractPrismicContent(shortInstructions2)}
                </TextContent>
              </GridItem>

              <GridItem width={40}>
                {!!(audioInstructions2?.url || hasContent(helpText2)) && (
                  <Notification showUser noMarginBottom>
                    {extractPrismicContent(helpText2)}

                    {!!audioInstructions2?.url && (
                      <AudioPlayer fileUrl={audioInstructions2?.url} />
                    )}
                  </Notification>
                )}
              </GridItem>
            </Grid>
          )}
        </Section>

        <Section smallPadding>
          <h3>Your Memories</h3>
          <Table
            cols={[
              { id: 'title', value: 'Memory' },
              { id: 'description1', value: 'Importance' },
              { id: 'description2', value: 'Influence' },
            ]}
            data={interestsData?.interests}
          />
        </Section>

        <TextContent redh6 smallMargin>
          <h6>Step 2</h6>
          <p>{extractPrismicString(stepInstructions2)}</p>
        </TextContent>

        <TagTable
          data={filteredHashtags}
          onDeleteTag={handleDeleteHashtag}
          tagLoading={loadingDeleteHashtag}
          form={{
            fields: hashtagFormFields,
            loading: loadingAddHashtag,
            validationSchema: hashtagFormValidationSchema,
            initialValues: hashtagFormInitialValues,
            onSubmit: handleNewHashtag,
          }}
        />

        {!!(errorAddHashtag || errorDeleteHashtag) && (
          <Notification type="red">
            {extractGraphQLError(errorAddHashtag || errorDeleteHashtag)}
          </Notification>
        )}
      </Container>

      <FooterBar
        title={extractPrismicString(title)}
        subTitle={extractPrismicString(subTitle)}
        primaryButton={{
          label: 'Next: Define Career Interests',
          to: `/activities/${activityId}/hashtags`,
          disabled: !isHashtagValid,
          level,
        }}
        secondaryButton={{
          label: 'Back to memories',
          to: `/activities/${activityId}/interests`,
        }}
      />
    </Layout>
  );
}

ActivityInterestsStep2.propTypes = {
  activityId: PropTypes.string,
};

ActivityInterestsStep2.defaultProps = {
  activityId: null,
};

export default ActivityInterestsStep2;
