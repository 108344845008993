import gql from 'graphql-tag';

const GET_INTERVIEW_TIPS = gql`
  query InterviewTips($activityId: ID!) {
    interviewTips(activityId: $activityId) {
      id
      title
      description1
      description2
      type
    }
  }
`;

const ADD_INTERVIEW_TIP = gql`
  mutation AddInterviewTip(
    $activityId: ID!
    $title: String
    $description1: String
    $description2: String
    $type: String
  ) {
    addInterviewTip(
      activityId: $activityId
      title: $title
      description1: $description1
      description2: $description2
      type: $type
    ) {
      id
      title
      description1
      description2
      type
    }
  }
`;

const UPDATE_INTERVIEW_TIP = gql`
  mutation UpdateInterviewTip(
    $id: ID!
    $title: String
    $description1: String
    $description2: String
    $type: String
  ) {
    updateInterviewTip(
      id: $id
      title: $title
      description1: $description1
      description2: $description2
      type: $type
    ) {
      id
      title
      description1
      description2
      type
    }
  }
`;

const DELETE_INTERVIEW_TIP = gql`
  mutation DeleteInterviewTip($id: ID!) {
    deleteInterviewTip(id: $id) {
      id
      title
      description1
      description2
      type
    }
  }
`;

export {
  GET_INTERVIEW_TIPS,
  ADD_INTERVIEW_TIP,
  UPDATE_INTERVIEW_TIP,
  DELETE_INTERVIEW_TIP,
};
