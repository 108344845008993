import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import Form from '../components/Form';
import AudioPlayer from '../components/AudioPlayer';
import Layout from '../components/Layout';
import Container from '../components/Container';
import Notification from '../components/Notification';
import TextContent from '../components/TextContent';
import Section from '../components/Section';
import FooterBar from '../components/FooterBar';
import Grid from '../components/Grid';
import GridItem from '../components/GridItem';
import Card from '../components/Card';

import { GET_ACTIVITY_DETAILS, UPDATE_ACTIVITY } from '../graph/activities';
import { GET_CMS_CONTENT } from '../graph/cms';

import extractGraphQLError from '../utils/extractGraphQLError';
import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';
import { validate, requiredString } from '../utils/validate';
import useTrackActivityGA from '../utils/useTrackActivityGA';

import { activities, ACTIVITY_STATUS } from '../config/levels';

const formValidationSchema = validate({
  name: requiredString,
  companyName: requiredString,
});

const formFields = [
  {
    id: 'name',
    name: 'name',
    label: 'Job Title',
    type: 'text',
    placeholder:
      "Example: The name of the company and the job role you're applying for",
  },
  {
    id: 'companyName',
    name: 'companyName',
    label: 'Company Name',
    type: 'text',
  },
  {
    id: 'jobDescription',
    name: 'jobDescription',
    label: 'Job Description',
    type: 'textarea',
  },
  {
    id: 'interviewDate',
    name: 'interviewDate',
    label: 'Interview Date',
    type: 'date',
  },
];

function ActivityInterviewGuideIntro({ activityId }) {
  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data, loading } = useQuery(GET_ACTIVITY_DETAILS, {
    variables: { id: activityId },
  });

  const isValid = !!data?.activity?.name;

  const [
    handleUpdateActivity,
    { loading: loadingUpdateActivity, error },
  ] = useMutation(UPDATE_ACTIVITY);

  const handleAddInterviewDetails = ({ variables }) => {
    handleUpdateActivity({
      variables: {
        id: activityId,
        ...variables,
      },
    });
  };

  const activityType = data?.activity?.type;

  useTrackActivityGA(data?.activity?.type, 'introduction');

  const { level } = activities[activityType] || {};

  const {
    title,
    sub_title: subTitle,
    instructions,
    audio_instructions_1: audioInstructions,
    help_text_1: helpText,
    short_instructions_1: shortInstructions,
    step_label_1: stepLabel,
  } =
    cmsData?.cmsContent?.activity?.find(
      activity =>
        activity.id ===
        (cmsData?.user?.currentLevel === 4 &&
        data?.activity?.status === ACTIVITY_STATUS.COMPLETED
          ? `ongoing_${activityType}`
          : activityType),
    ) ||
    cmsData?.cmsContent?.activity?.find(
      activity => activity.id === activityType,
    ) ||
    {};

  return (
    <Layout
      title={`${extractPrismicString(title)}`}
      loading={loading}
      hasFooterBar
    >
      <Container>
        <Section>
          {!!(hasContent(shortInstructions) || hasContent(helpText)) && (
            <Grid>
              <GridItem width={60}>
                <TextContent redh6 smallMargin>
                  {extractPrismicContent(shortInstructions)}
                </TextContent>
              </GridItem>

              <GridItem width={40}>
                {!!(audioInstructions?.url || hasContent(helpText)) && (
                  <Notification showUser noMarginBottom>
                    {extractPrismicContent(helpText)}

                    {!!audioInstructions?.url && (
                      <AudioPlayer fileUrl={audioInstructions?.url} />
                    )}
                  </Notification>
                )}
              </GridItem>
            </Grid>
          )}
        </Section>

        <Container narrow>
          <TextContent redh6 smallMargin>
            <h6>Step 1</h6>
            {extractPrismicContent(stepLabel)}
          </TextContent>

          <Section smallPadding>
            <Card>
              <Form
                fields={formFields}
                validationSchema={formValidationSchema}
                onSubmit={handleAddInterviewDetails}
                loading={loadingUpdateActivity}
                submitButtonText="Update"
                initialValues={data?.activity}
              />
            </Card>
          </Section>

          {!!error && (
            <Notification type="red">{extractGraphQLError(error)}</Notification>
          )}
        </Container>
      </Container>

      <FooterBar
        title={extractPrismicString(title)}
        subTitle={extractPrismicString(subTitle)}
        primaryButton={{
          label: 'Next: Match Personality Themes',
          to: `/activities/${activityId}/interview_guide/1`,
          disabled: !isValid,
          level,
        }}
        secondaryButton={{
          label: hasContent(instructions)
            ? 'Back to instructions'
            : 'Back to introduction',
          to: `/activities/${activityId}/${
            hasContent(instructions) ? 'instructions' : 'introduction'
          }`,
        }}
      />
    </Layout>
  );
}

ActivityInterviewGuideIntro.propTypes = {
  activityId: PropTypes.string,
};

ActivityInterviewGuideIntro.defaultProps = {
  activityId: null,
};

export default ActivityInterviewGuideIntro;
