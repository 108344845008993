import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ActivityList from './ActivityList';
import LevelStatusCircle from './LevelStatusCircle';
import TextContent from './TextContent';
import LevelText from './LevelText';
import Notification from './Notification';

import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';

import dotsSvg from '../assets/dots.svg';

const Wrapper = styled.section`
  padding-top: ${props => props.theme.spacing.xLarge};
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 4px;
    height: ${props => props.theme.spacing.xLarge};
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    transform: translateX(-2px);
    background-image: url(${dotsSvg});
  }
`;

function ActivityJourney({
  level,
  isDisabled,
  cmsContent,
  ongoing,
  ...props
}) {
  const { title, intro_text: introText, quote, quote_citation: quoteCite } =
    cmsContent?.level?.find(({ id }) => id === `${level}`) || {};

  if (ongoing) return false;

  return (
    <Wrapper id={`level-${level}`}>
      <LevelStatusCircle level={level} isDisabled={isDisabled} {...props} />

      <LevelText>
        <TextContent maxHeight={90} smallMargin noMarginTop noFloat>
          <h6>
            Level
            {' '}
            {level}
          </h6>
          <h2>{extractPrismicString(title)}</h2>

          {extractPrismicContent(introText)}

          {hasContent(quote) && (
            <Notification type="green">
              <blockquote>{extractPrismicString(quote)}</blockquote>
              {hasContent(quoteCite) && (
                <cite> 
                  {' '}
                  {extractPrismicString(quoteCite)}
                </cite>
              )}
            </Notification>
          )}
        </TextContent>
      </LevelText>

      <ActivityList
        isDisabled={isDisabled}
        level={level}
        cmsContent={cmsContent}
        {...props}
      />
    </Wrapper>
  );
}

ActivityJourney.propTypes = {
  level: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  cmsContent: PropTypes.shape({}).isRequired,
  ongoing: PropTypes.bool,
};

ActivityJourney.defaultProps = {
  ongoing: false,
};

export default ActivityJourney;
