import React from 'react';
import PropTypes from 'prop-types';

import ValuesVisualisation from '../components/ValuesVisualisation';
import CultureVisualisation from '../components/CultureVisualisation';
import OrganisationVisualisation from '../components/OrganisationVisualisation';
import InterestsVisualisation from '../components/InterestsVisualisation';
import PersonalityVisualisation from '../components/PersonalityVisualisation';
import PersonalityTraitVisualisation from '../components/PersonalityTraitVisualisation';

import RolesVisualisation from '../components/RolesVisualisation';
import CompetenciesVisualisation from '../components/CompetenciesVisualisation';
import ExperiencesVisualisation from '../components/ExperiencesVisualisation';
import AbilitiesVisualisation from '../components/AbilitiesVisualisation';
import BeliefsVisualisation from '../components/BeliefsVisualisation';
import GoalsVisualisation from '../components/GoalsVisualisation';
import ActionsVisualisation from '../components/ActionsVisualisation';

function ResultVisualisation({ resultData, cmsData, type, i }) {
  if (type === 'values') {
    return (
      <ValuesVisualisation
        data={resultData}
        cmsContent={cmsData?.cmsContent}
        noMargin
      />
    );
  }

  if (type === 'culture') {
    return <CultureVisualisation data={resultData} />;
  }

  if (type === 'organisation') {
    return <OrganisationVisualisation data={resultData} />;
  }

  if (type === 'interests') {
    return <InterestsVisualisation data={resultData} />;
  }

  if (type === 'personality' && i === 2) {
    return <PersonalityVisualisation data={resultData} />;
  }

  if (type === 'personality' && i === 3) {
    return <PersonalityTraitVisualisation data={resultData} />;
  }

  if (type === 'roles') {
    return <RolesVisualisation data={resultData} />;
  }

  if (type === 'competencies') {
    return <CompetenciesVisualisation data={resultData} />;
  }

  if (type === 'experiences') {
    return <ExperiencesVisualisation data={resultData} />;
  }

  if (type === 'abilities') {
    return <AbilitiesVisualisation data={resultData} />;
  }

  if (type === 'beliefs') {
    return <BeliefsVisualisation data={resultData} />;
  }

  if (type === 'goals') {
    return <GoalsVisualisation data={resultData} />;
  }
  if (type === 'actions') {
    return <ActionsVisualisation data={resultData} />;
  }

  return false;
}

ResultVisualisation.propTypes = {
  resultData: PropTypes.shape({}).isRequired,
  cmsData: PropTypes.shape({}).isRequired,
  type: PropTypes.string.isRequired,
  i: PropTypes.number.isRequired,
};

export default ResultVisualisation;
