import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { visuallyHidden } from '../theme/utils';

const Button = styled.button(
  ({ theme, loading }) => `
    align-items: center;
    display: flex;
    height: ${theme.spacing.midLarge};
    justify-content: center;
    cursor: pointer;

    svg {
      display: block;
      fill: ${theme.colors.grey500};
      height: ${theme.spacing.small};
      margin: 0;
      width: ${theme.spacing.small};
    }

    &:hover {
      svg {
        fill: ${theme.colors.red500};
      }
    }

    ${
      loading &&
      `
      opacity: 0.3;
      pointer-events: none;
    `
    }
`,
);

const HiddenText = styled.span`
  ${visuallyHidden};
`;

function TableAction({ onClick, text, icon, id, ...props }) {
  const handleClick = () => {
    onClick({ variables: { id } });
  };

  return (
    <Button type="button" onClick={handleClick} {...props}>
      {icon}
      <HiddenText>{text}</HiddenText>
    </Button>
  );
}

TableAction.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};

export default memo(TableAction);
