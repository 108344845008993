import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import AudioPlayer from '../components/AudioPlayer';
import Layout from '../components/Layout';
import Container from '../components/Container';
import Notification from '../components/Notification';
import TextContent from '../components/TextContent';
import Section from '../components/Section';
import FooterBar from '../components/FooterBar';
import Grid from '../components/Grid';
import GridItem from '../components/GridItem';
import Table from '../components/Table';
import Card from '../components/Card';

import { GET_ACTIVITY_DETAILS } from '../graph/activities';
import { GET_CMS_CONTENT } from '../graph/cms';
import {
  GET_INTERVIEW_TIPS,
  ADD_INTERVIEW_TIP,
  DELETE_INTERVIEW_TIP,
  UPDATE_INTERVIEW_TIP,
} from '../graph/interviewTips';
import { GET_RESULTS } from '../graph/results';

import extractGraphQLError from '../utils/extractGraphQLError';
import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';
import { validate, requiredString } from '../utils/validate';
import useTrackActivityGA from '../utils/useTrackActivityGA';

import { activities, ACTIVITY_STATUS } from '../config/levels';

import { ReactComponent as RemoveIcon } from '../assets/remove-icon.svg';
import VisionSummary from '../components/VisionSummary';

const formValidationSchema = validate({
  description1: requiredString,
});

const formInitialValues = {
  description1: '',
};

const formFields = [
  {
    id: 'description1',
    name: 'description1',
    label:
      'Explore how role aligns to own interests and empowers you to progress towards your vision',
    type: 'textarea',
    placeholder:
      'Example: “I am really interested in biomedicine [Interest] and see myself working on an exciting project in a scientific lab that allows me to experiment as part of a team [Vision]. This job not only aligns with my love of science [What it is in the job that aligns with interest] but allows me to actively analyse our clients’ problems and explore different possible solutions [What you hope to gain/learn from the job]”',
  },
];

function ActivityInterviewGuideStep5({ activityId }) {
  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data, loading } = useQuery(GET_ACTIVITY_DETAILS, {
    variables: { id: activityId },
  });

  const { loading: loadingInterviewTips, data: dataInterviewTips } = useQuery(
    GET_INTERVIEW_TIPS,
    {
      variables: { activityId },
    },
  );

  const { data: resultData, loading: loadingResults } = useQuery(GET_RESULTS);

  const typeName = 'INTEREST';

  const filteredInterviewTips = dataInterviewTips?.interviewTips?.filter(
    interviewTip => interviewTip?.type === typeName,
  );

  const isInterviewTipValid = filteredInterviewTips?.length > 0;

  const [
    handleAddInterviewTip,
    { loading: loadingAddInterviewTip, error: errorAddInterviewTip },
  ] = useMutation(ADD_INTERVIEW_TIP, {
    refetchQueries: ['InterviewTips'],
  });

  const handleNewInterviewTip = formData => {
    handleAddInterviewTip({
      variables: {
        activityId,
        type: typeName,
        ...formData,
      },
    });
  };

  const [
    handleDeleteInterviewTip,
    { loading: loadingDeleteInterviewTip, error: errorDeleteInterviewTip },
  ] = useMutation(DELETE_INTERVIEW_TIP, {
    refetchQueries: ['InterviewTips'],
  });

  const [
    handleUpdateInterviewTip,
    { loading: loadingUpdateInterviewTip, error: errorUpdateInterviewTip },
  ] = useMutation(UPDATE_INTERVIEW_TIP, {
    refetchQueries: ['InterviewTips'],
  });

  const activityType = data?.activity?.type;

  useTrackActivityGA(data?.activity?.type, '5');

  const { level } = activities[activityType] || {};

  const {
    title,
    sub_title: subTitle,
    audio_instructions_6: audioInstructions,
    help_text_6: helpText,
    short_instructions_6: shortInstructions,
    step_label_6: stepLabel,
  } =
    cmsData?.cmsContent?.activity?.find(
      activity =>
        activity.id ===
        (cmsData?.user?.currentLevel === 4 &&
        data?.activity?.status === ACTIVITY_STATUS.COMPLETED
          ? `ongoing_${activityType}`
          : activityType),
    ) ||
    cmsData?.cmsContent?.activity?.find(
      activity => activity.id === activityType,
    ) ||
    {};

  return (
    <Layout
      title={`${extractPrismicString(title)}`}
      loading={loading || loadingInterviewTips || loadingResults}
      hasFooterBar
    >
      <Container>
        <Section>
          {!!(hasContent(shortInstructions) || hasContent(helpText)) && (
            <Grid>
              <GridItem width={60}>
                <TextContent redh6 smallMargin>
                  {extractPrismicContent(shortInstructions)}
                </TextContent>
              </GridItem>

              <GridItem width={40}>
                {!!(audioInstructions?.url || hasContent(helpText)) && (
                  <Notification showUser noMarginBottom>
                    {extractPrismicContent(helpText)}

                    {!!audioInstructions?.url && (
                      <AudioPlayer fileUrl={audioInstructions?.url} />
                    )}
                  </Notification>
                )}
              </GridItem>
            </Grid>
          )}
        </Section>

        <Section smallPadding>
          <Grid>
            <GridItem width={60} alignTop>
              <h3>Your Career Oriented Interests</h3>
              <Table
                cols={[
                  { id: 'title', value: 'Career Interest' },
                  { id: 'description1', value: 'Description' },
                ]}
                data={resultData?.results?.interests?.interests}
              />
            </GridItem>

            <GridItem width={40} alignTop>
              <Card header="Your Vision">
                <VisionSummary data={resultData?.results?.vision} />
              </Card>
            </GridItem>
          </Grid>
        </Section>

        <Section smallPadding>
          <TextContent redh6 smallMargin>
            <h6>Step 6</h6>
            {extractPrismicContent(stepLabel)}
          </TextContent>
        </Section>

        <Table
          cols={[
            {
              id: 'description1',
              value:
                'Explore how role aligns to own interests and empowers you to progress towards your vision',
            },
          ]}
          data={filteredInterviewTips}
          actions={[
            {
              id: 'remove',
              text: 'remove',
              icon: <RemoveIcon />,
              onClick: handleDeleteInterviewTip,
              loading: loadingDeleteInterviewTip,
            },
          ]}
          form={{
            fields: formFields,
            loading: loadingAddInterviewTip,
            validationSchema: formValidationSchema,
            initialValues: formInitialValues,
            onSubmit: handleNewInterviewTip,
          }}
          editable
          updateForm={{
            fields: formFields,
            loading: loadingUpdateInterviewTip,
            validationSchema: formValidationSchema,
            onSubmit: handleUpdateInterviewTip,
          }}
          noMargin
        />

        {!!(
          errorAddInterviewTip ||
          errorDeleteInterviewTip ||
          errorUpdateInterviewTip
        ) && (
          <Notification type="red">
            {extractGraphQLError(
              errorAddInterviewTip ||
                errorDeleteInterviewTip ||
                errorUpdateInterviewTip,
            )}
          </Notification>
        )}
      </Container>

      <FooterBar
        title={extractPrismicString(title)}
        subTitle={extractPrismicString(subTitle)}
        primaryButton={{
          label: 'Next: Company Culture and Structure',
          to: `/activities/${activityId}/interview_guide/6`,
          disabled: !isInterviewTipValid,
          level,
        }}
        secondaryButton={{
          label: 'Back to Character Strengths',
          to: `/activities/${activityId}/interview_guide/4`,
        }}
      />
    </Layout>
  );
}

ActivityInterviewGuideStep5.propTypes = {
  activityId: PropTypes.string,
};

ActivityInterviewGuideStep5.defaultProps = {
  activityId: null,
};

export default ActivityInterviewGuideStep5;
