import styled from 'styled-components';

const GridItem = styled.div`
  padding: ${props => props.theme.spacing.small} 0;
  width: 100%;
  margin: auto;

  &:empty {
    padding-bottom: 0 !important;
  }

  ${props =>
    props.alignTop &&
    `
    margin: 0;
  `}

  ${props => props.theme.breakpoints.medium`
    flex: 0 0 ${props.width}%;
    width: ${props.width}%;
    padding: ${props.theme.spacing.small};
  `}

  ${props =>
    props.grow &&
    props.theme.breakpoints.medium`
    display: flex;
    margin: 0;
  `}

${props =>
  props.largeGutter &&
  `
  padding: ${props.theme.spacing.midLarge} 0;
  `}


${props =>
  props.largeGutter &&
  props.theme.breakpoints.medium`
  padding: ${props.theme.spacing.midLarge};
  `}
`;

export default GridItem;
