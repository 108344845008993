import React from 'react';
import styled from 'styled-components';

import Container from './Container';
import Section from './Section';
import TextContentMarketing from './TextContentMarketing';
import Button from './Button';

import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';

const Wrapper = styled.div`
  h2 {
    display: flex;
    color: ${props => props.theme.colors.red500};
    align-items: center;
  }
`;

const Icon = styled.img`
  height: 80px;
  margin-right: ${props => props.theme.spacing.large};
`;

function MarketingTextContent({
  rich_text_content_small: small,
  rich_text_content_background_color: backgroundColor,
  rich_text_content_title: title,
  rich_text_content_text: text,
  rich_text_content_button_text: buttonText,
  rich_text_content_button_url: buttonUrl,
  rich_text_content_title_icon: icon,
}) {
  return (
    <Wrapper>
      <Section
        padding={small ? 'medium' : 'large'}
        backgroundColor={backgroundColor}
      >
        <Container>
          <TextContentMarketing>
            <h2>
              {!!(icon && icon.url) && <Icon src={icon.url} />}
              {extractPrismicString(title)}
            </h2>

            {extractPrismicContent(text)}
          </TextContentMarketing>

          {!!(hasContent(buttonText) && hasContent(buttonUrl)) && (
            <Button green marginTop href={extractPrismicString(buttonUrl)}>
              {extractPrismicString(buttonText)}
            </Button>
          )}
        </Container>
      </Section>
    </Wrapper>
  );
}

MarketingTextContent.propTypes = {};

export default MarketingTextContent;
