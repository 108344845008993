import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import AudioPlayer from '../components/AudioPlayer';
import Layout from '../components/Layout';
import Container from '../components/Container';
import Notification from '../components/Notification';
import TextContent from '../components/TextContent';
import Section from '../components/Section';
import FooterBar from '../components/FooterBar';
import Grid from '../components/Grid';
import GridItem from '../components/GridItem';
import Table from '../components/Table';

import { GET_ACTIVITY_DETAILS, UPDATE_ACTIVITY } from '../graph/activities';
import { GET_CMS_CONTENT } from '../graph/cms';
import {
  GET_INTERVIEW_QUESTIONS,
  ADD_INTERVIEW_QUESTION,
  DELETE_INTERVIEW_QUESTION,
  UPDATE_INTERVIEW_QUESTION,
} from '../graph/interviewQuestions';
import { GET_RESULTS } from '../graph/results';

import extractGraphQLError from '../utils/extractGraphQLError';
import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';
import { validate, requiredString } from '../utils/validate';
import sleep from '../utils/sleep';
import useTrackActivityGA from '../utils/useTrackActivityGA';

import { activities, ACTIVITY_STATUS } from '../config/levels';

import { ReactComponent as RemoveIcon } from '../assets/remove-icon.svg';

const formValidationSchema = validate({
  description1: requiredString,
});

const formInitialValues = {
  description1: '',
};

const formFields = [
  {
    id: 'title',
    name: 'title',
    label: 'title',
    type: 'textarea',
    placeholder: 'Interview question category',
    medium: true,
  },
  {
    id: 'description1',
    name: 'description1',
    label:
      'Explore how role aligns to own interests and empowers you to progress towards your vision',
    type: 'textarea',
    placeholder: 'Interview question',
  },
];

function ActivityInterviewGuideStep7({ activityId }) {
  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data, loading } = useQuery(GET_ACTIVITY_DETAILS, {
    variables: { id: activityId },
  });

  const { data: resultData, loading: loadingResults } = useQuery(GET_RESULTS);

  const valueAndStrengthIds = [
    ...(resultData?.results?.strengths?.strengths || []),
    ...(resultData?.results?.values?.values || []),
  ].map(result => result.type);

  const questions = cmsData?.cmsContent?.interview_questions?.filter(
    question => {
      const slug = question.result_link?.slug;
      return valueAndStrengthIds.includes(slug);
    },
  );

  const [
    handleAddInterviewQuestion,
    { loading: loadingAddInterviewQuestion, error: errorAddInterviewQuestion },
  ] = useMutation(ADD_INTERVIEW_QUESTION, {
    refetchQueries: ['InterviewQuestions'],
  });

  const handleNewInterviewQuestion = formData => {
    handleAddInterviewQuestion({
      variables: {
        activityId,
        ...formData,
      },
    });
  };

  const [
    handleDeleteInterviewQuestion,
    {
      loading: loadingDeleteInterviewQuestion,
      error: errorDeleteInterviewQuestion,
    },
  ] = useMutation(DELETE_INTERVIEW_QUESTION, {
    refetchQueries: ['InterviewQuestions'],
  });

  const [
    handleUpdateInterviewQuestion,
    {
      loading: loadingUpdateInterviewQuestion,
      error: errorUpdateInterviewQuestion,
    },
  ] = useMutation(UPDATE_INTERVIEW_QUESTION, {
    refetchQueries: ['InterviewQuestions'],
  });

  const {
    loading: loadingInterviewQuestions,
    data: dataInterviewQuestions,
  } = useQuery(GET_INTERVIEW_QUESTIONS, {
    variables: { activityId },
    skip: !resultData,
    onCompleted: async questionData => {
      await Promise.all(
        questions.map(async value => {
          if (!questionData?.interviewQuestions?.length) {
            await handleAddInterviewQuestion({
              variables: {
                activityId,
                type: value.id,
                title: extractPrismicString(value.title),
                description1: extractPrismicString(value.text),
              },
            });
          }
        }),
      );
    },
  });

  const isValid = dataInterviewQuestions?.interviewQuestions?.length > 0;

  const [
    handleUpdateActivity,
    { loading: loadingUpdateActivity, error },
  ] = useMutation(UPDATE_ACTIVITY);

  const handleCompleteActivity = async () => {
    await handleUpdateActivity({
      variables: {
        id: activityId,
        status: ACTIVITY_STATUS.COMPLETED,
      },
    });

    await sleep(500);

    navigate(`/activities/${activityId}/completed`);
  };

  const activityType = data?.activity?.type;

  useTrackActivityGA(data?.activity?.type, '7');

  const { level } = activities[activityType] || {};

  const {
    title,
    sub_title: subTitle,
    audio_instructions_8: audioInstructions,
    help_text_8: helpText,
    short_instructions_8: shortInstructions,
    step_label_8: stepLabel,
  } =
    cmsData?.cmsContent?.activity?.find(
      activity =>
        activity.id ===
        (cmsData?.user?.currentLevel === 4 &&
        data?.activity?.status === ACTIVITY_STATUS.COMPLETED
          ? `ongoing_${activityType}`
          : activityType),
    ) ||
    cmsData?.cmsContent?.activity?.find(
      activity => activity.id === activityType,
    ) ||
    {};

  return (
    <Layout
      title={`${extractPrismicString(title)}`}
      loading={loading || loadingInterviewQuestions || loadingResults}
      hasFooterBar
    >
      <Container>
        <Section>
          {!!(hasContent(shortInstructions) || hasContent(helpText)) && (
            <Grid>
              <GridItem width={60}>
                <TextContent redh6 smallMargin>
                  {extractPrismicContent(shortInstructions)}
                </TextContent>
              </GridItem>

              <GridItem width={40}>
                {!!(audioInstructions?.url || hasContent(helpText)) && (
                  <Notification showUser noMarginBottom>
                    {extractPrismicContent(helpText)}

                    {!!audioInstructions?.url && (
                      <AudioPlayer fileUrl={audioInstructions?.url} />
                    )}
                  </Notification>
                )}
              </GridItem>
            </Grid>
          )}
        </Section>

        <Section smallPadding>
          <TextContent redh6 smallMargin>
            <h6>Step 8</h6>
            {extractPrismicContent(stepLabel)}
          </TextContent>
        </Section>

        <Table
          cols={[
            {
              id: 'title',
              value: 'Category',
              medium: true,
              transformer: value => <strong>{value}</strong>,
            },
            { id: 'description1', value: 'Question' },
          ]}
          data={dataInterviewQuestions?.interviewQuestions}
          actions={[
            {
              id: 'remove',
              text: 'remove',
              icon: <RemoveIcon />,
              onClick: handleDeleteInterviewQuestion,
              loading: loadingDeleteInterviewQuestion,
            },
          ]}
          form={{
            fields: formFields,
            loading: loadingAddInterviewQuestion,
            validationSchema: formValidationSchema,
            initialValues: formInitialValues,
            onSubmit: handleNewInterviewQuestion,
          }}
          editable
          updateForm={{
            fields: formFields,
            loading: loadingUpdateInterviewQuestion,
            validationSchema: formValidationSchema,
            onSubmit: handleUpdateInterviewQuestion,
          }}
          noMargin
        />

        {!!(
          error ||
          errorAddInterviewQuestion ||
          errorDeleteInterviewQuestion ||
          errorUpdateInterviewQuestion
        ) && (
          <Notification type="red">
            {extractGraphQLError(
              error ||
                errorAddInterviewQuestion ||
                errorDeleteInterviewQuestion ||
                errorUpdateInterviewQuestion,
            )}
          </Notification>
        )}
      </Container>

      <FooterBar
        title={extractPrismicString(title)}
        subTitle={extractPrismicString(subTitle)}
        primaryButton={{
          label: 'Complete Activity',
          onClick: handleCompleteActivity,
          loading: loadingUpdateActivity,
          disabled: !isValid,
          level,
        }}
        secondaryButton={{
          label: 'Back to Company Culture and Structure',
          to: `/activities/${activityId}/interview_guide/4`,
        }}
      />
    </Layout>
  );
}

ActivityInterviewGuideStep7.propTypes = {
  activityId: PropTypes.string,
};

ActivityInterviewGuideStep7.defaultProps = {
  activityId: null,
};

export default ActivityInterviewGuideStep7;
