import gql from 'graphql-tag';

const GET_GOALS = gql`
  query Goals($activityId: ID) {
    goals(activityId: $activityId) {
      id
      title
      description1
      description2
      description3
      description4
      description5
      ranking
      type
    }
  }
`;

const ADD_GOAL = gql`
  mutation AddGoal(
    $activityId: ID!
    $title: String!
    $description1: String
    $description2: String
    $description3: String
    $description4: String
    $description5: String
    $ranking: Int
    $type: String!
  ) {
    addGoal(
      activityId: $activityId
      title: $title
      description1: $description1
      description2: $description2
      description3: $description3
      description4: $description4
      description5: $description5
      ranking: $ranking
      type: $type
    ) {
      id
      title
      description1
      description2
      description3
      description4
      description5
      ranking
      type
    }
  }
`;

const UPDATE_GOAL = gql`
  mutation UpdateGoal(
    $id: ID!
    $title: String
    $description1: String
    $description2: String
    $description3: String
    $description4: String
    $description5: String
    $ranking: Int
  ) {
    updateGoal(
      id: $id
      title: $title
      description1: $description1
      description2: $description2
      description3: $description3
      description4: $description4
      description5: $description5
      ranking: $ranking
    ) {
      id
      title
      description1
      description2
      description3
      description4
      description5
      ranking
      type
    }
  }
`;

const DELETE_GOAL = gql`
  mutation DeleteGoal($id: ID!) {
    deleteGoal(id: $id) {
      id
      title
      description1
      description2
      description3
      description4
      description5
      ranking
      type
    }
  }
`;

export { GET_GOALS, ADD_GOAL, UPDATE_GOAL, DELETE_GOAL };
