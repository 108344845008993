import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TextContent from './TextContent';
import { extractPrismicContent } from '../utils/prismic';

import cardBase from '../theme/cardBase';

const Wrapper = styled.div`
  ${cardBase};
  padding: 0;
`;

const Inner = styled.div`
  border-bottom: solid 1px ${props => props.theme.colors.grey200};
  padding: ${props => props.theme.spacing.midLarge};

  &:last-child {
    border-bottom: none;
  }
`;

function InterviewQuestionList({ questions }) {
  return (
    <Wrapper>
      {questions.map(({ text }) => (
        <Inner>
          <TextContent>{extractPrismicContent(text)}</TextContent>
        </Inner>
      ))}
    </Wrapper>
  );
}

InterviewQuestionList.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default InterviewQuestionList;
