const getColorFromRating = ({ rating, theme }) => {
  if (rating > 3) {
    return theme.colors.green500;
  }

  if (rating < 3) {
    return theme.colors.red500;
  }

  return theme.colors.yellow500;
};

export default getColorFromRating;
