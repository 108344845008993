import React from 'react';

import PropTypes from 'prop-types';

import ActivityGridItem from './ActivityGridItem';
import Container from './Container';
import Section from './Section';
import TextContent from './TextContent';
import LevelText from './LevelText';
import Notification from './Notification';
import Grid from './Grid';
import LevelStatusCircle from './LevelStatusCircle';

import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';

function OngoingActivityList({ activities, cmsContent, level, ...props }) {
  const {
    title,
    intro_text: introText,
    quote,
    quote_citation: quoteCite,
  } = cmsContent?.level?.find(({ id }) => id === `${level}`) || {};

  return (
    <Section white id="level-4">
      <Container>
        <LevelStatusCircle level={`${level}`} {...props} />

        <LevelText>
          <TextContent maxHeight={90} smallMargin noMarginTop noFloat>
            <h6>Level {level}</h6>
            <h2>{extractPrismicString(title)}</h2>

            {extractPrismicContent(introText)}

            {hasContent(quote) && (
              <Notification type="green">
                <blockquote>{extractPrismicString(quote)}</blockquote>
                {hasContent(quoteCite) && (
                  <cite> {extractPrismicString(quoteCite)}</cite>
                )}
              </Notification>
            )}
          </TextContent>
        </LevelText>

        <Grid>
          {activities.map((activity) => (
            <ActivityGridItem
              cmsContent={cmsContent}
              {...props}
              {...activity}
            />
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

OngoingActivityList.propTypes = {
  level: PropTypes.number.isRequired,
  activities: PropTypes.arrayOf(PropTypes.shape).isRequired,
  cmsContent: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default OngoingActivityList;
