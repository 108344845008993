import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';

import Container from './Container';
import ProgressBar from './ProgressBar';
import Button from './Button';

import backgroundImageBase from '../theme/backgroundImageBase';

import { GET_CMS_CONTENT } from '../graph/cms';

import { extractPrismicString } from '../utils/prismic';

const Wrapper = styled.section`
  ${backgroundImageBase};
  box-shadow: ${props => props.theme.base.shadow};

  &:before {
    bottom: 25px;
  }

  ${props =>
    props.backgroundImage &&
    `
     background-image: url(${props.backgroundImage});
  `}

  ${props =>
    props.fullHero &&
    `
    display: flex;
    flex-direction: column;
    min-height: calc(
    100vh - ${props.theme.components.headerHeight});
  `}
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing.midLarge}
    ${props => props.theme.spacing.base};
  margin: auto;
  position: relative;
  z-index: 1;

  ${props => props.theme.breakpoints.medium`
    padding: ${props.theme.spacing.large};
    min-height: 240px;
  `}

  p {
    max-width: 540px;
  }

  svg {
    width: 40px;
    height: 40px;
    display: block;
    margin: 0 auto ${props => props.theme.spacing.midLarge};
    fill: ${props => props.theme.colors.white};
  }

  a {
    margin-top: ${props => props.theme.spacing.large};
  }
`;

const Title = styled.h1`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSizes.xxxLarge};
  font-weight: ${props => props.theme.fontWeights.normal};
  line-height: 1.2;
  font-family: ${props => props.theme.base.altFontFamily};
  max-width: 740px;

  &:last-child {
    margin-bottom: 0;
  }

  p {
    margin: 0;
    width: 100%;
    max-width: none;
  }

  strong {
    color: inherit;
    font-weight: inherit;
  }

  ${props => props.theme.breakpoints.medium`
    font-size: ${props.theme.fontSizes.xxxxLarge};
  `}
`;

function Hero({
  title,
  subTitle,
  renderHero,
  noProgressBar,
  fullHero,
  icon,
  to,
  actionLabel,
  backgroundImage,
}) {
  const { data } = useQuery(GET_CMS_CONTENT, { fetchPolicy: 'cache-only' });

  const level = data?.user?.currentLevel;
  const isAdmin = data?.user?.isAdmin;

  const { title: levelTitle } =
    data?.cmsContent?.level?.find(({ id }) => id === `${level}`) || {};

  return (
    <Wrapper fullHero={fullHero} backgroundImage={backgroundImage}>
      {!!(title || subTitle) && (
        <Inner>
          <Container>
            {icon && icon}
            <Title level={level}>{title}</Title>
            {!!subTitle && <p>{subTitle}</p>}
            {to && (
              <Button level={level} to="/activities">
                {actionLabel}
              </Button>
            )}
          </Container>
        </Inner>
      )}

      {renderHero && renderHero()}

      {!isAdmin && !noProgressBar && (
        <ProgressBar
          level={level}
          activitiesComplete={data?.user?.completedActivities?.[level - 1]}
          levelTitle={extractPrismicString(levelTitle)}
        />
      )}
    </Wrapper>
  );
}

Hero.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  renderHero: PropTypes.string,
  noProgressBar: PropTypes.bool,
  fullHero: PropTypes.bool,
  icon: PropTypes.node,
  to: PropTypes.string,
  actionLabel: PropTypes.string,
  backgroundImage: PropTypes.string,
};

Hero.defaultProps = {
  title: null,
  subTitle: null,
  renderHero: undefined,
  noProgressBar: false,
  fullHero: false,
  icon: undefined,
  to: null,
  actionLabel: null,
  backgroundImage: null,
};

export default Hero;
