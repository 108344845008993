import React, { memo, useState } from 'react';

import PropTypes from 'prop-types';

import TableCell from './TableCell';
import TableAction from './TableAction';
import TableForm from './TableForm';

import { ReactComponent as EditIcon } from '../assets/edit-icon.svg';

function TableRow({
  cols,
  data,
  editable,
  actions,
  updateForm,
  id,
  ...props
}) {
  const [editing, setEditing] = useState(false);

  const handleUpdate = async formData => {
    await updateForm?.onSubmit({
      variables: {
        ...formData,
        id,
      },
    });

    setEditing(false);
  };
  if (!data) return false;

  if (editing) {
    return (
      <td colSpan={(cols?.length || 0) + (actions?.length || 0) + 1}>
        <TableForm
          totalCols={1}
          white
          {...updateForm}
          initialValues={data}
          onSubmit={handleUpdate}
          update
          outerButton
        />
      </td>
    );
  }

  return (
    <>
      {cols.map(({ id: fieldId, transformer }) => (
        <TableCell
          key={fieldId}
          id={id}
          value={data[fieldId]}
          transformer={transformer}
          {...props}
        />
      ))}

      {!!editable && (
        <TableCell xSmall>
          <TableAction
            onClick={() => setEditing(true)}
            text="Edit"
            icon={<EditIcon />}
          />
        </TableCell>
      )}
    </>
  );
}

TableRow.propTypes = {
  data: PropTypes.shape({}),
  cols: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  editable: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.shape({})),
  updateForm: PropTypes.shape({}),
  onUpdate: PropTypes.func,
  id: PropTypes.string.isRequired,
};

TableRow.defaultProps = {
  data: null,
  editable: false,
  actions: [],
  updateForm: {},
  onUpdate: undefined,
};

export default memo(TableRow);
