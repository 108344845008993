import gql from 'graphql-tag';

const GET_VALUES = gql`
  query Activities($activityId: ID!) {
    values(activityId: $activityId) {
      id
      type
      ranking
    }
  }
`;

const ADD_VALUE = gql`
  mutation AddValue($activityId: ID!, $type: String!, $ranking: Int!) {
    addValue(activityId: $activityId, type: $type, ranking: $ranking) {
      id
      type
      ranking
    }
  }
`;

const UPDATE_VALUE = gql`
  mutation UpdateValue($id: ID!, $ranking: Int!) {
    updateValue(id: $id, ranking: $ranking) {
      id
      type
      ranking
    }
  }
`;

export { GET_VALUES, ADD_VALUE, UPDATE_VALUE };
