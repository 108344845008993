import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash.startcase';

import AdvancedBarList from './AdvancedBarList';

function PersonalityTraitVisualisation({ data }) {
  const transformedData = data?.traits?.map(item => {
    return {
      label: startCase(item?.type),
      value: item.value,
    };
  });

  return (
    <AdvancedBarList data={transformedData} calculateWidth={false} showValue />
  );
}

PersonalityTraitVisualisation.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  cmsContent: PropTypes.shape({}).isRequired,
};
export default PersonalityTraitVisualisation;
