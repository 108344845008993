import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import AudioPlayer from '../components/AudioPlayer';
import Layout from '../components/Layout';
import Container from '../components/Container';
import Notification from '../components/Notification';
import TextContent from '../components/TextContent';
import Section from '../components/Section';
import FooterBar from '../components/FooterBar';
import Grid from '../components/Grid';
import GridItem from '../components/GridItem';
import ValueRankingActivity from '../components/ValueRankingActivity';

import { GET_ACTIVITY_DETAILS, UPDATE_ACTIVITY } from '../graph/activities';
import { GET_CMS_CONTENT } from '../graph/cms';

import extractGraphQLError from '../utils/extractGraphQLError';
import sleep from '../utils/sleep';
import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';
import useTrackActivityGA from '../utils/useTrackActivityGA';

import { activities, ACTIVITY_STATUS } from '../config/levels';

function ActivityValues({ activityId }) {
  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data, loading } = useQuery(GET_ACTIVITY_DETAILS, {
    variables: { id: activityId },
  });

  const activityType = data?.activity?.type;

  useTrackActivityGA(data?.activity?.type, '1');

  const { level } = activities[activityType] || {};

  const {
    title,
    sub_title: subTitle,
    instructions,
    audio_instructions_1: audioInstructions,
    help_text_1: helpText,
    short_instructions_1: shortInstructions,
  } =
    cmsData?.cmsContent?.activity?.find(
      activity =>
        activity.id ===
        (cmsData?.user?.currentLevel === 4 &&
        data?.activity?.status === ACTIVITY_STATUS.COMPLETED
          ? `ongoing_${activityType}`
          : activityType),
    ) ||
    cmsData?.cmsContent?.activity?.find(
      activity => activity.id === activityType,
    ) ||
    {};

  const [
    handleUpdateActivity,
    { loading: loadingUpdateActivity, error },
  ] = useMutation(UPDATE_ACTIVITY);

  const handleCompleteActivity = async () => {
    await handleUpdateActivity({
      variables: {
        id: activityId,
        status: ACTIVITY_STATUS.COMPLETED,
      },
    });

    await sleep(500);

    navigate(`/activities/${activityId}/completed`);
  };

  return (
    <Layout
      title={`${extractPrismicString(title)}`}
      loading={loading}
      hasFooterBar
    >
      <Container>
        <Section>
          {!!(hasContent(shortInstructions) || hasContent(helpText)) && (
            <Grid>
              <GridItem width={60}>
                <TextContent redh6 smallMargin>
                  {extractPrismicContent(shortInstructions)}
                </TextContent>
              </GridItem>

              <GridItem width={40}>
                {!!(audioInstructions?.url || hasContent(helpText)) && (
                  <Notification showUser noMarginBottom>
                    {extractPrismicContent(helpText)}

                    {!!audioInstructions?.url && (
                      <AudioPlayer fileUrl={audioInstructions?.url} />
                    )}
                  </Notification>
                )}
              </GridItem>
            </Grid>
          )}
        </Section>

        {!!error && (
          <Notification type="red">{extractGraphQLError(error)}</Notification>
        )}

        <ValueRankingActivity
          values={cmsData?.cmsContent?.value}
          activityId={activityId}
        />
      </Container>

      <FooterBar
        title={extractPrismicString(title)}
        subTitle={extractPrismicString(subTitle)}
        primaryButton={{
          label: 'Complete Activity',
          onClick: handleCompleteActivity,
          loading: loadingUpdateActivity,
          level,
        }}
        secondaryButton={{
          label: hasContent(instructions)
            ? 'Back to instructions'
            : 'Back to introduction',
          to: `/activities/${activityId}/${
            hasContent(instructions) ? 'instructions' : 'introduction'
          }`,
        }}
      />
    </Layout>
  );
}

ActivityValues.propTypes = {
  activityId: PropTypes.string,
};

ActivityValues.defaultProps = {
  activityId: null,
};

export default ActivityValues;
