import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import buttonBase from '../theme/buttonBase';
import getButtonElement from '../utils/getButtonElement';

const Wrapper = styled.a`
  ${({ theme, inactive, active }) => `

  ${buttonBase}

  border-radius: ${theme.base.radius};
  background-color: transparent;
  border: solid 1px ${theme.colors.grey300};
  min-width: 0;
  text-align: center;
  font-size: ${theme.fontSizes.xLarge};
  margin: ${theme.spacing.xxSmall};

  ${props =>
    props.theme.breakpoints.medium`
    margin: ${theme.spacing.base} ${theme.spacing.xSmall} 0;
  `}

  &:hover {
    background-color: transparent;
    border-color: ${theme.colors.green500};
  }

  ${
    active
      ? `
      background-color: transparent;
      border-color: ${theme.colors.green500};
    `
      : ``
  }

  ${
    inactive
      ? `
      opacity: 0.3;
    `
      : ``
  }
`}
`;

function EmojiButton({ children, loading, disabled, ...props }) {
  const elementType = getButtonElement(props);
  const ElementType = Wrapper.withComponent(elementType);

  return (
    <ElementType {...props} inactive={disabled || loading}>
      {children}
    </ElementType>
  );
}

EmojiButton.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

EmojiButton.defaultProps = {
  loading: false,
  disabled: false,
};

export default memo(EmojiButton);
