import React, { memo } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import { Formik } from 'formik';

import Button from './Button';
import Notification from './Notification';
import TextField from './TextField';
import TextButton from './TextButton';

import extractGraphQLError from '../utils/extractGraphQLError';

import { ADD_COUPON, REMOVE_COUPON } from '../graph/payment';
import { GET_USER } from '../graph/auth';

const FormWrapper = styled.form`
  width: 100%;
`;

const InlineForm = styled.div`
  ${(props) => props.theme.breakpoints.small`
    align-items: center;
    display: flex;
  `}
`;

const InlineFormField = styled.div`
  margin: 0 0 ${(props) => props.theme.spacing.xSmall} 0;

  ${(props) => props.theme.breakpoints.small`
    flex: 1 0 auto;
    margin: 0 ${props.theme.spacing.base} 0 0;
  `}
`;

const InlineFormButton = styled.div`
  flex: 0 0 auto;
`;

const InlineText = styled.div`
  ${(props) => props.theme.breakpoints.small`
    align-items: center;
    display: flex;
    justify-content: space-between;
  `}
`;

const couponFormInitialValues = {
  couponId: '',
};

function CouponForm() {
  const [handleAddCoupon, { loading, error }] = useMutation(ADD_COUPON);
  const [handleRemoveCoupon, { loading: loadingRemove, error: errorRemove }] =
    useMutation(REMOVE_COUPON);

  const { data } = useQuery(GET_USER, { fetchPolicy: 'cache-only' });

  const onSubmit = (values) => {
    handleAddCoupon({ variables: values });
  };

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      initialValues={couponFormInitialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => {
        return (
          <FormWrapper onSubmit={handleSubmit} noValidate>
            {data?.user?.coupon ? (
              <Notification type="green">
                <InlineText>
                  <span>
                    {' '}
                    Discount applied: <strong>{data?.user?.coupon}</strong>{' '}
                  </span>
                  <TextButton
                    onClick={handleRemoveCoupon}
                    type="button"
                    disabled={loadingRemove}
                  >
                    Remove
                  </TextButton>
                </InlineText>
              </Notification>
            ) : (
              <Notification type="green">
                <InlineForm>
                  <InlineFormField>
                    <TextField
                      name="couponId"
                      id="couponId"
                      type="text"
                      label="Add discount code"
                      placeholder="Add discount code"
                      hideLabel
                      noMargin
                      small
                    />
                  </InlineFormField>

                  <InlineFormButton>
                    <Button
                      type="submit"
                      disabled={loading}
                      fullWidth
                      small
                      green
                    >
                      Add
                    </Button>
                  </InlineFormButton>
                </InlineForm>
              </Notification>
            )}

            {!!(error || errorRemove) && (
              <Notification type="red">
                {extractGraphQLError(error || errorRemove)}
              </Notification>
            )}
          </FormWrapper>
        );
      }}
    />
  );
}

export default memo(CouponForm);
