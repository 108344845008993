import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TextContentWraper = styled.div`
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: ${props => props.theme.spacing.midLarge};
    margin-bottom: ${props => props.theme.spacing.base};

    &:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    max-width: 1024px;
  }

  a {
    color: ${props => props.theme.colors.blue500};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  strong {
    color: ${props => props.theme.colors.red500};
  }

  .highlight-text {
    color: ${props => props.theme.colors.red500};
  }

  .bold-black-text {
    color: ${props => props.theme.colors.black};
    font-weight: ${props => props.theme.fontWeights.bold};
    font-size: ${props => props.theme.fontSizes.medium};
  }

  .strikeout {
    text-decoration: line-through;
  }

  ${props =>
    props.white &&
    `
      p, ul, li, h1, h2, h3, h4, h5 {
        color: ${props.theme.colors.white};
      }
    `}

  ul, ol {
    display: flex;
    flex-wrap: wrap;
    margin: -${props => props.theme.spacing.small} -${props =>
  props.theme.spacing.base};

    li {
      flex: 0 0 100%;
      padding: ${props => props.theme.spacing.small}
        ${props => props.theme.spacing.base};
      width: 100%;

      ${props => props.theme.breakpoints.medium`
        flex: 0 0 50%;
        width: 50%;
      `}

      ${props => props.theme.breakpoints.large`
        flex: 0 0 33.33%;
        width: 33.33%;
      `}
    }
  }

  ul {
    li {
      position: relative;
      padding-left: 64px;

      &:before {
        flex: 0 0 28px;
        content: '';
        width: 28px;
        height: 28px;
        display: block;
        background-image: url('/list-tick-icon.svg');
        position: absolute;
        left: ${props => props.theme.spacing.base};
      }
    }
  }

  ol {
    counter-reset: custom-counter;

    li {
      counter-increment: custom-counter;

      &:before {
        display: block;
        content: counter(custom-counter) '. ';
        color: ${props => props.theme.colors.red500};
        font-family: ${props => props.theme.base.altFontFamily};
        font-weight: ${props => props.theme.fontWeights.normal};
        font-size: ${props => props.theme.fontSizes.xxxLarge};
        line-height: 1;
        margin-bottom: ${props => props.theme.spacing.xSmall};
      }
    }
  }

  ${props =>
    props.fullWidthUl &&
    props.theme.breakpoints.medium`
      ul li {
        flex: 0 0 100%;
        width: 100%;
      }
  `}

  ${props =>
    props.fullWidthUl &&
    props.theme.breakpoints.large`
      ul li {
        flex: 0 0 100%;
        width: 100%;
      }
    `}


${props =>
  props.marginBottom &&
  `
      margin-bottom: ${props.theme.spacing.large};
    `}
`;

function TextContent({ children, ...props }) {
  return <TextContentWraper {...props}>{children}</TextContentWraper>;
}

TextContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TextContent;
