import React from 'react';
import PropTypes from 'prop-types';
import { useApolloClient, useQuery } from '@apollo/react-hooks';

import Card from './Card';
import TextArea from './TextArea';

import { GET_FORM_DATA } from '../graph/cache';

function VisionActivity({ activity }) {
  const client = useApolloClient();
  const { data } = useQuery(GET_FORM_DATA);

  const handleChange = (e) => {
    client.cache.writeQuery({
      query: GET_FORM_DATA,
      data: {
        formData: {
          vision: data?.formData.vision,
          visionSummary: data?.formData.visionSummary,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  return (
    <Card>
      <TextArea
        id="vision"
        name="vision"
        label="What would success in your career look like?"
        value={data?.formData.vision || activity?.vision}
        hintText={`${
          (data?.formData.vision !== undefined
            ? data?.formData.vision
            : activity?.vision
          )
            ?.split(' ')
            ?.filter((val) => val !== '').length
        } of at least 100 words`}
        rows="15"
        onChange={handleChange}
      />

      <TextArea
        id="visionSummary"
        name="visionSummary"
        label="Summarise your vision into a 1-3 sentences/ statement"
        value={data?.formData.visionSummary || activity?.visionSummary}
        hintText={`${
          (data?.formData.visionSummary !== undefined
            ? data?.formData.visionSummary
            : activity?.visionSummary
          )
            ?.split(' ')
            ?.filter((val) => val !== '').length
        } of at least 20 words`}
        rows="5"
        onChange={handleChange}
      />
    </Card>
  );
}

VisionActivity.propTypes = {
  activity: PropTypes.shape({}).isRequired,
};

VisionActivity.defaultProps = {};

export default VisionActivity;
