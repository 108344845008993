import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ResponsivePie } from '@nivo/pie';

import { CULTURES } from '../config/types';

import main, { colors } from '../theme/main';

const getColour = (culture, data) => {
  if (culture === data.culture) {
    return colors.red500;
  }

  if (data.altCultures?.includes(culture)) {
    return colors.red400;
  }

  return colors.grey200;
};

const ChartWrapper = styled.div`
  height: calc(
    ${(props) => props.theme.components.chartHeight} +
      ${(props) => props.theme.spacing.midLarge} +
      ${(props) => props.theme.spacing.midLarge}
  );
  margin: -${(props) => props.theme.spacing.midLarge};
  position: relative;
`;

function CultureVisualisation({ data }) {
  const cultureArray = Object.entries(CULTURES);
  const transformedCultureArray = cultureArray.map(([culture, label], i) => {
    return {
      i,
      id: label,
      label,
      value: 100 / cultureArray.length,
      color: getColour(culture, data),
    };
  });

  return (
    <ChartWrapper>
      <ResponsivePie
        data={transformedCultureArray}
        innerRadius={0.4}
        enableSlicesLabels={false}
        colors={{ datum: 'data.color' }}
        radialLabelsTextColor={colors.grey500}
        radialLabelsLinkColor={{ from: 'color' }}
        margin={{
          top: 40,
          right: 90,
          bottom: 40,
          left: 110,
        }}
        padAngle={1}
        animate
        isInteractive={false}
        theme={{
          textColor: colors.grey500,
          fontFamily: main.base.fontFamily,
        }}
      />
    </ChartWrapper>
  );
}

CultureVisualisation.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

CultureVisualisation.defaultProps = {};

export default CultureVisualisation;
