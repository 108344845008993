import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'formik';

import FieldWrapper from './FieldWrapper';

import fieldBase from '../theme/fieldBase';

import { ReactComponent as DownIcon } from '../assets/down-icon.svg';

const SelectWrapper = styled.div`
  ${fieldBase};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  padding-right: 50px;

  ${props =>
    props.error &&
    `
    border-color: ${props.theme.colors.red500};
  `}

  ${props =>
    props.modifiers.small &&
    `
    padding: 9px 50px 8px 14px;
    font-size: ${props.theme.fontSizes.small};
    height: 40px;
  `}

  svg {
    position: absolute;
    pointer-events: none;
    fill: ${props => props.theme.colors.green500};
    width: 10px;
    height: 10px;
    right: ${props => props.theme.spacing.small};
    top: 50%;
    transform: translateY(-50%);
  }

  select {
    cursor: pointer;
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
`;

function SelectField({
  name,
  hintText,
  disabled,
  readOnly,
  id,
  label,
  defaultLabel,
  options,
  small,
  hideLabel,
  noMargin,
  ...props
}) {
  return (
    <Field
      name={name}
      render={({ field, form: { errors, touched, submitCount } }) => {
        const errorMessage =
          ((touched && touched[field.name]) || submitCount > 0) &&
          errors &&
          errors[field.name];

        const selectedOption = options.find(
          option => option.value === field.value,
        );

        return (
          <FieldWrapper
            errorMessage={errorMessage}
            name={name}
            label={label}
            hintText={hintText}
            hideLabel={hideLabel}
            noMargin={noMargin}
            {...props}
          >
            <SelectWrapper modifiers={{ error: !!errorMessage, small }}>
              {selectedOption?.label || field.value || defaultLabel}
              <select
                name={name}
                disabled={disabled}
                readOnly={readOnly}
                {...field}
                id={id}
                value={field.value}
              >
                <option value="">{defaultLabel}</option>

                {options.map(option => (
                  <option key={option.id} value={option.value}>
                    {option.label || option.value}
                  </option>
                ))}
              </select>

              <DownIcon />
            </SelectWrapper>
          </FieldWrapper>
        );
      }}
    />
  );
}

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  hintText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  defaultLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hideLabel: PropTypes.bool,
  noMargin: PropTypes.bool,
  small: PropTypes.bool,
};

SelectField.defaultProps = {
  disabled: false,
  hintText: null,
  readOnly: false,
  hideLabel: false,
  noMargin: false,
  small: false,
};

export default SelectField;
