import React from 'react';
import Reorder from 'react-reorder';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import TableAction from './TableAction';
import TableCell from './TableCell';
import TableRow from './TableRow';
import TableForm from './TableForm';
import { ReactComponent as DownArrow } from '../assets/down-arrow.svg';
import { ReactComponent as UpArrow } from '../assets/up-arrow.svg';

import isArrayWithLength from '../utils/isArrayWithLength';
import cardBase from '../theme/cardBase';

import { ReactComponent as DraggableIcon } from '../assets/draggable-icon.svg';
import { hideText } from '../theme/utils';

const TableContainer = styled.div`
  ${cardBase};
  overflow: scroll;
  width: 100%;
  margin: ${(props) => props.theme.spacing.medium} 0;
  max-width: ${(props) => props.theme.components.tableMaxWidth};
  padding: 0;
`;

const TableWrapper = styled.table(
  ({ theme, noMargin }) => `
    border-spacing: 0;
    table-layout: fixed;
    width: 100%;
    min-width: 580px;

    thead {
      td {
        color: ${theme.colors.black};
        font-weight: ${theme.fontWeights.bold};
      }
    }

    tfoot {
     & > tr {
        & > td {
          padding: 0;
        }
      }
    }

    ${
      noMargin &&
      `
      margin: 0;
    `
    }
  `,
);

const Row = styled.tr`
  ${(props) =>
    props.sortable &&
    `
  background-color: ${props.theme.colors.white};
  cursor: grab;
  width: 100%;
  transition: background-color ${props.theme.base.transitionSpeed}
    ${props.theme.easing.move};
  
  td {
    & > svg {
      fill: ${props.theme.colors.grey200};
      width: ${props.theme.spacing.base};
      height: ${props.theme.spacing.base};
      display: block;
    }
  }
 
  &:hover {
    td {
      & > svg {
        fill: ${props.theme.colors.green500};
      }
    }
  }

  &.dragged {
    background-color: ${props.theme.colors.green100};
    z-index: ${props.theme.layers.modal};

    
    td {
      svg {
   
      }
      
      button {
        visibility: hidden;
      }

      & > svg {
        fill: ${props.theme.colors.green500};
        visibility: visible;
      }
    }
    
    td {
      ${hideText};
      border-bottom: none;
    }
  }

  &.placeholder {
    background-color: ${props.theme.colors.grey200};
    opacity: 0.5;

    td {
      ${hideText};

      svg {
        visibility: hidden;
      }

      button {
        visibility: hidden;
      }
    }
  }
  `}
`;

function Table({
  cols,
  data,
  actions,
  onReorder,
  form,
  sortable,
  redorderId,
  editable,
  updateForm,
  noMargin,
  hasSelectable,
  onSelectItemClick,
  selectedItems = [],
  sortBy,
  onSorting,
}) {
  const renderContent = React.useCallback(() => {
    if (!isArrayWithLength(data)) return false;
    return data.map(({ id, ...item }, i) => {
      return (
        <Row key={id} sortable={sortable}>
          {hasSelectable && (
            <TableCell small>
              <input
                type="checkbox"
                checked={selectedItems.includes(id)}
                onChange={(e) => onSelectItemClick(id, e.currentTarget.checked)}
              />
            </TableCell>
          )}
          {sortable && (
            <TableCell small>
              <DraggableIcon />
            </TableCell>
          )}

          <TableRow
            id={id}
            i={i}
            cols={cols}
            data={item}
            editable={editable}
            redorderId={redorderId}
            updateForm={updateForm}
          />

          {!!isArrayWithLength(actions) &&
            actions.map(({ id: actionId, ...action }) => (
              <TableCell key={actionId} xSmall>
                <TableAction id={id} {...action} />
              </TableCell>
            ))}
        </Row>
      );
    });
  }, [
    data,
    sortable,
    hasSelectable,
    selectedItems,
    cols,
    editable,
    redorderId,
    updateForm,
    actions,
    onSelectItemClick,
  ]);

  return (
    <TableContainer>
      <TableWrapper cellspacing={0} cellpadding={0} noMargin={noMargin}>
        <thead>
          <tr>
            {hasSelectable && (
              <TableCell small>
                <input
                  type="checkbox"
                  checked={selectedItems.length === data.length}
                  onChange={(e) =>
                    onSelectItemClick('All', e.currentTarget.checked)}
                />
              </TableCell>
            )}
            {sortable && <TableCell small />}

            {isArrayWithLength(cols) &&
              cols.map(
                ({ id: colId, ...col }) =>
                  !!col.value && (
                    <TableCell
                      key={colId}
                      {...col}
                      onClick={() => onSorting && onSorting(col.value)}
                      transformer={
                        sortBy?.column
                          ? (value) => {
                              return (
                                <>
                                  {value}
                                  {sortBy.column === value &&
                                    sortBy.isAscending && (
                                      <DownArrow
                                        style={{
                                          fill: 'black',
                                          maxWidth: 10,
                                          marginLeft: 10,
                                        }}
                                      />
                                    )}
                                  {sortBy.column === value &&
                                    !sortBy.isAscending && (
                                      <UpArrow
                                        style={{
                                          fill: 'black',
                                          maxWidth: 10,
                                          marginLeft: 10,
                                        }}
                                      />
                                    )}
                                </>
                              );
                            }
                          : undefined
                      }
                    />
                  ),
              )}

            {editable && <TableCell xSmall />}

            {!!isArrayWithLength(actions) &&
              actions.map(({ id: actionId }) => (
                <TableCell key={actionId} xSmall />
              ))}
          </tr>
        </thead>

        {sortable ? (
          <Reorder
            reorderId={redorderId}
            onReorder={onReorder}
            component="tbody"
            disabled={!sortable}
            holdTime={150}
          >
            {renderContent()}
          </Reorder>
        ) : (
          <tbody>{renderContent()}</tbody>
        )}

        {!!form && (
          <TableForm
            totalCols={
              (cols?.length || 0) +
              (actions?.length || 0) +
              (sortable ? 1 : 0) +
              (editable ? 1 : 0)
            }
            sortable={sortable}
            actions={actions}
            editable={editable}
            {...form}
          />
        )}
      </TableWrapper>
    </TableContainer>
  );
}

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  actions: PropTypes.arrayOf(PropTypes.shape({})),
  cols: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onReorder: PropTypes.func,
  form: PropTypes.shape({}),
  sortable: PropTypes.bool,
  redorderId: PropTypes.string,
  editable: PropTypes.bool,
  updateForm: PropTypes.shape({}),
  noMargin: PropTypes.bool,
};

Table.defaultProps = {
  data: null,
  actions: null,
  onReorder: undefined,
  form: null,
  sortable: null,
  redorderId: 'id',
  editable: false,
  updateForm: null,
  noMargin: false,
};

export default Table;
