import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';

import Button from './Button';
import Portal from './Portal';
import TextContent from './TextContent';

const Wrapper = styled(animated.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(0,0,0,0.9)};
  z-index: ${(props) => props.theme.layers.modal};
  display: flex;
`;

const Inner = styled.div`
  margin: auto;
  max-width: ${(props) => props.theme.grid.maxWidthXnarrow};
  padding: ${(props) => props.theme.spacing.large};

  h1,
  h2,
  h3,
  h4,
  p,
  a {
    color: ${(props) => props.theme.colors.white};
  }

  button {
    margin: 0 ${(props) => props.theme.spacing.xSmall};
  }
`;

function ExternalActivityOverlay({
  title,
  open,
  externalurl,
  onContinueClick,
  onCancelClick,
  loading,
}) {
  const style = useSpring({
    opacity: open ? 1 : 0,
    visibility: open ? 'visible' : 'hidden',
  });

  return (
    <Portal>
      <Wrapper style={style}>
        <Inner>
          <TextContent center>
            <h1>External Activity</h1>
            <p>
              We've directed you to the external acivity for '{title}
              '', if the activity didn't load please{' '}
              <a href={externalurl} target="_blank" rel="noopener noreferrer">
                click here.
              </a>{' '}
              Once you're done click 'External Activity Completed' to move to
              the next activity or click 'Cancel' to come back later.
            </p>

            <Button onClick={onCancelClick} secondaryWhite>
              Cancel
            </Button>
            <Button onClick={onContinueClick} loading={loading}>
              External Activity Completed
            </Button>
          </TextContent>
        </Inner>
      </Wrapper>
    </Portal>
  );
}

ExternalActivityOverlay.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  externalurl: PropTypes.string.isRequired,
  onContinueClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ExternalActivityOverlay;
