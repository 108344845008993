import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import AudioPlayer from '../components/AudioPlayer';
import Layout from '../components/Layout';
import Container from '../components/Container';
import Notification from '../components/Notification';
import TextContent from '../components/TextContent';
import Section from '../components/Section';
import FooterBar from '../components/FooterBar';
import Grid from '../components/Grid';
import GridItem from '../components/GridItem';
import Table from '../components/Table';

import { GET_ACTIVITY_DETAILS, UPDATE_ACTIVITY } from '../graph/activities';
import { GET_CMS_CONTENT } from '../graph/cms';
import { GET_GOALS, UPDATE_GOAL } from '../graph/goals';

import extractGraphQLError from '../utils/extractGraphQLError';
import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';
import { validate, requiredString } from '../utils/validate';
import sleep from '../utils/sleep';
import useTrackActivityGA from '../utils/useTrackActivityGA';

import { activities, ACTIVITY_STATUS } from '../config/levels';

import { colors } from '../theme/main';

const goalFormValidationSchema = validate({
  title: requiredString,
});

const goalFormFields = [
  {
    id: 'ranking',
    small: true,
  },
  {
    id: 'title',
    name: 'title',
    label: 'Goal',
    type: 'textarea',
    placeholder: 'Enter your goal...',
  },
  {
    id: 'description1',
    name: 'description1',
    label: 'Specific',
    type: 'textarea',
    placeholder: 'Clearly define what you want to achieve',
  },
  {
    id: 'description2',
    name: 'description2',
    label: 'Measureable',
    type: 'textarea',
    placeholder: 'How will you measure your success?',
  },
  {
    id: 'description3',
    name: 'description3',
    label: 'Achievable',
    type: 'textarea',
    placeholder: 'Who are you achieving this goal for?',
  },
  {
    id: 'description4',
    name: 'description4',
    label: 'Relevant',
    type: 'textarea',
    placeholder:
      'Explain why this goal is important for you and how it moves you towards your vision',
  },
  {
    id: 'description5',
    name: 'description5',
    label: 'Timebound',
    type: 'textarea',
    placeholder: 'Describe the day, month and year you will achieve it by',
  },
];

function ActivityGoalsStepFour({ activityId }) {
  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data, loading } = useQuery(GET_ACTIVITY_DETAILS, {
    variables: { id: activityId },
  });

  const { data: goalData, loading: loadingGoals } = useQuery(GET_GOALS, {
    variables: { activityId },
  });

  const [
    handleUpdateActivity,
    { loading: loadingUpdateActivity, error },
  ] = useMutation(UPDATE_ACTIVITY);

  const handleCompleteActivity = async () => {
    await handleUpdateActivity({
      variables: {
        id: activityId,
        status: ACTIVITY_STATUS.COMPLETED,
      },
    });

    await sleep(500);

    navigate(`/activities/${activityId}/completed`);
  };

  const filteredGoals = goalData?.goals
    ?.filter(goal => {
      return goal.type === 'FINAL';
    })
    .sort((a, b) => a.ranking - b.ranking)
    .slice(0, 5);

  const isGoalsValid = filteredGoals?.length > 4;

  const [
    handleUpdateGoal,
    { loading: loadingUpdateGoal, error: errorUpdateGoal },
  ] = useMutation(UPDATE_GOAL, {
    refetchQueries: ['Goals'],
  });

  const activityType = data?.activity?.type;

  useTrackActivityGA(data?.activity?.type, '4');

  const { level } = activities[activityType] || {};

  const {
    title,
    sub_title: subTitle,
    audio_instructions_4: audioInstructions4,
    help_text_4: helpText4,
    short_instructions_4: shortInstructions4,
    step_label_4: stepInstructions4,
  } =
    cmsData?.cmsContent?.activity?.find(
      activity =>
        activity.id ===
        (cmsData?.user?.currentLevel === 4 &&
        data?.activity?.status === ACTIVITY_STATUS.COMPLETED
          ? `ongoing_${activityType}`
          : activityType),
    ) ||
    cmsData?.cmsContent?.activity?.find(
      activity => activity.id === activityType,
    ) ||
    {};

  return (
    <Layout
      title={`${extractPrismicString(title)}`}
      loading={loading || loadingGoals}
      hasFooterBar
    >
      <Container>
        <Section>
          {!!(hasContent(shortInstructions4) || hasContent(helpText4)) && (
            <Grid>
              <GridItem width={60}>
                <TextContent redh6 smallMargin>
                  {extractPrismicContent(shortInstructions4)}
                </TextContent>
              </GridItem>

              <GridItem width={40}>
                {!!(audioInstructions4?.url || hasContent(helpText4)) && (
                  <Notification showUser noMarginBottom>
                    {extractPrismicContent(helpText4)}

                    {!!audioInstructions4?.url && (
                      <AudioPlayer fileUrl={audioInstructions4?.url} />
                    )}
                  </Notification>
                )}
              </GridItem>
            </Grid>
          )}
        </Section>

        <TextContent redh6 smallMargin>
          <h6>Step 4</h6>
          <p>{extractPrismicString(stepInstructions4)}</p>
        </TextContent>

        <Table
          cols={[
            {
              id: 'ranking',
              value: 'Ranking',
              small: true,
              transformer: (value, i) => (
                <h3
                  style={{
                    color: colors.green500,
                  }}
                >
                  {i + 1}
                </h3>
              ),
            },
            { id: 'title', value: 'Goal' },
            { id: 'description1', value: 'Specific' },
            { id: 'description2', value: 'Measureable' },
            { id: 'description3', value: 'Achievable' },
            { id: 'description4', value: 'Relevant' },
            { id: 'description5', value: 'Timebound' },
          ]}
          data={filteredGoals}
          redorderId="goalId"
          editable
          updateForm={{
            fields: goalFormFields,
            validationSchema: goalFormValidationSchema,
            onSubmit: handleUpdateGoal,
            loading: loadingUpdateGoal,
          }}
        />

        {!!(error || errorUpdateGoal) && (
          <Notification type="red">
            {extractGraphQLError(error || errorUpdateGoal)}
          </Notification>
        )}
      </Container>

      <FooterBar
        title={extractPrismicString(title)}
        subTitle={extractPrismicString(subTitle)}
        primaryButton={{
          label: 'Complete Activity',
          onClick: handleCompleteActivity,
          disabled: !isGoalsValid,
          loading: loadingUpdateActivity,
          level,
        }}
        secondaryButton={{
          label: 'Back to rank goals',
          to: `/activities/${activityId}/goals/3`,
        }}
      />
    </Layout>
  );
}

ActivityGoalsStepFour.propTypes = {
  activityId: PropTypes.string,
};

ActivityGoalsStepFour.defaultProps = {
  activityId: null,
};

export default ActivityGoalsStepFour;
