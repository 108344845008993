import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useSpring, animated } from 'react-spring';

import Footer from './Footer';
import Loader from './Loader';

import backgroundImageBase from '../theme/backgroundImageBase';

import useSiteSettings from '../hooks/useSiteSettings';

const Main = styled.main`
  ${backgroundImageBase};
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing.base};
  min-height: 100vh;

  ${props => props.theme.breakpoints.small`
    padding: ${props.theme.spacing.base}
    ${props.theme.spacing.midLarge};
  `}

  ${props =>
    props.darker &&
    `
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0,0,0,0.3);
    }
  `}
`;

const Inner = styled(animated.div)`
  margin: auto;
  position: relative;
  width: 100%;
  z-index: 1;

  ${props => props.theme.breakpoints.small`
    max-width: 440px;
  `}

  ${props =>
    props.modifiers.large &&
    props.theme.breakpoints.small`
    max-width: 800px;
  `}
`;

const Header = styled.header`
  margin-bottom: ${props => props.theme.spacing.large};
  display: flex;
  justify-content: flex-end;

  svg {
    margin: 0 auto;
    display: block;
    fill: ${props => props.theme.colors.white};
    position: relative;
    z-index: 1;
    width: 150px;
  }
`;

function LayoutAuth({ title, children, loading, large, ...props }) {
  const { logoURL, siteName, bgImage } = useSiteSettings();

  const style = useSpring({
    opacity: loading ? 0 : 1,
    transform: loading ? 'translate(0, 50px)' : 'translate(0, 0px)',
  });

  return (
    <>
      <Helmet>
        <title>{`${siteName} - ${title}`}</title>
      </Helmet>

      <Main style={{ backgroundImage: `url(${bgImage})` }} {...props}>
        <Header>
          <img src={logoURL} alt={siteName} />
        </Header>

        {loading && <Loader white />}

        <Inner style={style} modifiers={{ large }}>
          {children}
        </Inner>

        <Footer />
      </Main>
    </>
  );
}

LayoutAuth.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  large: PropTypes.bool,
};

LayoutAuth.defaultProps = {
  loading: false,
  large: false,
};

export default LayoutAuth;
