import { useQuery } from '@apollo/react-hooks';
import { GET_SITE_SETTINGS } from '../graph/siteSettings';

const INIT_SITE_SETTINGS = {
  logoURL: '',
  logoIconURL: '',
  favIconURL: '',
  siteName: '',
  privacyURL: '',
  siteURL: '',
  siteMetaDescription: '',
  termsAndConditionURL: '',
  disclaimerURL: '',
  linkedInURL: '',
  facebookURL: '',
  siteHost: '',
  instagramURL: '',
  profileBgImage: '',
  bgImage: '',
  bannerBgImage: '',
  supportEmail: '',
  resourcesLink: '/resources',
  isLoaded: false,
};

const useSiteSettings = () => {
  const { data } = useQuery(GET_SITE_SETTINGS);

  if (data?.siteSettings) {
    const url = new URL(data.siteSettings.siteURL);
    return { ...data.siteSettings, siteHost: url.origin, isLoaded: true };
  }

  return INIT_SITE_SETTINGS;
};

export default useSiteSettings;
