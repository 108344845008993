import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BarList from './BarList';

import transformArrayToBarChartObject from '../utils/transformArrayToBarChartObject';

const ChartWrapper = styled.div`
  height: ${props => props.theme.components.chartHeight};
  display: flex;
`;

function CompetenciesVisualisation({ data }) {
  const transformedData = transformArrayToBarChartObject(
    data?.competencies?.slice(0, 5),
    null,
    'value',
    'type',
    true,
    value => Math.round((value / data?.competencies[0].value) * 100),
  )?.slice(0, 5);

  return (
    <ChartWrapper>
      <BarList data={transformedData} calculateWidth={false} blue />
    </ChartWrapper>
  );
}

CompetenciesVisualisation.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default CompetenciesVisualisation;
