import gql from 'graphql-tag';

const GET_FORM_DATA = gql`
  query GetFormData {
    formData @client {
      vision
      visionSummary
    }
  }
`;

export { GET_FORM_DATA };
