import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ResponsiveRadar } from '@nivo/radar';
import startCase from 'lodash.startcase';

import main, { colors } from '../theme/main';

import { ORGANISATIONS } from '../config/types';

const ChartWrapper = styled.div`
  height: calc(
    ${props => props.theme.components.chartHeight} +
      ${props => props.theme.spacing.midLarge} +
      ${props => props.theme.spacing.midLarge}
  );
  margin: -${props => props.theme.spacing.midLarge};
`;

function OrganisationVisualisation({
  data: { status, id, new: isNew, __typename, ...values },
  largeMargin,
}) {
  const transformedData = Object.entries(values).map(([label, value]) => ({
    label: startCase(ORGANISATIONS[label]),
    'Culture Fit (%)': Math.floor(value / 8 * 100),
  }));

  return (
    <ChartWrapper>
      <ResponsiveRadar
        data={transformedData}
        indexBy="label"
        keys={['Culture Fit (%)']}
        maxValue={100}
        margin={{
          top: 40,
          right: largeMargin ? 110 : 90,
          bottom: 40,
          left: largeMargin ? 110 : 90,
        }}
        colors={colors.yellow500}
        radialLabelsTextColor={colors.grey500}
        enableDots
        animate
        isInteractive
        theme={{
          textColor: colors.grey500,
          fontFamily: main.base.fontFamily,
        }}
      />
    </ChartWrapper>
  );
}

OrganisationVisualisation.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  largeMargin: PropTypes.bool,
};

OrganisationVisualisation.defaultProps = {
  largeMargin: false,
};

export default OrganisationVisualisation;
