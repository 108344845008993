import React from 'react';
import PropTypes from 'prop-types';

import Grid from './Grid';
import GridItem from './GridItem';
import Card from './Card';
import formatCurrency from '../utils/formatCurrency';
import TextContent from './TextContent';
import Button from './Button';
import { extractPrismicString, extractPrismicContent } from '../utils/prismic';
import getValue from '../utils/getValue';

function ProductList({ products, cmsData, currency }) {
  return (
    <Grid>
      {products?.map(({ cmsId, id, ...productData }) => {
        const {
          title,
          sub_title: subTitle,
          short_description: shortDescription,
        } = cmsData.find(product => product.id === cmsId) || {};

        const hasDiscount = productData.discountedAmount !== null;
        const formattedPrice = formatCurrency(
          getValue(productData, currency),
          currency,
        );
        const formattedDiscountedPrice = formatCurrency(
          getValue(productData, currency, true),
          currency,
        );
        const priceWithDiscount = hasDiscount
          ? formattedDiscountedPrice
          : formattedPrice;

        return (
          <GridItem width={50} key={id} grow>
            <Card flex>
              <TextContent redh6 smallMargin noMarginTop>
                <h6>{extractPrismicString(subTitle)}</h6>
                <h1>{extractPrismicString(title)}</h1>
                <h2>
                  {priceWithDiscount}
                  {' '}
                  {!hasDiscount && <small>+ VAT</small>}
                  {hasDiscount && (
                  <small>
                    <strike>
                      {formattedPrice}
                      {' '}
                      + VAT
                    </strike>
                  </small>
                  )}
                </h2>
                <p>{extractPrismicContent(shortDescription)}</p>
                <Button to={`/payment/${id}`}>Buy</Button>
              </TextContent>
            </Card>
          </GridItem>
        );
      })}
    </Grid>
  );
}

ProductList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  cmsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currency: PropTypes.string.isRequired,
};

export default ProductList;
