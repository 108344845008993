import gql from 'graphql-tag';

const GET_INTERESTS = gql`
  query Interests($activityId: ID!) {
    interests(activityId: $activityId) {
      id
      title
      description1
      description2
      ranking
    }
  }
`;

const ADD_INTEREST = gql`
  mutation AddInterest(
    $activityId: ID!
    $title: String!
    $description1: String!
    $description2: String!
    $ranking: Int!
  ) {
    addInterest(
      activityId: $activityId
      title: $title
      description1: $description1
      description2: $description2
      ranking: $ranking
    ) {
      id
      title
      description1
      description2
      ranking
    }
  }
`;

const UPDATE_INTEREST = gql`
  mutation UpdateInterest(
    $id: ID!
    $title: String
    $description1: String
    $description2: String
    $ranking: Int
  ) {
    updateInterest(
      id: $id
      title: $title
      description1: $description1
      description2: $description2
      ranking: $ranking
    ) {
      id
      title
      description1
      description2
      ranking
    }
  }
`;

const DELETE_INTEREST = gql`
  mutation DeleteInterest($id: ID!) {
    deleteInterest(id: $id) {
      id
      title
      description1
      description2
      ranking
    }
  }
`;

export { GET_INTERESTS, ADD_INTEREST, UPDATE_INTEREST, DELETE_INTEREST };
