import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BarList from './BarList';
import Grid from './Grid';
import GridItem from './GridItem';

import transformArrayToBarChartObject from '../utils/transformArrayToBarChartObject';

const Wrapper = styled.div`
  width: 100%;
`;

function FullRoleVisualisation({ data }) {
  const transformedData = transformArrayToBarChartObject(data?.roles);

  return (
    <Wrapper>
      <Grid>
        <GridItem width={25} alignTop>
          <BarList
            data={transformedData.slice(0, 5)}
            calculateWidth={false}
            blue
            hideNumber
          />
        </GridItem>

        <GridItem width={25} alignTop>
          <BarList
            data={transformedData.slice(5, 10)}
            calculateWidth={false}
            hideNumber
          />
        </GridItem>

        <GridItem width={25} alignTop>
          <BarList
            data={transformedData.slice(10, 15)}
            calculateWidth={false}
            hideNumber
          />
        </GridItem>

        <GridItem width={25} alignTop>
          <BarList
            data={transformedData.slice(15, 20)}
            calculateWidth={false}
            hideNumber
          />
        </GridItem>
      </Grid>
    </Wrapper>
  );
}

FullRoleVisualisation.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default FullRoleVisualisation;
