import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import AudioPlayer from '../components/AudioPlayer';
import Layout from '../components/Layout';
import Container from '../components/Container';
import Notification from '../components/Notification';
import TextContent from '../components/TextContent';
import Section from '../components/Section';
import FooterBar from '../components/FooterBar';
import Grid from '../components/Grid';
import GridItem from '../components/GridItem';
import Table from '../components/Table';
import SimpleBarList from '../components/SimpleBarList';
import Card from '../components/Card';

import { GET_ACTIVITY_DETAILS } from '../graph/activities';
import { GET_CMS_CONTENT } from '../graph/cms';
import {
  GET_INTERVIEW_TIPS,
  ADD_INTERVIEW_TIP,
  DELETE_INTERVIEW_TIP,
  UPDATE_INTERVIEW_TIP,
} from '../graph/interviewTips';
import { GET_RESULTS } from '../graph/results';

import extractGraphQLError from '../utils/extractGraphQLError';
import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';
import { validate, requiredString } from '../utils/validate';
import useTrackActivityGA from '../utils/useTrackActivityGA';

import { activities, ACTIVITY_STATUS } from '../config/levels';

import { ReactComponent as RemoveIcon } from '../assets/remove-icon.svg';

const formValidationSchema = validate({
  description1: requiredString,
});

const formInitialValues = {
  description1: '',
};

const formFields = [
  {
    id: 'description1',
    name: 'description1',
    label:
      'Identify own top competencies required for role + real life examples',
    type: 'textarea',
    placeholder:
      'Example: “For example, I had to partner up with a classmate to deliver a final project for one of my classes last semester and it was 50% of my final grade. Unfortunately, my partner got very sick 3 weeks prior to submission and had to drop out. Instead of giving up, I was able to complete her portion of the project as well, because I planned so far in advance.…..............” [Linked real-life example]”',
  },
];

function ActivityInterviewGuideStep2({ activityId }) {
  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data, loading } = useQuery(GET_ACTIVITY_DETAILS, {
    variables: { id: activityId },
  });

  const { loading: loadingInterviewTips, data: dataInterviewTips } = useQuery(
    GET_INTERVIEW_TIPS,
    {
      variables: { activityId },
    },
  );

  const { data: resultData, loading: loadingResults } = useQuery(GET_RESULTS);

  const typeName = 'COMPETENCY';

  const filteredInterviewTips = dataInterviewTips?.interviewTips?.filter(
    interviewTip => interviewTip?.type === typeName,
  );

  const isInterviewTipValid = filteredInterviewTips?.length > 0;

  const [
    handleAddInterviewTip,
    { loading: loadingAddInterviewTip, error: errorAddInterviewTip },
  ] = useMutation(ADD_INTERVIEW_TIP, {
    refetchQueries: ['InterviewTips'],
  });

  const handleNewInterviewTip = formData => {
    handleAddInterviewTip({
      variables: {
        activityId,
        type: typeName,
        ...formData,
      },
    });
  };

  const [
    handleDeleteInterviewTip,
    { loading: loadingDeleteInterviewTip, error: errorDeleteInterviewTip },
  ] = useMutation(DELETE_INTERVIEW_TIP, {
    refetchQueries: ['InterviewTips'],
  });

  const [
    handleUpdateInterviewTip,
    { loading: loadingUpdateInterviewTip, error: errorUpdateInterviewTip },
  ] = useMutation(UPDATE_INTERVIEW_TIP, {
    refetchQueries: ['InterviewTips'],
  });

  const activityType = data?.activity?.type;

  useTrackActivityGA(data?.activity?.type, '2');

  const { level } = activities[activityType] || {};

  const {
    title,
    sub_title: subTitle,
    audio_instructions_3: audioInstructions,
    help_text_3: helpText,
    short_instructions_3: shortInstructions,
    step_label_3: stepLabel,
  } =
    cmsData?.cmsContent?.activity?.find(
      activity =>
        activity.id ===
        (cmsData?.user?.currentLevel === 4 &&
        data?.activity?.status === ACTIVITY_STATUS.COMPLETED
          ? `ongoing_${activityType}`
          : activityType),
    ) ||
    cmsData?.cmsContent?.activity?.find(
      activity => activity.id === activityType,
    ) ||
    {};

  return (
    <Layout
      title={`${extractPrismicString(title)}`}
      loading={loading || loadingInterviewTips || loadingResults}
      hasFooterBar
    >
      <Container>
        <Section>
          {!!(hasContent(shortInstructions) || hasContent(helpText)) && (
            <Grid>
              <GridItem width={60}>
                <TextContent redh6 smallMargin>
                  {extractPrismicContent(shortInstructions)}
                </TextContent>
              </GridItem>

              <GridItem width={40}>
                {!!(audioInstructions?.url || hasContent(helpText)) && (
                  <Notification showUser noMarginBottom>
                    {extractPrismicContent(helpText)}

                    {!!audioInstructions?.url && (
                      <AudioPlayer fileUrl={audioInstructions?.url} />
                    )}
                  </Notification>
                )}
              </GridItem>
            </Grid>
          )}
        </Section>

        <TextContent redh6 smallMargin>
          <h6>Step 3</h6>
          {extractPrismicContent(stepLabel)}
        </TextContent>

        <Grid>
          <GridItem width={60} alignTop>
            <Table
              cols={[
                {
                  id: 'description1',
                  value:
                    'Identify own top competencies required for role + real life examples',
                },
              ]}
              data={filteredInterviewTips}
              actions={[
                {
                  id: 'remove',
                  text: 'remove',
                  icon: <RemoveIcon />,
                  onClick: handleDeleteInterviewTip,
                  loading: loadingDeleteInterviewTip,
                },
              ]}
              form={{
                fields: formFields,
                loading: loadingAddInterviewTip,
                validationSchema: formValidationSchema,
                initialValues: formInitialValues,
                onSubmit: handleNewInterviewTip,
              }}
              editable
              updateForm={{
                fields: formFields,
                loading: loadingUpdateInterviewTip,
                validationSchema: formValidationSchema,
                onSubmit: handleUpdateInterviewTip,
              }}
              noMargin
            />
          </GridItem>

          <GridItem width={40} alignTop>
            <Card header="Your top Competencies">
              <SimpleBarList
                data={resultData?.results?.competencies?.competencies?.slice(
                  0,
                  5,
                )}
              />
            </Card>
          </GridItem>
        </Grid>

        {!!(
          errorAddInterviewTip ||
          errorDeleteInterviewTip ||
          errorUpdateInterviewTip
        ) && (
          <Notification type="red">
            {extractGraphQLError(
              errorAddInterviewTip ||
                errorDeleteInterviewTip ||
                errorUpdateInterviewTip,
            )}
          </Notification>
        )}
      </Container>

      <FooterBar
        title={extractPrismicString(title)}
        subTitle={extractPrismicString(subTitle)}
        primaryButton={{
          label: 'Next: Identify Cognitive Abilities',
          to: `/activities/${activityId}/interview_guide/3`,
          disabled: !isInterviewTipValid,
          level,
        }}
        secondaryButton={{
          label: 'Back to Match Personality Themes',
          to: `/activities/${activityId}/interview_guide/1`,
        }}
      />
    </Layout>
  );
}

ActivityInterviewGuideStep2.propTypes = {
  activityId: PropTypes.string,
};

ActivityInterviewGuideStep2.defaultProps = {
  activityId: null,
};

export default ActivityInterviewGuideStep2;
