import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Container from './Container';
import Tag from './Tag';
import TextInput from './TextInput';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.base.shadow};

  ${props => props.theme.breakpoints.medium`
    height: 68px;
  `}
`;

const Inner = styled.div`
  padding: ${props => props.theme.spacing.base} 0;

  ${props => props.theme.breakpoints.medium`
    padding: 0;
    display: flex;
    align-items: center;
  `}

  p {
    font-size: ${props => props.theme.fontSizes.small};
    color: ${props => props.theme.colors.grey500};
    line-height: 1;
    margin: 0 ${props => props.theme.spacing.xSmall} 0 0;
    display: inline-block;

    ${props => props.theme.breakpoints.medium`
      display: block;
      margin: 0 ${props.theme.spacing.base} 0 0;
    `}
  }
`;

const SearchArea = styled.div`
  margin-top: ${props => props.theme.spacing.base};

  ${props => props.theme.breakpoints.medium`
    margin: auto 0 auto auto;
    transform: translateY(-3px);
  `}
`;

function FilterBar({
  filterValue,
  onChangeFilters,
  searchValue,
  onChangeSearchValue,
}) {
  const handleSelectFilter = filter => {
    onChangeFilters({
      ...filterValue,
      [filter]: true,
    });
  };

  const handleRemoveFilter = filter => {
    onChangeFilters({
      ...filterValue,
      [filter]: false,
    });
  };

  const handleChangeSearch = e => {
    onChangeSearchValue(e?.target?.value);
  };

  return (
    <Wrapper>
      <Container>
        <Inner>
          <p>Filters:</p>
          <Tag
            dark={filterValue[1]}
            light={!filterValue[1]}
            onClick={!filterValue[1] ? () => handleSelectFilter(1) : undefined}
            onRemove={filterValue[1] ? () => handleRemoveFilter(1) : undefined}
          >
            Level 1
          </Tag>
          <Tag
            dark={filterValue[2]}
            light={!filterValue[2]}
            onClick={!filterValue[2] ? () => handleSelectFilter(2) : undefined}
            onRemove={filterValue[2] ? () => handleRemoveFilter(2) : undefined}
          >
            Level 2
          </Tag>
          <Tag
            dark={filterValue[3]}
            light={!filterValue[3]}
            onClick={!filterValue[3] ? () => handleSelectFilter(3) : undefined}
            onRemove={filterValue[3] ? () => handleRemoveFilter(3) : undefined}
          >
            Level 3
          </Tag>
          <Tag
            dark={filterValue?.new}
            light={!filterValue?.new}
            onClick={
              !filterValue?.new ? () => handleSelectFilter('new') : undefined
            }
            onRemove={
              filterValue?.new ? () => handleRemoveFilter('new') : undefined
            }
          >
            New
          </Tag>

          <Tag
            dark={filterValue?.interviewGuide}
            light={!filterValue?.interviewGuide}
            onClick={
              !filterValue?.interviewGuide
                ? () => handleSelectFilter('interviewGuide')
                : undefined
            }
            onRemove={
              filterValue?.interviewGuide
                ? () => handleRemoveFilter('interviewGuide')
                : undefined
            }
          >
            Interview Guide
          </Tag>

          <SearchArea>
            <TextInput
              id="search"
              name="search"
              onChange={handleChangeSearch}
              value={searchValue}
              small
              placeholder="Search..."
              noMargin
            />
          </SearchArea>
        </Inner>
      </Container>
    </Wrapper>
  );
}

FilterBar.propTypes = {
  filterValue: PropTypes.shape({}).isRequired,
  onChangeFilters: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  onChangeSearchValue: PropTypes.func.isRequired,
};

FilterBar.defaultProps = {
  searchValue: '',
};

export default FilterBar;
