import React from 'react';
import styled from 'styled-components';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import Container from './Container';
import Section from './Section';
import TextContentMarketing from './TextContentMarketing';

import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';

const Wrapper = styled.div`
  h2 {
    color: ${props => props.theme.colors.red500};
    margin-bottom: ${props => props.theme.spacing.large};
  }

  .accordion {
    ${props => props.theme.breakpoints.large`
      width: 80%;
    `}
  }

  .accordion__item {
    margin-bottom: 30px;

    ${props => props.theme.breakpoints.medium`
      margin-bottom: 50px;
    `}

    &:last-child {
      margin-bottom: 0;
    }
  }

  .accordion__heading {
    margin-bottom: 20px;

    ${props => props.theme.breakpoints.medium`
      margin-bottom: 30px;
    `}
  }

  .accordion__button {
    position: relative;
    cursor: pointer;
    padding-right: 40px;

    ${props => props.theme.breakpoints.medium`
      padding-right: 50px;
    `}

    &:after {
      content: '';
      background-image: url('/plus-icon.svg');
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      background-size: 20px 20px;

      ${props => props.theme.breakpoints.medium`
        background-size: 30px 30px;
        width: 30px;
        height: 30px;
      `}
    }

    &[aria-expanded='true'] {
      &:after {
        background-image: url('/minus-icon.svg');
      }
    }
  }
`;

function MarketingFaqs({
  faq_small: small,
  faq_background_color: backgroundColor,
  faq_title: title,
  items,
}) {
  if (!items) return null;

  return (
    <Wrapper>
      <Section
        padding={small ? 'medium' : 'large'}
        backgroundColor={backgroundColor}
      >
        <Container>
          {hasContent(title) && <h2>{extractPrismicString(title)}</h2>}

          <Accordion preExpanded={['0']}>
            {items.map(
              ({ faq_item_title: itemTitle, faq_item_text: itemText }, i) => (
                <AccordionItem uuid={`${i}`}>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      {hasContent(itemTitle) && (
                        <h3>{extractPrismicString(itemTitle)}</h3>
                      )}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <TextContentMarketing>
                      {extractPrismicContent(itemText)}
                    </TextContentMarketing>
                  </AccordionItemPanel>
                </AccordionItem>
              ),
            )}
          </Accordion>
        </Container>
      </Section>
    </Wrapper>
  );
}

MarketingFaqs.propTypes = {};

export default MarketingFaqs;
