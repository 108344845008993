import React from 'react';
import PropTypes from 'prop-types';

import Container from '../components/Container';
import TextContent from '../components/TextContent';
import Section from '../components/Section';
import RoleTable from '../components/RoleTable';
import Notification from '../components/Notification';
import Tag from '../components/Tag';
import Table from '../components/Table';

import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';
import getAbilityResult from '../utils/getAbilityResult';

import { ABILITIES, PRIORITIES } from '../config/types';

function ResultContent({ resultData, cmsData, type }) {
  if (type === 'values') {
    const dataWithCMSValues = resultData?.values?.slice(0, 5)?.map(item => {
      const cmsValue = cmsData?.cmsContent?.value?.find(
        value => item.type === value.id,
      );

      return {
        ...item,
        title: cmsValue?.title,
        description: cmsValue?.value_text,
      };
    });
    return (
      <Container narrow>
        <Section noPaddingTop>
          <h3>Your top 5 values</h3>

          <Table
            data={dataWithCMSValues}
            redorderId="values"
            cols={[
              {
                id: 'title',
                value: 'Value',
                medium: true,
                transformer: value => (
                  <strong>{extractPrismicString(value)}</strong>
                ),
              },
              {
                id: 'description',
                value: 'Description',
                transformer: value => extractPrismicString(value),
              },
            ]}
          />
        </Section>
      </Container>
    );
  }

  if (type === 'strengths') {
    const dataWithCMSValues = resultData?.strengths?.map(item => {
      const cmsValue = cmsData?.cmsContent?.strength?.find(
        value => item.type === value.id,
      );

      return {
        ...item,
        title: cmsValue?.title,
        description: cmsValue?.value_text,
      };
    });
    return (
      <Container narrow>
        <Section noPaddingTop>
          <h3>Your top 5 character strengths</h3>

          <Table
            data={dataWithCMSValues}
            redorderId="values"
            cols={[
              {
                id: 'title',
                value: 'Strength',
                medium: true,
                transformer: value => (
                  <strong>{extractPrismicString(value)}</strong>
                ),
              },
              {
                id: 'description',
                value: 'Description',
                transformer: value => extractPrismicContent(value),
              },
            ]}
          />
        </Section>
      </Container>
    );
  }

  if (type === 'culture') {
    const cmsCulture = cmsData?.cmsContent?.culture?.find(
      value => resultData?.culture === value.id,
    );

    return (
      <>
        <Section white>
          <Container narrow>
            <TextContent redh6>
              <h6>You are likely to feel most comfortable in</h6>

              <h3>{extractPrismicString(cmsCulture?.title)}</h3>

              {hasContent(cmsCulture?.quote) && (
                <Notification type="green">
                  <blockquote>
                    {extractPrismicString(cmsCulture?.quote)}
                  </blockquote>
                  {hasContent(cmsCulture?.quote_citation) && (
                    <cite>
                      {' '}
                      {extractPrismicString(cmsCulture?.quote_citation)}
                    </cite>
                  )}
                </Notification>
              )}

              {extractPrismicContent(cmsCulture?.main_content)}
            </TextContent>
          </Container>
        </Section>

        {resultData?.altCultures?.map((culture, i) => {
          const altCmsCulture = cmsData?.cmsContent?.culture?.find(
            value => culture === value.id,
          );

          return (
            <Section white={i % 2}>
              <Container narrow>
                <TextContent redh6>
                  <h6>You would also feel comfortable in</h6>

                  <h3>{extractPrismicString(altCmsCulture?.title)}</h3>

                  {hasContent(altCmsCulture?.quote) && (
                    <Notification type="green">
                      <blockquote>
                        {extractPrismicString(altCmsCulture?.quote)}
                      </blockquote>
                      {hasContent(altCmsCulture?.quote_citation) && (
                        <cite>
                          {' '}
                          {extractPrismicString(altCmsCulture?.quote_citation)}
                        </cite>
                      )}
                    </Notification>
                  )}

                  {extractPrismicContent(altCmsCulture?.main_content)}
                </TextContent>
              </Container>
            </Section>
          );
        })}
      </>
    );
  }

  if (type === 'organisation') {
    const organisationType = [
      {
        id: 'bureaucratic',
        value: resultData?.bureaucratic,
      },
      {
        id: 'entrepreneurial',
        value: resultData?.entrepreneurial,
      },
      {
        id: 'professional',
        value: resultData?.professional,
      },
      {
        id: 'smoagile',
        value: resultData?.smoagile,
      },
    ].reduce(
      (a, org) => {
        return org.value > a.value ? org : a;
      },
      { value: 0 },
    );

    const cmsOrg = cmsData?.cmsContent?.organisation_type?.find(
      value => organisationType.id === value.id,
    );

    return (
      <Section white>
        <Container narrow>
          <TextContent redh6 maxHeight={220}>
            <h6>My structural fit</h6>

            <h3>{extractPrismicString(cmsOrg?.title)}</h3>

            {extractPrismicContent(cmsOrg?.main_content)}
          </TextContent>
        </Container>
      </Section>
    );
  }

  if (type === 'interests') {
    return (
      <Section noPaddingTop>
        <Container narrow>
          <h3>Your Career Oriented Interests</h3>

          <Table
            cols={[
              { id: 'title', value: 'Career Interest' },
              { id: 'description1', value: 'Description' },
            ]}
            data={resultData?.interests}
          />
        </Container>
      </Section>
    );
  }

  if (type === 'vision') {
    return (
      <Section white>
        <Container narrow>
          <TextContent redh6 marginBottom>
            <h6>Your vision summary</h6>

            <p>{resultData?.vision}</p>
          </TextContent>

          <TextContent redh6 marginBottom maxHeight={1}>
            <h6>Your full vision</h6>

            <p>{resultData?.visionFull}</p>
          </TextContent>
        </Container>
      </Section>
    );
  }

  if (type === 'personality') {
    const dataWithCMSValues = resultData?.traits
      .map(item => {
        const cmsValue = cmsData?.cmsContent?.trait?.find(
          value => item.type === value.id,
        );

        return {
          ...item,
          title: cmsValue?.title,
          description: cmsValue?.text,
        };
      })
      .sort((a, b) => b.value - a.value);

    const topTraits = dataWithCMSValues.slice(0, 5);
    const bottomTraits = dataWithCMSValues.reverse().slice(0, 5);

    return (
      <Container narrow>
        <Section noPaddingTop>
          <h3>Your top 5 personality traits</h3>

          <Table
            data={topTraits}
            redorderId="toptrait"
            cols={[
              {
                id: 'title',
                value: 'Trait',
                medium: true,
                transformer: value => (
                  <strong>{extractPrismicString(value)}</strong>
                ),
              },
              {
                id: 'description',
                value: 'Description',
                transformer: value => extractPrismicString(value),
              },
            ]}
          />

          <h3>Lowest 5 personality traits</h3>

          <Table
            data={bottomTraits}
            redorderId="bottomtrait"
            cols={[
              {
                id: 'title',
                value: 'Trait',
                medium: true,
                transformer: value => (
                  <strong>{extractPrismicString(value)}</strong>
                ),
              },
              {
                id: 'description',
                value: 'Description',
                transformer: value => extractPrismicString(value),
              },
            ]}
          />
        </Section>
      </Container>
    );
  }

  if (type === 'competencies') {
    const dataWithCMSValues = resultData?.competencies.map(item => {
      const cmsValue = cmsData?.cmsContent?.competency?.find(
        value => item.type === value.id,
      );

      return {
        ...item,
        title: cmsValue?.title,
        description: cmsValue?.text,
      };
    });

    const topComptencies = dataWithCMSValues.slice(0, 5);
    const bottomComptencies = dataWithCMSValues.reverse().slice(0, 5);

    return (
      <Container narrow>
        <Section noPaddingTop>
          <h3>Your top 5 competencies that come easiest for you</h3>

          <Table
            data={topComptencies}
            redorderId="topcomp2"
            cols={[
              {
                id: 'title',
                value: 'Competency',
                medium: true,
                transformer: value => (
                  <strong>{extractPrismicString(value)}</strong>
                ),
              },
              {
                id: 'description',
                value: 'Description',
                transformer: value => extractPrismicContent(value),
              },
            ]}
          />

          <h3>Your top 5 competencies that take the most effort for you</h3>

          <Table
            data={bottomComptencies}
            redorderId="bottomcomp"
            cols={[
              {
                id: 'title',
                value: 'Competency',
                medium: true,
                transformer: value => (
                  <strong>{extractPrismicString(value)}</strong>
                ),
              },
              {
                id: 'description',
                value: 'Description',
                transformer: value => extractPrismicContent(value),
              },
            ]}
          />
        </Section>
      </Container>
    );
  }

  if (type === 'roles') {
    return (
      <Container narrow>
        <Section noPaddingTop>
          <RoleTable
            data={resultData?.roles?.slice(0, 5)}
            cmsContent={cmsData?.cmsContent}
          />
        </Section>
      </Container>
    );
  }

  if (type === 'experiences') {
    return (
      <Container narrow>
        <Section noPaddingTop>
          <h3>Your experiences</h3>

          <Table
            data={resultData?.experiences}
            redorderId="values"
            cols={[
              { id: 'title', value: 'Competency' },
              { id: 'description1', value: 'Experience' },
            ]}
          />
        </Section>
      </Container>
    );
  }

  if (type === 'abilities') {
    const workingWithNumbersContent = cmsData?.cmsContent?.ability?.find(
      item => item.id === ABILITIES.working_with_numbers,
    );

    const workingWithWorkdsContent = cmsData?.cmsContent?.ability?.find(
      item => item.id === ABILITIES.working_with_words,
    );

    const abstractReasoningContent = cmsData?.cmsContent?.ability?.find(
      item => item.id === ABILITIES.abstract_reasoning,
    );
    return (
      <>
        <Section white>
          <Container narrow>
            <TextContent>
              <h3>
                1. {extractPrismicString(workingWithNumbersContent?.title)}
              </h3>

              {extractPrismicContent(workingWithNumbersContent?.text)}

              {extractPrismicContent(
                getAbilityResult(
                  workingWithNumbersContent,
                  resultData?.working_with_numbers,
                ),
              )}
            </TextContent>
          </Container>
        </Section>

        <Section>
          <Container narrow>
            <TextContent>
              <h3>
                2. {extractPrismicString(workingWithWorkdsContent?.title)}
              </h3>

              {extractPrismicContent(workingWithWorkdsContent?.text)}

              {extractPrismicContent(
                getAbilityResult(
                  workingWithWorkdsContent,
                  resultData?.working_with_words,
                ),
              )}
            </TextContent>
          </Container>
        </Section>

        <Section white>
          <Container narrow>
            <TextContent>
              <h3>
                3. {extractPrismicString(abstractReasoningContent?.title)}
              </h3>

              {extractPrismicContent(abstractReasoningContent?.text)}

              {extractPrismicContent(
                getAbilityResult(
                  abstractReasoningContent,
                  resultData?.abstract_reasoning,
                ),
              )}
            </TextContent>
          </Container>
        </Section>
      </>
    );
  }

  if (type === 'beliefs') {
    const limitingBeliefs = resultData?.beliefs?.filter(
      belief => belief.type === 'LIMITING',
    );
    const empoweringBeliefs = resultData?.beliefs?.filter(
      belief => belief.type === 'EMPOWERING',
    );

    return (
      <Container narrow>
        <Section noPaddingTop>
          <h3>Your Limiting Beliefs</h3>

          <Table
            data={limitingBeliefs}
            redorderId="values"
            cols={[
              { id: 'title', value: 'Limiting Belief' },
              { id: 'description1', value: 'Why you believe it' },
              { id: 'description2', value: 'How it stops you' },
              { id: 'description3', value: 'Growth Mindset Response' },
            ]}
          />
        </Section>

        <Section noPaddingTop>
          <h3>Your Empowering Beliefs</h3>

          <Table
            data={empoweringBeliefs}
            redorderId="values"
            cols={[
              { id: 'title', value: 'Empowering Belief' },
              { id: 'description1', value: 'Why you believe it' },
              { id: 'description2', value: 'How it helps you' },
            ]}
          />
        </Section>
      </Container>
    );
  }

  if (type === 'goals') {
    return (
      <Container>
        <Section noPaddingTop>
          <h3>Your Goals</h3>

          <Table
            data={resultData?.goals}
            redorderId="goals"
            cols={[
              { id: 'title', value: 'Goal' },
              { id: 'description1', value: 'Specific' },
              { id: 'description2', value: 'Measureable' },
              { id: 'description3', value: 'Achievable' },
              { id: 'description4', value: 'Relevant' },
              { id: 'description5', value: 'Timebound' },
            ]}
          />
        </Section>
      </Container>
    );
  }

  if (type === 'actions') {
    return (
      <Container>
        <Section noPaddingTop>
          <h3>Your Actions</h3>

          <Table
            data={resultData?.actions}
            redorderId="actions"
            cols={[
              {
                id: 'priority',
                value: 'Priority',
                transformer: priority => (
                  <Tag light={priority !== PRIORITIES.HIGH}>{priority}</Tag>
                ),
              },
              { id: 'dueDate', value: 'Date Due' },
              { id: 'Goal', value: 'Goal', transformer: goal => goal?.title },
              { id: 'title', value: 'Action' },
            ]}
          />
        </Section>
      </Container>
    );
  }

  return false;
}

ResultContent.propTypes = {
  resultData: PropTypes.shape({}).isRequired,
  cmsData: PropTypes.shape({}).isRequired,
  type: PropTypes.string.isRequired,
};

export default ResultContent;
