import styled from 'styled-components';

const ListHeader = styled.div`
  margin-bottom: ${props => props.theme.spacing.midLarge};

  h3 {
    margin-bottom: ${props => props.theme.spacing.xxSmall};
  }

  ${props => props.theme.breakpoints.medium`
    display: flex;
    align-items: center;



    & > div {
      & > :last-child {
        margin-bottom: 0;
      }
    }
  `}

  a,
  button {
    margin-left: auto;
  }
`;

export default ListHeader;
