import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import Container from './Container';
import Section from './Section';
import TextContentMarketing from './TextContentMarketing';

import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';

const Wrapper = styled.div`
  h2 {
    color: ${props => props.theme.colors.red500};
    margin-bottom: ${props => props.theme.spacing.large};
  }

  blockquote {
    padding: 0;
    margin: 0%;
    position: relative;

    &:before {
      position: absolute;
      font-family: ${props => props.theme.base.altFontFamily};
      font-size: 84px;
      content: '"';
      color: ${props => props.theme.colors.green500};
      line-height: 1;
      top: -50px;
      left: 0;

      ${props => props.theme.breakpoints.medium`
        top: -20px;
        left: -40px;
      `}
    }
  }

  cite {
    color: ${props => props.theme.colors.red500};
    font-style: normal;
  }

  .slick-prev {
    left: 40px;
    z-index: 1;
    display: none !important;

    ${props => props.theme.breakpoints.medium`
      display: block!important;
    `}

    &:before {
      color: ${props => props.theme.colors.grey500};
    }
  }

  .slick-next {
    right: 40px;
    z-index: 1;
    display: none !important;

    ${props => props.theme.breakpoints.medium`
      display: block!important;
    `}

    &:before {
      color: ${props => props.theme.colors.grey500};
    }
  }

  .slick-dots li {
    margin: 0;
  }

  .slick-dots {
    bottom: 20px;
  }

  .slick-dots li button:before {
    opacity: 1;
    color: #ebebeb;
  }

  .slick-dots li.slick-active button:before {
    color: ${props => props.theme.colors.red500};
    opacity: 1;
  }
`;

const ItemList = styled.div`
  background-color: ${props => props.theme.colors.white};
  position: relative;

  ${props => props.theme.breakpoints.large`
    width: 80%;
  `}
`;

const Item = styled.div`
  padding: ${props => props.theme.spacing.xLarge}
    ${props => props.theme.spacing.midLarge};

  ${props => props.theme.breakpoints.medium`
    padding: 60px 130px;
  `}
`;

function MarketingTestimonials({
  testimonial_small: small,
  testimonial_background_color: backgroundColor,
  testimonial_title: title,
  items,
}) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  if (!items) return null;

  return (
    <Wrapper>
      <Section
        padding={small ? 'medium' : 'large'}
        backgroundColor={backgroundColor}
      >
        <Container>
          {hasContent(title) && <h2>{extractPrismicString(title)}</h2>}

          <ItemList>
            <Slider {...settings}>
              {items.map(
                ({
                  testimonial_citation: itemCite,
                  testimonial_text: itemText,
                }) => (
                  <div>
                    <Item>
                      <TextContentMarketing>
                        <blockquote>
                          {extractPrismicContent(itemText)}
                        </blockquote>

                        <cite>{extractPrismicString(itemCite)}</cite>
                      </TextContentMarketing>
                    </Item>
                  </div>
                ),
              )}
            </Slider>
          </ItemList>
        </Container>
      </Section>
    </Wrapper>
  );
}

MarketingTestimonials.propTypes = {};

export default MarketingTestimonials;
