import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import AudioPlayer from '../components/AudioPlayer';
import Layout from '../components/Layout';
import Container from '../components/Container';
import Notification from '../components/Notification';
import TextContent from '../components/TextContent';
import Section from '../components/Section';
import FooterBar from '../components/FooterBar';
import Grid from '../components/Grid';
import GridItem from '../components/GridItem';
import Form from '../components/Form';
import Card from '../components/Card';
import CultureVisualisation from '../components/CultureVisualisation';
import OrganisationVisualisation from '../components/OrganisationVisualisation';
import ValuesVisualisation from '../components/ValuesVisualisation';
import CardInner from '../components/CardInner';

import { GET_ACTIVITY_DETAILS, UPDATE_ACTIVITY } from '../graph/activities';
import { GET_CMS_CONTENT } from '../graph/cms';
import { GET_RESULTS } from '../graph/results';

import extractGraphQLError from '../utils/extractGraphQLError';
import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';
import { validate, requiredString } from '../utils/validate';
import useTrackActivityGA from '../utils/useTrackActivityGA';

import { activities, ACTIVITY_STATUS } from '../config/levels';
import { CULTURES, ORGANISATIONS } from '../config/types';

const formValidationSchema = validate({
  companyCulture: requiredString,
  companyStructure: requiredString,
});

const formFields = [
  {
    id: 'companyCulture',
    name: 'companyCulture',
    label: 'Company Culture',
    type: 'select',
    defaultLabel: 'Select a culture',
    options: [
      { id: "I don't know", value: "I don't know", label: "I don't know" },
      ...Object.values(CULTURES).map(value => ({
        id: value,
        value,
        label: value,
      })),
    ],
  },

  {
    id: 'companyStructure',
    name: 'companyStructure',
    label: 'Company Structure',
    type: 'select',
    defaultLabel: 'Select a structure',
    options: [
      { id: "I don't know", value: "I don't know", label: "I don't know" },
      ...Object.values(ORGANISATIONS).map(value => ({
        id: value,
        value,
        label: value,
      })),
    ],
  },

  {
    id: 'notes',
    name: 'notes',
    label: 'Additional Notes',
    type: 'textarea',
  },
];

function ActivityInterviewGuideStep6({ activityId }) {
  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data, loading } = useQuery(GET_ACTIVITY_DETAILS, {
    variables: { id: activityId },
  });

  const { data: resultData, loading: loadingResults } = useQuery(GET_RESULTS);

  const isValid = !!(
    data?.activity?.companyCulture && !!data?.activity?.companyStructure
  );

  const [
    handleUpdateActivity,
    { loading: loadingUpdateActivity, error },
  ] = useMutation(UPDATE_ACTIVITY);

  const handleAddInterviewDetails = ({ variables }) => {
    handleUpdateActivity({
      variables: {
        id: activityId,
        ...variables,
      },
    });
  };
  const activityType = data?.activity?.type;

  useTrackActivityGA(data?.activity?.type, '6');

  const { level } = activities[activityType] || {};

  const {
    title,
    sub_title: subTitle,
    audio_instructions_7: audioInstructions,
    help_text_7: helpText,
    short_instructions_7: shortInstructions,
    step_label_7: stepLabel,
  } =
    cmsData?.cmsContent?.activity?.find(
      activity =>
        activity.id ===
        (cmsData?.user?.currentLevel === 4 &&
        data?.activity?.status === ACTIVITY_STATUS.COMPLETED
          ? `ongoing_${activityType}`
          : activityType),
    ) ||
    cmsData?.cmsContent?.activity?.find(
      activity => activity.id === activityType,
    ) ||
    {};

  return (
    <Layout
      title={`${extractPrismicString(title)}`}
      loading={loading || loadingResults}
      hasFooterBar
    >
      <Container>
        <Section>
          {!!(hasContent(shortInstructions) || hasContent(helpText)) && (
            <Grid>
              <GridItem width={60}>
                <TextContent redh6 smallMargin>
                  {extractPrismicContent(shortInstructions)}
                </TextContent>
              </GridItem>

              <GridItem width={40}>
                {!!(audioInstructions?.url || hasContent(helpText)) && (
                  <Notification showUser noMarginBottom>
                    {extractPrismicContent(helpText)}

                    {!!audioInstructions?.url && (
                      <AudioPlayer fileUrl={audioInstructions?.url} />
                    )}
                  </Notification>
                )}
              </GridItem>
            </Grid>
          )}
        </Section>

        <Section smallPadding>
          <Grid>
            <GridItem width={33.33} alignTop>
              <Card header="Your Values">
                <CardInner>
                  <ValuesVisualisation data={resultData?.results?.values} />
                </CardInner>
              </Card>
            </GridItem>

            <GridItem width={33.33} alignTop>
              <Card header="Your Company Culture Match">
                <CardInner negativeMargin>
                  <CultureVisualisation
                    data={resultData?.results?.culture}
                    largeMargin
                  />
                </CardInner>
              </Card>
            </GridItem>

            <GridItem width={33.33} alignTop>
              <Card header="Your Company Structure Match">
                <CardInner negativeMargin>
                  <OrganisationVisualisation
                    data={resultData?.results?.organisation}
                    largeMargin
                  />
                </CardInner>
              </Card>
            </GridItem>
          </Grid>
        </Section>

        <Container narrow>
          <TextContent redh6 smallMargin>
            <h6>Step 7</h6>
            {extractPrismicContent(stepLabel)}
          </TextContent>

          <Section smallPadding>
            <Card>
              <Form
                fields={formFields}
                validationSchema={formValidationSchema}
                onSubmit={handleAddInterviewDetails}
                loading={loadingUpdateActivity}
                submitButtonText="Update"
                initialValues={data?.activity}
              />
            </Card>
          </Section>

          {!!error && (
            <Notification type="red">{extractGraphQLError(error)}</Notification>
          )}
        </Container>
      </Container>

      <FooterBar
        title={extractPrismicString(title)}
        subTitle={extractPrismicString(subTitle)}
        primaryButton={{
          label: 'Next: Interview Questions',
          to: `/activities/${activityId}/interview_guide/7`,
          disabled: !isValid,
          level,
        }}
        secondaryButton={{
          label: 'Back to Identify Interests',
          to: `/activities/${activityId}/interview_guide/5`,
        }}
      />
    </Layout>
  );
}

ActivityInterviewGuideStep6.propTypes = {
  activityId: PropTypes.string,
};

ActivityInterviewGuideStep6.defaultProps = {
  activityId: null,
};

export default ActivityInterviewGuideStep6;
