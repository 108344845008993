import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ActivityListItem from './ActivityListItem';

import cardBase from '../theme/cardBase';

const List = styled.ul`
  ${cardBase};
  margin: 0;
  padding: 0;
`;

function ActivityList({ activities, ...props }) {
  return (
    <List>
      {activities.map((activity, i) => (
        <ActivityListItem
          i={i}
          activities={activities}
          {...props}
          {...activity}
        />
      ))}
    </List>
  );
}

ActivityList.propTypes = {
  level: PropTypes.number.isRequired,
  activities: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default ActivityList;
