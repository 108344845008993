import React from 'react';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import Card from '../components/Card';
import LayoutAuth from '../components/LayoutAuth';
import Form from '../components/Form';

import { RESET_PASSWORD, GET_TOKEN, IS_AUTHENTICATED } from '../graph/auth';

import { validate, requiredPassword } from '../utils/validate';
import extractGraphQLError from '../utils/extractGraphQLError';

const resetPasswordFormFields = [
  {
    id: 'password',
    type: 'password',
    name: 'password',
    label: 'New password',
  },
];

const resetPasswordFormValidationSchema = validate({
  password: requiredPassword,
});

const resetPasswordFormInitialValues = {
  password: '',
};

function ResetPassword({ token }) {
  const client = useApolloClient();
  const [handleResetPassword, { loading, error }] = useMutation(
    RESET_PASSWORD,
    {
      onCompleted(response) {
        if (!response?.resetPassword) return;

        client.cache.writeQuery({
          query: IS_AUTHENTICATED,
          data: {
            isAuthenticated: true,
          },
        });

        client.cache.writeQuery({
          query: GET_TOKEN,
          data: response?.resetPassword,
        });

        navigate('/');
      },
    },
  );

  return (
    <LayoutAuth title="Create new password">
      <Card header="Create new password">
        <Form
          fields={resetPasswordFormFields}
          onSubmit={({ variables }) =>
            handleResetPassword({ variables: { ...variables, token } })}
          validationSchema={resetPasswordFormValidationSchema}
          initialValues={resetPasswordFormInitialValues}
          loading={loading}
          error={!!error}
          errorMessage={extractGraphQLError(error)}
          submitButtonText="Set password"
          fullWidthButton
        />
      </Card>
    </LayoutAuth>
  );
}

ResetPassword.propTypes = {
  token: PropTypes.string,
};

ResetPassword.defaultProps = {
  token: null,
};

export default ResetPassword;
