import styled from 'styled-components';

const LevelText = styled.div`
  padding: ${props => props.theme.spacing.large} 0;
  text-align: center;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 4px;
    height: ${props => props.theme.spacing.base};
    position: absolute;
    left: 50%;
    display: block;
    transform: translateX(-2px);
    background-color: #e5e5e5;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  h2 {
    font-family: ${props => props.theme.base.altFontFamily};
    font-weight: ${props => props.theme.fontWeights.normal};
  }

  ${props =>
    props.noLines &&
    `
      padding-top:0;
      
      &:after, &:before {
        display: none;
      }
  `}

  ${props =>
    props.white &&
    `
      p,
      h6,
      h2 {
        color: ${props.theme.colors.white};
      }
  `}
`;

export default LevelText;
