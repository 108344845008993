import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { globalHistory, Router } from '@reach/router';
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';
import TawkTo from 'tawkto-react';

import AdminDashboard from './views/AdminDashboard';
import AdminUserDetails from './views/AdminUserDetails';
import AdminActivityDetails from './views/AdminActivityDetails';
import Dashboard from './views/Dashboard';
import Login from './views/Login';
import SignUp from './views/SignUp';
import Profile from './views/Profile';
import NotFound from './views/NotFound';
import ResetRequest from './views/ResetRequest';
import ResetPassword from './views/ResetPassword';
import Payment from './views/Payment';
import Results from './views/Results';
import Activities from './views/Activities';
import ActivityValues from './views/ActivityValues';
import ActivityVision from './views/ActivityVision';
import ActivityStart from './views/ActivityStart';
import ActivityIntro from './views/ActivityIntro';
import ActivityInstructions from './views/ActivityInstructions';
import ActivityInterests from './views/ActivityInterests';
import ActivityInterestsStep2 from './views/ActivityInterestsStep2';
import ActivityHashtags from './views/ActivityHashtags';
import ActivityExperience from './views/ActivityExperience';
import ActivityBeliefs from './views/ActivityBeliefs';
import ActivityBeliefsStep2 from './views/ActivityBeliefsStep2';
import ActivityBeliefsStep3 from './views/ActivityBeliefsStep3';
import ActivityGoals from './views/ActivityGoals';
import ActivityGoalsStepTwo from './views/ActivityGoalsStepTwo';
import ActivityGoalsStepThree from './views/ActivityGoalsStepThree';
import ActivityGoalsStepFour from './views/ActivityGoalsStepFour';
import ActivityActions from './views/ActivityActions';
import ActivityInterviewGuide from './views/ActivityInterviewGuide';
import ActivityInterviewGuideIntro from './views/ActivityInterviewGuideIntro';
import ActivityInterviewGuideStep2 from './views/ActivityInterviewGuideStep2';
import ActivityInterviewGuideStep3 from './views/ActivityInterviewGuideStep3';
import ActivityInterviewGuideStep4 from './views/ActivityInterviewGuideStep4';
import ActivityInterviewGuideStep5 from './views/ActivityInterviewGuideStep5';
import ActivityInterviewGuideStep6 from './views/ActivityInterviewGuideStep6';
import ActivityInterviewGuideStep7 from './views/ActivityInterviewGuideStep7';
import ActivityCompleted from './views/ActivityCompleted';
import Result from './views/Result';
import Actions from './views/Actions';
import InterviewGuide from './views/InterviewGuide';
import InterviewQuestions from './views/InterviewQuestions';
import Page from './views/Page';
import Coaching from './views/Coaching';
import PaymentProduct from './views/PaymentProduct';
import GoalGuide from './views/GoalGuide';
import OnboardingProfile from './views/OnboardingProfile';
import AdminFeedback from './views/AdminFeedback';
import AdminPayments from './views/AdminPayments';
import Plans from './views/Plans';

import MainLoader from './components/MainLoader';
import Notification from './components/Notification';

import useSiteSettings from './hooks/useSiteSettings';

import { IS_AUTHENTICATED } from './graph/auth';
import { GET_CMS_CONTENT } from './graph/cms';

import extractGraphQLError from './utils/extractGraphQLError';

function App() {
  const [called, setCalled] = useState(false);
  const { isLoaded: isSiteSettingsLoaded } = useSiteSettings();
  // Initialise GA
  ReactGA.initialize(process.env.REACT_APP_GA_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);

  // Hook into router change event
  useEffect(() => {
    if (process.env.REACT_APP_TAWK_ID && process.env.REACT_APP_TAWK_KEY) {
      new TawkTo(process.env.REACT_APP_TAWK_ID, process.env.REACT_APP_TAWK_KEY);
    }
    globalHistory.listen(() => {
      ReactGA.pageview(window.location.pathname + window.location.search);
      window.scrollTo(0, 0);
    });
  }, []);

  const {
    data: { isAuthenticated },
  } = useQuery(IS_AUTHENTICATED, {
    onCompleted: (data) => {
      if (!data.isAuthenticated) {
        setCalled(true);
      }
    },
  });

  const { loading, data, error } = useQuery(GET_CMS_CONTENT, {
    skip: !isAuthenticated,
    onCompleted: () => {
      setCalled(true);
    },
  });

  if (!called || loading || !isSiteSettingsLoaded) return <MainLoader />;

  if (error)
    return <Notification type="red">{extractGraphQLError(error)}</Notification>;

  if (!data?.user || !isAuthenticated) {
    return (
      <Router>
        <SignUp path="/sign-up" />
        <ResetRequest path="/reset-password" />
        <ResetPassword path="/reset-password/:token" />
        <Login path="/" default />
      </Router>
    );
  }

  Sentry.configureScope((scope) => {
    scope.setUser({ email: data?.user?.email, id: data?.user?.id });
  });

  if (data?.user?.isAdmin) {
    return (
      <Router>
        <AdminDashboard path="/" />
        <AdminFeedback path="/feedback" />
        <AdminPayments path="/payments" />
        <AdminUserDetails path="/users/:userId" />
        <AdminActivityDetails path="/activities/:activityId" />
        <Actions path="/results/actions/:resultId" />
        <InterviewGuide path="/results/interview_guide/:resultId" />
        <Result path="/results/:type/:resultId" />
        <Profile path="/profile" />
        <NotFound default />
      </Router>
    );
  }

  return (
    <Router>
      <Profile path="/profile" />
      <Dashboard path="/" />
      <Dashboard path="/sign-up" />
      <Activities path="/activities" />
      <ActivityStart path="/activities/:activityType/start" />
      <ActivityIntro path="/activities/:activityId/introduction" />
      <ActivityInstructions path="/activities/:activityId/instructions" />
      <ActivityValues path="/activities/:activityId/values" />
      <ActivityVision path="/activities/:activityId/vision" />
      <ActivityInterests path="/activities/:activityId/interests" />
      <ActivityInterestsStep2 path="/activities/:activityId/interests/2" />
      <ActivityHashtags path="/activities/:activityId/hashtags" />
      <ActivityExperience path="/activities/:activityId/experience" />
      <ActivityGoals path="/activities/:activityId/goals" />
      <ActivityGoalsStepTwo path="/activities/:activityId/goals/2" />
      <ActivityGoalsStepThree path="/activities/:activityId/goals/3" />
      <ActivityGoalsStepFour path="/activities/:activityId/goals/4" />
      <ActivityBeliefs path="/activities/:activityId/limiting_beliefs" />
      <ActivityBeliefsStep2 path="/activities/:activityId/limiting_beliefs/2" />
      <ActivityBeliefsStep3 path="/activities/:activityId/limiting_beliefs/3" />
      <ActivityActions path="/activities/:activityId/actions" />
      <ActivityInterviewGuideIntro path="/activities/:activityId/interview_guide" />
      <ActivityInterviewGuide path="/activities/:activityId/interview_guide/1" />
      <ActivityInterviewGuideStep2 path="/activities/:activityId/interview_guide/2" />
      <ActivityInterviewGuideStep3 path="/activities/:activityId/interview_guide/3" />
      <ActivityInterviewGuideStep4 path="/activities/:activityId/interview_guide/4" />
      <ActivityInterviewGuideStep5 path="/activities/:activityId/interview_guide/5" />
      <ActivityInterviewGuideStep6 path="/activities/:activityId/interview_guide/6" />
      <ActivityInterviewGuideStep7 path="/activities/:activityId/interview_guide/7" />
      <ActivityCompleted path="/activities/:activityId/completed" />
      <Results path="/results" />
      <Actions path="/results/actions/:resultId" />
      <InterviewGuide path="/results/interview_guide/:resultId" />
      <Result path="/results/:type/:resultId" />
      <InterviewQuestions path="/interview-questions" />
      <Coaching path="/coaching" />
      <GoalGuide path="/goal-guide" />
      <PaymentProduct path="/payment/:id" />
      <Payment path="/payment" />
      <Page path="/page/:id" />
      <OnboardingProfile path="/onboarding/profile" />
      <Plans path="/onboarding/plans" />
      <ResetPassword path="/reset-password/:token" />
      <Payment path="/payment" />
      <Payment path="/onboarding/payment" onboarding />
      <NotFound default />
    </Router>
  );
}

export default App;
