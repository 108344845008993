import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import Layout from '../components/Layout';
import Container from '../components/Container';
import Section from '../components/Section';
import TextContent from '../components/TextContent';
import Notification from '../components/Notification';

import InterviewQuestionList from '../components/InterviewQuestionList';

import { GET_RESULTS } from '../graph/results';
import { GET_CMS_CONTENT } from '../graph/cms';

function InterviewQuestions() {
  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data, loading } = useQuery(GET_RESULTS);

  const valueAndStrengthIds = [
    ...(data?.results?.strengths?.strengths || []),
    ...(data?.results?.values?.values || []),
  ].map(result => result.type);

  const questions = valueAndStrengthIds?.length
    ? cmsData?.cmsContent?.interview_questions?.filter(question => {
        const slug = question.result_link?.slug;
        return valueAndStrengthIds.includes(slug);
      })
    : null;

  const generalQuestions = cmsData?.cmsContent?.interview_questions?.filter(
    question => {
      return !question.result_link?.slug;
    },
  );

  return (
    <Layout
      title="Interview Questions"
      hero={{
        title: (
          <>
            Prepare yourself <br />
            <span>for your next interview</span>
          </>
        ),
        subTitle: 'How you will continuously move closer towards your vision!.',
      }}
      loading={loading}
    >
      <Container narrow>
        <Section>
          <TextContent marginBottom>
            <h1>Interview Questions</h1>
            <h5>How to pitch yourself in an interview</h5>
          </TextContent>
        </Section>

        <Section smallPadding noPaddingTop>
          <h3> Interview Questions </h3>

          {questions ? (
            <InterviewQuestionList questions={questions} />
          ) : (
            <Notification showUser>
              We currently don't have any matched interview questions for you
              yet, please complete a few more activities.
            </Notification>
          )}
        </Section>

        <Section smallPadding>
          <h3> General Interview Questions </h3>
          <InterviewQuestionList questions={generalQuestions} />
        </Section>
      </Container>
    </Layout>
  );
}

export default InterviewQuestions;
