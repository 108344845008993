import startCase from 'lodash.startcase';

const transformArrayToBarChartObject = (
  array,
  valueOffset,
  key = 'value',
  labelKey = 'type',
  transform = true,
  valueFunction = undefined,
) => {
  if (!array) return null;

  return array.map(item => {
    const value = valueOffset ? valueOffset - item[key] : item[key];
    return {
      label: transform ? startCase(item[labelKey]) : item[labelKey],
      value: valueFunction ? valueFunction(value) : value,
    };
  });
};

export default transformArrayToBarChartObject;
