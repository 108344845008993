import gql from 'graphql-tag';

const GET_USERS = gql`
  query Users(
    $limit: Int
    $offset: Int
    $search: String
    $sortBy: String
    $isAscending: String
  ) {
    users(
      limit: $limit
      offset: $offset
      search: $search
      sortBy: $sortBy
      isAscending: $isAscending
    ) {
      id
      name
      currentLevel
      lastCompletedActivity
      lastCompletedActivityTime
      completedActivities
      email
      phoneNumber
      pendingActivities
      deactivateFeedback
      hasActiveSubscription
      availableFreeActivities
      createdAt
      tag
    }
  }
`;

const IMPORT_PERSONALITY_TEST = gql`
  mutation importPersonalityTest($users: [UserImportInput!]!) {
    importPersonalityTest(users: $users) {
      usersImported
    }
  }
`;

const GET_USER_DETAILS = gql`
  query UserDetails($userId: ID!) {
    userDetails(userId: $userId) {
      tag
      id
      name
      currentLevel
      completedActivities
      email
      pendingActivities
      availableFreeActivities
      activities {
        id
        type
        status
        type
        level
        isJoinedFree
      }
    }
  }
`;

const GET_ACTIVITY_DETAILS = gql`
  query ActivityDetails($activityId: ID!) {
    activityDetails(activityId: $activityId) {
      id
      userId
      type
      status
      type
      level
      strengths {
        id
        type
        value
      }
      traits {
        id
        type
        value
      }
      competencies {
        id
        type
        value
      }
      abilities {
        id
        type
        value
      }
    }
  }
`;

const GET_USER_RESULTS = gql`
  query Results($userId: ID, $resultId: ID) {
    results(userId: $userId, resultId: $resultId) {
      values {
        id
        status
        new
        values {
          id
          type
          ranking
        }
      }
      strengths {
        id
        status
        new
        status
        strengths {
          id
          type
          value
        }
      }
      culture {
        id
        status
        new
        status
        flexiblity
        interdependence
        culture
        altCultures
      }
      organisation {
        id
        status
        new
        status
        entrepreneurial
        smoagile
        bureaucratic
        professional
      }
      interests {
        id
        status
        new
        interests {
          id
          title
          description1
          description2
          ranking
        }
      }
      vision {
        id
        status
        new
        vision
        visionFull
      }
      personality {
        id
        status
        new
        status
        themes {
          id
          type
          value
          topTrait
        }
        themeVisualisation {
          id
          type
          value
        }
        traits {
          id
          type
          value
        }
      }
      competencies {
        id
        status
        new
        status
        competencies {
          id
          type
          value
        }
      }
      roles {
        id
        status
        new
        status
        roles {
          id
          type
          value
        }
      }
      experiences {
        id
        status
        new
        experiences {
          id
          title
          description1
        }
      }

      abilities {
        id
        status
        new
        status
        working_with_numbers
        working_with_words
        abstract_reasoning
      }

      goals {
        id
        status
        new
        goals {
          id
          title
          description1
          description2
          description3
          description4
          description5
          ranking
        }
      }
      beliefs {
        id
        status
        new
        beliefs {
          id
          title
          description1
          description2
          description3
          type
        }
      }

      actions {
        id
        status
        new
        actions {
          id
          Goal {
            id
            title
          }
          priority
          status
          dueDate
          title
          description1
          description2
        }
      }

      interviewGuides {
        id
        status
        new
        status
        name
        companyName
        jobDescription
        interviewDate
        notes
        companyCulture
        companyStructure
        interviewTips {
          id
          type
          description1
        }
        interviewQuestions {
          id
          type
          description1
        }
      }
    }
  }
`;

const ADD_STRENGTH = gql`
  mutation AddStrength($activityId: ID!, $type: String!, $value: Int!) {
    addStrength(activityId: $activityId, type: $type, value: $value) {
      id
      type
      value
    }
  }
`;

const DELETE_STRENGTH = gql`
  mutation DeleteStrength($id: ID!) {
    deleteStrength(id: $id) {
      id
      type
      value
    }
  }
`;

const ADD_TRAIT = gql`
  mutation AddTrait($activityId: ID!, $type: String!, $value: Int!) {
    addTrait(activityId: $activityId, type: $type, value: $value) {
      id
      type
      value
    }
  }
`;

const DELETE_TRAIT = gql`
  mutation DeleteTrait($id: ID!) {
    deleteTrait(id: $id) {
      id
      type
      value
    }
  }
`;

const ADD_COMPETENCY = gql`
  mutation AddCompetency($activityId: ID!, $type: String!, $value: Int!) {
    addCompetency(activityId: $activityId, type: $type, value: $value) {
      id
      type
      value
    }
  }
`;

const DELETE_COMPETENCY = gql`
  mutation DeleteCompetency($id: ID!) {
    deleteCompetency(id: $id) {
      id
      type
      value
    }
  }
`;

const ADD_ABILITY = gql`
  mutation AddAbility($activityId: ID!, $type: String!, $value: Int!) {
    addAbility(activityId: $activityId, type: $type, value: $value) {
      id
      type
      value
    }
  }
`;

const DELETE_ABILITY = gql`
  mutation DeleteAbility($id: ID!) {
    deleteAbility(id: $id) {
      id
      type
      value
    }
  }
`;

const GET_ACTIVITY_FEEDBACK = gql`
  query ActivityFeedback {
    activityFeedback {
      id
      type
      level
      rating
      ratingText
      user {
        id
      }
      updatedAt
    }
  }
`;

const GET_PAYMENTS = gql`
  query Payments {
    payments {
      id
      purchaseDate
      status
      user {
        id
      }
      product {
        id
        name
      }
      coupon
    }
  }
`;

export {
  GET_USERS,
  GET_USER_DETAILS,
  GET_ACTIVITY_DETAILS,
  GET_USER_RESULTS,
  ADD_STRENGTH,
  DELETE_STRENGTH,
  ADD_TRAIT,
  DELETE_TRAIT,
  ADD_COMPETENCY,
  DELETE_COMPETENCY,
  ADD_ABILITY,
  DELETE_ABILITY,
  IMPORT_PERSONALITY_TEST,
  GET_ACTIVITY_FEEDBACK,
  GET_PAYMENTS,
};
