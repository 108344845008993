import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import AudioPlayer from '../components/AudioPlayer';
import Layout from '../components/Layout';
import Container from '../components/Container';
import Notification from '../components/Notification';
import TextContent from '../components/TextContent';
import Section from '../components/Section';
import FooterBar from '../components/FooterBar';
import Grid from '../components/Grid';
import GridItem from '../components/GridItem';
import Table from '../components/Table';

import { GET_ACTIVITY_DETAILS, UPDATE_ACTIVITY } from '../graph/activities';
import { GET_CMS_CONTENT } from '../graph/cms';
import {
  GET_EXPERIENCES,
  ADD_EXPERIENCE,
  DELETE_EXPERIENCE,
} from '../graph/experiences';
import { GET_RESULTS } from '../graph/results';

import extractGraphQLError from '../utils/extractGraphQLError';
import sleep from '../utils/sleep';
import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';
import { validate, requiredString } from '../utils/validate';
import useTrackActivityGA from '../utils/useTrackActivityGA';

import { activities, ACTIVITY_STATUS } from '../config/levels';

import { ReactComponent as RemoveIcon } from '../assets/remove-icon.svg';

const formValidationSchema = validate({
  title: requiredString,
  description1: requiredString,
});

const formInitialValues = {
  title: '',
  description1: '',
};

function ActivityExperience({ activityId }) {
  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data, loading } = useQuery(GET_ACTIVITY_DETAILS, {
    variables: { id: activityId },
  });

  const { loading: loadingExperiences, data: dataExperiences } = useQuery(
    GET_EXPERIENCES,
    {
      variables: { activityId },
    },
  );

  const { data: resultData, loading: loadingResults } = useQuery(GET_RESULTS);

  const isExperienceValid =
    dataExperiences?.experiences?.length > 4 &&
    dataExperiences?.experiences?.length < 11;

  const [
    handleAddExperience,
    { loading: loadingAddExperience, error: errorAddExperience },
  ] = useMutation(ADD_EXPERIENCE, {
    refetchQueries: ['Experiences'],
  });

  const handleNewExperience = formData => {
    handleAddExperience({
      variables: {
        activityId,
        type: 'FINAL',
        ...formData,
      },
    });
  };

  const [
    handleDeleteExperience,
    { loading: loadingDeleteExperience, error: errorDeleteExperience },
  ] = useMutation(DELETE_EXPERIENCE, {
    refetchQueries: ['Experiences'],
  });

  const [
    handleUpdateActivity,
    { loading: loadingUpdateActivity, error },
  ] = useMutation(UPDATE_ACTIVITY);

  const activityType = data?.activity?.type;

  useTrackActivityGA(data?.activity?.type, '1');

  const { level } = activities[activityType] || {};

  const {
    title,
    sub_title: subTitle,
    instructions,
    audio_instructions_1: audioInstructions,
    help_text_1: helpText,
    short_instructions_1: shortInstructions,
  } =
    cmsData?.cmsContent?.activity?.find(
      activity =>
        activity.id ===
        (cmsData?.user?.currentLevel === 4 &&
        data?.activity?.status === ACTIVITY_STATUS.COMPLETED
          ? `ongoing_${activityType}`
          : activityType),
    ) ||
    cmsData?.cmsContent?.activity?.find(
      activity => activity.id === activityType,
    ) ||
    {};

  const dataWithCMSValues = resultData?.results?.competencies?.competencies?.map(
    item => {
      const cmsValue = cmsData?.cmsContent?.competency?.find(
        value => item.type === value.id,
      );

      return {
        ...item,
        value: extractPrismicString(cmsValue?.title),
        title: cmsValue?.title,
        description: cmsValue?.text,
      };
    },
  );

  const formFields = [
    {
      id: 'title',
      name: 'title',
      label: 'Competency',
      type: 'select',
      defaultLabel: 'Enter a competency...',
      options: dataWithCMSValues || [],
      medium: true,
    },
    {
      id: 'description1',
      name: 'description1',
      label: 'Experience',
      type: 'textarea',
      placeholder:
        'Due to COVID my studies had to change from classroom based to remote. For one of my classes, 2 of my classmates and I designed a presentation that involved quite a bit of in-person activities. Instead of asking the Professor to reschedule, I set up a daily zoom meeting with my teammates to redesign the presentation and run it fully digital. Being able to adapt to change helped me to pivot quickly and help my team to change our presentation rather than asking to cancel it. In this way, I not only gained new skills on how to present digitally, but also gained respect and a good grade from my Professor for rising to the challenge and giving a great presentation to the rest of the class.',
    },
  ];

  const topComptencies = dataWithCMSValues?.slice(0, 5);

  const handleCompleteActivity = async () => {
    await handleUpdateActivity({
      variables: {
        id: activityId,
        status: ACTIVITY_STATUS.COMPLETED,
      },
    });

    await sleep(500);

    navigate(`/activities/${activityId}/completed`);
  };

  return (
    <Layout
      title={`${extractPrismicString(title)}`}
      loading={loading || loadingExperiences || loadingResults}
      hasFooterBar
    >
      <Container>
        <Section>
          {!!(hasContent(shortInstructions) || hasContent(helpText)) && (
            <Grid>
              <GridItem width={60}>
                <TextContent redh6 smallMargin>
                  {extractPrismicContent(shortInstructions)}
                </TextContent>
              </GridItem>

              <GridItem width={40}>
                {!!(audioInstructions?.url || hasContent(helpText)) && (
                  <Notification showUser noMarginBottom>
                    {extractPrismicContent(helpText)}

                    {!!audioInstructions?.url && (
                      <AudioPlayer fileUrl={audioInstructions?.url} />
                    )}
                  </Notification>
                )}
              </GridItem>
            </Grid>
          )}
        </Section>

        <Section smallPadding>
          <h3>Your top competencies that come easiest for you</h3>

          <Table
            data={topComptencies}
            redorderId="topcomp"
            cols={[
              {
                id: 'title',
                value: 'Competency',
                medium: true,
                transformer: value => (
                  <strong>{extractPrismicString(value)}</strong>
                ),
              },
              {
                id: 'description',
                value: 'Description',
                transformer: value => extractPrismicString(value),
              },
            ]}
          />
        </Section>

        <TextContent redh6 smallMargin>
          <p>Enter between 5 and 10 experiences</p>
        </TextContent>

        <Table
          cols={[
            { id: 'title', value: 'Competency', medium: true },
            { id: 'description1', value: 'Experience' },
          ]}
          data={dataExperiences?.experiences}
          actions={[
            {
              id: 'remove',
              text: 'remove',
              icon: <RemoveIcon />,
              onClick: handleDeleteExperience,
              loading: loadingDeleteExperience,
            },
          ]}
          form={{
            fields: formFields,
            loading: loadingAddExperience,
            validationSchema: formValidationSchema,
            initialValues: formInitialValues,
            onSubmit: handleNewExperience,
          }}
        />

        {!!(error || errorAddExperience || errorDeleteExperience) && (
          <Notification type="red">
            {extractGraphQLError(
              error || errorAddExperience || errorDeleteExperience,
            )}
          </Notification>
        )}
      </Container>

      <FooterBar
        title={extractPrismicString(title)}
        subTitle={extractPrismicString(subTitle)}
        primaryButton={{
          label: 'Complete Activity',
          onClick: handleCompleteActivity,
          loading: loadingUpdateActivity,
          disabled: !isExperienceValid,
          level,
        }}
        secondaryButton={{
          label: hasContent(instructions)
            ? 'Back to instructions'
            : 'Back to introduction',
          to: `/activities/${activityId}/${
            hasContent(instructions) ? 'instructions' : 'introduction'
          }`,
        }}
      />
    </Layout>
  );
}

ActivityExperience.propTypes = {
  activityId: PropTypes.string,
};

ActivityExperience.defaultProps = {
  activityId: null,
};

export default ActivityExperience;
