import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveCirclePacking } from '@nivo/circle-packing';
import styled from 'styled-components';

import transformArrayToBubbleChartObject from '../utils/transformArrayToBubbleChartObject';

import main, { colors } from '../theme/main';

const colorMap = {
  5: colors.green500,
  4: colors.green450,
  3: colors.green400,
  2: colors.green350,
  1: colors.green300,
};

const colorTextMap = {
  5: colors.white,
  4: colors.white,
  3: colors.white,
  2: colors.grey500,
  1: colors.grey500,
};

const fontSizeMap = {
  5: '12px',
  4: '11px',
  3: '10px',
  2: '8px',
  1: '8px',
};

const ChartWrapper = styled.div`
  height: calc(
    ${(props) => props.theme.components.chartHeight} +
      ${(props) => props.theme.spacing.midLarge} +
      ${(props) => props.theme.spacing.midLarge}
  );
  margin: -${(props) => props.theme.spacing.midLarge};

  ${(props) =>
    props.noMargin &&
    `
      margin: 0;
    `}
`;

function ValuesVisualisation({ data, cmsContent, ...props }) {
  const numberOfCircles = 5;

  if (!data?.values) return false;

  const dataOjbect = transformArrayToBubbleChartObject(
    data?.values,
    numberOfCircles,
    'ranking',
    cmsContent,
  );

  console.log('dataOjbect', dataOjbect);

  return (
    <ChartWrapper {...props}>
      <ResponsiveCirclePacking
        data={dataOjbect}
        identity="label"
        value="value"
        padding={4}
        margin={{
          top: 20,
          bottom: 20,
          right: 35,
        }}
        leavesOnly
        animate
        enableLabels
        colorBy="value"
        colors={({ value }) => colorMap[value]}
        // eslint-disable-next-line
        label={({ id, data, ...rest }) => {
          return (
            <tspan
              overflow="hidden"
              fontSize={fontSizeMap[data?.value]}
              fill={colorTextMap[data?.value]}
              fontFamily={main.base.fontFamily}
            >
              {data.label}
            </tspan>
          );
        }}
        tooltipFormat={(value) => numberOfCircles - value + 1}
      />
    </ChartWrapper>
  );
}

ValuesVisualisation.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  cmsContent: PropTypes.shape({}).isRequired,
};

export default ValuesVisualisation;
