import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';

import TextContent from "./TextContent";
import Grid from "./Grid";
import GridItem from "./GridItem";
import Card from "./Card";
import Button from './Button';
import Container from "./Container";
import Section from "./Section";
import Notification from "./Notification";

import { CREATE_ORDER } from '../graph/order'

import {
  extractPrismicString,
  extractPrismicContent,
} from '../utils/prismic';
import extractGraphQLError from '../utils/extractGraphQLError';

const Action = styled.div`
  margin-top: auto;
`;

function Marketplace({ marketplace_title: title , marketplace_sub_title: subTitle, products}) {
  const [success, setSuccess] = useState(false);

  const [
    handleAddActivity,
    { loading, error },
  ] = useMutation(CREATE_ORDER, {
    async onCompleted(response) {
      if (!response?.createOrder?.id) return;

      setSuccess(true);
    },
  });

  return (
    <Section smallPadding>
      <Container narrow>
        <TextContent marginBottom center>
          <h2>{extractPrismicString(title)}</h2>
          <p>{extractPrismicString(subTitle)}</p>

        </TextContent>

        <Grid>
          {products.map(product => (
            <GridItem width={50} key={extractPrismicString(product.product_uid)} grow>
              <Card noMargin>
                <TextContent smallMargin noMarginTop small marginBottom>
                  <h2>{extractPrismicString(product.product_title)}</h2>
                  <h3>{extractPrismicString(product.price)}</h3>

                  {extractPrismicContent(product.details)}
                </TextContent>

                <Action>
                  <Button
                    disabled={loading}
                    onClick={() => handleAddActivity({variables: { marketplaceProduct: extractPrismicString(product.product_uid) }})}
                  >
                    Register your interest
                  </Button>
                </Action>
              </Card>
            </GridItem>
          ))}
        </Grid>

        {success && (
          <Notification type="green">Thank you for registering your interest, an advisor will be in touch via email with the next steps.</Notification>
        )}

        {!!error && (
          <Notification type="red">{extractGraphQLError(error)}</Notification>
        )}
   
      </Container>
    </Section>
  )
}

Marketplace.propTypes = {
  marketplace_title: PropTypes.string.isRequired,
  marketplace_sub_title: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Marketplace;
