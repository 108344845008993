import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from './Button';

import { ReactComponent as ChevronIcon } from '../assets/chevron-right-icon.svg';
import TextContent from './TextContent';

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spacing.base}
    ${props => props.theme.spacing.midLarge}
    ${props => props.theme.spacing.base};
  margin: 0 -${props => props.theme.spacing.midLarge} 0;
  height: 80px;
  border-top: solid 1px ${props => props.theme.colors.grey200};
  flex: 1 0 auto;
  width: calc(
    100% + ${props => props.theme.spacing.midLarge} +
      ${props => props.theme.spacing.midLarge}
  );

  &:first-child {
    border-top: none;
  }

  h4 {
    display: flex;
    align-items: center;
    font-size: ${props => props.theme.fontSizes.medium};
    line-height: 1;
    margin-bottom: ${props => props.theme.spacing.xxSmall};

    &:last-child {
      margin-bottom: 0;
    }
  }

  button {
    transform: rotate(-90deg);
    margin-left: auto;
  }

  ${props =>
    props.open &&
    `
     border-bottom: solid 1px ${props.theme.colors.grey200};

     button {
      transform: rotate(90deg);
    }
  `}
`;

const Inner = styled.div`
  padding: ${props => props.theme.spacing.base} 0;
`;

function Expandable({ title, children }) {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <Header open={open}>
        <div>
          <h4>{title}</h4>
        </div>

        {!!children && (
          <Button small round onClick={handleToggle}>
            <ChevronIcon />
          </Button>
        )}
      </Header>

      {!!(open && children) && (
        <Inner>
          <TextContent>{children}</TextContent>
        </Inner>
      )}
    </>
  );
}

Expandable.propTypes = {
  title: PropTypes.shape({}).isRequired,
  children: PropTypes.node,
};

Expandable.defaultProps = {
  children: undefined,
};

export default Expandable;
