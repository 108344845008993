import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dayjs from 'dayjs';

import TextContent from './TextContent';

const ChartWrapper = styled.div`
  height: 172px;
  display: flex;
`;

const ChartInner = styled.div`
  margin: auto;
  padding: ${props => props.theme.spacing.small} 0;
  width: 100%;
`;

function VisionSummary({ data }) {
  return (
    <ChartWrapper>
      <ChartInner>
        <TextContent redh6 smallMargin>
          <h6>{data?.companyName}</h6>
          <h1>{data?.name}</h1>
          {data?.interviewDate && (
            <p>
              Interview date{' '}
              <strong>
                {dayjs(data?.interviewDate).format('D MMMM YYYY')}
              </strong>
            </p>
          )}
        </TextContent>
      </ChartInner>
    </ChartWrapper>
  );
}

VisionSummary.propTypes = {
  data: PropTypes.string.isRequired,
};

export default VisionSummary;
