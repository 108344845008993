import styled from 'styled-components';

const Grid = styled.div`
  ${props => props.theme.breakpoints.medium`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -${props.theme.spacing.small};
  `}

  ${props =>
    props.center &&
    `
    justify-content: center;
  `}


${props =>
  props.largeGutter &&
  props.theme.breakpoints.medium`
    margin: 0 -${props.theme.spacing.midLarge};
  `}

  ${props =>
    props.reverse &&
    `
    flex-direction: row-reverse;
  `}
`;

export default Grid;
