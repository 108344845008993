import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Link } from '@reach/router';
import Layout from '../components/Layout';
import Container from '../components/Container';
import Section from '../components/Section';
import Table from '../components/Table';
import Select from '../components/Select';
import Notification from '../components/Notification';

import {
  GET_ACTIVITY_DETAILS,
  ADD_STRENGTH,
  DELETE_STRENGTH,
  ADD_TRAIT,
  DELETE_TRAIT,
  ADD_COMPETENCY,
  DELETE_COMPETENCY,
  ADD_ABILITY,
  DELETE_ABILITY,
} from '../graph/admin';
import { UPDATE_ACTIVITY } from '../graph/activities';

import { ACTIVITY_STATUS } from '../config/levels';
import { STRENGTHS, TRAITS, COMPETENCIES, ABILITIES } from '../config/types';

import extractGraphQLError from '../utils/extractGraphQLError';
import { validate, requiredString, requiredNumber } from '../utils/validate';

import { ReactComponent as RemoveIcon } from '../assets/remove-icon.svg';

const FormWrapper = styled.form`
  width: 100%;
  max-width: 480px;
`;

function AdminActivityDetails({ activityId }) {
  const { data, loading } = useQuery(GET_ACTIVITY_DETAILS, {
    variables: { activityId },
  });

  const [
    handleUpdateActivity,
    { loading: loadingUpdateActivity, error },
  ] = useMutation(UPDATE_ACTIVITY);

  const handleStatusChange = e => {
    handleUpdateActivity({
      variables: {
        id: activityId,
        status: e.target.value,
      },
    });
  };

  const [
    handleAddStrength,
    { loading: loadingAddStrength, error: errorAddStrength },
  ] = useMutation(ADD_STRENGTH, {
    refetchQueries: ['ActivityDetails'],
  });

  const handleNewStrength = formData => {
    handleAddStrength({
      variables: {
        activityId,
        ...formData,
      },
    });
  };

  const [
    handleDeleteStrength,
    { loading: loadingDeleteStrength, error: errorDeleteStrength },
  ] = useMutation(DELETE_STRENGTH, {
    refetchQueries: ['ActivityDetails'],
  });

  const [
    handleAddTrait,
    { loading: loadingAddTrait, error: errorAddTrait },
  ] = useMutation(ADD_TRAIT, {
    refetchQueries: ['ActivityDetails'],
  });

  const handleNewTrait = formData => {
    handleAddTrait({
      variables: {
        activityId,
        ...formData,
      },
    });
  };

  const [
    handleDeleteTrait,
    { loading: loadingDeleteTrait, error: errorDeleteTrait },
  ] = useMutation(DELETE_TRAIT, {
    refetchQueries: ['ActivityDetails'],
  });

  const [
    handleAddCompetency,
    { loading: loadingAddCompetency, error: errorAddCompetency },
  ] = useMutation(ADD_COMPETENCY, {
    refetchQueries: ['ActivityDetails'],
  });

  const handleNewCompetency = formData => {
    handleAddCompetency({
      variables: {
        activityId,
        ...formData,
      },
    });
  };

  const [
    handleDeleteCompetency,
    { loading: loadingDeleteCompetency, error: errorDeleteCompetency },
  ] = useMutation(DELETE_COMPETENCY, {
    refetchQueries: ['ActivityDetails'],
  });

  const [
    handleAddAbility,
    { loading: loadingAddAbility, error: errorAddAbility },
  ] = useMutation(ADD_ABILITY, {
    refetchQueries: ['ActivityDetails'],
  });

  const handleNewAbility = formData => {
    handleAddAbility({
      variables: {
        activityId,
        ...formData,
      },
    });
  };

  const [
    handleDeleteAbility,
    { loading: loadingDeleteAbility, error: errorDeleteAbility },
  ] = useMutation(DELETE_ABILITY, {
    refetchQueries: ['ActivityDetails'],
  });

  const activityType = data?.activityDetails?.type;

  const renderContent = () => {
    if (activityType === 'strength_assessment') {
      return (
        <Section>
          <h3>Character Strengths</h3>

          <Table
            cols={[
              { id: 'type', value: 'Type' },
              { id: 'value', value: 'Value' },
            ]}
            data={data?.activityDetails?.strengths}
            actions={[
              {
                id: 'remove',
                text: 'remove',
                icon: <RemoveIcon />,
                onClick: handleDeleteStrength,
                loading: loadingDeleteStrength,
              },
            ]}
            form={{
              fields: [
                {
                  id: 'type',
                  name: 'type',
                  label: 'Type',
                  type: 'select',
                  defaultLabel: 'Select type',
                  options: Object.values(STRENGTHS).map(value => ({
                    id: value,
                    value,
                  })),
                },
                {
                  id: 'value',
                  name: 'value',
                  label: 'Value',
                  type: 'number',
                  placeholder: 'Enter value (ranking)',
                },
              ],
              loading: loadingAddStrength,
              validationSchema: validate({
                type: requiredString,
                value: requiredNumber,
              }),
              initialValues: {
                type: '',
                value: '',
              },
              onSubmit: handleNewStrength,
            }}
          />
        </Section>
      );
    }

    if (activityType === 'personality_test') {
      return (
        <Section>
          <h3>Personality Traits</h3>

          <Table
            cols={[
              { id: 'type', value: 'Type' },
              { id: 'value', value: 'Value' },
            ]}
            data={data?.activityDetails?.traits}
            actions={[
              {
                id: 'remove',
                text: 'remove',
                icon: <RemoveIcon />,
                onClick: handleDeleteTrait,
                loading: loadingDeleteTrait,
              },
            ]}
            form={{
              fields: [
                {
                  id: 'type',
                  name: 'type',
                  label: 'Type',
                  type: 'select',
                  defaultLabel: 'Select type',
                  options: Object.values(TRAITS).map(value => ({
                    id: value,
                    value,
                  })),
                },
                {
                  id: 'value',
                  name: 'value',
                  label: 'Value',
                  type: 'number',
                  placeholder: 'Enter value (percentile)',
                },
              ],
              loading: loadingAddTrait,
              validationSchema: validate({
                type: requiredString,
                value: requiredNumber,
              }),
              initialValues: {
                type: '',
                value: '',
              },
              onSubmit: handleNewTrait,
            }}
          />

          <Section>
            <h3>Competencies</h3>

            <Table
              redorderId="com-id"
              cols={[
                { id: 'type', value: 'Type' },
                { id: 'value', value: 'Value' },
              ]}
              data={data?.activityDetails?.competencies}
              actions={[
                {
                  id: 'remove',
                  text: 'remove',
                  icon: <RemoveIcon />,
                  onClick: handleDeleteCompetency,
                  loading: loadingDeleteCompetency,
                },
              ]}
              form={{
                fields: [
                  {
                    id: 'type',
                    name: 'type',
                    label: 'Type',
                    type: 'select',
                    defaultLabel: 'Select type',
                    options: Object.values(COMPETENCIES).map(value => ({
                      id: value,
                      value,
                    })),
                  },
                  {
                    id: 'value',
                    name: 'value',
                    label: 'Value',
                    type: 'number',
                    placeholder: 'Enter value (percentile)',
                  },
                ],
                loading: loadingAddCompetency,
                validationSchema: validate({
                  type: requiredString,
                  value: requiredNumber,
                }),
                initialValues: {
                  type: '',
                  value: '',
                },
                onSubmit: handleNewCompetency,
              }}
            />
          </Section>
        </Section>
      );
    }

    if (activityType === 'cognative_ability_test') {
      return (
        <Section>
          <h3>Abilities</h3>

          <Table
            cols={[
              { id: 'type', value: 'Type' },
              { id: 'value', value: 'Value' },
            ]}
            data={data?.activityDetails?.abilities}
            actions={[
              {
                id: 'remove',
                text: 'remove',
                icon: <RemoveIcon />,
                onClick: handleDeleteAbility,
                loading: loadingDeleteAbility,
              },
            ]}
            form={{
              fields: [
                {
                  id: 'type',
                  name: 'type',
                  label: 'Type',
                  type: 'select',
                  defaultLabel: 'Select type',
                  options: Object.values(ABILITIES).map(value => ({
                    id: value,
                    value,
                  })),
                },
                {
                  id: 'value',
                  name: 'value',
                  label: 'Value',
                  type: 'number',
                  placeholder: 'Enter value (percentile)',
                },
              ],
              loading: loadingAddAbility,
              validationSchema: validate({
                type: requiredString,
                value: requiredNumber,
              }),
              initialValues: {
                type: '',
                value: '',
              },
              onSubmit: handleNewAbility,
            }}
          />
        </Section>
      );
    }

    return false;
  };

  return (
    <Layout title="AdminActivityDetails" loading={loading}>
      <Container>
        <Section>
          <p>
            <Link to={`/users/${data?.activityDetails?.userId}`}>
              Back to activities
            </Link>
          </p>

          <h2>Activity details</h2>

          <p>
            Type
            <br />
            <strong>{activityType}</strong>
          </p>

          <FormWrapper>
            <Select
              onChange={handleStatusChange}
              label="Change Status"
              name="status"
              id="status"
              value={data?.activityDetails?.status}
              options={Object.values(ACTIVITY_STATUS).map(value => ({
                id: value,
                value,
              }))}
              disabled={loadingUpdateActivity}
            />
          </FormWrapper>

          {!!(
            error ||
            errorAddStrength ||
            errorDeleteStrength ||
            errorAddTrait ||
            errorDeleteTrait ||
            errorAddCompetency ||
            errorDeleteCompetency ||
            errorAddAbility ||
            errorDeleteAbility
          ) && (
            <Notification type="red">
              {extractGraphQLError(
                error ||
                  errorAddStrength ||
                  errorDeleteStrength ||
                  errorAddTrait ||
                  errorDeleteTrait ||
                  errorAddCompetency ||
                  errorDeleteCompetency ||
                  errorAddAbility ||
                  errorDeleteAbility,
              )}
            </Notification>
          )}

          {renderContent()}
        </Section>
      </Container>
    </Layout>
  );
}

AdminActivityDetails.propTypes = {
  activityId: PropTypes.string,
};

AdminActivityDetails.defaultProps = {
  activityId: null,
};

export default AdminActivityDetails;
