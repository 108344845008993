import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from './Button';
import Card from './Card';
import TextContent from './TextContent';
import StatusIcon from './StatusIcon';
import ValuesVisualisation from './ValuesVisualisation';
import StrengthsVisualisation from './StrengthsVisualisation';
import CultureVisualisation from './CultureVisualisation';
import OrganisationVisualisation from './OrganisationVisualisation';
import InterestsVisualisation from './InterestsVisualisation';
import PersonalityVisualisation from './PersonalityVisualisation';
import RolesVisualisation from './RolesVisualisation';
import CompetenciesVisualisation from './CompetenciesVisualisation';
import ExperiencesVisualisation from './ExperiencesVisualisation';
import AbilitiesVisualisation from './AbilitiesVisualisation';
import BeliefsVisualisation from './BeliefsVisualisation';
import GoalsVisualisation from './GoalsVisualisation';
import ActionsVisualisation from './ActionsVisualisation';
import InterviewGuideVisualisation from './InterviewGuideVisualisation';
import VisionSummary from './VisionSummary';
import CardInner from './CardInner';

import { extractPrismicString } from '../utils/prismic';

import { ACTIVITY_STATUS } from '../config/levels';

const IconWrapper = styled.div`
  svg {
    fill: ${props => props.theme.colors.green500};
    width: 30px;
    height: 30px;
    display: block;
    margin: auto;
  }
`;

function ResultsSummaryCard({
  id,
  level,
  cmsContent,
  currentLevel,
  resultData,
  result,
}) {
  const { title, subtitle } =
    cmsContent?.result?.find(resultItem => resultItem.id === id) || {};

  const resultWithData = result || resultData?.[id];

  const isDisabled =
    currentLevel < level ||
    resultWithData?.status === ACTIVITY_STATUS.PENDING ||
    !resultWithData;

  const renderContent = () => {
    if (resultWithData?.status === ACTIVITY_STATUS.PENDING) {
      return (
        <CardInner>
          <TextContent center>
            <IconWrapper>
              <StatusIcon status={resultWithData?.status} center />
            </IconWrapper>

            <h4>We're working on it</h4>
            <p>
              Hang tight, we're currently processing your data and your result
              will be ready soon
            </p>
          </TextContent>
        </CardInner>
      );
    }

    if (resultWithData?.status !== ACTIVITY_STATUS.COMPLETED) {
      return (
        <CardInner>
          <TextContent center>
            <IconWrapper>
              <StatusIcon status={resultWithData?.status} center />
            </IconWrapper>

            <h4>Results not available</h4>
            <p>You need to complete the activity to view these result</p>

            <Button small to="/activities">
              Start activity
            </Button>
          </TextContent>
        </CardInner>
      );
    }

    if (resultWithData && id === 'values') {
      return (
        <ValuesVisualisation data={resultWithData} cmsContent={cmsContent} />
      );
    }

    if (resultWithData && id === 'strengths') {
      return <StrengthsVisualisation data={resultWithData} />;
    }

    if (resultWithData && id === 'culture') {
      return <CultureVisualisation data={resultWithData} />;
    }

    if (resultWithData && id === 'organisation') {
      return <OrganisationVisualisation data={resultWithData} />;
    }

    if (resultWithData && id === 'interests') {
      return <InterestsVisualisation data={resultWithData} />;
    }

    if (resultWithData && id === 'vision') {
      return <VisionSummary data={resultWithData} />;
    }

    if (resultWithData && id === 'personality') {
      return <PersonalityVisualisation data={resultWithData} />;
    }

    if (resultWithData && id === 'roles') {
      return <RolesVisualisation data={resultWithData} />;
    }

    if (resultWithData && id === 'competencies') {
      return <CompetenciesVisualisation data={resultWithData} />;
    }

    if (resultWithData && id === 'experiences') {
      return <ExperiencesVisualisation data={resultWithData} />;
    }

    if (resultWithData && id === 'abilities') {
      return <AbilitiesVisualisation data={resultWithData} />;
    }

    if (resultWithData && id === 'beliefs') {
      return <BeliefsVisualisation data={resultWithData} />;
    }

    if (resultWithData && id === 'goals') {
      return <GoalsVisualisation data={resultWithData} />;
    }

    if (resultWithData && id === 'actions') {
      return <ActionsVisualisation data={resultWithData} />;
    }

    if (resultWithData && id === 'interview_guide') {
      return <InterviewGuideVisualisation data={resultWithData} />;
    }
    return false;
  };

  return (
    <Card
      new={resultWithData?.new}
      header={extractPrismicString(title)}
      subheader={extractPrismicString(subtitle)}
      to={`/results/${id}/${resultWithData?.id}`}
      isDisabled={isDisabled}
      flex
    >
      {renderContent()}
    </Card>
  );
}

ResultsSummaryCard.propTypes = {
  id: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  cmsContent: PropTypes.shape({}).isRequired,
  currentLevel: PropTypes.number.isRequired,
  resultData: PropTypes.shape({}).isRequired,
  result: PropTypes.shape({}),
};

ResultsSummaryCard.defaultProps = {
  result: null,
};

export default ResultsSummaryCard;
