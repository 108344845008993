export const STRENGTHS = {
  honesty: 'honesty',
  perspective: 'perspective',
  humility: 'humility',
  perseverance: 'perseverance',
  humor: 'humor',
  judgment: 'judgment',
  bravery: 'bravery',
  leadership: 'leadership',
  hope: 'hope',
  kindness: 'kindness',
  curiosity: 'curiosity',
  creativity: 'creativity',
  fairness: 'fairness',
  social_intelligence: 'social_intelligence',
  self_regulation: 'self_regulation',
  love: 'love',
  gratitude: 'gratitude',
  zest: 'zest',
  teamwork: 'teamwork',
  prudence: 'prudence',
  love_of_learning: 'love_of_learning',
  appreciation_of_beauty_and_excellence:
    'appreciation_of_beauty_and_excellence',
  forgiveness: 'forgiveness',
  spirituality: 'spirituality',
};

export const TRAITS = {
  accommodating: 'accommodating',
  collaborative: 'collaborative',
  empathetic: 'empathetic',
  adaptable: 'adaptable',
  flexible: 'flexible',
  spontaneous: 'spontaneous',
  conceptual: 'conceptual',
  imaginative: 'imaginative',
  radical: 'radical',
  sociable: 'sociable',
  demonstrative: 'demonstrative',
  takes_charge: 'takes_charge',
  tough: 'tough',
  competitive: 'competitive',
  logical: 'logical',
  purposeful: 'purposeful',
  structured: 'structured',
  reliable: 'reliable',
  practical: 'practical',
  evidence_based: 'evidence_based',
  cautious: 'cautious',
  measured: 'measured',
  intimate: 'intimate',
  observing: 'observing',
};

export const COMPETENCIES = {
  adapting_to_change: 'adapting_to_change',
  agile_learning: 'agile_learning',
  conceptualising_strategies: 'conceptualising_strategies',
  fostering_creativity: 'fostering_creativity',
  working_under_pressure: 'working_under_pressure',
  engaging_and_energising: 'engaging_and_energising',
  providing_direction: 'providing_direction',
  purposeful_argumentation: 'purposeful_argumentation',
  pursuing_and_achieving_goals: 'pursuing_and_achieving_goals',
  planning_and_organising: 'planning_and_organising',
  ensuring_accountability: 'ensuring_accountability',
  gathering_and_analysing_information: 'gathering_and_analysing_information',
  supporting_others: 'supporting_others',
  coaching_and_developing_others: 'coaching_and_developing_others',
  working_together: 'working_together',
  being_interpersonally_astute: 'being_interpersonally_astute',
};

export const ABILITIES = {
  working_with_numbers: 'working_with_numbers',
  working_with_words: 'working_with_words',
  abstract_reasoning: 'abstract_reasoning',
};

export const CULTURES = {
  purpose: 'Purpose',
  caring: 'Caring',
  order: 'Order',
  safety: 'Safety',
  results: 'Results',
  authority: 'Authority',
  enjoyment: 'Enjoyment',
  learning: 'Learning',
};

export const ORGANISATIONS = {
  entrepreneurial: 'Entrepreneurial',
  smoagile: 'SMO / Agile',
  bureaucratic: 'Bureaucratic',
  professional: 'professional',
};

export const PRIORITIES = {
  HIGH: 'High',
  MEDIUM: 'Medium',
  LOW: 'Low',
};
