import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useApolloClient } from '@apollo/react-hooks';
import { navigate } from '@reach/router';
import queryString from 'query-string';
import dayjs from 'dayjs';

import Button from '../components/Button';
import Card from '../components/Card';
import Layout from '../components/Layout';
import Form from '../components/Form';
import Container from '../components/Container';
import Section from '../components/Section';
import Sidebar from '../components/Sidebar';
import TextContent from '../components/TextContent';
import Notification from '../components/Notification';
import Loader from '../components/Loader';
import Modal from '../components/Modal';

import {
  IS_AUTHENTICATED,
  UPDATE_USER,
  DEACTIVATE_USER,
  UPDATE_USER_LINKEDIN,
} from '../graph/auth';
import { GET_CMS_CONTENT } from '../graph/cms';
import { GET_PRODUCTS, CANCEL_SUBSCRIPTION } from '../graph/payment';
import { GET_CURRENCY } from '../graph/currency';

import { validate, requiredString } from '../utils/validate';
import extractGraphQLError from '../utils/extractGraphQLError';
import formatCurrency from '../utils/formatCurrency';
import getValue from '../utils/getValue';

import { countryList } from '../config/fields';

import useSiteSettings from '../hooks/useSiteSettings';

const profileFormFields = [
  {
    id: 'email',
    type: 'email',
    name: 'email',
    label: 'Email Address',
    readOnly: true,
  },
  {
    id: 'phoneNumber',
    type: 'phone',
    name: 'phoneNumber',
    label: 'Phone number',
  },
  {
    id: 'firstName',
    type: 'text',
    name: 'firstName',
    label: 'First name',
  },
  {
    id: 'lastName',
    type: 'text',
    name: 'lastName',
    label: 'Last name',
  },

  {
    id: 'country',
    type: 'select',
    name: 'country',
    label: 'Country',
    defaultLabel: 'Select country',
    options: countryList,
  },
  {
    id: 'city',
    type: 'text',
    name: 'city',
    label: 'City / Town',
  },
  {
    id: 'dateOfBirth',
    type: 'date',
    name: 'dateOfBirth',
    label: 'Date of Birth',
  },
  {
    id: 'profession',
    type: 'select',
    name: 'profession',
    label: 'Current Status',
    defaultLabel: 'Select status',
    options: [
      { id: 'Student', value: 'Student' },
      { id: 'Employed', value: 'Employed' },
      { id: 'Currently Looking', value: 'Currently Looking' },
      { id: 'Self-Employed', value: 'Self-Employed' },
      { id: 'Other', value: 'Other' },
    ],
  },
];

const redirectUri = `${process.env.REACT_APP_URL}profile`;

const profileFormValidationSchema = validate({
  name: requiredString,
});

function Profile() {
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const client = useApolloClient();

  const { siteName, supportEmail } = useSiteSettings();

  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data, loadingProducts } = useQuery(GET_PRODUCTS, {
    variables: { type: 'subscription' },
    fetchPolicy: 'network-only',
    skip: cmsData?.user?.hasActiveSubscription,
  });

  const {
    data: { currency },
  } = useQuery(GET_CURRENCY);

  const [handleConnectLinkedIn, { loading: loadingLinkedIn }] =
    useMutation(UPDATE_USER_LINKEDIN);

  const [handleProfileUpdate, { loading, error: errorUpdate, called }] =
    useMutation(UPDATE_USER);

  const [
    handleDeactivateUser,
    { loading: loadingDeactivate, error: errorDeactivate },
  ] = useMutation(DEACTIVATE_USER);

  const [handleCancelSubscription, { loading: loadingCancelSubscription }] =
    useMutation(CANCEL_SUBSCRIPTION);

  const handleShowDeactiveModal = () => {
    setShowDeactivateModal(true);
  };

  const handleHideDeactiveModal = () => {
    setShowDeactivateModal(false);
  };

  const handleDeactivateAccount = async ({ variables }) => {
    const result = await handleDeactivateUser({
      variables: { ...variables, id: cmsData?.user?.id },
    });

    if (!result?.data?.deactivateUser?.success) return;

    await client.cache.writeQuery({
      query: IS_AUTHENTICATED,
      data: {
        isAuthenticated: false,
      },
    });

    navigate('/');

    client.clearStore();
  };

  const { code, error: urlError } =
    queryString.parse(window.location.search) || {};

  useEffect(() => {
    const linkedInConnect = async () => {
      const response = await handleConnectLinkedIn({
        variables: { code, id: cmsData?.user?.id, redirectUri },
      });

      if (response.errors) {
        setError(
          'There has been a problem connecting your LinkedIn Account, please try again.',
        );

        return;
      }

      if (response.data.updateUserLinkedIn) {
        setSuccess('Your LinkedIn account has been connected successfully');
      }
    };

    if (urlError) {
      setError(
        'There has been a problem connecting your LinkedIn Account, please try again.',
      );

      return;
    }

    if (!code) return;

    linkedInConnect();
  }, []); //eslint-disable-line

  const deactivateFormFields = [
    {
      id: 'deactivateFeedback',
      type: 'textarea',
      name: 'deactivateFeedback',
      label: `We're really sad to see you go! Any feedback would be greatly appricated so we can continue to improve ${siteName}.`,
    },
  ];

  const showConnect =
    !loadingLinkedIn && !success && !cmsData?.user?.profilePicture;

  return (
    <Layout title="Profile" nofooter noProgressBar loading={loadingProducts}>
      <Container hasSidebar>
        <Section>
          <Card header="Your Subscription">
            <p>
              You're currently on the {siteName}{' '}
              <strong>
                {cmsData?.user?.hasActiveSubscription ? 'Premium' : 'Free'}{' '}
                account
              </strong>
              . This gives you full access to{' '}
              {cmsData?.user?.hasActiveSubscription
                ? `all ${siteName} premium activities`
                : `one ${siteName} free activity`}
              .
            </p>

            {!cmsData?.user?.hasActiveSubscription && (
              <>
                <p>
                  To access all the premium activities please{' '}
                  <strong>
                    upgrade your account to Premium for{' '}
                    {formatCurrency(
                      getValue(data?.products?.[0], currency),
                      currency,
                      true,
                    )}
                    + VAT
                  </strong>
                  .
                </p>
                <Button to="/payment">Upgrade to Premium</Button>
              </>
            )}

            {cmsData?.user?.currentSubscription && (
              <div>
                <p>
                  <strong>Last bill date</strong>{' '}
                  {dayjs
                    .unix(cmsData?.user?.currentSubscription.lastBillDate)
                    .format('D MMMM YYYY')}
                </p>

                <p>
                  <strong>Next bill date</strong>{' '}
                  {dayjs
                    .unix(cmsData?.user?.currentSubscription.nextBillDate)
                    .format('D MMMM YYYY')}
                </p>

                <p>
                  <strong>Amount</strong>{' '}
                  {formatCurrency(
                    cmsData?.user?.currentSubscription.price,
                    cmsData?.user?.currentSubscription.currency,
                  )}{' '}
                  per month
                </p>

                <Button
                  onClick={() => handleCancelSubscription()}
                  disabled={loadingCancelSubscription}
                >
                  Cancel Subscription
                </Button>
              </div>
            )}
          </Card>
        </Section>
        <Section noPaddingTop>
          <Card header="Update your profile">
            {showConnect && (
              <Notification showUser>
                <p>
                  Why not connect your LinkedIn account to automatically pull in
                  your profile picture.
                </p>
                <Button
                  href={`https://www.linkedin.com/oauth/v2/authorization?client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=code&scope=r_liteprofile`}
                >
                  Connect your account
                </Button>
              </Notification>
            )}

            {error && <Notification type="red">{error}</Notification>}
            {success && <Notification type="green">{success}</Notification>}

            {loadingLinkedIn ? (
              <Section>
                <Loader />
              </Section>
            ) : (
              <Form
                fields={profileFormFields}
                onSubmit={handleProfileUpdate}
                initialValues={cmsData?.user}
                validationSchema={profileFormValidationSchema}
                loading={loading}
                error={!!errorUpdate}
                errorMessage="There has been a problem updating your profile"
                success={!!(!loading && !errorUpdate && called)}
                successMessage="Profile updated successfully"
                submitButtonText="Update"
                container
              />
            )}
          </Card>
        </Section>
        <Section noPaddingTop>
          <Card header="Danger zone">
            <TextContent>
              <p>
                Once you deactivate your account, there is no going back, you
                will lose everything.
              </p>

              <p>
                Once your account is deactivated we anonymise your data but we
                keep your activity responses for learning and reasearch
                purposes. If you'd like your account and data to be deleted
                completely please email {supportEmail}
              </p>

              {!!errorDeactivate && (
                <Notification type="red">
                  {extractGraphQLError(errorDeactivate)}
                </Notification>
              )}

              <Button red small onClick={handleShowDeactiveModal}>
                Deactivate account
              </Button>
            </TextContent>
          </Card>
        </Section>
      </Container>

      <Sidebar profile />

      <Modal isVisible={showDeactivateModal} onClose={handleHideDeactiveModal}>
        <Card header="Deactivate account">
          <Form
            fields={deactivateFormFields}
            onSubmit={handleDeactivateAccount}
            loading={loadingDeactivate}
            error={!!errorDeactivate}
            errorMessage="There has been a problem deactivating your account"
            submitButtonText="Deactivate account"
            container
            renderAfterFields={() => (
              <TextContent marginBottom redh6>
                <h6>
                  Are you really sure you want to deactivate your account? There
                  is no going back, you will lose everything.
                </h6>
              </TextContent>
            )}
          />
        </Card>
      </Modal>
    </Layout>
  );
}

Profile.propTypes = {};

Profile.defaultProps = {};

export default Profile;
