import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from './Button';
import TextContent from './TextContent';
import ImageLoader from './ImageLoader';

import { extractPrismicContent, extractPrismicString } from '../utils/prismic';

const Wrapper = styled.div`
  margin-bottom: 30px;
  text-align: left;
`;

const ImageWrapper = styled.div`
  width: 129px;
  margin: 0 0 30px;
`;

function Profile({ title, text, image, actionText, actionOnClick }) {
  return (
    <Wrapper>
      <ImageWrapper>
        <ImageLoader imageUrl={image} circle square />
      </ImageWrapper>

      <TextContent marginBottom maxHeight={240}>
        <h4>{extractPrismicString(title)}</h4>

        {extractPrismicContent(text)}
      </TextContent>

      {!!(actionText && actionOnClick) && (
        <Button onClick={actionOnClick}>{actionText}</Button>
      )}
    </Wrapper>
  );
}

Profile.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  text: PropTypes.shape({}).isRequired,
  actionText: PropTypes.string,
  actionOnClick: PropTypes.func,
};

Profile.defaultProps = {
  actionText: null,
  actionOnClick: undefined,
};

export default Profile;
