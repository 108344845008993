import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import Layout from '../components/Layout';
import Container from '../components/Container';
import Section from '../components/Section';
import TextContent from '../components/TextContent';
import ProductList from '../components/ProductList';
import Grid from '../components/Grid';
import GridItem from '../components/GridItem';
import Profile from '../components/Profile';

import { GET_CMS_CONTENT } from '../graph/cms';
import { GET_PRODUCTS } from '../graph/payment';
import { GET_CURRENCY } from '../graph/currency';

import {
  extractPrismicContent,
  extractPrismicString,
  hasContent,
} from '../utils/prismic';

function Coaching() {
  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data, loading } = useQuery(GET_PRODUCTS, {
    variables: { type: 'product' },
    fetchPolicy: 'network-only',
  });

  const {
    data: { currency },
  } = useQuery(GET_CURRENCY);

  const {
    title,
    sub_title: subTitle,
    hero_text: heroText,
    content1,
    content2,
  } = cmsData?.cmsContent?.coaching || {};

  return (
    <Layout
      title={extractPrismicString(title)}
      loading={loading}
      hero={{
        title: (
          <>
            {extractPrismicString(title)} <br />
            <span>{extractPrismicString(subTitle)}</span>
          </>
        ),
        subTitle: extractPrismicString(heroText),
      }}
    >
      <Section white>
        <Container narrow>
          {hasContent(content1) && (
            <TextContent redh6>{extractPrismicContent(content1)}</TextContent>
          )}

          <Section noPaddingBottom>
            <Grid>
              {cmsData?.cmsContent?.coaches?.map(({ name, text, image }) => (
                <GridItem width={50} alignTop>
                  <Profile title={name} text={text} image={image?.url} />
                </GridItem>
              ))}
            </Grid>
          </Section>
        </Container>
      </Section>

      <Section>
        <Container narrow>
          <ProductList
            products={data?.products}
            cmsData={cmsData?.cmsContent?.products}
            currency={currency}
          />
        </Container>
      </Section>

      <Section white>
        <Container narrow>
          {hasContent(content2) && (
            <TextContent redh6>{extractPrismicContent(content2)}</TextContent>
          )}
        </Container>
      </Section>
    </Layout>
  );
}

export default Coaching;
