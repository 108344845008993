import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from './Button';
import Container from './Container';
import Portal from './Portal';
import Tag from './Tag';

const Bar = styled.div`
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.base.shadow};
  z-index: ${props => props.theme.layers.footerBar};

  ${props => props.theme.breakpoints.medium`
    position: fixed;
  `}
`;

const Inner = styled.div`
  padding: ${props => props.theme.spacing.base} 0;

  ${props => props.theme.breakpoints.medium`
    display: flex;
    align-items: center;
    padding: 0;
    height: ${props.theme.components.footerBar};
  `}
`;

const Text = styled.div`
  margin-right: auto;

  h4,
  p {
    margin-bottom: 0;
  }

  h4 {
    display: flex;
    align-items: center;
  }
`;

const ButtonContainer = styled.div`
  flex: 0 0 auto;
  width: 100%;

  ${props => props.theme.breakpoints.medium`
    display: flex;
    flex-direction: row-reverse;
  `}

  button,
  a {
    margin-top: ${props => props.theme.spacing.base};
  }

  ${props => props.theme.breakpoints.medium`
    width: auto;

    button,
    a {
      margin-top: 0;
      margin-left: ${props.theme.spacing.base};
    }
  `}
`;

function FooterBar({
  title,
  subTitle,
  primaryButton,
  secondaryButton,
  isNew,
}) {
  return (
    <Portal>
      <Bar>
        <Container>
          <Inner>
            <Text>
              <h4>
                {isNew && <Tag marginRight>New</Tag>} 
                {' '}
                {title}
              </h4>
              <p>{subTitle}</p>
            </Text>

            <ButtonContainer>
              {primaryButton && (
                <Button small {...primaryButton}>
                  {primaryButton.label}
                </Button>
              )}

              {secondaryButton && (
              <Button small secondary {...secondaryButton}>
                {secondaryButton.label}
              </Button>
              )}
            </ButtonContainer>
          </Inner>
        </Container>
      </Bar>
    </Portal>
  );
}

FooterBar.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  primaryButton: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }),
  secondaryButton: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }),
  isNew: PropTypes.bool,
};

FooterBar.defaultProps = {
  primaryButton: undefined,
  secondaryButton: undefined,
  isNew: false,
};

export default FooterBar;
