import React from 'react';
import styled from 'styled-components';

import Container from './Container';
import TextContentMarketing from './TextContentMarketing';
import ImageLoader from './ImageLoader';

import backgroundImageBase from '../theme/backgroundImageBase';

import {
  extractPrismicString,
  hasContent,
  extractPrismicContent,
} from '../utils/prismic';

const Wrapper = styled.div`
  ${backgroundImageBase};
  color: ${props => props.theme.colors.white};

  h2 {
    font-family: ${props => props.theme.base.altFontFamily};
    font-weight: ${props => props.theme.fontWeights.normal};
    font-size: ${props => props.theme.fontSizes.xxLarge};
    line-height: 1.1;
    color: ${props => props.theme.colors.white};

    ${props => props.theme.breakpoints.medium`
      font-size: ${props.theme.fontSizes.xxxxLarge};
    `}
  }

  ${props =>
    props.backgroundImage &&
    `
     background-image: url(${props.backgroundImage});
  `}
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spacing.large} 0;
`;

const Image = styled.image`
  border-radius: 100%;
  overflow: hidden;
  width: 80px;
  height: 80px;
  margin-right: 20px;

  ${props => props.theme.breakpoints.medium`
    width: 150px;
    height: 150px;
    margin-right: 40px;
  `}
`;

function MarketingTitleBanner({
  title_banner_title: title,
  title_banner_image: image,
  title_banner_text: text,
  backgroundImage,
}) {
  return (
    <Wrapper backgroundImage={backgroundImage}>
      <Container>
        <Inner>
          {!!(image && image.url) && (
            <Image>
              <ImageLoader
                square
                imageUrl={image.url}
                alt={extractPrismicString(title)}
              />
            </Image>
          )}
          <TextContentMarketing>
            {hasContent(title) && <h2>{extractPrismicString(title)}</h2>}
            {hasContent(text) && extractPrismicContent(text)}
          </TextContentMarketing>
        </Inner>
      </Container>
    </Wrapper>
  );
}

MarketingTitleBanner.propTypes = {};

export default MarketingTitleBanner;
